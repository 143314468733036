import { useEffect, useState } from "react";
import { InventoryStatusValues } from "../../common/Constants";

const CellInventoryStatusBadge: React.FC<{ data: any, data_key: string }> = ({ data, data_key }) => {

    const [statusClass, setStatusClass] = useState('');
    const [statusString, setStatusString] = useState('');

    useEffect(() => {
        if (data !== undefined && data[data_key] !== undefined) {
            if (data[data_key] == InventoryStatusValues.ALLOCATED) {
                setStatusClass("badge-soft-danger");
                setStatusString("Allocated");
            } else if (data[data_key] == InventoryStatusValues.UNALLOCATED) {
                setStatusClass("unallocated");
                setStatusString("Unallocated");
            } else if (data[data_key] == InventoryStatusValues.UNTRANSIT) {
                setStatusClass("badge-soft-warning");
                setStatusString("Untransit");
            } else if (data[data_key] == InventoryStatusValues.ORDERED) {
                setStatusClass("ordered");
                setStatusString("Ordered");
            } else if (data[data_key] == InventoryStatusValues.INSTOCK) {
                setStatusClass("badge-soft-info");
                setStatusString("InStock");
            } else if (data[data_key] == InventoryStatusValues.INSTOCKUA) {
                setStatusClass("badge-soft-info");
                setStatusString("InStock (UA)");
            }
            else if (data[data_key] == InventoryStatusValues.SOLD) {
                setStatusClass("badge-soft-success");
                setStatusString("Sold");
            }
        }
    }, [data]);

    return (
        <span className={'badge ' + statusClass}>{statusString}</span>
    )

}

export default CellInventoryStatusBadge;