import { Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { API, ProjectStrings, uploadImageType } from "../common/Constants";
import { getDataFromAPI, frameSelectOptions } from "../common/Utilities";


const DemoCarEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, }> = ({ showDialog, closeDialog, reloadData, id }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.DemoCar>>(null);
    const [initialValue, setInitialValue] = useState<APIData.DemoCar>(InitialData.DemoCar);
    const [formDataSet, setFormDataSet] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [showroomOptions, setShowroomOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [modelOptions, setModelOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [variantOptions, setVariantOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [colorOptions, setColorOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [fuelOptions, setFuelOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [tranmissionOptions, setTranmissionOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedModelID, setSelectedModelID] = useState('');
    const [selectedVariantID, setSelectedVariantID] = useState('');

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.ShowroomList, setShowroomOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'showroom_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.ModelList, setModelOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'model_name', 'data', 'list');
    }, []);

    useEffect(() => {
        if (id !== undefined && id !== '') {
            addProcessingRequests();
            ServerAPI.getDemoCar(id).then(response => {
                if (response && response.data && response.data.details) {
                    setInitialValue(response.data.details);
                    setFormDataSet(true);
                    setEditMode(true);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else {
            setFormDataSet(true);
        }
    }, [id]);

    const loadColors = () => {
        addProcessingRequests();
        ServerAPI.getModelVariantColors(selectedVariantID).then(response => {
            if (response && response.data) {
                let options = frameSelectOptions(response.data, 'color_uuid', 'interior_color_name', ['exterior_color_name'], ' [int] + ', ' [ext]');
                setColorOptions(options);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    useEffect(() => {
        if (selectedModelID) {
            let params: { [k: string]: any } = {};
            params["model_uuid"] = selectedModelID;
            params["limit"] = 10000;
            params["status"] = 1;
            getDataFromAPI(API.EndPoint.ModelVariantList, setVariantOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'variant_name', 'data', 'list');
        }
    }, [selectedModelID]);

    useEffect(() => {
        if (selectedVariantID) {
            loadColors();
            let params: { [k: string]: any } = {};
            params["uuid"] = selectedVariantID;
            params["status"] = 1;
            getDataFromAPI(API.EndPoint.ModelVariantFuelList, setFuelOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'fuel_uuid', 'fuel_name');
            getDataFromAPI(API.EndPoint.ModelVariantTransmissionList, setTranmissionOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'transmission_type_uuid', 'transmission_type_name');
        }
    }, [selectedVariantID]);

    const yupSchema = Yup.object().shape({
        showroom_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        car_tc_number: Yup.string().required(ProjectStrings.ValidationRequired),
        car_vin_number: Yup.string().required(ProjectStrings.ValidationRequired),
        car_model_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        car_variant_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        car_color_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        car_fuel_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        car_transmission_type_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        car_manufacture_year: Yup.number().typeError('Must be a Number') // Error message for non-number values
            .integer('Not a valid Year') // Error message for non-integer numbers
            .test(
                'is-4-digit-number',
                'You must enter a 4 digit number', // Error message for this test
                number => {
                    if (number !== null && number !== undefined) {
                        return number.toString().length === 4;
                    }
                    return false;
                } // The actual test function
            )
            .required(ProjectStrings.ValidationRequired),
    });

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-fit">
            {formDataSet && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };
                    if (id != undefined && id != '') {
                        if (initialValue.car_image_url === submitValues['car_image_url']) {
                            delete submitValues['car_image_url'];
                        }
                        addProcessingRequests();
                        ServerAPI.updateDemoCar(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            } else {
                                toast.error('Unknown Error Occurred');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    } else {
                        addProcessingRequests();
                        ServerAPI.addDemoCar(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            } else {
                                toast.error('Unknown Error Occurred');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    }
                }} >
                    {(formikProps) => {
                        if (formikProps.values.car_model_uuid !== selectedModelID) {
                            setSelectedModelID(formikProps.values.car_model_uuid);
                        }
                        if (formikProps.values.car_variant_uuid !== selectedVariantID) {
                            setSelectedVariantID(formikProps.values.car_variant_uuid);
                        }
                        return (
                            <div className="tabdetails">
                                <h4 className="title-text text-center">{editMode ? 'Edit' : 'Add New'} Demo Car</h4>
                                <div className="custom-border-grey my-2"></div>
                                <p className="detailed-heading">Demo Car Details</p>
                                <div className="tabpage">
                                    <div className="custom-border-grey my-2"></div>
                                    <div className="tab-details-section">
                                        <Form>
                                            <div className="px-2 form-field-container">
                                                <div className="row px-2 py-2">
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", name: "car_tc_number", label: "Car TC Number", placeholder: "Car TC Number", required: true }} />
                                                    </div>
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", name: "car_vin_number", label: "Car VIN Number", placeholder: "Car VIN Number", required: true }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-2">
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                        {/* <label className="label-custom">Showroom Name *</label> */}
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "showroom_uuid", label: "Showroom", placeholder: "Showroom", required: true, options: showroomOptions }} />
                                                    </div>
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                        {/* <label className="label-custom">Model *</label> */}
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "car_model_uuid", label: "Model", placeholder: "Model", required: true, options: modelOptions }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-2">
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                        {/* <label className="label-custom">Variant *</label> */}
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "car_variant_uuid", label: "Variant", placeholder: "Variant", required: true, options: variantOptions }} />
                                                    </div>
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                        {/* <label className="label-custom">Color *</label> */}
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "car_color_uuid", label: "Color", placeholder: "Color", required: true, options: colorOptions }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-2">
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                        {/* <label className="label-custom">Fuel Type *</label> */}
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "car_fuel_uuid", required: true, label: "Fuel Type", placeholder: "Fuel Type", options: fuelOptions }} />
                                                    </div>
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                        {/* <label className="label-custom">Transmission Type *</label> */}
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "car_transmission_type_uuid", required: true, label: "Tranmission Type", placeholder: "Tranmission Type", options: tranmissionOptions }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-2">
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", name: "car_manufacture_year", label: "Manufacture Year", placeholder: "Manufacture Year", required: true }} />
                                                    </div>
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "fileupload", name: "car_image_url", label: "Vehicle Image", placeholder: "Vehicle Image", accept: "image/*", imageType: uploadImageType.DEMO_VEHICLE }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-2">
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "switch", label: "Status", name: "status" }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row btn-form-submit">
                                                <button type="button" className="button1" onClick={closeDialog}>Cancel</button>
                                                <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                    formikProps.submitForm();
                                                    if (!formikProps.isValid) {
                                                        toast.error("Please ensure all fields are Filled and Valid!");
                                                    }
                                                }}>Submit</button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                </Formik>
            )
            }
        </Drawer>
    )
}

export default DemoCarEntryDialog;