import { Drawer } from "@mui/material";
import { useRef, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData } from "../common/DataTypes";
import FormField from "../components/form_items/FormField";
import { ProjectStrings } from "../common/Constants";

const TestDriveCancelDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, testDriveUUID: string, }> = ({ showDialog, closeDialog, reloadData, testDriveUUID }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.TestDriveCancel>>(null);
    const [initialValue, setInitialValue] = useState<APIData.TestDriveCancel>({ test_drive_uuid: '', remarks: '' });

    const yupSchema = Yup.object().shape({
        remarks: Yup.string().required(ProjectStrings.ValidationRequired),
    });

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-min">
            <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                let submitValues: any = { ...values };
                submitValues.test_drive_uuid = testDriveUUID;
                addProcessingRequests();
                ServerAPI.cancelTestDrive(submitValues).then(response => {
                    if (response && response['success'] && response['message']) {
                        toast.success(response['message']);
                        reloadData();
                        closeDialog();
                    } else if (response && response['message']) {
                        toast.error(response['message']);
                    } else {
                        toast.error('Unknown Error Occurred');
                    }
                }).finally(() => {
                    setSubmitting(false);
                    reduceProcessingRequests();
                });

            }} >
                {(formikProps) => {
                    return (
                        <div className="tabdetails">
                            <h4 className="title-text text-center">Cancel Test Drive</h4>
                            <div className="custom-border-grey my-2"></div>
                            <Form>
                                <div className="px-2 form-field-container">
                                    <div className="row px-2 py-3">
                                        <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom">
                                            {/* <label className="label-custom">State Name</label> */}
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textarea", label: "Remarks", name: "remarks", placeholder: "Remarks", required: true }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row btn-form-submit">
                                    <button type="button" className="button1" onClick={closeDialog}>Cancel</button>
                                    <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                        formikProps.submitForm();
                                        if (!formikProps.isValid) {
                                            toast.error("Please ensure all fields are Filled and Valid!");
                                        }
                                    }}>Submit</button>
                                </div>
                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </Drawer>
    )
}

export default TestDriveCancelDialog;