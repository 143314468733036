import { Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { FieldArray, Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { API, ProjectStrings } from "../common/Constants";
import { getDataFromAPI } from "../common/Utilities";


const ModelVaraintEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, copyID?: string, }> = ({ showDialog, closeDialog, reloadData, id, copyID }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.ModelVariant>>(null);
    const [initialValue, setInitialValue] = useState<APIData.ModelVariant>(InitialData.ModelVariant);
    const [formDataSet, setFormDataSet] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [modelOptions, setModelOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [fuelTypeOptions, setFuelTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [transmissionTypeOptions, setTrasmissionTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [interiorColorOptions, setInteriorColorOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [exteriorColorOptions, setExteriorColorOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [formIsValid, setFormIsValid] = useState(false);

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.ModelList, setModelOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'model_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.FuelTypeList, setFuelTypeOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'fuel_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.TransmissionTypeList, setTrasmissionTypeOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'transmission_type_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.InteriorColorList, setInteriorColorOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'interior_color_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.ExteriorColorList, setExteriorColorOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'exterior_color_name', 'data', 'list');
    }, []);

    useEffect(() => {
        if (id) {
            fetchVariantData(id);
        } else if (copyID) {
            fetchVariantData(copyID, true);
        } else {
            setFormDataSet(true);
        }
    }, [id, copyID]);

    const fetchVariantData = (fetchID: string, copy?: boolean) => {
        addProcessingRequests();
        ServerAPI.getModelVariant(fetchID).then(response => {
            if (response && response.data && response.data.details) {
                const variantDetail = response.data.details;
                if (response.data.details.colors && response.data.details.colors.length > 0) {
                    variantDetail.color = [...response.data.details.colors].map(color => ({ interior_uuid: color.interior_uuid, exterior_uuid: color.exterior_uuid }));
                }
                delete variantDetail.colors;
                if (copy) {
                    variantDetail.uuid = '';
                    variantDetail.variant_name = '';
                    variantDetail.variant_code = '';
                }
                setInitialValue(response.data.details);
                setFormDataSet(true);
                setEditMode(true);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const yupSchema = Yup.object().shape({
        model_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        variant_name: Yup.string().required(ProjectStrings.ValidationRequired),
        variant_code: Yup.string().required(ProjectStrings.ValidationRequired),
        fuel_type_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        transmission_type_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        color: Yup.array()
            .of(
                Yup.object().shape({
                    interior_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
                    exterior_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
                })
            ),
    });


    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-fit">
            {formDataSet && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    setFormIsValid(true);
                    let submitValues: any = { ...values };
                    if (id != undefined && id != '') {
                        addProcessingRequests();
                        ServerAPI.updateModelVariant(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            } else {
                                toast.error('Unknown Error Occurred');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    } else {
                        addProcessingRequests();
                        ServerAPI.addModelVariant(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            } else {
                                toast.error('Unknown Error Occurred');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    }
                }} >
                    {(formikProps) => {
                        return (
                            <div className="tabdetails">
                                <h4 className="title-text text-center">{editMode ? 'Edit' : 'Add New'} Model Variant</h4>
                                <div className="custom-border-grey my-2"></div>
                                <p className="detailed-heading">Model Variant Details</p>
                                <div className="custom-border-grey my-2"></div>
                                <Form>
                                    <div className="px-2 form-field-container">
                                        <div className="row px-3 py-3" style={{ width: "99%" }}>
                                            <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user">
                                                {/* <label className="label-custom">Model</label> */}
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Model", name: "model_uuid", placeholder: "Model", required: true, options: modelOptions }} />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user" style={{ marginTop: "-3px" }}>
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Model Variant Name", name: "variant_name", placeholder: "Model Variant Name", required: true }} />
                                            </div>
                                        </div>
                                        <div className="row px-3 py-3" style={{ width: "99%" }}>
                                            <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user" style={{ marginTop: "-3px" }}>
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Model Variant Code", name: "variant_code", placeholder: "Model Variant Code", required: true }} />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user">
                                                {/* <label className="label-custom">Fuel Type</label> */}
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Fuel Type", name: "fuel_type_uuid", placeholder: "Fuel Type", required: true, options: fuelTypeOptions }} />
                                            </div>
                                        </div>
                                        <div className="row px-3 py-3" style={{ width: "99%" }}>
                                            <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user">
                                                {/* <label className="label-custom">Transmission Type</label> */}
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Transmission Type", name: "transmission_type_uuid", placeholder: "Transmission Type", required: true, options: transmissionTypeOptions }} />
                                            </div>
                                        </div>

                                        <div className="px-3">
                                            <FieldArray name="color">
                                                {({ insert, remove, push }) => (
                                                    <div className='individual_sub_content'>
                                                        <h4>Colors</h4>
                                                        <button type="button" className="mb-2 mr-2 btn" style={{ backgroundColor: "#14408b", color: "#FFF" }} onClick={() => push(InitialData.ModelVariantColor)}>Add Color</button>
                                                        {formikProps.values.color != undefined && formikProps.values.color.length > 0 &&
                                                            <>
                                                                {formikProps.values.color.map((data, index) => {
                                                                    const uniqueKey = `${index}-${data.interior_uuid}-${data.exterior_uuid}`;
                                                                    return (
                                                                        <div key={uniqueKey} className="row py-3">
                                                                            <div className="col-lg-5 col-md-5 col-xl-5 text-field-empty-custom-user">
                                                                                {/* <label className="label-custom">Interior Color</label> */}
                                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Interior Color", name: `color.${index}.interior_uuid`, placeholder: "Interior Color", required: true, options: interiorColorOptions }} />
                                                                            </div>
                                                                            <div className="col-lg-5 col-md-5 col-xl-5 text-field-empty-custom-user">
                                                                                {/* <label className="label-custom">Exterior Color</label> */}
                                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Exterior Color", name: `color.${index}.exterior_uuid`, placeholder: "Exterior Color", required: true, options: exteriorColorOptions }} />
                                                                            </div>
                                                                            <div className="col-lg-2 col-md-2 col-xl-2 text-field-empty-custom-user">
                                                                                <button type="button" className={`btn btn-sm ml-1`} style={{ backgroundColor: "#f14b4b" }} onClick={() => remove(index)}><i className="text-white fas fa-trash pr-1 pl-1"></i></button>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </>
                                                        }
                                                    </div>
                                                )}
                                            </FieldArray>
                                        </div>

                                        <div className="row px-3 pt-3" style={{ width: "99%" }}>
                                            <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "switch", label: "Status", name: "variant_status" }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row btn-form-submit">
                                        <button type="button" className="button1" onClick={closeDialog}>Cancel</button>
                                        <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                            setFormIsValid(false);
                                            formikProps.submitForm();
                                            setTimeout(() => {
                                                //SetTimeout will always return old value for a state. So we are fetching the new value using the setter function.
                                                setFormIsValid(previousIsValid => {
                                                    if (!previousIsValid) {
                                                        toast.error("Please ensure all fields are Filled and Valid!");
                                                    }
                                                    return previousIsValid; // Return the same state to avoid changing it
                                                });
                                            }, 500);
                                        }}>Submit</button>
                                    </div>
                                </Form>
                            </div>
                        )
                    }}
                </Formik>
            )}
        </Drawer>
    )
}

export default ModelVaraintEntryDialog;