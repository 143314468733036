import { Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { API, LeadLocationNames, PageLinks, ProjectStrings } from "../common/Constants";
import PlanningNextTaskDialog from "./PlanningNextTaskDialog";
import { getDataFromAPI } from "../common/Utilities";
import BookaCarEntryDialog from "./BookaCarEntryDialog";
import LostDropEntryDialog from "./LostDropEntryDialog";
import { useNavigate } from "react-router-dom";
import UserTestdriveEntryDialog from "./userTestdriveEntryDialog";




const LeadTaskUpdateDialog: React.FC<{ showDialog: boolean, closeDialog: any, updateLead: any, leadQualify: number, id?: string, q1Heading: string, pageTitle: string, details?: APIData.LeadDetail, isVisit: boolean, leadTaskUUID?: string, leadUUID?: string, testdriveUUID?: string, leadFollowupUUID?: string }> = ({ showDialog, closeDialog, updateLead, id, q1Heading, pageTitle, details, isVisit, leadTaskUUID, leadUUID, leadQualify, testdriveUUID, leadFollowupUUID }) => {
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.UpdateLeadFollowUp>>(null);
    const [initialValue, setInitialValue] = useState<APIData.UpdateLeadFollowUp>(InitialData.UpdateLeadFollowUp);
    const [formDataSet, setFormDataSet] = useState(false);
    const [speakToCustomerYes, setSpeakToCustomerYes] = useState(true);
    const [speakToCustomerNo, setSpeakToCustomerNo] = useState(false);
    const [testdriveGiven, setTestdriveGiven] = useState(false);
    const [selectedLeadProspectType, setSelectedLeadProspectType] = useState<APIData.LeadProspectType>();
    const [taskReschedule, setTaskReschedule] = useState(false);
    const [visitCustomer, setVisitCustomer] = useState(false);
    const leadUpdateRef = useRef<HTMLDivElement | null>(null);

    const [leadReasonOptions, setLeadReasonOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [leadProspectTypes, setLeadProspectTypes] = useState<APIData.LeadProspectType[]>([]);


    const [testdrivePopup, setTestdrivePopup] = useState(false);
    const [testdriveID, setTestdriveID] = useState("");
    const [planPopup, setPlanPopup] = useState(false);
    const [resetState, setResetState] = useState(false);
    const [selectedLocationName, setSelectedLocationName] = useState('');
    const [selectedLocationUUID, setSelectedLocationUUID] = useState('');
    const [leadTestdrivelocation, setLeadTestdrivelocation] = useState<APIData.LeadTestDriveLocation[]>([]);
    const [remarks, setRemarks] = useState('');
    const [dateTime, setDateTime] = useState('');
    const [reason, setReason] = useState('');
    const [updateSubmitValues, setUpdateSubmitvalues] = useState<APIData.UpdateLeadFollowUp>();
    const [planHeading, setPlanHeading] = useState('');
    const [enableOptions, setEnableOptions] = useState(leadQualify !== undefined ? leadQualify !== 0 : false);
    const [bookaCar, setBookaCar] = useState(false);
    const [lostDrop, setLostDrop] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const navigate = useNavigate();
    console.log(testdriveUUID, "testdriveuuid");

    const handlePlanNextDialog = () => {
        let planHeadingText = '';
        let planPopupStatus = false;

        switch (selectedOption) {
            case 'followUp':
                planHeadingText = "Follow-up Call";
                planPopupStatus = true;
                break;
            case 'testDrive':
                if (selectedLocationName !== "" && selectedLocationUUID !== "") {
                    planHeadingText = selectedLocationName === LeadLocationNames.Home ? `Test Drive ${selectedLocationName}` : `Test Drive ${selectedLocationName}`;
                }
                // planHeadingText = selectedLocationName === LeadLocationNames.Home ? "Test Drive Home" : "Test Drive Showroom";
                planPopupStatus = true;
                break;
            case 'visit':
                if (selectedLocationName !== "" && selectedLocationUUID !== "") {
                    planHeadingText = selectedLocationName === LeadLocationNames.Home ? `Visit ${selectedLocationName}` : `Visit ${selectedLocationName}`;
                }
                planPopupStatus = true;
                break;
            case 'bookaCar':
                setBookaCar(true);
                break;
            case 'lostDrop':
                setLostDrop(true);
                break;
            default:
                break;
        }

        setPlanHeading(planHeadingText);
        setPlanPopup(planPopupStatus);

    };

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>, option: string) => {
        setSelectedOption(event.target.checked ? option : '');
        setSelectedLocationName('');
        setSelectedLocationUUID('');
    };

    const handleLocationRadioChange = (locationName: string, locationUUID: string) => {
        setSelectedLocationName(locationName);
        setSelectedLocationUUID(locationUUID);
        // handlePlanNextDialog()
    };

    useEffect(() => {
        if (resetState) {
            // setBookaCar(false);
            setTaskReschedule(false);
            setSpeakToCustomerYes(true);
            setSpeakToCustomerNo(false);
            setRemarks("");
            setSelectedOption('');
            setSelectedLocationName('');
            setSelectedLocationUUID('');
            setSelectedLeadProspectType(leadProspectTypes[0]);
            setResetState(false);
        }
    }, [resetState]);


    const handleRemarksChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setRemarks(event.target.value);
    };

    const handleSpeakCustomerNo = () => {
        setSpeakToCustomerNo(true);
        setVisitCustomer(false);
        if (pageTitle === 'Updating a Test Drive') {
            setSpeakToCustomerYes(!speakToCustomerYes);
        }
    }

    const handleVisityes = () => {
        setVisitCustomer(true)
    }
    const handleSpeakCustomerYes = () => {
        setSpeakToCustomerYes(true);
        setSpeakToCustomerNo(false);
        setTaskReschedule(false);

    }
    const handletaskReschedule = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTaskReschedule(event.target.checked);
        setSpeakToCustomerYes(!speakToCustomerYes);
    }
    const handleLeadQualifyButtonClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked && enableOptions === false) {
            setEnableOptions(true);
        }
    }

    useEffect(() => {
        if (leadProspectTypes.length > 0) {
            setSelectedLeadProspectType(leadProspectTypes[0]);
        }
    }, [leadProspectTypes]);

    useEffect(() => {
        if (id !== undefined && id !== '') {
            addProcessingRequests();
            ServerAPI.getTransmissionType(id).then(response => {
                if (response && response.data && response.data.details) {
                    setInitialValue(response.data.details);
                    setFormDataSet(true);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else {
            setFormDataSet(true);
        }
    }, [id]);

    useEffect(() => {
        setEnableOptions(leadQualify !== 0);
    }, [leadQualify]);

    useEffect(() => {
        getDataFromAPI(API.EndPoint.LeadReasonList, setLeadReasonOptions, addProcessingRequests, reduceProcessingRequests, { lead_task_uuid: leadTaskUUID }, true, 'uuid', 'lead_reason_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.LeadProspectTypeList, setLeadProspectTypes, addProcessingRequests, reduceProcessingRequests, null, false, '', '', 'data', 'list');
        getDataFromAPI(API.EndPoint.LeadTestDriveLocation, setLeadTestdrivelocation, addProcessingRequests, reduceProcessingRequests, null, false, '', '', 'data');
        setFormDataSet(true);
        handleSpeakCustomerYes();
    }, []);



    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (leadUpdateRef.current && !leadUpdateRef.current.contains(event.target as Node)) {
                if (showDialog === true && planPopup === false && bookaCar === false && lostDrop === false) {
                    setResetState(true);
                }
            }
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [showDialog, planPopup, bookaCar, lostDrop]);


    const getLeadProspectTypeSelectedClass = (prospectType: string) => {
        if (prospectType === "Hot") {
            return 'active';
        } else if (prospectType === "Warm") {
            return 'active-1';
        } else if (prospectType === "Cold") {
            return 'active-2';
        }
    }

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-min" style={{ marginBottom: "50px" }} >
            {formDataSet && (
                <Formik enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };
                    submitValues['did_you_speak_to_the_customer'] = speakToCustomerYes === true ? 1 : speakToCustomerNo === true ? 0 : 0;
                    submitValues['did_you_meet_the_customer'] = speakToCustomerYes === true ? 1 : speakToCustomerNo === true ? 0 : 0;
                    submitValues['reschedule_this_task'] = taskReschedule === true ? 1 : 0;
                    submitValues['lead_status_uuid'] = (selectedLeadProspectType !== undefined) ? selectedLeadProspectType?.uuid : '';
                    submitValues['lead_uuid'] = leadUUID;
                    submitValues['lead_followup_remarks'] = remarks;
                    submitValues['lead_followup_uuid'] = leadFollowupUUID;
                    submitValues['was_the_customer_given_testdrive'] = isVisit ? testdriveGiven === true ? "1" : "0" : speakToCustomerYes === true ? 1 : speakToCustomerNo === true ? 0 : 0;

                    if (!submitValues['lead_status_uuid']) {
                        toast.error("Lead status UUID is required");
                        setSubmitting(false);
                        return;
                    }

                    if (!submitValues['lead_uuid']) {
                        toast.error("Lead UUID is required");
                        setSubmitting(false);
                        return;
                    }

                    if (!submitValues['lead_followup_uuid'] && pageTitle === 'Updating a Test Drive') {
                        toast.error("Lead Followup UUID is required");
                        setSubmitting(false);
                        return;
                    }

                    if (!submitValues['lead_followup_remarks'] && pageTitle !== 'Updating a Test Drive') {
                        toast.error("Lead follow-up remarks are required");
                        setSubmitting(false);
                        return;
                    }

                    // if (!submitValues['did_you_speak_to_the_customer']) {
                    //     toast.error("Did you speak to the customer is required");
                    //     setSubmitting(false);
                    //     return;
                    // }

                    if (parseInt(submitValues['reschedule_this_task']) === 1) {
                        if (!submitValues['reschedule_date_time']) {
                            toast.error("Rescheduled Date time is Required")
                            setSubmitting(false);
                            return
                        }
                    }
                    if (parseInt(submitValues['reschedule_this_task']) === 0 && pageTitle !== 'Updating a Test Drive') {
                        if (selectedOption === "") {
                            toast.error("Please select any planning options")
                            setSubmitting(false);
                            return
                        }
                    }
                    if (parseInt(submitValues['did_you_speak_to_the_customer']) === 0 && pageTitle !== 'Updating a Test Drive') {
                        if ((!submitValues['lead_reason_uuid'] || submitValues['lead_reason_uuid'] === '')) {
                            toast.error("Reason is Required")
                            setSubmitting(false);
                            return
                        }
                    }

                    if ((selectedOption === 'testDrive' || selectedOption === 'visit') && parseInt(submitValues['reschedule_this_task']) === 0 && pageTitle !== 'Updating a Test Drive') {
                        if (selectedLocationName === "" && selectedLocationUUID === "") {
                            toast.error("Please select a location option (Home or Showroom) before submitting.");
                            setSubmitting(false);
                            return;
                        }
                    }


                    // submitValues['reschedule_date_time'] = submitValues['reschedule_date_time'] + ' 00:00:00';
                    setUpdateSubmitvalues(submitValues)
                    if (speakToCustomerNo && pageTitle === 'Updating a Test Drive') {
                        addProcessingRequests();
                        ServerAPI.LeadRescheduleTestdrive(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                updateLead();
                                handleSpeakCustomerYes();
                                setRemarks("");
                                setSelectedLeadProspectType(leadProspectTypes[0]);
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            }
                        }).finally(() => {
                            reduceProcessingRequests();
                            setSubmitting(false);
                        });
                    } else if (taskReschedule && pageTitle !== 'Updating a Test Drive') {
                        addProcessingRequests();
                        ServerAPI.LeadUpdateFollowUp(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                updateLead();
                                handleSpeakCustomerYes();
                                setRemarks("");
                                setSelectedLeadProspectType(leadProspectTypes[0]);
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            }
                        }).finally(() => {
                            reduceProcessingRequests();
                            setSubmitting(false);
                        });
                    }
                    else {
                        handlePlanNextDialog();
                        setUpdateSubmitvalues(submitValues);
                        setSubmitting(false);
                    }
                }
                } >
                    {(formikProps) => {
                        const handleRescheduleButtonClick = () => {
                            if (formikProps.isValid) {
                                formikProps.submitForm();
                            } else {
                                toast.error("Please ensure all fields are filled and valid!");
                            }
                        };

                        const handlePlanNextButtonClick = () => {
                            if (formikProps.values.reschedule_this_task === 0) {
                                if (formikProps.isValid) {
                                    formikProps.submitForm();
                                    // handlePlanNextDialog();
                                } else {
                                    toast.error("Please ensure all fields are filled and valid!");
                                }
                            }
                        };

                        if (formikProps.values.lead_reason_uuid && formikProps.values.lead_reason_uuid !== reason) {
                            setReason(formikProps.values.lead_reason_uuid);
                        }
                        if (formikProps.values.reschedule_date_time && formikProps.values.reschedule_date_time !== dateTime) {
                            setDateTime(formikProps.values.reschedule_date_time);
                        }
                        return (
                            <>
                                <div className="tabdetails lead-update" ref={leadUpdateRef}>
                                    <h4 className="title-text text-center"> {pageTitle}</h4>
                                    <Form>
                                        <div className="custom-border-grey my-2"></div>
                                        <div className="form-field-container">
                                            <div className="update-popup-container ">
                                                <div className="update-popup-yes-or-no">
                                                    <div><label className="update-form-label"> {q1Heading} <span className="text-danger me-4"> *</span> </label></div>
                                                </div>
                                                <div className="update-popup-yes-or-no">
                                                    <div><label className="update-form-label" ></label></div>
                                                    <div id="radio-btn-color">
                                                        <div className="yes-or-no-container">
                                                            <div className="yes-no-section" onClick={() => { handleSpeakCustomerYes(); { isVisit && handleVisityes() } }}>
                                                                <label htmlFor="yes" className="radio-label">
                                                                    <input type="radio" id="yes" name="did_speak_to_customer" value="YES" defaultChecked />
                                                                    <span className="radio-label"> Yes</span>
                                                                </label>
                                                            </div>
                                                            <div className="yes-no-section" onClick={handleSpeakCustomerNo}>
                                                                <label htmlFor="no" className="radio-label">
                                                                    <input type="radio" id="no" name="did_speak_to_customer" value="NO" />
                                                                    <span className="radio-label"> No</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                {visitCustomer && <>   <div className="pb-0">
                                                    <div className="update-popup-yes-or-no">
                                                        <div><label className="update-form-label"> Was the customer given Test Drive?<span className="text-danger me-4"> *</span> </label></div>
                                                    </div>
                                                    <div className="update-popup-yes-or-no">
                                                        <div id="radio-btn-color">
                                                            <div className="yes-or-no-container">
                                                                <div className="yes-no-section" onClick={() => setTestdriveGiven(true)}>
                                                                    <label htmlFor="meetyes" className="radio-label">
                                                                        <input type="radio" id="meetyes" name="was_the_customer_given_testdrive" value="YES" />
                                                                        <span className="radio-label"> Yes</span>
                                                                    </label>
                                                                </div>
                                                                <div className="yes-no-section" onClick={() => setTestdriveGiven(false)}>
                                                                    <label htmlFor="meetno" className="radio-label">
                                                                        <input type="radio" id="meetno" name="was_the_customer_given_testdrive" value="NO" />
                                                                        <span className="radio-label"> No</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </>}

                                                {speakToCustomerNo && pageTitle !== 'Updating a Test Drive' && <>
                                                    <div className="reschedule-container">
                                                        <div className="update-popup-yes-or-no">
                                                            <div><label className="update-form-label" ></label></div>
                                                            <div className="text-field-empty-custom custom-label">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "lead_reason_uuid", required: true, label: "Reason", placeholder: " Select a reason", options: leadReasonOptions }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="reschedule-container">
                                                        <div className="update-popup-yes-or-no">
                                                            <div><label className="update-form-label" ></label></div>
                                                            <div >
                                                                <input type="checkbox" id="rescheduletask" name="rescheduletask" value="Reschedule this Task" onChange={handletaskReschedule} />
                                                                <label htmlFor="rescheduletask">
                                                                    <span className="update-form-label" style={{ paddingLeft: "7px", cursor: "pointer" }}>Reschedule this Task</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {taskReschedule && <div className="reschedule-container">
                                                        <div className="update-popup-yes-or-no">
                                                            <div><label className="update-form-label" ></label></div>
                                                            <div className="text-field-empty-custom-user custom-label" >
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "datetimepicker", label: "Reschedule Date and Time", name: "reschedule_date_time", placeholder: "Select New Date & Time", required: true, }} />
                                                            </div>
                                                        </div>
                                                    </div>}
                                                </>
                                                }
                                            </div>

                                            {(speakToCustomerYes && pageTitle !== 'Updating a Test Drive' || speakToCustomerNo && pageTitle === 'Updating a Test Drive') && <div className="update-popup-container ">
                                                <div className="update-popup-yes-or-no">
                                                    <div><label className="update-form-label">Lead Status? <span className="text-danger me-4">*</span> </label></div>
                                                </div>
                                                <div className="update-popup-yes-or-no">
                                                    <div><label className="update-form-label" ></label></div>
                                                    <div>
                                                        <div className='toggle-buttons  update-form-lead-status-btn '>
                                                            {leadProspectTypes.map((prospectType, index) => (
                                                                <button
                                                                    type="button"
                                                                    key={index}
                                                                    className={`${selectedLeadProspectType !== undefined && selectedLeadProspectType.uuid === prospectType.uuid ? getLeadProspectTypeSelectedClass(prospectType.prospect_type_name) : 'disable'}`}
                                                                    onClick={(e) => { e.preventDefault(); setSelectedLeadProspectType(prospectType); }}
                                                                >
                                                                    {prospectType.prospect_type_name}
                                                                </button>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}


                                            {(speakToCustomerYes && pageTitle !== 'Updating a Test Drive' || speakToCustomerNo && pageTitle === 'Updating a Test Drive' || taskReschedule) && <div className="update-popup-container ">
                                                <div className="update-popup-yes-or-no">
                                                    <div><label className="update-form-label"> Remarks <span className="text-danger me-4">*</span> </label></div>
                                                </div>
                                                <div className="update-popup-yes-or-no w-100">
                                                    <div><label className="update-form-label" ></label></div>
                                                    <textarea name="" id="update-remark" placeholder="Type your remarks here" value={remarks} onChange={handleRemarksChange}></textarea>
                                                </div>
                                            </div>}

                                            {leadQualify === 0 && pageTitle === 'Updating Follow-up Call' && <div className="reschedule-container mb-2">
                                                <div className="update-popup-yes-or-no">
                                                    <div >
                                                        <input type="checkbox" id="rescheduletask" name="rescheduletask" value="Reschedule this Task" onChange={handleLeadQualifyButtonClick} />
                                                        <label htmlFor="rescheduletask">
                                                            <span className="update-form-label" style={{ paddingLeft: "7px", cursor: "pointer", fontSize: "14px" }}>Lead Qualify</span></label>
                                                    </div>
                                                </div>
                                            </div>}

                                            {speakToCustomerNo && pageTitle === 'Updating a Test Drive' && <>
                                                <div className="reschedule-container">
                                                    <div className="update-popup-yes-or-no">
                                                        <div><label className="update-form-label" ></label></div>
                                                        <div className="text-field-empty-custom-user custom-label" >
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "datetimepicker", label: "Date and Time", name: "date_time", placeholder: "Select New Date & Time", required: true, }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}

                                            {(speakToCustomerYes === true || speakToCustomerNo === true) && taskReschedule === false && pageTitle !== 'Updating a Test Drive' && <div>
                                                <div className="update-plan-filter-1 pb-2">
                                                    <div id="radio-btn-color" className="d-flex align-items-center">
                                                        <input type="radio" id="followup" name="speak_to_customer" value="followUp" onChange={(e) => handleOptionChange(e, 'followUp')} />
                                                        <div><img src="/assets/svg/followup.svg" alt="" /></div>
                                                        <div className="plan-filter-label"><label className="m-0" htmlFor="followup">Follow-Up Call</label></div>
                                                    </div>
                                                </div>
                                                {/* <hr className="m-0" /> */}

                                                {enableOptions && (<><div className="update-plan-filter d-flex flex-direction-column pl-0">
                                                    <div id="radio-btn-color" className="d-flex align-items-center">
                                                        <input type="radio" id="testDrive" name="speak_to_customer" value="testDrive" onChange={(e) => handleOptionChange(e, 'testDrive')} />
                                                        <div><img src="/assets/svg/testdrive-task.svg" /></div>
                                                        <div className="plan-filter-label"><label htmlFor="testDrive">Test Drive</label></div>
                                                    </div>
                                                    {selectedOption === 'testDrive' && <div className="update-popup-yes-or-no">
                                                        <div id="radio-btn-color">
                                                            <div className="yes-or-no-container">
                                                                {leadTestdrivelocation.map((location, index) => (<div className="yes-no-section">
                                                                    <input type="radio" id={index.toString()} name="test_drive_filter" value={index.toString()} onChange={() => handleLocationRadioChange(location.test_drive_location_name, location.uuid)} />
                                                                    <div className="plan-filter-label"><label className="ml-1" htmlFor={index.toString()}>{location.test_drive_location_name}</label></div>
                                                                </div>))}
                                                            </div>
                                                        </div>
                                                    </div>}
                                                </div>
                                                    <div className="update-plan-filter d-flex flex-direction-column pl-0">
                                                        <div id="radio-btn-color" className="d-flex align-items-center">
                                                            <input type="radio" id="visit" name="speak_to_customer" value="visit" onChange={(e) => handleOptionChange(e, 'visit')} />
                                                            <div><img src="/assets/svg/visit.svg" alt="" /></div>
                                                            <div className="plan-filter-label"><label htmlFor="visit">Visit</label></div>
                                                        </div>
                                                        {selectedOption === 'visit' && <div className="update-popup-yes-or-no">
                                                            <div id="radio-btn-color">
                                                                <div className="yes-or-no-container">
                                                                    {leadTestdrivelocation.map((location, index) => (<div className="yes-no-section">
                                                                        <input type="radio" id={index.toString()} name="test_drive_filter" value={index} onChange={() => handleLocationRadioChange(location.test_drive_location_name, location.uuid)} />
                                                                        <div className="plan-filter-label"><label className="ml-1" htmlFor={index.toString()}>{location.test_drive_location_name}</label></div>
                                                                    </div>))}
                                                                </div>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                    <div className="update-plan-filter-1">
                                                        <div id="radio-btn-color" className="d-flex align-items-center pt-2 pb-2">
                                                            <input type="radio" id="bookCar" name="speak_to_customer" value="bookCar" onChange={(e) => handleOptionChange(e, 'bookaCar')} />
                                                            <div><img src="/assets/svg/book-car.svg" alt="" /></div>
                                                            <div className="plan-filter-label"><label className="m-0" htmlFor="bookCar">Book a Car</label></div>
                                                        </div>
                                                    </div>
                                                    <hr className="m-0" />
                                                </>)}
                                                <div className="update-plan-filter pl-0">
                                                    <div id="radio-btn-color" className="d-flex align-items-center">
                                                        <input type="radio" id="lostDrop" name="speak_to_customer" value="lostDrop" onChange={(e) => handleOptionChange(e, 'lostDrop')} />
                                                        <div><img src="/assets/svg/lost-drop.svg" alt="" /></div>
                                                        <div className="plan-filter-label"> <label className="m-0" htmlFor="lostDrop">Lost/Drop</label></div>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>


                                        <div className="row btn-form-submit">
                                            {speakToCustomerYes && pageTitle !== 'Updating a Test Drive' && (
                                                <div className="reschedule-btn-container " >
                                                    <button type="button" className="button2" onClick={() => handlePlanNextButtonClick()} disabled={formikProps.isSubmitting}>
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            <div><img src="/assets/svg/plan-arrow.svg" alt="" /></div>
                                                            <div> Plan Next Task</div>
                                                        </div>
                                                    </button>
                                                </div>
                                            )}
                                            {speakToCustomerYes && pageTitle === 'Updating a Test Drive' && (
                                                <div className="reschedule-btn-container " >
                                                    <button type="button" className="button2" onClick={() => { setTestdrivePopup(true); setTestdriveID(testdriveUUID ? testdriveUUID : "") }}>
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            <div><img src="/assets/svg/plan-arrow.svg" alt="" /></div>
                                                            <div>Continue</div>
                                                        </div>
                                                    </button>
                                                </div>
                                            )}

                                            {(taskReschedule || !speakToCustomerYes && pageTitle === 'Updating a Test Drive' && speakToCustomerNo) && (
                                                <div className="reschedule-btn-container">
                                                    <button type="button" className="button2" onClick={handleRescheduleButtonClick} disabled={formikProps.isSubmitting}>
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            <div><img src="/assets/svg/reschedule.svg" alt="" /></div>
                                                            <div> Reschedule</div>
                                                        </div>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </Form >
                                </div>
                            </>
                        )
                    }}
                </Formik >
            )
            }
            <UserTestdriveEntryDialog showDialog={testdrivePopup} closeDialog={() => setTestdrivePopup(false)} reloadData={() => { }} id={testdriveID} />
            <PlanningNextTaskDialog showDialog={planPopup} closeDialog={() => setPlanPopup(false)} updateLead={updateLead} dialogHeading={planHeading} leadTaskUUID={leadTaskUUID} updateSubmitValues={updateSubmitValues} pageTitle={pageTitle} locationUUID={selectedLocationUUID} leadQualify={enableOptions} updateCloseDialog={closeDialog} resetUpdateSubmitvalues={() => setUpdateSubmitvalues(undefined)} resetState={() => setResetState(true)} />
            <BookaCarEntryDialog showDialog={bookaCar} closeDialog={() => setBookaCar(false)} id={leadUUID} resetUpdateSubmitvalues={() => setUpdateSubmitvalues(undefined)} resetState={() => setResetState(true)} updateSubmitValues={updateSubmitValues} updateCloseDialog={closeDialog} reloadData={() => { }} />
            <LostDropEntryDialog showDialog={lostDrop} closeDialog={() => setLostDrop(false)} resetUpdateSubmitvalues={() => setUpdateSubmitvalues(undefined)} resetState={() => setResetState(true)} updateSubmitValues={updateSubmitValues} updateCloseDialog={closeDialog} id={leadUUID} />

        </Drawer >
    )
}

export default LeadTaskUpdateDialog;