import { Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { API, ProjectStrings, uploadImageType } from "../common/Constants";
import { getDataFromAPI } from "../common/Utilities";


const CompanyEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, detailView?: boolean }> = ({ showDialog, closeDialog, reloadData, id, detailView }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.Company>>(null);
    const [initialValue, setInitialValue] = useState<APIData.Company>(InitialData.Company);
    const [formDataSet, setFormDataSet] = useState(false);
    const [businessOptions, setBusinessOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.BusinessList, setBusinessOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'business_name', 'data', 'list');
    }, []);

    useEffect(() => {
        if (id !== undefined && id !== '') {
            addProcessingRequests();
            ServerAPI.getCompany(id).then(response => {
                if (response && response.data && response.data.details) {
                    let companyDetail = { ...response.data.details };
                    companyDetail.business_id = [];
                    if (companyDetail.business_uuid && companyDetail.business_uuid.length > 0) {
                        companyDetail.business_id = companyDetail.business_uuid.map((business: any) => business.uuid);
                    }
                    delete companyDetail.business_uuid;
                    //delete companyDetail.date_time;
                    setInitialValue(companyDetail);
                    setFormDataSet(true);
                    setEditMode(true);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else {
            setFormDataSet(true);
        }
    }, [id]);

    const yupSchema = Yup.object().shape({
        company_name: Yup.string().required(ProjectStrings.ValidationRequired),
        company_code: Yup.string().required(ProjectStrings.ValidationRequired),
        contact_phone: Yup.string().required(ProjectStrings.ValidationRequired),
        contact_email: Yup.string().required(ProjectStrings.ValidationRequired),
        url_context_path: Yup.string().required(ProjectStrings.ValidationRequired),
        business_id: Yup.array().min(1, ProjectStrings.ValidationArrayEmpty).of(Yup.string().required(ProjectStrings.ValidationRequired)),
    });

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-fit">
            {formDataSet && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };
                    if (id != undefined && id != '') {
                        addProcessingRequests();
                        ServerAPI.updateCompany(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            } else {
                                toast.error('Unknown Error Occurred');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    } else {
                        addProcessingRequests();
                        ServerAPI.addCompany(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            } else {
                                toast.error('Unknown Error Occurred');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    }
                }} >
                    {(formikProps) => {
                        return (
                            <div className="tabdetails">
                                <h4 className="title-text text-center">{editMode ? 'Edit' : 'Add New'} Company</h4>
                                <div className="custom-border-grey my-2"></div>
                                <p className="detailed-heading">Company Details</p>
                                <div className="tabpage">
                                    <div className="custom-border-grey my-2"></div>
                                    <div className="tab-details-section">
                                        <Form>
                                            <div className="px-2 form-field-container">
                                                <div className="row px-2 py-3">
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", name: "company_name", label: "Company Name", placeholder: "Company Name", required: true }} />
                                                    </div>
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", name: "company_code", label: "Company Code", placeholder: "Company Code", required: true }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-3">
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", name: "contact_phone", label: "Contact Phone", placeholder: "Contact Phone", required: true }} />
                                                    </div>
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", name: "contact_email", label: "Contact Email", placeholder: "Contact Email", required: true }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-3">
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", name: "url_context_path", label: "URL Context Path", placeholder: "URL Context Path", required: true }} />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user">
                                                        {/* <label className="label-custom-user"><p>Business</p></label> */}
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "multiselect", label: "Business", name: "business_id", placeholder: "Business", required: true, options: businessOptions, readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-3">
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", name: "password", label: "Password", placeholder: "Password" }} />
                                                    </div>
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "fileupload", name: "company_logo", label: "Company Logo", placeholder: "Company Logo", accept: "image/*", imageType: uploadImageType.DEMO_VEHICLE }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-3">
                                                    <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "switch", label: "Status", name: "status" }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row btn-form-submit">
                                                <button type="button" className="button1" onClick={closeDialog}>Cancel</button>
                                                <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                    formikProps.submitForm();
                                                    if (!formikProps.isValid) {
                                                        toast.error("Please ensure all fields are Filled and Valid!");
                                                    }
                                                }}>Submit</button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                </Formik>
            )
            }
        </Drawer>
    )
}

export default CompanyEntryDialog;