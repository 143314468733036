import { Drawer } from "@mui/material";
import { useAppStateAPI } from "../common/AppStateAPI";
import { ServerAPI } from "../common/ServerAPI";
import { useEffect, useState } from "react";
import { APIData } from "../common/DataTypes";
import { getFormattedLocalDateTime } from "../common/Utilities";
import ImagePreview from "../components/ui_components/ImagePreview";

const TestDriveDetailDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, }> = ({ showDialog, closeDialog, reloadData, id }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [testDrive, setTestDrive] = useState<APIData.TestDrive>();
    const [previewImage, setPreviewImage] = useState('');
    const [showImagePreview, setShowImagePreview] = useState(false);

    useEffect(() => {
        if (id !== undefined && id !== '') {
            addProcessingRequests();
            ServerAPI.getTestDriveDetail(id).then(response => {
                if (response && response.data && response.data.details) {
                    setTestDrive(response.data.details);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }, [id]);

    const handleImagePreview = (img: string) => {
        setPreviewImage(img);
        setShowImagePreview(true);
    }

    return (
        <Drawer
            anchor="right"
            open={showDialog}
            onClose={closeDialog}
            className="drawer-fit"
        >
            {testDrive !== undefined && (
                <div className="tabdetails">
                    <ImagePreview previewImage={previewImage} showModal={showImagePreview} closeModal={() => setShowImagePreview(false)} />
                    <h4 className="title-text ml-5">Test Drive Details</h4>
                    <div className="tabpage">
                        <div className="custom-border-grey my-2"></div>
                        <div className="tab-details-section">
                            <div className="row px-2 py-3">
                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-custom">
                                    <label className="label-custom">Customer Address</label>
                                    <textarea className="form-control" readOnly={true} >{testDrive.test_drive_address}</textarea>
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-custom">
                                    <label className="label-custom">Showroom Name</label>
                                    <input type="text" className="form-control" readOnly={true} value={testDrive.showroom_name} />
                                </div>
                            </div>
                            <div className="row px-2 py-3">
                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-custom">
                                    <label className="label-custom">Customer Name</label>
                                    <input type="text" className="form-control" readOnly={true} value={testDrive.customer_name} />
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-custom">
                                    <label className="label-custom">Mobile Number</label>
                                    <input type="text" className="form-control" readOnly={true} value={testDrive.mobile_number} />
                                </div>
                            </div>
                            <div className="row px-2 py-3">
                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-custom">
                                    <label className="label-custom">Vehicle VIN Number</label>
                                    <input type="text" className="form-control" readOnly={true} value={testDrive.car_vin_number} />
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-custom">
                                    <label className="label-custom">Vehicle TC Number</label>
                                    <input type="text" className="form-control" readOnly={true} value={testDrive.car_tc_number} />
                                </div>
                            </div>
                            <div className="row px-2 py-3">
                                <div className="col-md-12 col-lg-12 col-xl-12 text-field-custom">
                                    <label className="label-custom">Vehicle Details</label>
                                    <input type="text" className="form-control" readOnly={true} value={testDrive.model_name + ' ' + testDrive.variant_name + ' ' + testDrive.color_name + ' ' + testDrive.fuel_name} />
                                </div>
                            </div>
                            <div className="row px-2 py-3">
                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-custom">
                                    <label className="label-custom">Testdrive Date & Time</label>
                                    <input type="text" className="form-control" readOnly={true} value={getFormattedLocalDateTime(testDrive.test_drive_date_time)} />
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-custom">
                                    <label className="label-custom">Driving License Number</label>
                                    <input type="text" className="form-control" readOnly={true} value={testDrive.driving_licence_number} />
                                </div>
                            </div>
                            <div className="row px-2 py-3">
                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-custom">
                                    <label className="label-custom">Start Km</label>
                                    <input type="text" className="form-control" readOnly={true} value={testDrive.starting_odometer_reading} />
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-custom">
                                    <label className="label-custom">End Km</label>
                                    <input type="text" className="form-control" readOnly={true} value={testDrive.ending_odometer_reading} />
                                </div>
                            </div>
                            <div className="row px-2 py-3">
                                {testDrive.gate_pass_url != '' && (
                                    <div className="col-md-12 col-lg-6 col-xl-6">
                                        <a href={testDrive.gate_pass_url} target="_blank" download>Download Gate Pass</a>
                                    </div>
                                )}
                                {testDrive.decleration_pdf_url != '' && (
                                    <div className="col-md-12 col-lg-6 col-xl-6">
                                        <a href={testDrive.decleration_pdf_url} target="_blank" download>Download Declaration</a>
                                    </div>
                                )}
                            </div>
                            <div className="row px-2 py-3">
                                <div className="col-md-12 col-lg-6 col-xl-6">
                                    <label className="label-custom image-title">Test Drive Start Image</label>
                                    <img src={testDrive.starting_image_url} className="detail-image" onClick={() => handleImagePreview(testDrive.starting_image_url)} />
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-6">
                                    <label className="label-custom image-title">Test Drive End Image</label>
                                    <img src={testDrive.ending_image_url} className="detail-image" onClick={() => handleImagePreview(testDrive.ending_image_url)} />
                                </div>
                            </div>
                            {testDrive.driving_licence_image != '' && (
                                <div className="row px-2 py-3">
                                    <div className="col-md-12 col-lg-6 col-xl-6">
                                        <label className="label-custom image-title">Driving License Image</label>
                                        <img src={testDrive.driving_licence_image} className="detail-image" onClick={() => handleImagePreview(testDrive.driving_licence_image)} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Drawer>
    )
}

export default TestDriveDetailDialog;