import { Drawer, Tabs, Tab, Button } from "@mui/material";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import * as Yup from 'yup';
import React, { useEffect, useRef, useState } from "react";
import FormField from "../components/form_items/FormField";
import { InitialData } from "./InitialData";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { useAppStateAPI } from "../common/AppStateAPI";
import { API, ProjectStrings } from "../common/Constants";
import { getDataFromAPI } from "../common/Utilities";
import toast from "react-hot-toast";
import { ServerAPI } from "../common/ServerAPI";

interface UpdateLeadEntryDialogProps {
    showDialog: boolean;
    closeDialog: () => void;
    reloadData: () => void;
    id?: string;
    leadDetails?: any
}

const UpdateLeadDialogEntry: React.FC<UpdateLeadEntryDialogProps> = ({ showDialog, closeDialog, reloadData, id, leadDetails }) => {
    console.log(id, "updatleadId")
    console.log(leadDetails, "updatleadId")
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [initialValue, setInitialValue] = useState<APIData.UpdateLead | undefined>(InitialData.UpdateLead);
    const formikRef = useRef<FormikProps<APIData.UpdateLead>>(null);
    const [formDataSet, setFormDataSet] = useState(false);
    const [leadTitleOptions, setleadTitleOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [customerTypeOptions, setCustomerTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [customerCityOptions, setCustomerCityOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [customerStateOptions, setCustomerStateOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedCustomerStateID, setSelectedCustomerStateID] = useState('');
    const [selectedAddress, setSelectedAddress] = useState('');
    const [selectedLandmark, setSelectedLandmark] = useState('');
    const [isSameBillingAddress, setIsSameBillingAddress] = useState('');
    const UpdateLeadRef = useRef<HTMLDivElement | null>(null);
    const [selectedCityID, setSelectedCityID] = useState('');
    const [selectedStateID, setSelectedStateID] = useState('');
    const [selectedPincode, setSelectedPincode] = useState<number | undefined>();
    const [landmark, setlandmark] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedBillingCityID, setSelectedBillingCityID] = useState('');
    const [selectedBillingStateID, setSelectedBillingStateID] = useState('');
    const [selectedBillingPincode, setSelectedBillingPincode] = useState<number | undefined>();
    const [billingLandmark, setBillingLandmark] = useState<FormDataTypes.SelectOption[]>([]);

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.LeadTitleList, setleadTitleOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'lead_title', 'data', 'list');
        getDataFromAPI(API.EndPoint.CustomerTypeList, setCustomerTypeOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'customer_type_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.StateList, setCustomerStateOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'state_name', 'data', 'list');
        setFormDataSet(true);
    }, []);


    useEffect(() => {
        if (showDialog === false) {
            setSelectedPincode(undefined);
            setlandmark([]);
            setBillingLandmark([]);
            setSelectedCityID("");
            setSelectedCustomerStateID("");
            setSelectedStateID("");
            setSelectedAddress("");
            setSelectedBillingCityID("");
            setSelectedBillingStateID("");
            setSelectedBillingPincode(undefined);
            // setInitialValue(InitialData.UpdateLead);
            setIsSameBillingAddress("0")
        }

    }, [showDialog]);

    useEffect(() => {
        if (selectedPincode !== undefined && selectedPincode.toString().length === 6) {
            addProcessingRequests();
            ServerAPI.getBookCarPincode(selectedPincode).then(response => {
                if (response && response.data && response.data.list.length > 0) {
                    const landmarkData = response.data.list[0].landmark.map((item: any) => ({
                        label: item,
                        value: item
                    }));
                    const newLandmark = landmarkData;
                    const newStateID = response.data.list[0].state_uuid;
                    const newCityID = response.data.list[0].city_uuid;
                    setSelectedCityID(newCityID);
                    setSelectedStateID(newStateID);
                    setlandmark(newLandmark);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }, [selectedPincode]);

    useEffect(() => {
        if (selectedBillingPincode !== undefined && selectedBillingPincode.toString().length === 6) {
            addProcessingRequests();
            ServerAPI.getBookCarPincode(selectedBillingPincode).then(response => {
                if (response && response.data && response.data.list.length > 0) {
                    const landmarkData = response.data.list[0].landmark.map((item: any) => ({
                        label: item,
                        value: item
                    }));
                    const newLandmark = landmarkData;
                    const newStateID = response.data.list[0].state_uuid;
                    const newCityID = response.data.list[0].city_uuid;
                    setSelectedBillingCityID(newCityID);
                    setSelectedBillingStateID(newStateID);
                    setBillingLandmark(newLandmark);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }, [selectedBillingPincode]);

    useEffect(() => {
        if (selectedCustomerStateID) {
            let params: { [k: string]: any } = {};
            params["limit"] = 10000;
            params["status"] = 1;
            params["state_uuid"] = selectedCustomerStateID;
            getDataFromAPI(API.EndPoint.CityList, setCustomerCityOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'city_name', 'data', 'list');
        } else {
            setCustomerCityOptions([]);
        }
    }, [selectedCustomerStateID]);

    useEffect(() => {
        // if (id) {
        //     addProcessingRequests();
        //     ServerAPI.getLeadDetail(id).then(response => {
        //         if (response && response.data && response.data.details && response.data.details.lead_details && response.data.details.customer_details) {
        //             const leadDetails = response.data.details;
        //             setInitialValue({
        //                 lead_uuid: leadDetails.customer_details.lead_uuid,
        //                 customer_details: {
        //                     title: leadDetails.customer_details.lead_cust_title,
        //                     first_name: leadDetails.customer_details.lead_cust_first_name,
        //                     last_name: leadDetails.customer_details.lead_cust_last_name,
        //                     date_of_birth: leadDetails.customer_details.lead_cust_dob,
        //                     primary_mobile_number: leadDetails.customer_details.lead_cust_mobile_number,
        //                     secondary_mobile_number: leadDetails.customer_details.lead_cust_secondary_number,
        //                     email_id: leadDetails.customer_details.lead_cust_email,
        //                     other_email_ids: [],
        //                     customer_type_uuid: leadDetails.customer_details.customer_type_uuid,
        //                     address: leadDetails.customer_details.lead_cust_address,
        //                     pin_code: leadDetails.customer_details.lead_cust_pincode,
        //                     state_uuid: leadDetails.customer_details.state_uuid,
        //                     city_uuid: leadDetails.customer_details.city_uuid,
        //                     landmark: leadDetails.customer_details.lead_cust_landmark,
        //                     billing_as_shipping: leadDetails.customer_details.billing_address_as_above,
        //                     billing_landmark: leadDetails.customer_details.lead_cust_bill_landmark,
        //                     billing_address: leadDetails.customer_details.lead_cust_bill_address,
        //                     billing_pincode: leadDetails.customer_details.lead_cust_bill_pincode,
        //                     billing_address_state_uuid: leadDetails.customer_details.billing_state_uuid,
        //                     billing_address_city_uuid: leadDetails.customer_details.billing_city_uuid,
        //                 },
        //                 lead_details: {
        //                     lead_type_uuid: leadDetails.lead_details.vehicle_lead_type_uuid,
        //                     lead_source_category_uuid: leadDetails.lead_details.lead_source_category_uuid,
        //                     lead_source_uuid: leadDetails.lead_details.lead_source_uuid,
        //                     lead_status_uuid: leadDetails.lead_details.lead_status_uuid
        //                 }
        //             })
        //         }
        //     }).finally(() => {
        //         reduceProcessingRequests();
        //     });
        // }
        if (leadDetails) {
            setInitialValue({
                lead_uuid: leadDetails.customer_details.lead_uuid,
                customer_details: {
                    title: leadDetails.customer_details.lead_cust_title,
                    first_name: leadDetails.customer_details.lead_cust_first_name,
                    last_name: leadDetails.customer_details.lead_cust_last_name,
                    date_of_birth: leadDetails.customer_details.lead_cust_dob,
                    primary_mobile_number: leadDetails.customer_details.lead_cust_mobile_number,
                    secondary_mobile_number: leadDetails.customer_details.lead_cust_secondary_number,
                    email_id: leadDetails.customer_details.lead_cust_email,
                    other_email_ids: [],
                    customer_type_uuid: leadDetails.customer_details.customer_type_uuid,
                    address: leadDetails.customer_details.lead_cust_address,
                    pin_code: leadDetails.customer_details.lead_cust_pincode,
                    state_uuid: leadDetails.customer_details.state_uuid,
                    city_uuid: leadDetails.customer_details.city_uuid,
                    landmark: leadDetails.customer_details.lead_cust_landmark,
                    billing_as_shipping: leadDetails.customer_details.billing_address_as_above,
                    billing_landmark: leadDetails.customer_details.lead_cust_bill_landmark,
                    billing_address: leadDetails.customer_details.lead_cust_bill_address,
                    billing_pincode: leadDetails.customer_details.lead_cust_bill_pincode,
                    billing_address_state_uuid: leadDetails.customer_details.billing_state_uuid,
                    billing_address_city_uuid: leadDetails.customer_details.billing_city_uuid,
                },
                lead_details: {
                    lead_type_uuid: leadDetails.lead_details.vehicle_lead_type_uuid,
                    lead_source_category_uuid: leadDetails.lead_details.lead_source_category_uuid,
                    lead_source_uuid: leadDetails.lead_details.lead_source_uuid,
                    lead_status_uuid: leadDetails.lead_details.lead_status_uuid
                }
            })
        }

    }, [leadDetails]);


    useEffect(() => {
        formikRef.current?.setFieldValue('customer_details.state_uuid', selectedStateID);
        formikRef.current?.setFieldValue('customer_details.city_uuid', selectedCityID);
    }, [selectedCityID, selectedStateID]);
    useEffect(() => {
        formikRef.current?.setFieldValue('customer_details.billing_address_state_uuid', selectedBillingStateID);
        formikRef.current?.setFieldValue('customer_details.billing_address_city_uuid', selectedBillingCityID);

    }, [selectedBillingCityID, selectedBillingStateID]);
    useEffect(() => {
        if (isSameBillingAddress === "1") {
            formikRef.current?.setFieldValue('customer_details.billing_address_state_uuid', selectedStateID);
            formikRef.current?.setFieldValue('customer_details.billing_address_city_uuid', selectedCityID);
            formikRef.current?.setFieldValue('customer_details.billing_pincode', selectedPincode);
            formikRef.current?.setFieldValue('customer_details.billing_landmark', selectedLandmark);
        }
    }, [selectedCityID, selectedStateID, selectedPincode, selectedLandmark, isSameBillingAddress]); //selectedAddress

    const yupSchema = Yup.object().shape({
        customer_details: Yup.object().shape({
            title: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            first_name: Yup.string().required(ProjectStrings.ValidationRequired),
            last_name: Yup.string().required(ProjectStrings.ValidationRequired),
            date_of_birth: Yup.string().required(ProjectStrings.ValidationRequired),
            primary_mobile_number: Yup.string().matches(/^[6-9]\d{9}$/, { message: "Please enter valid number.", excludeEmptyString: false }).required(ProjectStrings.ValidationRequired),
            email_id: Yup.string().matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, { message: "Please enter valid Email.", excludeEmptyString: false }).required(ProjectStrings.ValidationRequired),
            customer_type_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            address: Yup.string().required(ProjectStrings.ValidationRequired),
            pin_code: Yup.string().required(ProjectStrings.ValidationRequired),
            state_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            city_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            landmark: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            billing_as_shipping: Yup.string(),
            billing_landmark: Yup.string().when('billing_as_shipping', ([billing_as_shipping], schema) => {
                if (parseInt(billing_as_shipping) === 1)
                    return Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired)
                return schema;
            }),
            billing_address: Yup.string().when('billing_as_shipping', ([billing_as_shipping], schema) => {
                if (parseInt(billing_as_shipping) === 1)
                    return Yup.string().required(ProjectStrings.ValidationRequired)
                return schema;
            }),
            billing_address_city_uuid: Yup.string().when('billing_as_shipping', ([billing_as_shipping], schema) => {
                if (parseInt(billing_as_shipping) === 1)
                    return Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired)
                return schema;
            }),
            billing_address_state_uuid: Yup.string().when('billing_as_shipping', ([billing_as_shipping], schema) => {
                if (parseInt(billing_as_shipping) === 1)
                    return Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired)
                return schema;
            }),
            billing_pincode: Yup.string().when('billing_as_shipping', ([billing_as_shipping], schema) => {
                if (parseInt(billing_as_shipping) === 1)
                    return Yup.string().required(ProjectStrings.ValidationRequired)
                return schema;
            })
        }),

    });

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="book-a-car-section drawer-max">
            {formDataSet && initialValue !== undefined && (
                <Formik
                    validationSchema={yupSchema} enableReinitialize={true} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                        let submitValues: any = { ...values };
                        submitValues.customer_details.billing_as_shipping = parseInt(submitValues.customer_details.billing_as_shipping);
                        submitValues.customer_details.billing_pincode = submitValues.customer_details.billing_pincode + "";
                        submitValues.customer_details.pin_code = submitValues.customer_details.pin_code + "";
                        submitValues.customer_details.primary_mobile_number = submitValues.customer_details.primary_mobile_number + "";
                        addProcessingRequests();
                        submitValues['lead_uuid'] = id;
                        ServerAPI.UpdateLead(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                closeDialog();
                                reloadData();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });

                    }}>
                    {(formikProps) => {
                        if (formikProps.values.customer_details.state_uuid && formikProps.values.customer_details.state_uuid !== selectedCustomerStateID) {
                            setSelectedCustomerStateID(formikProps.values.customer_details.state_uuid);
                        }

                        if (
                            formikProps.values.customer_details.address &&
                            formikProps.values.customer_details.address !== formikProps.values.customer_details.billing_address
                        ) {
                            if (isSameBillingAddress === "1") {
                                formikRef.current?.setFieldValue('customer_details.billing_address', formikProps.values.customer_details.address);
                            }
                        }

                        if (
                            formikProps.values.customer_details.billing_as_shipping !== undefined &&
                            formikProps.values.customer_details.billing_as_shipping != parseInt(isSameBillingAddress)
                        ) {
                            setIsSameBillingAddress(formikProps.values.customer_details.billing_as_shipping.toString());
                        }


                        if (formikProps.values.customer_details.landmark && formikProps.values.customer_details.landmark !== selectedLandmark) {
                            setSelectedLandmark(formikProps.values.customer_details.landmark);
                        }

                        if (
                            formikProps.values.customer_details.pin_code &&
                            formikProps.values.customer_details.pin_code.toString().length === 6 &&
                            parseInt(formikProps.values.customer_details.pin_code) !== selectedPincode
                        ) {
                            const newPincode = parseInt(formikProps.values.customer_details.pin_code);
                            setSelectedPincode(newPincode);
                        }

                        if (
                            formikProps.values.customer_details.billing_pincode &&
                            formikProps.values.customer_details.billing_pincode.toString().length === 6 &&
                            parseInt(formikProps.values.customer_details.billing_pincode) !== selectedBillingPincode
                        ) {
                            const newPincode = parseInt(formikProps.values.customer_details.billing_pincode);
                            setSelectedBillingPincode(newPincode);
                        }


                        return (
                            <Form>
                                <div className="tabdetails create-new-lead-section" ref={UpdateLeadRef} >
                                    <div className="tabdetails-1" style={{ overflowY: "auto", height: "90vh" }}>
                                        <h4 className="title-text text-center">Update Lead</h4>
                                        <div className="custom-border-grey"></div>
                                        <div className="tabpage">
                                            <div className="form-field-container-tabs">
                                                <>
                                                    <div className="row pt-3 pb-1">
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 mt-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Title", name: "customer_details.title", placeholder: "Select Title", options: leadTitleOptions, required: true, }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "First Name", name: "customer_details.first_name", placeholder: "Enter First Name", required: true, }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Last Name", name: "customer_details.last_name", placeholder: "Enter Last Name", required: true, }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1 mt-2">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "datepicker", label: "Date of Birth", name: "customer_details.date_of_birth", placeholder: "Select DOB" }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1 mt-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Mobile Number", name: "customer_details.primary_mobile_number", placeholder: "Enter Mobile Number", required: true, }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1 mt-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Secondary Number", name: "customer_details.secondary_mobile_number", placeholder: "Enter Mobile Number" }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 mt-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Customer Type", name: "customer_details.customer_type_uuid", placeholder: "Select Type", options: customerTypeOptions, required: true, }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Email Id  ", name: "customer_details.email_id", placeholder: "Enter Email Id", required: true, }} />
                                                            {/* <img src="/assets/images/icons/add-black.svg" alt="" onClick={closeDialog} style={{ cursor: 'pointer' }} /> */}
                                                        </div>
                                                        <div style={{ width: "100%", marginLeft: "12px", marginRight: "8px", marginBottom: "10px" }}>
                                                            <FieldArray name="customer_details.other_email_ids">
                                                                {({ insert, remove, push }) => (
                                                                    <>
                                                                        <button type="button" className="mb-3 mr-2 btn" style={{ backgroundColor: "#14408b", color: "#FFF" }} onClick={() => push('')}>Add Other Email Id</button>
                                                                        <div className='individual_sub_content' style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridColumnGap: '17px', height: 'auto' }}>

                                                                            <>
                                                                                {formikProps.values.customer_details.other_email_ids.map((data, index) => {
                                                                                    const uniqueKey = `${index}`;
                                                                                    return (

                                                                                        <div key={uniqueKey} className="overall mb-4">
                                                                                            <div className="field text-field-empty-custom-user-1">
                                                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Other Email id", name: `customer_details.other_email_ids.${index}`, placeholder: "Enter Other Email Id", required: true }} />
                                                                                            </div>
                                                                                            <div className="icon text-field-empty-custom-user">
                                                                                                <button type="button" className={`btn btn-sm ml-1`} style={{ backgroundColor: "#f14b4b" }} onClick={() => remove(index)}><i className="text-white fas fa-trash pr-1 pl-1"></i></button>
                                                                                            </div>
                                                                                        </div>

                                                                                    )
                                                                                })}
                                                                            </>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </FieldArray>
                                                        </div>
                                                    </div>
                                                    <div className="custom-border-grey mb-3 px-2"></div>
                                                    <div className="row pt-3 pb-1">
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Pincode", name: "customer_details.pin_code", placeholder: "Enter Pin Code", required: true, }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 mt-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "State", name: "customer_details.state_uuid", placeholder: "Select State", options: customerStateOptions, required: true, }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 mt-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "City", name: "customer_details.city_uuid", placeholder: "Select City", options: customerCityOptions, required: true, }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Landmark  ", name: "customer_details.landmark", placeholder: "Select Landmark", options: landmark, required: true, }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 textarea">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textarea", label: "Address", name: "customer_details.address", placeholder: "Enter Address *", required: true, }} />
                                                        </div>
                                                    </div>
                                                    <div className="row pb-1">
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 ">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "checkbox", label: "Billing Address same as above", name: "customer_details.billing_as_shipping", }} />
                                                        </div>
                                                        {formikProps.values.customer_details.billing_as_shipping === 1 && <>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Billing Pincode", name: "customer_details.billing_pincode", placeholder: "Enter Pin Code", required: true, disabled: true }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 mt-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Billing State", name: "customer_details.billing_address_state_uuid", placeholder: "Select State", options: customerStateOptions, required: true, readOnly: true }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 mt-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Billing City", name: "customer_details.billing_address_city_uuid", placeholder: "Select City", options: customerCityOptions, required: true, readOnly: true }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Billing Landmark", name: "customer_details.billing_landmark", placeholder: "Select Landmark", options: landmark, required: true, readOnly: true }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 textarea">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textarea", label: "Billing Address", name: "customer_details.billing_address", placeholder: "Enter Billing Address *", required: true, readOnly: true }} />
                                                            </div>
                                                        </>}
                                                        {formikProps.values.customer_details.billing_as_shipping === 0 && <>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Billing Pincode", name: "customer_details.billing_pincode", placeholder: "Enter Pin Code", required: true }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 mt-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Billing State", name: "customer_details.billing_address_state_uuid", placeholder: "Select State", options: customerStateOptions, required: true }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 mt-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Billing City", name: "customer_details.billing_address_city_uuid", placeholder: "Select City", options: customerCityOptions, required: true }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Billing Landmark", name: "customer_details.billing_landmark", placeholder: "Select Landmark", options: billingLandmark, required: true }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 textarea">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textarea", label: "Billing Address", name: "customer_details.billing_address", placeholder: "Enter Billing Address *", required: true }} />
                                                            </div>
                                                        </>}
                                                    </div>
                                                    <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                        <button type="button" className="button1" onClick={() => closeDialog()}>Back</button>
                                                        <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                            formikProps.submitForm();
                                                            if (!formikProps.isValid) {
                                                                toast.error("Please ensure all fields are Filled and Valid!");
                                                            }
                                                        }}>Save Changes</button>
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            )
            }
        </Drawer >
    )
}

export default UpdateLeadDialogEntry;
