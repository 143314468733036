import { Drawer, Tab, Tabs } from "@mui/material";
import { useAppStateAPI } from "../common/AppStateAPI";
import { ServerAPI } from "../common/ServerAPI";
import { useEffect, useRef, useState } from "react";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { getDataFromAPI, getFormattedLocalDateTime } from "../common/Utilities";
import React from "react";
import * as Yup from 'yup';
import FormField from "../components/form_items/FormField";
import { Formik, FormikProps } from "formik";
import { InitialData } from "../forms/InitialData";
import { API, ProjectStrings } from "../common/Constants";
import toast from "react-hot-toast";
import LeadInterestedVehicleEntryDialog from "../forms/LeadInterestedVehicleEntryDialog";
import LeadIntVehicleBookEntryDialog from "../forms/LeadIntVehicleBookEntryDialog";

const LeadDetailDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, detailView?: boolean }> = ({ showDialog, closeDialog, reloadData, id, detailView }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [leadDetail, setLeadDetail] = useState<APIData.LeadDetail>();
    const [currentTab, setCurrentTab] = React.useState(0);
    const [formDataSet, setFormDataSet] = useState(false);

    const formikRef = useRef<FormikProps<APIData.LeadEntry>>(null);
    const [initialValue, setInitialValue] = useState<APIData.LeadEntry>();

    const [editMode, setEditMode] = useState(false);
    const [leadTypeOptions, setLeadTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [leadSourceCategoryOptions, setLeadSourceCategoryOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [leadSourceOptions, setLeadSourceOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [titleOptions, setTitleOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [customerTypeOptions, setCustomerTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [stateOptions, setStateOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [shipCityOptions, setShipCityOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [billCityOptions, setBillCityOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedLeadSourceCategoryID, setSelectedLeadSourceCategoryID] = useState("");
    const [selectedShippingStateID, setSelectedShippingStateID] = useState("");
    const [selectedBillingStateID, setSelectedBillingStateID] = useState("");
    const [formIsValid, setFormIsValid] = useState(false);

    const [showInterestedVehicleEntry, setShowInterestedVehicleEntry] = useState(false);
    const [editInterestedVehicleID, setEditInterestedVehicleID] = useState('');

    const [showBookVehicleEntry, setShowBookVehicleEntry] = useState(false);
    const [bookingDetailView, setBookingDetailView] = useState(false);

    useEffect(() => {
        fetchLeadDetail();
    }, [id]);

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.LeadTitleList, setTitleOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'lead_title', 'data', 'list');
        getDataFromAPI(API.EndPoint.CustomerTypeList, setCustomerTypeOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'customer_type_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.StateList, setStateOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'state_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.LeadTypeList, setLeadTypeOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'vehicle_lead_type_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.LeadSourceCategoryList, setLeadSourceCategoryOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'lead_source_category_name', 'data');
    }, []);

    useEffect(() => {
        if (selectedLeadSourceCategoryID) {
            let params: { [k: string]: any } = {};
            params["limit"] = 10000;
            params["status"] = 1;
            params["lead_source_cat_uuid"] = selectedLeadSourceCategoryID;
            getDataFromAPI(API.EndPoint.LeadSourceList, setLeadSourceOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'lead_source_name', 'data', 'list');
        } else {
            setLeadSourceOptions([]);
        }
    }, [selectedLeadSourceCategoryID]);

    useEffect(() => {
        if (selectedShippingStateID) {
            let params: { [k: string]: any } = {};
            params["limit"] = 10000;
            params["status"] = 1;
            params["state_uuid"] = selectedShippingStateID;
            getDataFromAPI(API.EndPoint.CityList, setShipCityOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'city_name', 'data', 'list');
        } else {
            setShipCityOptions([]);
        }
    }, [selectedShippingStateID]);

    useEffect(() => {
        if (selectedBillingStateID) {
            let params: { [k: string]: any } = {};
            params["limit"] = 10000;
            params["status"] = 1;
            params["state_uuid"] = selectedBillingStateID;
            getDataFromAPI(API.EndPoint.CityList, setBillCityOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'city_name', 'data', 'list');
        } else {
            setBillCityOptions([]);
        }
    }, [selectedBillingStateID]);

    const fetchLeadDetail = () => {
        if (id !== undefined && id !== '') {
            addProcessingRequests();
            ServerAPI.getLeadDetail(id).then(response => {
                if (response && response.data && response.data.details) {
                    const leadDetail = response.data.details;
                    setInitialValue({ customer_details: leadDetail.customer_details, lead_details: leadDetail.lead_details })
                    setLeadDetail(leadDetail);
                    setFormDataSet(true);
                    setEditMode(true);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    const handelTabChange = (e: any, val: React.SetStateAction<number>) => {
        setCurrentTab(val)
    }

    function TabPanel(props: { children: any; value: any; index: any; }) {
        const { children, value, index } = props;
        return (
            <div>
                {
                    value === index && (
                        <h1>{children}</h1>
                    )
                }
            </div>
        )
    }

    const leadSchema = Yup.object().shape({
        vehicle_lead_type_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        lead_source_category_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        lead_source_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
    })

    const customerSchema = Yup.object().shape({
        lead_cust_title: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        lead_cust_first_name: Yup.string().required(ProjectStrings.ValidationRequired),
        lead_cust_last_name: Yup.string().required(ProjectStrings.ValidationRequired),
        lead_cust_dob: Yup.string().required(ProjectStrings.ValidationRequired),
        lead_cust_email: Yup.string().required(ProjectStrings.ValidationRequired),
        lead_cust_mobile_number: Yup.string().required(ProjectStrings.ValidationRequired),
        state_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        city_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
    });

    const yupSchema = Yup.object().shape({
        lead_details: leadSchema,
        customer_details: customerSchema,
    });

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-fit">
            {initialValue !== undefined && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    setFormIsValid(true);
                    let submitValues: any = {
                        lead_details: { ...values.lead_details },
                        customer_details: { ...values.customer_details }
                    };

                    if (id != undefined && id != '') {
                        addProcessingRequests();
                        ServerAPI.updateLead(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            } else {
                                toast.error('Unknown Error Occurred');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    }
                }} >
                    {(formikProps) => {
                        if (formikProps.values.customer_details.state_uuid != selectedShippingStateID) {
                            setSelectedShippingStateID(formikProps.values.customer_details.state_uuid);
                        }
                        if (formikProps.values.customer_details.billing_address_state_uuid != selectedBillingStateID) {
                            setSelectedBillingStateID(formikProps.values.customer_details.billing_address_state_uuid);
                        }
                        if (formikProps.values.lead_details.lead_source_category_uuid != selectedLeadSourceCategoryID) {
                            setSelectedLeadSourceCategoryID(formikProps.values.lead_details.lead_source_category_uuid);
                        }

                        return (
                            <div className="tabdetails">
                                <h4 className="title-text ml-5">Customer Details</h4>
                                {/* <div>
                                    <div className="circle-fill-green"></div>
                                    <p>Lead</p>
                                </div> */}
                                <div className="tabpage">

                                    <Tabs value={currentTab} onChange={handelTabChange}
                                        sx={{ backgroundColor: "white", borderTop: "2px solid #e2e2e2", borderBottom: "2px solid #e2e2e2", "& button": { width: "33%" }, justifyContent: "space-between", marginTop: "0" }}
                                        TabIndicatorProps={{
                                            style: { backgroundColor: "#052160" }
                                        }}
                                    >
                                        <Tab label="Tasks" className="tab-headers" />
                                        <Tab label="Vehicle Details" className="tab-headers" />
                                        <Tab label="Lead Details" className="tab-headers" />
                                    </Tabs>

                                    <TabPanel value={currentTab} index={0}>
                                        {leadDetail !== undefined && (
                                            <div className="tab-details-section">
                                                <div className="add-task-btn-section" style={{ display: 'none' }}>
                                                    <button type="button" className="add-task-btn">+ Add</button>
                                                </div>
                                                {leadDetail.task.planned_task !== undefined && leadDetail.task.planned_task.task_name !== undefined && (
                                                    <>
                                                        <h4 className="ml-1 header-title">Planned Tasks</h4>
                                                        <div className="row px-3 pb-3">
                                                            <div className="detailed-section-container">
                                                                <div className="d-flex-justify-between">
                                                                    {/* <p className="side-headings-details">{leadDetail.task.planned_task ? leadDetail.task.planned_task.task_name : "N/A"}</p> */}
                                                                    <p className="task-date">{leadDetail.task.planned_task ? getFormattedLocalDateTime(leadDetail.task.planned_task.date_time) : "N/A"}</p>
                                                                </div>
                                                                <div className="border-line-split"></div>
                                                                <div className="d-flex-start-between">
                                                                    <div className="tasks-sub-headings col-2">
                                                                        <p className="subheading-one">Assigned To</p>
                                                                        <p className="ps-menu-label">{leadDetail.task.planned_task ? leadDetail.task.planned_task.assigned_to : "N/A"}</p>
                                                                    </div>
                                                                    <div className="tasks-sub-headings col-4">
                                                                        <p className="subheading-one">Date & Time</p>
                                                                        <p className="ps-menu-label">{leadDetail.task.planned_task ? getFormattedLocalDateTime(leadDetail.task.planned_task.date_time) : "N/A"}</p>
                                                                    </div>
                                                                    <div className="tasks-sub-headings col-6">
                                                                        <p className="subheading-one">Remarks</p>
                                                                        <p className="ps-menu-label">{leadDetail.task.planned_task ? leadDetail.task.planned_task.remarks : "N/A"}</p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {leadDetail.task.past_task !== undefined && leadDetail.task.past_task.length > 0 && (
                                                    <>
                                                        <h4 className="ml-1 header-title">Past Tasks</h4>
                                                        {leadDetail.task.past_task.map((task, tskIdx) => (
                                                            <div className="row px-3 pb-3" key={tskIdx}>
                                                                <div className="detailed-section-container">
                                                                    <div className="d-flex-justify-between">
                                                                        <p className="side-headings-details">{task.task_name}</p>
                                                                        <p className="task-date">{getFormattedLocalDateTime(task.date_time)}</p>
                                                                    </div>
                                                                    <div className="border-line-split"></div>
                                                                    <div className="d-flex-start-between">
                                                                        <div className="tasks-sub-headings col-2">
                                                                            <p className="subheading-one">Assigned To</p>
                                                                            <p className="ps-menu-label">{task.assigned_to}</p>
                                                                        </div>
                                                                        <div className="tasks-sub-headings col-4">
                                                                            <p className="subheading-one">Date & Time</p>
                                                                            <p className="ps-menu-label">{getFormattedLocalDateTime(task.date_time)}</p>
                                                                        </div>
                                                                        <div className="tasks-sub-headings col-6">
                                                                            <p className="subheading-one">To Address</p>
                                                                            <p className="ps-menu-label">{task.address}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex-start-between">
                                                                        <div className="tasks-sub-headings col-6">
                                                                            <p className="subheading-one">Remarks</p>
                                                                            <p className="ps-menu-label">{task.remarks}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={1}>
                                        {leadDetail !== undefined && (
                                            <div className="tab-details-section">
                                                {leadDetail.interested_vehicle_details !== undefined && leadDetail.interested_vehicle_details.length > 0 && (
                                                    <>
                                                        <div className="d-flex-justify-between">
                                                            <h4 className="ml-1 header-title">Interested Vehicles</h4>
                                                            <div className="add-task-btn-section">
                                                                <button className="add-task-btn" onClick={() => {
                                                                    setEditInterestedVehicleID('');
                                                                    setShowInterestedVehicleEntry(true)
                                                                }}>+ Add</button>
                                                            </div>
                                                        </div>
                                                        <div className="row px-3 pb-3">
                                                            {leadDetail.interested_vehicle_details.map((vehicle, vehicleIdx) => (
                                                                <div className="detailed-section-container" key={vehicleIdx}>
                                                                    <div className="d-flex-start-between" style={{ display: "flex", justifyContent: "space-between", background: "#F7FAFF", padding: "10px" }}>
                                                                        <div className="tasks-sub-headings">
                                                                            <p className="subheading-one">Model</p>
                                                                            <p className="ps-menu-label">{vehicle.model_name}</p>
                                                                        </div>
                                                                        <div className="tasks-sub-headings">
                                                                            <p className="subheading-one">Variant</p>
                                                                            <p className="ps-menu-label">{vehicle.variant_name}</p>
                                                                        </div>
                                                                        <div className="tasks-sub-headings">
                                                                            <p className="subheading-one">Exterior Color</p>
                                                                            <p className="ps-menu-label">{vehicle.exterior_color_name}</p>
                                                                        </div>
                                                                        <div className="tasks-sub-headings">
                                                                            <p className="subheading-one">Interior Color</p>
                                                                            <p className="ps-menu-label">{vehicle.interior_color_name}</p>
                                                                        </div>
                                                                        <div className="tasks-sub-headings">
                                                                            <p className="subheading-one">Fuel Type</p>
                                                                            <p className="ps-menu-label">{vehicle.fuel_name}</p>
                                                                        </div>
                                                                    </div>
                                                                    {vehicle.booked_status === 1 && vehicle.booking_details !== undefined ?
                                                                        <div className="d-flex-justify-end" style={{ width: "100%", textAlign: "right" }}>
                                                                            <div>
                                                                                <div className="status-container">Vehicle Booked</div>
                                                                                {vehicle.booking_details.booking_car_details.vin_number !== null && vehicle.booking_details.booking_car_details.vin_number !== '' ?
                                                                                    <p className="vehicle-status-text success-text">VIN Allocated</p>
                                                                                    :
                                                                                    <p className="vehicle-status-text alert-text">VIN Unallocated</p>
                                                                                }

                                                                            </div>
                                                                            {/* <div className="modal-button-empty" style={{ marginLeft: "10px", marginBottom: "10px" }}>
                                                                                <a href="#" onClick={e => {
                                                                                    e.preventDefault();
                                                                                }}>Cancel Booking</a>
                                                                            </div> */}
                                                                            <div className="modal-button-empty" style={{ marginLeft: "10px", marginBottom: "10px" }}>
                                                                                <a href="#" onClick={e => {
                                                                                    e.preventDefault();
                                                                                }}>Invoice</a>
                                                                            </div>
                                                                            <div className="modal-button-fill" style={{ marginLeft: "10px", width: "200px" }}>
                                                                                <a href="#" onClick={e => {
                                                                                    e.preventDefault();
                                                                                    setBookingDetailView(true);
                                                                                    setShowBookVehicleEntry(true);
                                                                                }}>View Booking Details</a>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="d-flex-justify-end" style={{ width: "100%", textAlign: "right" }}>
                                                                            <a href="#" onClick={e => {
                                                                                e.preventDefault();
                                                                                setEditInterestedVehicleID(vehicle.interested_vehicle_uuid);
                                                                                setShowInterestedVehicleEntry(true)
                                                                            }}>
                                                                                <img className="edit-vehicle" src={window.location.origin + '/assets/images/icons/edit_icon_yellow.svg'} style={{ marginLeft: "10px" }} />
                                                                            </a>
                                                                            <a href="#" onClick={e => {
                                                                                e.preventDefault();
                                                                            }}>
                                                                                <img className="delete-vehicle" src={window.location.origin + '/assets/images/icons/delete_icon_red.svg'} style={{ marginLeft: "10px" }} />
                                                                            </a>
                                                                            <div className="modal-button-empty" style={{ marginLeft: "10px", marginBottom: "10px" }}>
                                                                                <a href="#" onClick={e => {
                                                                                    e.preventDefault();
                                                                                }}>Inventory &amp; Price Details</a>
                                                                            </div>
                                                                            <div className="modal-button-fill" style={{ marginLeft: "10px", width: "150px" }}>
                                                                                <a href="#" onClick={e => {
                                                                                    e.preventDefault();
                                                                                }}>Book</a>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={2}>
                                        <div className="tab-details-section">
                                            <h4 className="h4 ml-1">Lead Details</h4>

                                            <div className="row px-3 py-3">
                                                <div className="col-lg-4 col-md-6 col-xl-4 text-field-empty-custom-user">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Lead Id", name: "customer_details.lead_id", placeholder: "Lead ID", readOnly: true }} />
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-xl-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Lead Type", name: "lead_details.vehicle_lead_type_uuid", placeholder: "Lead Type", required: true, options: leadTypeOptions }} />
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-xl-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Lead Category", name: "lead_details.lead_source_category_uuid", placeholder: "Lead Source Category", required: true, options: leadSourceCategoryOptions }} />
                                                </div>
                                            </div>
                                            <div className="row px-3 py-3">
                                                <div className="col-lg-4 col-md-6 col-xl-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Lead Source", name: "lead_details.lead_source_uuid", placeholder: "Lead Source", required: true, options: leadSourceOptions }} />
                                                </div>
                                            </div>
                                            <>
                                                <h4>Customer Details</h4>
                                                <div className="row px-3 py-3">
                                                    <div className="col-lg-4 col-md-6 col-xl-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Title", name: "customer_details.lead_cust_title", placeholder: "Title", required: true, options: titleOptions }} />
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-xl-4 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "First Name", name: "customer_details.lead_cust_first_name", placeholder: "First Name", required: true }} />
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-xl-4 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Last Name", name: "customer_details.lead_cust_last_name", placeholder: "Last Name", required: true }} />
                                                    </div>
                                                </div>
                                                <div className="row px-3 py-3">
                                                    <div className="col-lg-4 col-md-6 col-xl-4 text-field-empty-custom-user " style={{ lineHeight: "20px" }}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "datepicker", label: "Date of Birth", name: "customer_details.lead_cust_dob", placeholder: "Date of Birth", required: true }} />
                                                    </div>

                                                    <div className="col-lg-4 col-md-6 col-xl-4 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Mobile Number", name: "customer_details.lead_cust_mobile_number", placeholder: "Mobile Number", required: true }} />
                                                    </div>
                                                    {/* <div className="col-lg-4 col-md-6 col-xl-4 text-field-empty-custom-user">
                                                        <label className="label-custom-user"><p>Secondary Number</p></label>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Secondary Number", name: "customer_details.lead_cust_secondary_number", placeholder: "Secondary Number", required: true }} />
                                                    </div> */}
                                                    <div className="col-lg-4 col-md-6 col-xl-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Email ID", name: "customer_details.lead_cust_email", placeholder: "Email ID", required: true }} />
                                                    </div>
                                                </div>
                                                {/* <div className="row px-3 py-3">
                                                    <div className="col-lg-4 col-md-6 col-xl-4 text-field-empty-custom-user">
                                                        <label className="label-custom-user"><p>Email ID</p></label>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Email ID", name: "customer_details.lead_cust_email", placeholder: "Email ID", required: true }} />
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-xl-4 text-field-empty-custom-user">
                                                        <label className="label-custom-user"><p>Secondary Email ID</p></label>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Secondary Email ID", name: "customer_details.lead_id", placeholder: "Role", required: true, options: roleOptions }} />
                                                    </div>
                                                </div> */}
                                                <div className="row px-3 py-3">
                                                    <div className="col-lg-4 col-md-6 col-xl-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Customer Type", name: "customer_details.customer_type_uuid", placeholder: "Customer Type", required: true, options: customerTypeOptions }} />
                                                    </div>
                                                    <div className="col-lg-8 col-md-8 col-xl-8 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Home Address", name: "customer_details.lead_cust_address", placeholder: "Home Address", required: true }} />
                                                    </div>
                                                    {/* <div className="col-lg-4 col-md-6 col-xl-4 text-field-empty-custom-user">
                                                        <label className="label-custom-user"><p>Landmark</p></label>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Landmark", name: "customer_details.lead_id", placeholder: "Role", required: true, options: roleOptions }} />
                                                    </div> */}
                                                </div>
                                                <div className="row px-3 py-3">
                                                    <div className="col-lg-4 col-md-6 col-xl-4 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Pincode", name: "customer_details.lead_cust_pincode", placeholder: "Pincode", required: true }} />
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-xl-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "State", name: "customer_details.state_uuid", placeholder: "State", required: true, options: stateOptions }} />
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-xl-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "City", name: "customer_details.city_uuid", placeholder: "City", required: true, options: shipCityOptions }} />
                                                    </div>
                                                </div>
                                                <div className="row px-3 py-3">
                                                    <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom-user">
                                                        {/* <input type="checkbox" id="same_billing" />
                                                        <label htmlFor="same_billing">&nbsp;Billing Address is same as Shipping</label> */}
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "checkbox", label: "Billing Address is same as Shipping", name: "customer_details.billing_address_as_above" }} />
                                                    </div>
                                                </div>
                                                {formikProps.values.customer_details.billing_address_as_above === 0 && (
                                                    <>
                                                        <div className="row px-3 py-3">
                                                            <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom-user">

                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textarea", label: "Home Address", name: "customer_details.lead_cust_bill_address", placeholder: "Home Address", required: true }} />
                                                            </div>
                                                        </div>
                                                        <div className="row px-3 py-3">
                                                            <div className="col-lg-4 col-md-6 col-xl-4 text-field-empty-custom-user">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Pincode", name: "customer_details.lead_cust_bill_pincode", placeholder: "Pincode", required: true }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-6 col-xl-4 text-field-empty-custom-user">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "State", name: "customer_details.billing_address_state_uuid", placeholder: "State", required: true, options: stateOptions }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-6 col-xl-4 text-field-empty-custom-user">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "City", name: "customer_details.billing_address_city_uuid", placeholder: "City", required: true, options: billCityOptions }} />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        </div>
                                    </TabPanel>
                                    {showInterestedVehicleEntry &&
                                        <LeadInterestedVehicleEntryDialog showDialog={showInterestedVehicleEntry} closeDialog={() => setShowInterestedVehicleEntry(false)} reloadData={fetchLeadDetail} leadUUID={id} id={editInterestedVehicleID} />
                                    }
                                    {showBookVehicleEntry &&
                                        <LeadIntVehicleBookEntryDialog showDialog={showBookVehicleEntry} closeDialog={() => {
                                            setShowBookVehicleEntry(false);
                                            setBookingDetailView(false);
                                        }} reloadData={fetchLeadDetail} detailView={bookingDetailView} id={id} />
                                    }
                                    <div className="row btn-form-submit">
                                        <button className="button1" onClick={closeDialog}>Close</button>
                                        {/* {currentTab < 2 && <button className="button2" onClick={() => setCurrentTab(currentTab + 1)}>Next</button>}
                                        {detailView && currentTab > 0 && <button className="button2" onClick={() => setCurrentTab(currentTab - 1)}>Previous</button>} */}
                                        {currentTab == 2 &&
                                            <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                setFormIsValid(false);
                                                formikProps.submitForm();
                                                setTimeout(() => {
                                                    //SetTimeout will always return old value for a state. So we are fetching the new value using the setter function.
                                                    setFormIsValid(previousIsValid => {
                                                        if (!previousIsValid) {
                                                            toast.error("Please ensure all fields are Filled and Valid!");
                                                        }
                                                        return previousIsValid; // Return the same state to avoid changing it
                                                    });
                                                }, 500);
                                            }}>Save Changes</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                </Formik >
            )}
        </Drawer >
    )
}

export default LeadDetailDialog;