import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useProSidebar } from 'react-pro-sidebar';
import { EndUser, PageLinks } from "../common/Constants";
import { getDealerCode, getLoginLogo, getLoginUserName, getLoginUserType, getUserTypeDashboardLink, logout, getLoginUserNumber, getCompanyCode, getSelectedDealer, persistSelectedDealer, persistLoginDetail } from "../common/Utilities";
import DealerEntryDialog from "../forms/DealerEntryDialog";
import { APIData } from "../common/DataTypes";
import toast from "react-hot-toast";
import { useAppStateAPI } from "../common/AppStateAPI";
import { ServerAPI } from "../common/ServerAPI";

import DealerSelectionModal from "./ui_components/DealerSelectionModal";


interface Dealer {
  name: string;
  dealer_code: string;
  image_url: string;
  mobile_image_url: string;
}

const Header: React.FC = () => {
  const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
  const { collapseSidebar } = useProSidebar();
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLLIElement>(null);
  const [showProfile, setShowProfile] = useState(false);
  const [loginDealer, setLoginDealer] = useState<Dealer>();
  const [dealerDetails, setDealerDetails] = useState<APIData.DealerSelection>();
  const [selectedDealer, setSelectedDealer] = useState('');
  const [verifyOtpPopup, setVerifyOtpPopup] = useState(false);
  const [showDealerPopup, setShowDealerPopup] = useState(false);
  const customTableViewRef = useRef<HTMLDivElement | null>(null);
  const [otp, setOtp] = useState('');
  const [endUser, setEndUser] = useState(EndUser.User);
  const [dealerOption, setDealerOption] = useState(true);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (customTableViewRef.current && !customTableViewRef.current.contains(event.target as Node)) {
        setShowDealerPopup(false);
        setVerifyOtpPopup(false)
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDealerChange = (dealerCode: string) => {
    setSelectedDealer(dealerCode);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  const toggleDropdown = () => {
    setShowMenu(!showMenu);
  };

  const handleLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    logout();
    if (getLoginUserType() == EndUser.SuperAdmin) {
      navigate(PageLinks.ADMIN_LOGIN);
    } else {
      navigate(PageLinks.LOGIN);
    }
  };

  const validateLogin = () => {
    if (!getCompanyCode()) {
      toast.error('Please Enter Company Code');
    } else if (!getLoginUserNumber()) {
      toast.error('Please Enter Phone Number');
    } else {
      addProcessingRequests();
      ServerAPI.userLogin(getCompanyCode(), getLoginUserNumber()).then((response: any) => {
        if (response['success'] === true) {
          // toast.success(response['message']);
          if (response.data && response.data.dealer_details) {
            setDealerDetails(response.data.dealer_details);
          }
          // setStep(1);
        } else if (response['message']) {
          toast.error(response['message']);
        } else {
          toast.error('Something Went Wrong');
        }
      }).finally(() => {
        reduceProcessingRequests();
      })
    }
  }

  const executeSendOTP = () => {
    if (!selectedDealer) {
      toast.error('Please select a Dealer');
    } else {
      addProcessingRequests();
      ServerAPI.userSendOtp(getCompanyCode(), selectedDealer, getLoginUserNumber()).then((response: any) => {
        if (response['success'] === true) {
          toast.success(response['message']);
          persistSelectedDealer(selectedDealer)
        } else if (response['message']) {
          toast.error(response['message']);
        } else {
          toast.error('Something Went Wrong');
        }
      }).finally(() => {
        reduceProcessingRequests();
      });
    }
  }

  const executeVerifyOtp = (otp: string) => {
    if (!otp) {
      toast.error('Enter OTP');
    } else {
      addProcessingRequests();
      ServerAPI.userVerifyOtp(getCompanyCode(), getSelectedDealer(), getLoginUserNumber(), otp).then((response: any) => {
        if (response['success'] === true) {
          toast.success(response['message']);
          const userDetail = response['data'];
          persistLoginDetail(true, userDetail['uuid'], userDetail['dealer_code'], userDetail['name'], userDetail['contact_email'], userDetail['login_token'], userDetail['profile_image'], endUser, userDetail['company_code'], userDetail['contact_phone'], userDetail['short_name']);
          navigate(PageLinks.USER_DASHBOARD_TODAY, { state: { selectedDealer } });
          if (dealerDetails) {
            const loginDealer = dealerDetails?.dealers.find(dealer => dealer.dealer_code === getSelectedDealer()) || null;
            if (loginDealer) {
              setLoginDealer(loginDealer);
            }
          }
          // setEditDealerImg(selectedDealerImg);
        } else if (response['message']) {
          toast.error(response['message']);
        } else {
          toast.error('Something Went Wrong');
        }
      }).finally(() => {
        reduceProcessingRequests();
        setShowDealerPopup(false);
        setVerifyOtpPopup(false);
        setOtp('')
      });
    }
  }


  useEffect(() => {
    if (dealerDetails) {
      const loginDealer = dealerDetails.dealers.find(dealer => dealer.dealer_code === getSelectedDealer());
      if (loginDealer) {
        setLoginDealer(loginDealer);
      }
    }
  }, [dealerDetails]);

  useEffect(() => {
    if (getCompanyCode() !== '' && getLoginUserNumber() !== '') {
      validateLogin()
    }
  }, [])

  useEffect(() => {
    if (selectedDealer !== '') {
      executeSendOTP();
    }
  }, [selectedDealer]);



  return (
    <div>
      <div className="topbar">
        <div className="topbar-left">
          <Link to={getUserTypeDashboardLink()} className="logo">
            <span>
              <img src={window.location.origin + "/assets/images/logo512.png"} alt="logo-large" className="logo-lg logo-light" />
              <img src={window.location.origin + "/assets/images/logo512.png"} alt="logo-large" className="logo-lg" />
            </span>
          </Link>
        </div>
        <nav className="navbar-custom">
          <ul className="list-unstyled topbar-nav float-right mb-0">
            <li className="dropdown notification-list">
              <a className="nav-link dropdown-toggle arrow-none waves-light waves-effect" data-toggle="dropdown"
                href="#" role="button" aria-haspopup="false" aria-expanded="false">
                <i className="ti-bell noti-icon"></i>
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-lg pt-0">

                <h6
                  className="dropdown-item-text font-15 m-0 py-3 bg-primary text-white d-flex justify-content-between align-items-center">
                  Notifications <span className="badge badge-light badge-pill">2</span>
                </h6>
                <div className="slimscroll notification-list">

                  <a href="#" className="dropdown-item py-3">
                    <small className="float-right text-muted pl-2">2 min ago</small>
                    <div className="media">
                      <div className="avatar-md bg-primary">
                        <i className="la la-cart-arrow-down text-white"></i>
                      </div>
                      <div className="media-body align-self-center ml-2 text-truncate">
                        <h6 className="my-0 font-weight-normal text-dark">Your order is placed</h6>
                        <small className="text-muted mb-0">Dummy text of the printing and
                          industry.</small>
                      </div>
                    </div>
                  </a>

                  <a href="#" className="dropdown-item py-3">
                    <small className="float-right text-muted pl-2">10 min ago</small>
                    <div className="media">
                      <div className="avatar-md bg-success">
                        <i className="la la-group text-white"></i>
                      </div>
                      <div className="media-body align-self-center ml-2 text-truncate">
                        <h6 className="my-0 font-weight-normal text-dark">Meeting with designers</h6>
                        <small className="text-muted mb-0">It is a long established fact that a
                          reader.</small>
                      </div>
                    </div>
                  </a>

                  <a href="#" className="dropdown-item py-3">
                    <small className="float-right text-muted pl-2">40 min ago</small>
                    <div className="media">
                      <div className="avatar-md bg-pink">
                        <i className="la la-list-alt text-white"></i>
                      </div>
                      <div className="media-body align-self-center ml-2 text-truncate">
                        <h6 className="my-0 font-weight-normal text-dark">UX 3 Task complete.</h6>
                        <small className="text-muted mb-0">Dummy text of the printing.</small>
                      </div>
                    </div>
                  </a>

                  <a href="#" className="dropdown-item py-3">
                    <small className="float-right text-muted pl-2">1 hr ago</small>
                    <div className="media">
                      <div className="avatar-md bg-warning">
                        <i className="la la-truck text-white"></i>
                      </div>
                      <div className="media-body align-self-center ml-2 text-truncate">
                        <h6 className="my-0 font-weight-normal text-dark">Your order is placed</h6>
                        <small className="text-muted mb-0">It is a long established fact that a
                          reader.</small>
                      </div>
                    </div>
                  </a>

                  <a href="#" className="dropdown-item py-3">
                    <small className="float-right text-muted pl-2">2 hrs ago</small>
                    <div className="media">
                      <div className="avatar-md bg-info">
                        <i className="la la-check-circle text-white"></i>
                      </div>
                      <div className="media-body align-self-center ml-2 text-truncate">
                        <h6 className="my-0 font-weight-normal text-dark">Payment Successfull</h6>
                        <small className="text-muted mb-0">Dummy text of the printing.</small>
                      </div>
                    </div>
                  </a>
                </div>

                <a href="#" className="dropdown-item text-center text-primary" onClick={e => e.preventDefault()}>
                  View all <i className="fi-arrow-right"></i>
                </a>
              </div>
            </li>
            <li className="dropdown" ref={dropdownRef}>
              <button
                className="nav-link dropdown-toggle waves-effect waves-light nav-user"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
                onClick={toggleDropdown}
                style={{ background: "none", outline: "none", border: "none" }}
              >
                <img
                  src={getLoginLogo()}
                  // src={getLoginUserType() === 3 ? getLoginUserImage() ? getLoginUserImage() : window.location.origin + "/assets/images/blank-profile.png" : getLoginUserType() === 1 ? getLoginLogo() ? getLoginLogo() : window.location.origin + "/assets/images/blank-profile.png" : window.location.origin + "/assets/images/blank-profile.png"}
                  alt="profile-user"
                  className="rounded-circle"
                />
                <span className="ml-1 nav-user-name hidden-sm">
                  {getLoginUserName()} <i className="mdi mdi-chevron-down"></i>
                </span>
              </button>
              {showMenu && (
                <div className="dropdown-main">
                  <Link to="/" onClick={e => {
                    e.preventDefault();
                    setShowProfile(true);
                    setShowMenu(false);
                  }}>
                    <div className="dropdown-menu-items dropdown-menu-right show">
                      <div className="logout-spacer"></div>
                      <p className="nav-user-name mb-0">Profile</p>
                      <div className="logout-spacer"></div>
                    </div>
                  </Link>
                  <Link to="/" onClick={e => {
                    e.preventDefault();
                    handleLogout(e);
                  }} >
                    <div className="dropdown-menu-items dropdown-menu-right show">
                      <div className="logout-spacer"></div>
                      <p className="nav-user-name mb-0">Logout</p>
                      <div className="logout-spacer"></div>
                    </div>
                  </Link>
                </div>

              )}
            </li>

          </ul>

          <ul className="list-unstyled topbar-nav mb-0">
            <li>
              <button className="nav-link button-menu-mobile button-menu-mobile-hidden waves-effect waves-light"
                onClick={() => {
                  collapseSidebar();
                }}>
                <i className="ti-menu nav-icon"></i>
              </button>
            </li>
            <li>
              {dealerDetails !== undefined && dealerDetails.dealers.length > 1 && <>
                <div className="header-dealer">
                  <div className="header-dealer-con">
                    <img src={loginDealer !== undefined ? loginDealer.image_url : window.location.origin + 'assets/images/users/user-1.png'} style={{ width: '100%', height: '100%' }} />
                  </div>
                  <p className="dealer-name" onClick={() => { setShowDealerPopup(true); setDealerOption(true) }}>{loginDealer !== undefined ? loginDealer.name : 'Not Selected'} <i className="mdi mdi-chevron-down"></i></p>
                </div>
              </>}
            </li>
          </ul>
        </nav>
        {showProfile && (
          <DealerEntryDialog showDialog={showProfile} closeDialog={() => setShowProfile(false)} reloadData={() => { }} id={getDealerCode()} />
        )}


        {/* {showDealerPopup && <div className="custom-tableheight">
          <div ref={customTableViewRef} className="custom-tableview">
            <div className="custom-tableview-scroll">
              {dealerOption && dealerDetails !== undefined && (
                <>
                  <h4 className="et-custom-h4 title-text text-center">Select Dealer</h4>
                  <hr />
                  <div className="header-dealer justify-content-center">
                    <div className="header-dealer-con" style={{ height: '90px', width: '90px' }}>
                      <img
                        src={editDealerImg?.image_url || (dealerDetails.dealers.length > 0 ? dealerDetails.dealers[0].image_url : '')}
                        alt={editDealerImg?.name || (dealerDetails.dealers.length > 0 ? dealerDetails.dealers[0].name : '')}
                        style={{ width: '100%', height: '100%' }}
                      />
                    </div>
                    <select className="header-dealer-input" value={getSelectedDealer() ? getSelectedDealer() : selectedDealer || ''} onChange={e=>handleDealerChange(e.target.value)} style={{ width: '70%' }}>
                      <option value="0"></option>
                      {dealerDetails.dealers.map((dealer, dealerIdx) => (
                        <option key={dealerIdx} value={dealer.dealer_code}>{dealer.name}</option>
                      ))}
                    </select>
                  </div>
                </>
              )}
              {verifyOtpPopup && <div className="px-3">
                <div className="text-center auth-logo-text">

                  <h4 className="et-custom-h4 title-text text-center">Verify OTP</h4>
                  <hr />
                  <p className="text-muted text-muted-user-action mb-0">We Send you a 4 digit code to associated mobile number with your account</p>
                </div>
                <form className="form-horizontal auth-form" onSubmit={e => {
                  e.preventDefault();
                  executeVerifyOtp();
                }}>
                  <div className="d-flex justify-content-center mt-4 mb-0">
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      renderSeparator={<span> </span>}
                      renderInput={(props) => <input {...props} className="otp-box" />}
                    />
                  </div>
                  <div className="form-group mb-0 row">
                    <div className="col-12 mt-2 d-flex justify-content-center">
                      <button type="submit" className="btn btn-login-primary btn-round btn-block waves-effect waves-light w-80">Verify</button>
                    </div>
                  </div>
                  <div className="text-center auth-logo-text mt-4">
                    <p className="text-muted text-muted-user-action mb-0">Did'nt get a OTP? <span className="color-orange">Resend OTP</span></p>
                  </div>
                </form>
              </div>}

            </div>
          </div>
        </div>} */}
        {showDealerPopup && dealerOption && dealerDetails !== undefined && <DealerSelectionModal dealerDetails={dealerDetails} dealerSelection={handleDealerChange} showModal={dealerOption} closeModal={() => setDealerOption(false)} verifyOtp={verifyOtpPopup} executeOtp={(otp) => executeVerifyOtp(otp)} />}
      </div>
    </div >
  );
};

export default Header;
