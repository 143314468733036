import React from 'react';
import { Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { API, ProjectStrings } from "../common/Constants";
import { getDataFromAPI } from "../common/Utilities";

const TargetRulesEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, detailView?: boolean }> = ({ showDialog, closeDialog, reloadData, id, detailView }) => {
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.TargetRules>>(null);
    const [initialValue, setInitialValue] = useState<APIData.TargetRules>(InitialData.TargetRules);
    const [formDataSet, setFormDataSet] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [targetStageOptions, setTargetStageOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [showroomOptions, setShowroomOptions] = useState<FormDataTypes.SelectOption[]>([]);

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.TargetStageList, setTargetStageOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'target_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.ShowroomList, setShowroomOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'showroom_name', 'data', 'list');
    }, []);

    useEffect(() => {
        if (id !== undefined && id !== '') {
            addProcessingRequests();
            ServerAPI.getTargetRule(id).then(response => {
                if (response && response.data.details) {
                    setInitialValue(response.data.details);
                    setFormDataSet(true);
                    setEditMode(true);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else {
            setFormDataSet(true);
        }
    }, [id]);

    const yupSchema = Yup.object().shape({
        target_stages_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        showroom_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        rules: Yup.string().required(ProjectStrings.ValidationRequired),
    });

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-min">
            {formDataSet && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };
                    if (id != undefined && id != '') {
                        addProcessingRequests();
                        ServerAPI.updateTargetRules(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    } else {
                        addProcessingRequests();
                        ServerAPI.addTargetRules(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    }
                }} >
                    {(formikProps) => {
                        return (
                            <div className="tabdetails">
                                <h4 className="title-text text-center">{editMode ? 'Edit' : 'Add New'} Target Rules</h4>
                                <div className="custom-border-grey my-2"></div>
                                <p className="detailed-heading">Target Rules Details</p>
                                <div className="tabpage">
                                    <div className="custom-border-grey my-2"></div>
                                    <Form>
                                        <div className="px-2 form-field-container">
                                            <div className="row px-2 py-3">
                                                <div className="col-md-12 col-lg-12 col-xl-12 text-field-empty-custom-user">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "target_stages_uuid", label: "Target Stage", placeholder: "Target Stage", required: true, options: targetStageOptions }} />
                                                </div>
                                            </div>
                                            <div className="row px-2 py-3">
                                                <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom-user">
                                                    {/* <label className="label-custom-user"><p>Department</p></label> */}
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Showroom", name: "showroom_uuid", placeholder: "Showroom", required: true, options: showroomOptions }} />
                                                </div>
                                            </div>
                                            <div className="row px-2 py-3">
                                                <div className="col-md-12 col-lg-12 col-xl-12 text-field-empty-custom-user">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", name: "rules", label: "Rules", placeholder: "Rules", required: true }} />
                                                </div>
                                            </div>
                                            {/* <div className="row px-2 py-3">
                                                    <div className="col-md-12 col-lg-12 col-xl-12 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "switch", label: "Status", name: "status", readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div> */}
                                        </div>
                                        <div className="row btn-form-submit">
                                            <button type="button" className="button1" onClick={closeDialog}>Cancel</button>
                                            <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                formikProps.submitForm();
                                                if (!formikProps.isValid) {
                                                    toast.error("Please ensure all fields are Filled and Valid!");
                                                }
                                            }}>Submit</button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        )
                    }}
                </Formik>
            )
            }
        </Drawer>
    )
};

export default TargetRulesEntryDialog;