import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { APIData, FormDataTypes } from '../../common/DataTypes';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { getDataFromAPI } from '../../common/Utilities';
import { useAppStateAPI } from '../../common/AppStateAPI';
import { API } from '../../common/Constants';

const UserLeadsFilterModal: React.FC<{ showModal: boolean, closeModal: () => void, filterLeads: any }> = ({ showModal, closeModal, filterLeads }) => {
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [modelOptions, setModelOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [leadTaskOptions, setLeadTaskOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [LeadCatedgoryOptions, setLeadCatedgoryOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const customStyles = { control: (provided: any) => ({ ...provided, border: '2px solid #D3D3D3', '&:hover': { borderColor: '#14408B' }, borderRadius: '10px' }) };
    const [modelUUID, setModelUUID] = useState<string>("");
    const [leadSourceCategoryUUID, setLeadSourceCategoryUUID] = useState<string>("");
    const [task, setTask] = useState<string>("");

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.ModelList, setModelOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'model_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.LeadTaskList, setLeadTaskOptions, addProcessingRequests, reduceProcessingRequests, null, true, 'uuid', 'lead_task_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.LeadSourceCategoryList, setLeadCatedgoryOptions, addProcessingRequests, reduceProcessingRequests, null, true, 'uuid', 'lead_source_category_name', 'data');
    }, []);

    const handleFilter = () => {
        const filters: APIData.FilterLeads = {
            modelUUID: modelUUID,
            leadSourceCategoryUUID: leadSourceCategoryUUID,
            task: task
        };
        filterLeads(filters);
    };

    const clearFilter = () => {
        setModelUUID("");
        setLeadSourceCategoryUUID("");
        setTask("");
    };

    const handleModelChange = (selectedOption: FormDataTypes.SelectOption | null) => {
        setModelUUID(selectedOption?.value || "");
    };

    const handleLeadCategoryChange = (selectedOption: FormDataTypes.SelectOption | null) => {
        setLeadSourceCategoryUUID(selectedOption?.value || "");
    };

    const handleTaskChange = (selectedOption: FormDataTypes.SelectOption | null) => {
        setTask(selectedOption?.value || "");
    };

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        minWidth: '400px',
        minHeight: '380px'
    };

    return (
        <div className='tabdetails' style={{ height: "87vh", overflowY: "auto" }}>
            <h4 className="title-text text-center" style={{ fontWeight: "400" }}>Filter</h4>
            <div className="custom-border-grey my-2"></div>
            <div className='px-3 pb-2'>
                <div className="text-center auth-logo-text pb-2">
                    {/* <h4 className="mt-0 mb-3">Filter</h4> */}
                </div>
                <div className="form-group-1 text-left mb-4">
                    <div className="ew-section-2">
                        <div className="text-field-empty-custom-user lead-select">
                            <label className='label-custom' >Model</label>
                            <Select
                                styles={customStyles}
                                onChange={handleModelChange}
                                value={modelOptions.find(option => option.value === modelUUID) || null}
                                options={modelOptions}
                                placeholder={"Select Model"}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group-1 text-left mb-4">
                    <div className="ew-section-2">
                        <div className="text-field-empty-custom-user lead-select">
                            <label className='label-custom' >Lead Source Category</label>
                            <Select
                                styles={customStyles}
                                onChange={handleLeadCategoryChange}
                                value={LeadCatedgoryOptions.find(option => option.value === leadSourceCategoryUUID) || null}
                                options={LeadCatedgoryOptions}
                                placeholder={"Select Lead Source Category"}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group-1 text-left mb-4">
                    <div className="ew-section-2">
                        <div className="text-field-empty-custom-user lead-select">
                            <label className='label-custom' >Task</label>
                            <Select
                                styles={customStyles}
                                onChange={handleTaskChange}
                                value={leadTaskOptions.find(option => option.value === task) || null}
                                options={leadTaskOptions}
                                placeholder={"Select Task"}
                            />
                        </div>
                    </div>
                </div>
                <div className="row btn-form-submit" style={{ position: "absolute" }}>
                    <button type="button" className="button1" onClick={clearFilter}>Clear</button>
                    <button type="button" className="button2" onClick={() => { handleFilter(); closeModal() }}>Filter</button>
                </div>
            </div>
        </div>
    );
};

export default UserLeadsFilterModal;
