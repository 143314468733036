import { useEffect, useState } from "react";
import { TestDriveStatusValues } from "../../common/Constants";

const CellTestdriveStatus: React.FC<{ data: any, data_key: string }> = ({ data, data_key }) => {

    const [statusClass, setStatusClass] = useState('');
    const [testDriveStatusString, setTestDriveStatusString] = useState('');

    useEffect(() => {
        if (data !== undefined && data[data_key] !== undefined) {
            if (data[data_key] == TestDriveStatusValues.SCHEDULED) {
                setStatusClass("badge-soft-scheduled");
                setTestDriveStatusString("Scheduled");
            } else if (data[data_key] == TestDriveStatusValues.COMPLETED) {
                setStatusClass("badge-soft-success");
                setTestDriveStatusString("Completed");
            } else if (data[data_key] == TestDriveStatusValues.ON_GOING) {
                setStatusClass("badge-soft-info");
                setTestDriveStatusString("Ongoing");
            } else if (data[data_key] == TestDriveStatusValues.CANCELLED) {
                setStatusClass("badge-soft-danger");
                setTestDriveStatusString("Cancelled");
            }
        }
    }, [data]);

    return (
        <span className={'badge ' + statusClass}>{testDriveStatusString}</span>
    )

}

export default CellTestdriveStatus;
