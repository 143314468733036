import { useState } from "react";
import ConfirmDialog from "../ConfirmDialog";
import { useAppStateAPI } from "../../common/AppStateAPI";
import { PageMappings } from "../../common/PageMappings";
import { TableViewMappings } from "../../common/TableViewMappings";
import { ServerAPI } from "../../common/ServerAPI";
import toast from "react-hot-toast";
import { TestDriveStatusValues } from "../../common/Constants";

const ActionTestDriveVerify: React.FC<{ data: any, params: { [k: string]: any }, pageMapping: PageMappings.PageDetail, tableDetail: TableViewMappings.TableDetail, reloadData: any }> = ({ data, reloadData }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [showVerifyConfirm, setShowVerifyConfirm] = useState(false);

    const verifyTestDrive = () => {
        addProcessingRequests();
        ServerAPI.verifyTestDrive({ test_drive_uuid: data['uuid'] }).then(response => {
            if (response && response['success'] && response['message']) {
                toast.success(response['message']);
                reloadData();
                setShowVerifyConfirm(false);
            } else if (response && response['message']) {
                toast.error(response['message']);
            } else {
                toast.error('Unknown Error Occurred');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    return (

        <>
            {(data['status'] == TestDriveStatusValues.SCHEDULED || data['status'] == TestDriveStatusValues.ON_GOING) && (
                <button type="button" className="btn btn-no-style px-6" title="Verify" onClick={() => {
                    setShowVerifyConfirm(true);
                }}>
                    <i className="fa-solid fa-person-circle-check font-16"></i>
                </button>
            )}
            <ConfirmDialog showConfirm={showVerifyConfirm} confirmHeading={'Verify Confirm'} confirmMsg={'Are you sure you want to verify this Test Drive?'} handleNo={() => {
                setShowVerifyConfirm(false);
            }} handleYes={() => {
                verifyTestDrive();
                setShowVerifyConfirm(false);
            }} />
        </>
    )
}

export default ActionTestDriveVerify;