import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { EndUser, PageLinks } from "../common/Constants";
import { UIDataTypes } from "../common/DataTypes";
import { useAppStateAPI } from "../common/AppStateAPI";
import ApartmentIcon from '@mui/icons-material/Apartment';
import HandshakeIcon from '@mui/icons-material/Handshake';
import PeopleIcon from '@mui/icons-material/People';
import GroupsIcon from '@mui/icons-material/Groups';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { getLoginUserType } from "../common/Utilities";
import { Dashboard } from "@mui/icons-material";

const superAdminSideMenuItems: UIDataTypes.MenuItem[] = [
  {
    label: 'Companies',
    icon: <ApartmentIcon />,
    iconClass: 'sidebar-icon iconly-Curved-Bag2',
    subMenus: [
      {
        label: 'All Companies',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-Curved-Bag2',
        menuLink: PageLinks.ADMIN_COMPANIES
      }
    ]
  },
  {
    label: 'Dealers',
    icon: <HandshakeIcon />,
    iconClass: 'sidebar-icon iconly-Curved-Chart',
    subMenus: [
      {
        label: 'All Dealers',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.ADMIN_DEALERS
      },
    ]
  },
  {
    label: 'Masters',
    icon: <GroupsIcon />,
    iconClass: 'sidebar-icon iconly-Curved-Chart',
    subMenus: [
      {
        label: 'Menus',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.ADMIN_MENUS
      },
      {
        label: 'Provider',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.ADMIN_PROVIDERS
      },
      {
        label: 'Provider Category',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.ADMIN_PROVIDER_CATEGORY
      },
      {
        label: 'Business',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.ADMIN_BUSINESS
      },
      {
        label: 'Business Mode',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.ADMIN_BUSINESS_MODE
      },
      {
        label: 'Category',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.ADMIN_CATEGORY
      },
      {
        label: 'Category Type',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.ADMIN_CATEGORY_TYPE
      },
      {
        label: 'Version',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.ADMIN_VERSION
      },
    ]
  },
]

const dealerSideMenuItems: UIDataTypes.MenuItem[] = [
  {
    label: 'Users',
    icon: <ApartmentIcon />,
    iconClass: 'sidebar-icon iconly-Curved-Bag2',
    subMenus: [
      {
        label: 'All Users',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-Curved-Bag2',
        menuLink: PageLinks.DEALER_USERS
      },
      {
        label: 'Teams',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-Curved-Bag2',
        menuLink: PageLinks.DEALER_TEAMS
      }
    ]
  },

  {
    label: 'Masters',
    icon: <GroupsIcon />,
    iconClass: 'sidebar-icon iconly-Curved-Chart',
    subMenus: [
      {
        label: 'Locations',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_LOCATIONS
      },
      {
        label: 'Model',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_MODEL
      },
      {
        label: 'Variant',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_MODEL_VARIANT
      },
      /*{
        label: 'Color',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_COLOR
      },*/
      {
        label: 'Fuel Type',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_FUEL_TYPE
      },
      {
        label: 'Demo Vehicles',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_DEMO_CAR
      },
      {
        label: 'Body Type',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_BODY_TYPE
      },
      {
        label: 'Transmission Type',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_TRANSMISSION_TYPE
      },
      {
        label: 'Interior Color',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_INTERIOR_COLOR
      },
      {
        label: 'Exterior Color',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_EXTERIOR_COLOR
      },
      {
        label: 'Role',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_ROLE
      },
      // {
      //   label: 'Category',
      //   icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
      //   iconClass: 'sidebar-icon iconly-radio-button',
      //   menuLink: PageLinks.DEALER_CATEGORY
      // },
      {
        label: 'State',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_STATE
      },
      {
        label: 'City',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_CITY
      },
      {
        label: 'Pincode',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_PINCODE
      },
      /*{
        label: 'Lead Category',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_LEAD_CATEGORY
      },*/
      {
        label: 'Customer Type',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_CUSTOMER_TYPE
      },
      {
        label: 'Lead Source',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_LEAD_SOURCE
      },
      {
        label: 'Lead Source Category',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_LEAD_SOURCE_CATEGORY
      },
      {
        label: 'Lead Status',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_LEAD_STATUS
      },
      {
        label: 'Insurance Type',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_INSURANCE_TYPE
      },
      {
        label: 'Insurance Companies',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_INSURANCE_COMPANIES
      },
      {
        label: 'Lead Type',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_LEAD_TYPE
      },
      {
        label: 'Lead Task',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_LEAD_TASK
      },
      {
        label: 'Prospect Type',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_LEAD_PROSPECT_TYPE
      },
      {
        label: 'Lead Reason',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_LEAD_REASON
      },
      {
        label: 'Lead Title',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_LEAD_TITLE
      },
      {
        label: 'Payment Mode',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_PAYMENT_MODE
      },
      {
        label: 'Target Rules',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_TARGET_RULES
      },
      // {
      //   label: 'Category Type',
      //   icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
      //   iconClass: 'sidebar-icon iconly-radio-button',
      //   menuLink: PageLinks.DEALER_CATEGORY_TYPE
      // },
    ]
  },

  {
    label: 'Menu Settings',
    icon: <HandshakeIcon />,
    iconClass: 'sidebar-icon iconly-Curved-Chart',
    subMenus: [
      {
        label: 'Test Drive',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_TESTDRIVE
      },
      {
        label: 'Leads',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_LEADS_TODAY
      },
    ]
  },

  {
    label: 'Report',
    icon: <HandshakeIcon />,
    iconClass: 'sidebar-icon iconly-Curved-Chart',
    subMenus: [
      {
        label: 'Test Drive',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.DEALER_REPORT_TESTDRIVE
      },
    ]
  },
]

const companySideMenuItems: UIDataTypes.MenuItem[] = [
  {
    label: 'Dashboard',
    icon: <PeopleIcon />,
    iconClass: 'sidebar-icon iconly-Curved-Bag2',
    subMenus: [
      {
        label: 'All Dealers',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-Curved-Bag2',
        menuLink: PageLinks.COMPANY_DEALERS
      }
    ]
  },
  {
    label: 'Users',
    icon: <GroupsIcon />,
    iconClass: 'sidebar-icon iconly-Curved-Chart',
    subMenus: [
      {
        label: 'All Users',
        icon: <RadioButtonUncheckedIcon className="custom-small-icon" />,
        iconClass: 'sidebar-icon iconly-radio-button',
        menuLink: PageLinks.COMPANY_USERS
      },
    ]
  },
]

const userSideMenuItems: UIDataTypes.MenuItem[] = [
  {
    label: 'Dashboard',
    icon: <Dashboard />,
    iconClass: 'sidebar-icon iconly-Curved-Chart',
    menuLink: PageLinks.USER_DASHBOARD_TODAY
  },
  {
    label: 'TestDrive',
    icon: <img src="/assets/svg/testdrive.svg" />,
    iconClass: 'sidebar-icon iconly-Curved-Chart',
    menuLink: PageLinks.USER_TESTDRIVE_DASHBOARD
  },
  {
    label: 'My Tasks',
    icon: <img src="/assets/svg/my-leads.svg" />,
    iconClass: 'sidebar-icon iconly-Curved-Chart',
    menuLink: PageLinks.USER_MY_TASKS
  },
  {
    label: 'My Leads',
    icon: <img src="/assets/svg/my-leads.svg" />,
    iconClass: 'sidebar-icon iconly-Curved-Chart',
    menuLink: PageLinks.USER_MY_LEADS
  },
  {
    label: 'Enquiry Transfer',
    icon: <img src="/assets/svg/enquiry-t.svg" />,
    iconClass: 'sidebar-icon iconly-Curved-Chart',
    menuLink: PageLinks.USER_ENQUIRY_TRANSFER
  },
  {
    label: 'Target',
    icon: <img src="/assets/svg/target.svg" />,
    iconClass: 'sidebar-icon iconly-Curved-Chart',
    menuLink: PageLinks.USER_TARGETS
  },
  {
    label: 'Available Inventory',
    icon: <img src="/assets/svg/my-leads.svg" />,
    iconClass: 'sidebar-icon iconly-Curved-Chart',
    menuLink: PageLinks.USER_AVAILABLE_INVENTORY
  },
  {
    label: 'Report',
    icon: <img src="/assets/svg/my-leads.svg" />,
    iconClass: 'sidebar-icon iconly-Curved-Chart',
    menuLink: PageLinks.USER_REPORT
  },
  {
    label: 'Help & Support',
    icon: <img src="/assets/svg/my-leads.svg" />,
    iconClass: 'sidebar-icon iconly-Curved-Chart',
    menuLink: PageLinks.USER_HELP_SUPPORT
  },
]
const userStockManagerSideMenuItems: UIDataTypes.MenuItem[] = [
  {
    label: 'Inventory',
    icon: <img src="/assets/svg/my-leads.svg" />,
    iconClass: 'sidebar-icon iconly-Curved-Chart',
    menuLink: PageLinks.USER_INVENTORY
  },
  {
    label: 'Inventory Price',
    icon: <img src="/assets/svg/my-leads.svg" />,
    iconClass: 'sidebar-icon iconly-Curved-Chart',
    menuLink: PageLinks.USER_INVENTORY_PRICE
  },
  {
    label: 'Allotment List',
    icon: <img src="/assets/svg/my-leads.svg" />,
    iconClass: 'sidebar-icon iconly-Curved-Chart',
    menuLink: PageLinks.USER_ALLOTMENT_LIST
  },
  {
    label: 'Help & Support',
    icon: <img src="/assets/svg/my-leads.svg" />,
    iconClass: 'sidebar-icon iconly-Curved-Chart',
    menuLink: PageLinks.USER_HELP_SUPPORT
  },
]

const AppSidebar: React.FC = () => {
  const { currentPage, setCurrentPage } = useAppStateAPI();
  const [sideBar, setSideBar] = useState<UIDataTypes.MenuItem[]>([]);
  const [sideBarkey, setSideBarKey] = useState(Math.random());
  const [openedMenu, setOpenedMenu] = useState('');
  const userType = getLoginUserType();
  const loginRole = localStorage.getItem("short_name");

  useEffect(() => {
    const userType = getLoginUserType();
    if (userType === EndUser.SuperAdmin) {
      setSideBar(superAdminSideMenuItems);
    } else if (userType === EndUser.Dealer) {
      setSideBar(dealerSideMenuItems);
    } else if (userType === EndUser.Company) {
      setSideBar(companySideMenuItems);
    } else if (userType === EndUser.User && loginRole !== "SM") {
      setSideBar(userSideMenuItems);
    } else if (userType === EndUser.User && loginRole === "SM") {
      setSideBar(userStockManagerSideMenuItems);
    }
  }, [])

  const handleSubMenuClick = (menu: string) => {
    if (openedMenu === menu) {
      setOpenedMenu(''); // Toggle off if clicked on the active menu
    } else {
      setOpenedMenu(menu);
    }
    setSideBarKey(Math.random());
  };

  return (
    <div>
      <div>
        <Sidebar style={{ height: "100vh", textAlign: "start", backgroundColor: userType === EndUser.User ? "#14408B" : "#F7FAFF" }} className="metismenu left-sidenav-menu" key={sideBarkey}>
          {/* <Menu>
            {sideBar.map((menuItem, index) => {
              if (menuItem.subMenus === undefined) {
                return (
                  <MenuItem style={{ backgroundColor: userType === EndUser.User ? "#14408B" : "#F7FAFF", color: userType === EndUser.User ? "#F7FAFF" : "#14408B" }} active={currentPage === menuItem.menuLink} key={index} icon={menuItem.icon}>
                    <Link style={{ color: userType === EndUser.User ? "#F7FAFF" : "#14408B" }} to={menuItem.menuLink ? menuItem.menuLink : ''} onClick={() => setCurrentPage(menuItem.menuLink)}>{menuItem.label}</Link>
                  </MenuItem>
                )
              } else {
                return (
                  <SubMenu label={menuItem.label} icon={menuItem.icon} open={menuItem.label === openedMenu} onClick={() => handleSubMenuClick(menuItem.label)} key={index}>
                    {menuItem.subMenus.map((subMenu, subIdx) => {
                      return (
                        <Link style={{ backgroundColor: userType === EndUser.User ? "#14408B" : "#F7FAFF" }} to={subMenu.menuLink ? subMenu.menuLink : ''} onClick={() => setCurrentPage(subMenu.menuLink)} key={subIdx}>
                          <MenuItem style={{ color: userType === EndUser.User ? "#F7FAFF" : "#14408B" }} active={currentPage === subMenu.menuLink} icon={subMenu.icon}>
                            {subMenu.label}
                          </MenuItem>
                        </Link>
                      )
                    })}
                  </SubMenu>
                )
              }
            })
            }
          </Menu> */}
          <Menu>
            {sideBar.map((menuItem, index) => {
              if (menuItem.subMenus === undefined) {
                return (
                  <Link style={{ color: userType === EndUser.User ? "#F7FAFF" : "#14408B" }} to={menuItem.menuLink ? menuItem.menuLink : ''} onClick={() => setCurrentPage(menuItem.menuLink)} key={index}>
                    <MenuItem style={{ backgroundColor: userType === EndUser.User ? "#14408B" : "#F7FAFF", color: userType === EndUser.User ? "#F7FAFF" : "#14408B" }} active={currentPage === menuItem.menuLink} icon={menuItem.icon}>{menuItem.label}</MenuItem>
                  </Link>
                );
              } else {
                return (
                  <SubMenu label={menuItem.label} icon={menuItem.icon} open={menuItem.label === openedMenu} onClick={() => handleSubMenuClick(menuItem.label)} key={index}>
                    {menuItem.subMenus.map((subMenu, subIdx) => {
                      return (
                        <Link style={{ backgroundColor: userType === EndUser.User ? "#14408B" : "#F7FAFF" }} to={subMenu.menuLink ? subMenu.menuLink : ''} onClick={() => setCurrentPage(subMenu.menuLink)} key={subIdx}>
                          <MenuItem style={{ color: userType === EndUser.User ? "#F7FAFF" : "#14408B" }} active={currentPage === subMenu.menuLink} icon={subMenu.icon}>{subMenu.label}</MenuItem>
                        </Link>
                      );
                    })}
                  </SubMenu>
                );
              }
            })}
          </Menu>
        </Sidebar>
        {/* <Menu>
            {dealerSideMenuItems.map((menuItem, index) => {
              if (menuItem.subMenus == undefined) {
                if (menuItem.restrictUserTypes === undefined || menuItem.restrictUserTypes.filter(restrictUserType => restrictUserType == userType).length > 0) {
                  return (
                    <MenuItem active={currentPage == menuItem.menuLink} key={index} icon={menuItem.icon}>
                      <Link to={menuItem.menuLink ? menuItem.menuLink : ''} onClick={() => setCurrentPage(menuItem.menuLink)}>{menuItem.label}</Link>
                    </MenuItem>
                  )
                }
              } else {
                return (
                  <SubMenu label={menuItem.label} icon={menuItem.icon}>
                    {menuItem.subMenus.map((subMenu, subIdx) => {
                      return (
                        <Link to={subMenu.menuLink ? subMenu.menuLink : ''} onClick={() => setCurrentPage(subMenu.menuLink)}>
                          <MenuItem active={currentPage == subMenu.menuLink} key={subIdx} icon={subMenu.icon}>
                            {subMenu.label}
                          </MenuItem>
                        </Link>
                      )
                    })}
                  </SubMenu>
                )
              }
            })
            }
          </Menu> */}
      </div>
    </div >
  );
}
export default AppSidebar;