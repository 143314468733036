const CellTDAddress: React.FC<{ data: any }> = ({ data }) => {

    if (data["test_drive_location_name"] === "Home") {
        return (
            <>{data["test_drive_address"]}
            </>
        )
    } else if (data["test_drive_location_name"] === "Showroom") {
        return (
            <>
                {data["showroom_name"]}
            </>
        )
    } else {
        return <></>
    }

}

export default CellTDAddress;