import { Drawer } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from 'yup';
import React, { useEffect, useRef, useState } from "react";
import FormField from "../components/form_items/FormField";
import { InitialData } from "./InitialData";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { useAppStateAPI } from "../common/AppStateAPI";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import { getDataFromAPI } from "../common/Utilities";
import { API, ProjectStrings } from "../common/Constants";

interface LostDropEntryDialogProps {
    showDialog: boolean;
    closeDialog: () => void;
    reloadData?: () => void;
    id?: string;
    resetState: () => void;
    updateSubmitValues: APIData.UpdateLeadFollowUp | undefined;
    resetUpdateSubmitvalues: () => void;
    updateCloseDialog: any,
}

const LostDropEntryDialog: React.FC<LostDropEntryDialogProps> = ({ showDialog, closeDialog, reloadData, id, resetState, updateCloseDialog, updateSubmitValues, resetUpdateSubmitvalues }) => {
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [initialValue, setInitialValue] = useState<APIData.LostDrop>(InitialData.LostDrop);
    const formikRef = useRef<FormikProps<APIData.LostDrop>>(null);
    const [lostDropList, setLostDropList] = useState<APIData.LostDropList>();
    const [formDataSet, setFormDataSet] = useState(false);
    const closeLeadOptions: FormDataTypes.SelectOption[] = [{ label: 'Yes', value: "1" }, { label: "No", value: "0" }];
    const lostDropOptions: FormDataTypes.SelectOption[] = [{ label: 'Lost', value: "1" }, { label: "Drop", value: "2" }];
    const [noOptions, setNoOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [dropReasonOptions, setDropReasonOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedDropReasonOptions, setSelectedDropReasonOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedLostReasonOptions, setSelectedLostReasonOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [LostReasonOptions, setLostReasonOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedNoOptions, setSelectedNoOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [dropSubReasonOptions, setDropSubReasonOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [lostSubReasonOptions, setLostSubReasonOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedLostDrop, setSelectedLostDrop] = useState<number>(0);
    const [isLostDrop, setIsLostDrop] = useState<string>("");
    const [selectedNoParentOption, setSelectedNoParentOption] = useState('');
    const [selectedDropParentOption, setSelectedDropParentOption] = useState('');
    const [selectedLostParentOption, setSelectedLostParentOption] = useState('');
    const [selectedLostSubOption, setSelectedLostSubOption] = useState('');
    const [selectedLostSubReasonOptions, setSelectedLostSubReasonOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [assignedToOptions, setAssignedToOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const LostDropRef = useRef<HTMLDivElement | null>(null);
    // console.log(selectedDropParentOption, "drop")
    // console.log(selectedLostParentOption, "lost")
    // console.log(dropReasonOptions, "droplist")
    // console.log(selectedDropReasonOptions, "dropSelist")
    // console.log(selectedLostReasonOptions, "lostSelist")
    // console.log(noOptions, "noOption")
    // console.log(selectedNoOptions, "noSeOption")
    // console.log(LostReasonOptions, "lostlist")
    // console.log(selectedLostDrop, "lostdrop")
    // console.log(parseInt(isLostDrop), "lostdrop1")
    // console.log(formikRef.current?.values, "formik")
    // console.log(lostDropList, "lostdrop List")


    const fetchLostDropList = () => {
        if (isLostDrop !== "") {
            addProcessingRequests();
            ServerAPI.getLostDrop().then((response: any) => {
                if (response && response.data) {
                    //lostdroplist
                    setLostDropList({
                        data: { lost: response.data.lost, drop: response.data.drop, no: response.data.no }
                    });
                } else if (response['message']) {
                    toast.error(response['message']);
                } else {
                    toast.error('Something Went Wrong');
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else {
            setDropSubReasonOptions([]);
            setSelectedDropReasonOptions([]);
            setDropReasonOptions([]);
            setLostReasonOptions([]);
            setSelectedLostReasonOptions([]);
            setLostSubReasonOptions([]);
            setSelectedLostDrop(0);
            setSelectedDropParentOption("");
            setSelectedLostParentOption("");
            setSelectedNoParentOption("");
            setNoOptions([]);
            setSelectedNoOptions([]);
            setIsLostDrop("");
            setSelectedLostSubOption("");
        }
    };


    useEffect(() => {
        fetchLostDropList();
        console.log("executed fetch")
        setFormDataSet(true);
    }, [isLostDrop]);


    useEffect(() => {
        if (lostDropList &&
            lostDropList.data &&
            lostDropList.data.drop &&
            lostDropList.data.drop.drop_reason &&
            lostDropList.data.drop.drop_reason.length > 0 &&
            lostDropList.data.lost &&
            lostDropList.data.lost.lost_reason &&
            lostDropList.data.lost.lost_reason.length > 0) {
            //nodata
            if (isLostDrop === "0") {
                const noData = lostDropList.data.no.map((item: any) => ({
                    label: item.no_option_reason_name,
                    value: item.no_option_reason_uuid
                }));
                setNoOptions(noData);
            }
            //selectedNoData
            if (isLostDrop === "0" && selectedNoParentOption !== "") {
                const selectedNoData = lostDropList.data.no
                    .filter((item: any) => item.no_option_reason_uuid === selectedNoParentOption)
                    .map((item: any) => ({
                        label: item.no_option_reason_name,
                        value: item.no_option_reason_uuid
                    }));
                setSelectedNoOptions(selectedNoData);
            }
            //dropData
            if (selectedLostDrop === 2 && isLostDrop === "1") {
                const dropReason = lostDropList.data.drop.drop_reason
                    .map((item: any) => ({
                        label: item.drop_reason_name,
                        value: item.drop_reason_uuid
                    }));
                setDropReasonOptions(dropReason);
            };
            //lostData
            if (selectedLostDrop === 1 && isLostDrop === "1") {
                const lostReason = lostDropList.data.lost.lost_reason
                    .map((item: any) => ({
                        label: item.lost_reason_name,
                        value: item.lost_reason_uuid
                    }));
                setLostReasonOptions(lostReason);
            };
            // selectedDropData
            if (selectedDropParentOption !== "" && selectedLostDrop === 2 && isLostDrop === "1") {
                const dropReasonObj = lostDropList.data.drop.drop_reason.filter((item: any) => item.drop_reason_uuid === selectedDropParentOption)
                    .map((item: any) => item)
                if (dropReasonObj.length > 0) {
                    setSelectedDropReasonOptions([{ label: dropReasonObj[0].drop_reason_name, value: dropReasonObj[0].drop_reason_uuid }])
                    if (dropReasonObj) {
                        const dropSubReasons = dropReasonObj[0].drop_sub_selection.map((subItem: any) => ({
                            label: subItem.drop_sub_reason_name,
                            value: subItem.drop_sub_reason_uuid
                        }));
                        setDropSubReasonOptions(dropSubReasons);
                        // console.log(dropReasonObj, "sub drop")
                    } else {
                        setDropSubReasonOptions([]);
                    }
                }
            }
            // selectedlostData
            if (selectedLostParentOption !== "" && selectedLostDrop === 1 && isLostDrop === "1") {
                const lostReasonObj = lostDropList.data.lost.lost_reason.filter((item: any) => item.lost_reason_uuid === selectedLostParentOption)
                    .map((item: any) => item)
                if (lostReasonObj.length > 0) {
                    setSelectedLostReasonOptions([{ label: lostReasonObj[0].lost_reason_name, value: lostReasonObj[0].lost_reason_uuid }]);
                    if (lostReasonObj) {
                        const lostSubReasons = lostReasonObj[0].lost_sub_selection.map((subItem: any) => ({
                            label: subItem.sub_lost_reason_name,
                            value: subItem.sub_lost_reason_uuid
                        }));
                        setLostSubReasonOptions(lostSubReasons);
                    } else {
                        setLostSubReasonOptions([]);
                    }
                }
            }
        }
    }, [selectedNoParentOption, selectedDropParentOption, selectedLostParentOption, selectedLostDrop, isLostDrop, lostDropList]);


    useEffect(() => {
        setFormDataSet(true);
        getDataFromAPI(API.EndPoint.LeadAssignToList, setAssignedToOptions, addProcessingRequests, reduceProcessingRequests, null, true, 'uuid', 'name', 'data');
    }, []);

    useEffect(() => {
        if (isLostDrop === "0") {
            setSelectedDropParentOption("");
            setDropReasonOptions([]);
            setSelectedDropReasonOptions([]);
            setDropSubReasonOptions([]);
            setSelectedLostParentOption("");
            setLostReasonOptions([]);
            setSelectedLostReasonOptions([]);
            setLostSubReasonOptions([]);
            setSelectedLostSubReasonOptions([]);

        } else if (isLostDrop === "1") {
            setNoOptions([]);
            setSelectedNoParentOption("");
            setSelectedNoOptions([]);
        }
    }, [isLostDrop]);

    useEffect(() => {
        if (selectedLostDrop === 1) {
            setSelectedDropParentOption("");
            setDropSubReasonOptions([]);
            setSelectedDropReasonOptions([]);
            setDropReasonOptions([]);


        } else if (selectedLostDrop === 2) {
            setSelectedLostParentOption("");
            setSelectedLostSubOption("");
            setLostReasonOptions([]);
            setSelectedLostReasonOptions([]);
            setLostSubReasonOptions([]);
        }
    }, [selectedLostDrop]);

    useEffect(() => {
        const filteredItem = lostSubReasonOptions.find((item: any) => item.value === selectedLostSubOption);
        if (filteredItem) {
            setSelectedLostSubReasonOptions([filteredItem])
        }
        // console.log("Selected Sub Option:", filteredItem);
    }, [selectedLostSubOption, lostSubReasonOptions]);




    const yupSchema = Yup.object().shape({
        do_you_want_to_close_this_lead: Yup.string().required(ProjectStrings.ValidationRequired),
        closing_lead_type: Yup.string().when('do_you_want_to_close_this_lead', ([do_you_want_to_close_this_lead], schema) => {
            if (parseInt(do_you_want_to_close_this_lead) === 1)
                return Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired)
            return schema;
        }),
    });


    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="lost-drop-section drawer-min-1">
            {formDataSet && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} innerRef={formikRef} initialValues={initialValue} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };
                    submitValues['lead_uuid'] = id;

                    if (submitValues['do_you_want_to_close_this_lead'] === "0") {
                        submitValues['sub_option_uuid'] = "";
                        submitValues['lost_to_delear_name'] = "";
                        submitValues['lost_to_car_model'] = "";
                        submitValues['lost_to_brand'] = "";
                        submitValues['closing_lead_type'] = "0";
                        submitValues['customer_tentative_purchase_date'] = "";
                        submitValues['other_reason'] = "";
                    }
                    if (selectedLostReasonOptions[0]?.label === "Bought Used Car" && submitValues['do_you_want_to_close_this_lead'] === "1" && submitValues['closing_lead_type'] === "1") {
                        submitValues['sub_option_uuid'] = "";
                        submitValues['customer_tentative_purchase_date'] = "";
                        submitValues['assign_to_another_executive'] = "";
                        submitValues['other_reason'] = "";
                    }

                    if (selectedLostReasonOptions[0]?.label === "Bought New Car" && submitValues['do_you_want_to_close_this_lead'] === "1" && submitValues['closing_lead_type'] === "1") {
                        submitValues['customer_tentative_purchase_date'] = "";
                        submitValues['assign_to_another_executive'] = "";
                        submitValues['other_reason'] = "";
                        if (selectedLostSubReasonOptions[0]?.label !== "Others") {
                            submitValues['lost_to_delear_name'] = "";
                            submitValues['lost_to_car_model'] = "";
                            submitValues['lost_to_brand'] = "";
                        }
                    }

                    if (submitValues['do_you_want_to_close_this_lead'] === "1" && submitValues['closing_lead_type'] === "2") {
                        submitValues['assign_to_another_executive'] = "";
                        submitValues['lost_to_delear_name'] = "";
                        submitValues['lost_to_car_model'] = "";
                        submitValues['lost_to_brand'] = "";
                        if (submitValues['customer_tentative_purchase_date'] !== "" || selectedDropReasonOptions[0]?.label === "Other Department Queries") {
                            submitValues['sub_option_uuid'] = "";
                        }
                    }

                    //validation
                    if (submitValues['do_you_want_to_close_this_lead'] === "0" || (submitValues['closing_lead_type'] === "1" && selectedLostReasonOptions[0]?.label !== "Bought Used Car" && submitValues['do_you_want_to_close_this_lead'] === "1") || submitValues['closing_lead_type'] === "2") {
                        if (submitValues['parent_option_uuid'] === "") {
                            toast.error("Parent UUID is Required");
                            setSubmitting(false);
                            return
                        }
                    }

                    if (selectedDropReasonOptions.length > 0 || selectedLostReasonOptions.length > 0) {
                        if ((selectedDropReasonOptions[0]?.label === "Casual/Invaild Query" || selectedDropReasonOptions[0]?.label === "Customer lost interest to buy car" || selectedDropReasonOptions[0]?.label === "Not able to contact customer" || selectedLostReasonOptions[0]?.label === "Bought New Car") && submitValues["do_you_want_to_close_this_lead"] !== "0") {
                            if (!submitValues['sub_option_uuid']) {
                                toast.error("Sub Option UUID is Required");
                                setSubmitting(false);
                                return
                            }
                        }
                    }

                    if (selectedDropReasonOptions.length > 0) {
                        if (selectedDropReasonOptions[0].label === "Customer postponded his paln to buy") {
                            if (!submitValues['customer_tentative_purchase_date']) {
                                toast.error("Cuatomer Tentative Purchase Date is Required");
                                setSubmitting(false);
                                return
                            }
                        }
                    }
                    if (selectedDropReasonOptions.length > 0) {
                        if (selectedDropReasonOptions[0].label === "Other Department Queries") {
                            if (!submitValues['other_reason']) {
                                toast.error("Other Reason is Required");
                                setSubmitting(false);
                                return
                            }
                        }
                    }
                    if (selectedLostSubReasonOptions.length > 0) {
                        if (selectedLostSubReasonOptions[0].label === "Others") {
                            if (!submitValues['lost_to_brand']) {
                                toast.error("Lost to Brand is Required");
                                setSubmitting(false);
                                return
                            }
                            if (!submitValues['lost_to_delear_name']) {
                                toast.error("Lost to Dealer Name is Required");
                                setSubmitting(false);
                                return
                            }
                            if (!submitValues['lost_to_car_model']) {
                                toast.error("Lost to Car Model is Required");
                                setSubmitting(false);
                                return
                            }
                        }
                    }

                    if (selectedNoOptions.length > 0) {
                        if (selectedNoOptions[0].label === "Further follow-up needed") {
                            if (!submitValues['assign_to_another_executive']) {
                                toast.error("Assign To Another Executive is Required");
                                setSubmitting(false);
                                return
                            }
                        }
                    }

                    addProcessingRequests();
                    ServerAPI.LeadUpdateFollowUp(updateSubmitValues)
                        .then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                ServerAPI.LostDrop(submitValues).then(response => {
                                    if (response && response['success'] && response['message']) {
                                        toast.success(response['message']);
                                        resetUpdateSubmitvalues();
                                        resetState();
                                        updateCloseDialog();
                                        closeDialog();
                                    } else if (response && response['message']) {
                                        toast.error(response['message']);
                                    } else {
                                        toast.error('Unknown Error Occurred');
                                    }
                                }).finally(() => {
                                    setSubmitting(false);
                                    reduceProcessingRequests();
                                });
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            } else {
                                toast.error('Lead Update API Should Call First');
                            }
                        })
                        .finally(() => {
                            reduceProcessingRequests();
                        });
                }}>
                    {(formikProps) => {
                        if (formikProps.values.parent_option_uuid !== "" && formikProps.values.parent_option_uuid !== selectedNoParentOption && formikProps.values.do_you_want_to_close_this_lead !== "1") {
                            setSelectedNoParentOption(formikProps.values.parent_option_uuid);
                            // console.log("no parent", formikProps.values.parent_option_uuid)
                        }
                        if (formikProps.values.parent_option_uuid !== "" && formikProps.values.parent_option_uuid !== selectedDropParentOption && formikProps.values.do_you_want_to_close_this_lead !== "0" && formikProps.values.closing_lead_type !== "1") {
                            setSelectedDropParentOption(formikProps.values.parent_option_uuid);
                            // console.log("drop parent", formikProps.values.parent_option_uuid)
                        }
                        if (formikProps.values.parent_option_uuid !== "" && formikProps.values.parent_option_uuid !== selectedLostParentOption && formikProps.values.do_you_want_to_close_this_lead !== "0" && formikProps.values.closing_lead_type !== "2") {
                            setSelectedLostParentOption(formikProps.values.parent_option_uuid);
                            // console.log("lost parent", formikProps.values.parent_option_uuid)
                        }
                        if (formikProps.values.sub_option_uuid !== "" && formikProps.values.sub_option_uuid !== selectedLostSubOption && formikProps.values.do_you_want_to_close_this_lead !== "0" && formikProps.values.closing_lead_type !== "2") {
                            setSelectedLostSubOption(formikProps.values.sub_option_uuid);
                            // console.log("lost sub", formikProps.values.sub_option_uuid)
                        }

                        if (formikProps.values.do_you_want_to_close_this_lead !== "" && formikProps.values.do_you_want_to_close_this_lead !== isLostDrop) {
                            setIsLostDrop(formikProps.values.do_you_want_to_close_this_lead);
                        } else if (formikProps.values.do_you_want_to_close_this_lead === "" && formikProps.values.do_you_want_to_close_this_lead !== isLostDrop) {
                            setIsLostDrop("");
                        }


                        if (formikProps.values.closing_lead_type !== "" && parseInt(formikProps.values.closing_lead_type) !== selectedLostDrop) {
                            setSelectedLostDrop(parseInt(formikProps.values.closing_lead_type));
                        }

                        return (
                            <Form>
                                <div className="tabdetails lost-drop" style={{ overflowY: "auto", height: "88vh" }} ref={LostDropRef}>
                                    <h4 className="title-text text-center">Closing the Lead</h4>
                                    <div className="custom-border-grey mb-2"></div>
                                    <div id="reason-radio-btn-main-color">
                                        <FormField formik={formikProps} fieldProps={{ fieldType: "radio", label: "Do you want to close this lead?", name: "do_you_want_to_close_this_lead", options: closeLeadOptions, required: true, }} />
                                    </div>
                                    {formikProps.values.do_you_want_to_close_this_lead === "1" && <div id="reason-radio-btn-main-color" style={{ marginTop: "-20px" }}>
                                        <div className="update-popup-yes-or-no pl-4">
                                            <div id="radio-btn-color">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "radio", name: "closing_lead_type", options: lostDropOptions }} />
                                            </div>
                                        </div>
                                    </div>}
                                    {formikProps.values.closing_lead_type === "2" && formikProps.values.do_you_want_to_close_this_lead !== "0" && <div id="reason-radio-btn-main-color" style={{ marginTop: "-20px" }}>
                                        <div className="update-popup-yes-or-no pl-5">
                                            <div id="radio-btn-color">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "radio", name: "parent_option_uuid", options: dropReasonOptions }} />
                                            </div>
                                        </div>
                                    </div>}
                                    {formikProps.values.closing_lead_type === "2" && formikProps.values.do_you_want_to_close_this_lead !== "0" && <div id="reason-radio-btn-main-color" style={{ marginTop: "-20px" }}>
                                        <div className="update-popup-yes-or-no" style={{ paddingLeft: "4.5rem", paddingRight: "6rem" }}>
                                            <div id="radio-btn-color">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "radio", name: "sub_option_uuid", options: dropSubReasonOptions }} />
                                            </div>
                                        </div>
                                    </div>}
                                    {formikProps.values.closing_lead_type === "1" && formikProps.values.do_you_want_to_close_this_lead !== "0" && <div id="reason-radio-btn-main-color" style={{ marginTop: "-20px" }}>
                                        <div className="update-popup-yes-or-no pl-5">
                                            <div id="radio-btn-color">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "radio", name: "parent_option_uuid", options: LostReasonOptions }} />
                                            </div>
                                        </div>
                                    </div>}
                                    {formikProps.values.closing_lead_type === "1" && selectedLostReasonOptions[0]?.label === "Bought New Car" && formikProps.values.do_you_want_to_close_this_lead !== "0" && <div id="reason-radio-btn-main-color" style={{ marginTop: "-20px" }}>
                                        <div className="update-popup-yes-or-no" style={{ paddingLeft: "4.5rem", paddingRight: "2rem" }}>
                                            <div id="radio-btn-color">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "radio", name: "sub_option_uuid", options: lostSubReasonOptions }} />
                                            </div>
                                        </div>
                                    </div>}
                                    {formikProps.values.closing_lead_type === "1" && selectedLostReasonOptions[0]?.label === "Bought New Car" && formikProps.values.do_you_want_to_close_this_lead !== "0" && <div id="reason-radio-btn-main-color" style={{ marginTop: "-20px" }}>
                                        <div className="update-popup-yes-or-no pt-4" style={{ paddingLeft: "4.5rem", paddingRight: "2rem" }}>
                                            <div className="py-2">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Lost to Brand", name: "lost_to_brand", placeholder: "Enter Lost to Brand", required: true, }} />
                                            </div>
                                            <div className="py-2">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Lost to Dealer Name", name: "lost_to_delear_name", placeholder: "Enter Lost to Dealer Name", required: true, }} />
                                            </div>
                                            <div className="py-2">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Lost to Car Model", name: "lost_to_car_model", placeholder: "Enter Lost to Car Model", required: true, }} />
                                            </div>
                                        </div>
                                    </div>}
                                    {formikProps.values.closing_lead_type === "2" && selectedDropReasonOptions[0]?.label === "Customer postponded his paln to buy" && formikProps.values.do_you_want_to_close_this_lead !== "0" && <div id="reason-radio-btn-main-color" style={{ marginTop: "-20px" }}>
                                        <div className="update-popup-yes-or-no" style={{ paddingLeft: "4.5rem", paddingRight: "2rem", paddingTop: "2rem" }}>
                                            <div className="py-2 text-field-empty-custom-user  custom-label-1">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "datetimepicker", label: "Customer’s Tentative Purchase Date", name: "customer_tentative_purchase_date", placeholder: "Select Date" }} />
                                            </div>
                                        </div>
                                    </div>}
                                    {formikProps.values.closing_lead_type === "2" && selectedDropReasonOptions[0]?.label === "Other Department Queries" && formikProps.values.do_you_want_to_close_this_lead !== "0" && <div id="reason-radio-btn-main-color" style={{ marginTop: "-20px" }}>
                                        <div className="update-popup-yes-or-no" style={{ paddingLeft: "4.5rem", paddingRight: "2rem", paddingTop: "2rem" }}>
                                            <div className="py-2 text-field-empty-custom-user text-field-empty-custom-user-1  textarea">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textarea", label: "Reason", name: "other_reason", placeholder: "Enter a Reason" }} />
                                            </div>
                                        </div>
                                    </div>}
                                    {formikProps.values.closing_lead_type === "1" && selectedLostReasonOptions[0]?.label === "Bought Used Car" && formikProps.values.do_you_want_to_close_this_lead !== "0" && <div id="reason-radio-btn-main-color" style={{ marginTop: "-20px" }}>
                                        <div className="update-popup-yes-or-no pt-3" style={{ paddingLeft: "4.5rem", paddingRight: "2rem" }}>
                                            <div className="py-2">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Lost to Brand", name: "lost_to_brand", placeholder: "Enter Lost to Brand", required: true, }} />
                                            </div>
                                            <div className="py-2">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Lost to Dealer Name", name: "lost_to_delear_name", placeholder: "Enter Lost to Dealer Name", required: true, }} />
                                            </div>
                                            <div className="py-2">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Lost to Car Model", name: "lost_to_car_model", placeholder: "Enter Lost to Car Model", required: true, }} />
                                            </div>
                                        </div>
                                    </div>}
                                    {formikProps.values.do_you_want_to_close_this_lead === "0" && <div id="reason-radio-btn-main-color" style={{ marginTop: "-20px" }}>
                                        <div className="update-popup-yes-or-no pl-3">
                                            <div id="radio-btn-color">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "radio", name: "parent_option_uuid", options: noOptions }} />
                                            </div>
                                        </div>
                                    </div>}
                                    {selectedNoOptions[0]?.label === "Further follow-up needed" && formikProps.values.do_you_want_to_close_this_lead === "0" && <div className="py-2 px-3">
                                        <div className="text-field-empty-custom-user text-field-empty-custom-user-1 custom-label">
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Assign to Another Executive", name: "assign_to_another_executive", placeholder: "Select Executive", options: assignedToOptions }} />
                                        </div>
                                    </div>}
                                </div>

                                <div className="row btn-form-submit lost-drop-btn " style={{ position: "absolute" }}>
                                    <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                        formikProps.submitForm();
                                        if (!formikProps.isValid) {
                                            console.log('Errors', formikProps.errors);
                                            toast.error("Please ensure all fields are Filled and Valid!");
                                        }
                                    }}>Submit for Approval</button>
                                </div>

                            </Form>
                        )
                    }}
                </Formik>
            )}
        </Drawer>
    )
}

export default LostDropEntryDialog;







