import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';


const ImagePreview: React.FC<{ previewImage: string, showModal: boolean, closeModal: () => void }> = ({ previewImage, showModal, closeModal }) => {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal
            open={showModal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <img src={previewImage} alt="" style={{ maxWidth: '800px', maxHeight: '800px' }} />
            </Box>

        </Modal>
    )
}

export default ImagePreview;