import { Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { API, ProjectStrings } from "../common/Constants";
import { getDataFromAPI } from "../common/Utilities";

const RoleEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, detailView?: boolean }> = ({ showDialog, closeDialog, reloadData, id, detailView }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.Role>>(null);
    const [initialValue, setInitialValue] = useState<APIData.Role>(InitialData.Role);
    const [formDataSet, setFormDataSet] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [departmentOptions, setDepartmentOptions] = useState<FormDataTypes.SelectOption[]>([]);

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.DepartmentList, setDepartmentOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'department_name', 'data');
    }, []);

    useEffect(() => {
        if (id !== undefined && id !== '') {
            addProcessingRequests();
            ServerAPI.getRole(id).then(response => {
                if (response && response.data && response.data.details) {
                    setInitialValue(response.data.details);
                    setFormDataSet(true);
                    setEditMode(true);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else {
            setFormDataSet(true);
        }
    }, [id]);

    const yupSchema = Yup.object().shape({
        role_name: Yup.string().required(ProjectStrings.ValidationRequired),
        department_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        hierarchy_level: Yup.number().required(ProjectStrings.ValidationRequired),
    });

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-min">
            {formDataSet && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };
                    if (id != undefined && id != '') {
                        addProcessingRequests();
                        ServerAPI.updateRole(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            } else {
                                toast.error('Unknown Error Occurred');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    } else {
                        addProcessingRequests();
                        ServerAPI.addRole(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            } else {
                                toast.error('Unknown Error Occurred');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    }
                }} >
                    {(formikProps) => {
                        return (
                            <div className="tabdetails">
                                <h4 className="title-text text-center">{editMode ? 'Edit' : 'Add New'} Role</h4>
                                <div className="custom-border-grey my-2"></div>
                                <p className="detailed-heading">Role Details</p>
                                <div className="tabpage">
                                    <div className="custom-border-grey my-2"></div>
                                    <div className="tab-details-section">
                                        <Form>
                                            <div className="px-2 form-field-container">
                                                <div className="row py-3">
                                                    <div className="col-md-12 col-lg-12 col-xl-12 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", name: "role_name", label: "Role Name", placeholder: "Role Name", required: true, readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row py-3">
                                                    <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom-user">
                                                        {/* <label className="label-custom-user"><p>Department</p></label> */}
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Department", name: "department_uuid", placeholder: "Department", required: true, options: departmentOptions, readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row py-3">
                                                    <div className="col-md-12 col-lg-12 col-xl-12 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "hierarchy_level", label: "Hierarchy Level", placeholder: "Hierarchy Level", required: true, readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row py-3">
                                                    <div className="col-md-12 col-lg-12 col-xl-12 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "switch", label: "Status", name: "status", readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row btn-form-submit">
                                                <button type="button" className="button1" onClick={closeDialog}>Cancel</button>
                                                <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                    formikProps.submitForm();
                                                    if (!formikProps.isValid) {
                                                        toast.error("Please ensure all fields are Filled and Valid!");
                                                    }
                                                }}>Submit</button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                </Formik>
            )
            }
        </Drawer>
    )
}

export default RoleEntryDialog;