import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { APIData } from '../../common/DataTypes';
import { useState } from 'react';
import OtpInput from 'react-otp-input';
import { getSelectedDealer } from '../../common/Utilities';

const DealerSelectionModal: React.FC<{ dealerDetails: APIData.DealerSelection, dealerSelection: (dealerCode: string) => void, showModal: boolean, closeModal: () => void, executeOtp: (otp: string) => void, verifyOtp: boolean }> = ({ dealerDetails, dealerSelection, showModal, closeModal, verifyOtp, executeOtp }) => {
    const [showDealerOption, setshowDealerOption] = useState(showModal);
    const [verifyOtpPopup, setVerifyOtpPopup] = useState(verifyOtp);
    const [otp, setOtp] = useState('');

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        minWidth: '750px'
    };

    return (
        <Modal
            open={showModal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {showDealerOption && dealerDetails !== undefined && (
                    <>
                        <div className="text-center auth-logo-text">
                            <h4 className="mt-0 mb-3">{dealerDetails.title}</h4>
                        </div>
                        <div className="d-flex align-self-center justify-content-between w-70 m-auto py-4 dealer-select">
                            {dealerDetails.dealers.map((dealer, dealerIdx) => (
                                <button className={`img-bg ${dealer.dealer_code === getSelectedDealer() ? 'border-blue' : 'border-grey'}`} onClick={() => { dealerSelection(dealer.dealer_code); setshowDealerOption(!showModal); setVerifyOtpPopup(!verifyOtp) }} key={dealerIdx}>
                                    <div className="logo logo-admin" >
                                        <img src={dealer.image_url} height={120} width={120} alt="logo" className="auth-logo" />
                                    </div>
                                </button>
                            ))}
                        </div>
                        <div className="form-group mb-0 row">
                            <div className="col-12 mt-2 text-center">
                                <button onClick={closeModal} className="btn btn-login-primary w-50 btn-block waves-effect waves-light">Cancel</button>
                            </div>
                        </div>
                    </>
                )
                }
                {/* : (
                    <div className="text-center auth-logo-text">
                        <h4 className="mt-0 mb-3 mt-5">Dealer Details not found</h4>
                    </div>
                ) */}

                {
                    verifyOtpPopup && <div className="px-3">
                        <div className="text-center auth-logo-text">

                            <h4 className="et-custom-h4 title-text text-center">Verify OTP</h4>
                            <hr />
                            <p className="text-muted text-muted-user-action mb-0">We Send you a 4 digit code to associated mobile number with your account</p>
                        </div>
                        <form className="form-horizontal auth-form" onSubmit={e => {
                            e.preventDefault();
                            executeOtp(otp);
                        }}>
                            <div className="d-flex justify-content-center mt-4 mb-0">
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    renderSeparator={<span> </span>}
                                    renderInput={(props) => <input {...props} className="otp-box" />}
                                />
                            </div>
                            <div className="form-group mb-0 row">
                                <div className="col-12 mt-2 d-flex justify-content-center">
                                    <button type="submit" className="btn btn-login-primary btn-round btn-block waves-effect waves-light w-80">Verify</button>
                                </div>
                            </div>
                            <div className="text-center auth-logo-text mt-4">
                                <p className="text-muted text-muted-user-action mb-0">Did'nt get a OTP? <span className="color-orange">Resend OTP</span></p>
                            </div>
                        </form>
                    </div>
                }

            </Box >

        </Modal >
    )
}

export default DealerSelectionModal;