import { Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { API, ProjectStrings } from "../common/Constants";
import { getDataFromAPI } from "../common/Utilities";

const LeadInterestedVehicleEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, leadUUID?: string, detailView?: boolean }> = ({ showDialog, closeDialog, reloadData, id, leadUUID, detailView }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.LeadInterestedVehicleEntry>>(null);
    const [initialValue, setInitialValue] = useState<APIData.LeadInterestedVehicleEntry>(InitialData.LeadInterestedVehicleEntry);
    const [formDataSet, setFormDataSet] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [modelOptions, setModelOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [variantOptions, setVariantOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [exteriorColorOptions, setExteriorColorOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [interiorColorOptions, setInteriorColorOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [fuelTypeOptions, setFuelTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.ModelList, setModelOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'model_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.ModelVariantList, setVariantOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'variant_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.InteriorColorList, setInteriorColorOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'interior_color_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.ExteriorColorList, setExteriorColorOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'exterior_color_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.FuelTypeList, setFuelTypeOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'fuel_name', 'data', 'list');
    }, []);

    useEffect(() => {
        if (id !== undefined && id !== '' && leadUUID) {
            addProcessingRequests();
            ServerAPI.getLeadInterestedVehicle(leadUUID, id).then(response => {
                if (response && response.data && response.data.details) {
                    const vehicleDetail = response.data.details;
                    setInitialValue({
                        uuid: vehicleDetail.uuid,
                        lead_uuid: vehicleDetail.lead_uuid,
                        vehicle_model_uuid: vehicleDetail.lead_model_uuid,
                        vehicle_variant_uuid: vehicleDetail.lead_variant_uuid,
                        vehicle_exterior_color_uuid: vehicleDetail.lead_exterior_uuid,
                        vehicle_interior_color_uuid: vehicleDetail.lead_interior_uuid,
                        vehicle_fuel_uuid: vehicleDetail.lead_fuel_uuid,
                    });
                    setFormDataSet(true);
                    setEditMode(true);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else {
            setFormDataSet(true);
        }
    }, [id, leadUUID]);

    const yupSchema = Yup.object().shape({
        vehicle_model_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        vehicle_variant_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        vehicle_exterior_color_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        vehicle_interior_color_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        vehicle_fuel_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
    });

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-fit">
            {formDataSet && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };
                    if (id != undefined && id != '') {
                        addProcessingRequests();
                        ServerAPI.updateLeadInterestedVehicle(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            } else {
                                toast.error('Unknown Error Occurred');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    } else {
                        if (leadUUID) {
                            submitValues['lead_uuid'] = leadUUID;
                        }
                        addProcessingRequests();
                        ServerAPI.addLeadInterestedVehicle(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            } else {
                                toast.error('Unknown Error Occurred');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    }
                }} >
                    {(formikProps) => {
                        return (
                            <div className="tabdetails">
                                <h4 className="title-text text-center">{editMode ? 'Edit' : 'Add New'} Interested Vehicle</h4>
                                <div className="custom-border-grey my-2"></div>
                                <p className="detailed-heading">Interested Vehicle Details</p>
                                <div className="tabpage">
                                    <div className="custom-border-grey my-2"></div>
                                    <div className="tab-details-section">
                                        <Form>
                                            <div className="px-2 form-field-container">
                                                <div className="row px-2 py-3">
                                                    <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user">
                                                        {/* <label className="label-custom">Model</label> */}
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Model", name: "vehicle_model_uuid", placeholder: "Model", required: true, options: modelOptions }} />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user">
                                                        {/* <label className="label-custom">Variant</label> */}
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Variant", name: "vehicle_variant_uuid", placeholder: "Variant", required: true, options: variantOptions }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-3">
                                                    <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user">
                                                        {/* <label className="label-custom">Exterior Color</label> */}
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Exterior Color", name: "vehicle_exterior_color_uuid", placeholder: "Exterior Color", required: true, options: exteriorColorOptions }} />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user">
                                                        {/* <label className="label-custom">Interior Color</label> */}
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Interior Color", name: "vehicle_interior_color_uuid", placeholder: "Interior Color", required: true, options: interiorColorOptions }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-3">
                                                    <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user">
                                                        {/* <label className="label-custom">Fuel Type</label> */}
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Fuel Type", name: "vehicle_fuel_uuid", placeholder: "Fuel Type", required: true, options: fuelTypeOptions }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row btn-form-submit">
                                                <button type="button" className="button1" onClick={closeDialog}>Cancel</button>
                                                <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                    formikProps.submitForm();
                                                    if (!formikProps.isValid) {
                                                        toast.error("Please ensure all fields are Filled and Valid!");
                                                    }
                                                }}>Submit</button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                </Formik>
            )
            }
        </Drawer>
    )
}

export default LeadInterestedVehicleEntryDialog;