import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { PageLinks } from "./common/Constants";
import PageTemplate from "./pages/PageTemplate";
import Login from "./pages/Login";
import AppToast from "./components/AppToast";
import PreloaderBackdrop from "./components/PreloaderBackdrop";
import AdminLogin from "./pages/AdminLogin";

const AppNavigate: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path={PageLinks.LOGIN} element={<Login />} />
                <Route path={PageLinks.ADMIN_LOGIN} element={<AdminLogin />} />
                <Route path={PageLinks.PAGE_DEALER + "/*"} element={<PageTemplate />} />
                <Route path={PageLinks.PAGE_COMPANY + "/*"} element={<PageTemplate />} />
                <Route path={PageLinks.PAGE_USER + "/*"} element={<PageTemplate />} />
                <Route path={PageLinks.PAGE_SUPER_ADMIN + "/*"} element={<PageTemplate />} />
                <Route path="/" element={<Navigate to={PageLinks.LOGIN} />} />
            </Routes>
            <AppToast />
            <PreloaderBackdrop />
        </Router>
    );
}

export default AppNavigate;