import React, { useEffect, useState } from "react";
import { useAppStateAPI } from "../../common/AppStateAPI";
import { ServerAPI } from "../../common/ServerAPI";
import { APIData } from "../../common/DataTypes";
import { toast } from "react-hot-toast";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useLocation, useNavigate } from "react-router-dom";
import { PageLinks } from "../../common/Constants";

const UserDashboardTodayTable: React.FC<{ setReloadData: React.Dispatch<React.SetStateAction<boolean>> }> = ({ setReloadData }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [userDashboard, setUserDashboard] = useState<APIData.UserDashboardToday>();
    const [showRoomList, setShowroomList] = useState<APIData.ShowroomList>()
    const [activeShowroom, setActiveShowroom] = useState(0);
    const [teamMembersVisibility, setTeamMembersVisibility] = useState<{ [key: string]: boolean }>({});
    const [selectedTeam, setSelectedTeam] = useState<string | null>(null);
    const [selectedTeamUUID, setSelectedTeamUUID] = useState<string | null>(null);
    const [selectedShowroom, setSelectedShowroom] = useState<string | null>(null);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        fetchShowroomList();
    }, [location.state]);

    const handleShowroomSelection = (index: number, uuid: string) => {
        setActiveShowroom(index);
        setSelectedShowroom(uuid);
        setSelectedTeamUUID(null)
    };

    const fetchShowroomList = () => {
        addProcessingRequests();
        ServerAPI.getShowrooms().then((response: any) => {
            if (response['success'] === true) {
                setShowroomList({ data: { list: response.data.list } });
                const firstShowRoomUUID = (response.data.list[0].uuid);
                loadTodayTasks(firstShowRoomUUID);
            } else if (response['message']) {
                toast.error(response['message']);
            } else {
                toast.error('Something Went Wrong');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const loadTodayTasks = (uuid: string, teamUUID?: string) => {
        addProcessingRequests();
        ServerAPI.getUserDashboardToday(uuid, teamUUID).then(dashboard => {
            setUserDashboard({ data: dashboard.data, total_status_counts: dashboard.total_status_counts });
        }).finally(() => {
            reduceProcessingRequests();
        });
    };

    useEffect(() => {
        fetchShowroomList();
    }, []);

    useEffect(() => {
        loadTodayTasks(selectedShowroom ? selectedShowroom : "", selectedTeamUUID ? selectedTeamUUID : "");
    }, [selectedTeamUUID, selectedShowroom]);

    const toggleTeamMembersVisibility = (teamId: string) => {
        setTeamMembersVisibility((prevVisibility) => {
            const newVisibility = { [teamId]: !prevVisibility[teamId] };
            Object.keys(prevVisibility).forEach((prevTeamId) => {
                if (prevTeamId !== teamId) {
                    newVisibility[prevTeamId] = false;
                }
            });
            setSelectedTeam(newVisibility[teamId] ? teamId : null);
            return newVisibility;
        });
    };
    const getPaddingLeft = (dataIdx: number) => {
        switch (dataIdx) {
            case 0:
                return '16px';
            case 2:
                return '11px';
            case 3:
                return '7px';
            case 4:
                return '8px';
            default:
                return '12px';
        }
    };
    return (
        <div>
            {showRoomList !== undefined && (
                <div className="showroomlist-btncon mt-2">
                    {showRoomList.data.list.map((showroom, index) => (
                        <button
                            key={index}
                            className={`showroomlist-btn ${activeShowroom === index ? 'active' : ''}`}
                            onClick={() => handleShowroomSelection(index, showroom.uuid)}
                        >
                            {showroom.showroom_name}
                        </button>
                    ))}
                </div>
            )}
            {!!userDashboard && !!userDashboard.data && userDashboard.data.team.length !== 0 ? (
                <>
                    <div className="table-responsive table-noborder" style={{ width: '99%' }}>
                        <table className="table table-1 custom-border">
                            <thead className="color-native-blue">
                                <tr>
                                    <th className="custom-width"> </th>
                                    {userDashboard.total_status_counts.map((count, countIdx) => (
                                        <th style={{ width: "10%" }} key={countIdx}>{count.lead_status}</th>
                                    ))}
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="custom-tablescroll">
                        <div className="table-container">
                            <div className="table-scroll">
                                {userDashboard.data.team.map((team, teamIdx) => (
                                    <>
                                        <div className="table-responsive" key={teamIdx}>
                                            {/* <div className='d-flex align-items-center ml-2' style={{ padding: '2.8px', gap: '5px' }}>
                                                <PeopleAltIcon fontSize='small' />
                                                <h4 className='team-headers'>{team.team_name}</h4>
                                            </div> */}
                                            <div className='d-flex' style={{ cursor: 'pointer', justifyContent: teamMembersVisibility[team.team_name] ? "space-between" : "" }} onClick={() => { toggleTeamMembersVisibility(team.team_name); setSelectedTeamUUID(team.team_uuid); setSelectedShowroom(team.showroom_uuid) }}>
                                                <div className='d-flex align-items-center ml-2' style={{ padding: '2.8px', gap: '5px', width: "19%", borderRight: !teamMembersVisibility[team.team_name] ? "1px solid #dddddd" : "" }}>
                                                    <PeopleAltIcon fontSize='small' />
                                                    <h4 className="team-headers">{team.team_name}</h4>
                                                </div>
                                                {!teamMembersVisibility[team.team_name] && (
                                                    team.lead_data.map((user, userIdx) => (
                                                        <div
                                                            className="d-flex"
                                                            key={userIdx}
                                                            style={{
                                                                width: userIdx === team.lead_data.length - 1 ? "14%" : "16%",
                                                                padding: "12px",
                                                                fontSize: "15px",
                                                                color: "#252525",
                                                                fontWeight: "400",
                                                                fontFamily: "Roboto, sans-serif",
                                                                justifyContent: "center",
                                                                height: "50px",
                                                                // marginRight: userIdx === team.lead_data.length - 1 ? "42px" : undefined,
                                                                paddingLeft: userIdx === team.lead_data.length - 1 ? "42px" : "12px",
                                                                borderRight: userIdx === team.lead_data.length - 1 ? "" : "1px solid #dddddd"
                                                            }}
                                                        >
                                                            {user.lead_count}
                                                        </div>
                                                    ))
                                                )}
                                                {/* {!teamMembersVisibility[team.team_name] && (
                                                    team.lead_data.map((user, userIdx) => (
                                                        <div
                                                            className="d-flex"
                                                            key={userIdx}
                                                            style={{
                                                                width: userIdx === team.lead_data.length - 1 ? "10%" : "16%",
                                                                padding: "12px",
                                                                fontSize: "15px",
                                                                color: "#252525",
                                                                fontWeight: "400",
                                                                fontFamily: "Roboto, sans-serif",
                                                                justifyContent: userIdx === team.lead_data.length - 1 ? "end" : "center",
                                                                marginRight: userIdx === team.lead_data.length - 1 ? "42px" : undefined,
                                                                paddingRight: userIdx === team.lead_data.length - 1 ? "17px" : "12px",
                                                                borderRight: userIdx === team.lead_data.length - 1 ? "" : "1px solid #dddddd"
                                                            }}
                                                        >
                                                            {user.lead_count}
                                                        </div>
                                                    ))
                                                )} */}

                                                <i className={`arrow-icon mdi ${teamMembersVisibility[team.team_name] ? "mdi-chevron-down" : "mdi-chevron-right"}`} style={{ textAlign: "end", paddingRight: "10px", margin: "auto 0" }}></i>
                                            </div>
                                            {/* {!teamMembersVisibility[team.team_name] && (
                                                <table className="table table-1 custom-border">
                                                    <tbody>
                                                        <tr key={teamIdx}>
                                                            <td className="custom-width text-align-l"></td>
                                                            {team.lead_data.map((user, userIdx) => (
                                                                <td key={userIdx}>
                                                                    {user.lead_count}
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            )} */}
                                            {teamMembersVisibility[team.team_name] && (
                                                <table className="table table-1 custom-border">
                                                    <tbody>
                                                        {team.lead_data[0].team_members && team.lead_data[0].team_members.map((user, userIdx) => (
                                                            <tr key={userIdx}>
                                                                <td className="custom-width text-align-l">{user.user_name}</td>
                                                                {team.lead_data.map((leadStatus, leadStatusIdx, array) => (
                                                                    <td key={leadStatusIdx} onClick={() => navigate(`${PageLinks.USER_MY_TASKS}?user_name=${leadStatus.team_members[userIdx].user_name}&user_id=${leadStatus.team_members[userIdx].user_id}&status_name=${leadStatus.team_members[userIdx].status_name}`)} style={{ cursor: "pointer" }}>
                                                                        {leadStatus.team_members[userIdx].lead_count}
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    </>
                                ))}

                                <div className="table-responsive table-noborder">
                                    <table className="table table-2 custom-border">
                                        <thead className="color-native-blue">
                                            <tr>
                                                <th className="custom-width text-align-l">Total</th>
                                                {userDashboard.total_status_counts.map((data, dataIdx) => (
                                                    <th style={{
                                                        width: "10%",
                                                        paddingLeft: getPaddingLeft(dataIdx)
                                                    }} key={dataIdx}>{data.lead_count}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div >
                        </div>
                    </div>
                </>
            ) : <div className="table-responsive">
                <table className="table table-1 custom-border">
                    <tbody>
                        <tr>
                            <td className="text-align-l">No Data Available</td>
                        </tr>
                    </tbody>

                </table>
            </div>
            }
        </div >
    );
}

export default UserDashboardTodayTable;