
const CellReportTDVehicleDetail: React.FC<{ data: any }> = ({ data }) => {

    return (
        <>
            {data['demo_car_model']} {data['demo_car_variant']}<br />
            <small>{data['demo_car_fule_type']}</small><br />
            <small>Vin: {data['demo_car_vin_number']}</small><br />
            <small>TC: {data['demo_car_tc_number']}</small>
            <small>{data['interior_color_name']}</small>
            <small>{data['exterior_color_name']}</small>
        </>
    )
}

export default CellReportTDVehicleDetail;