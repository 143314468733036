import { Drawer } from '@mui/material';
import { Form } from 'formik';
import React, { useState } from 'react';
import { ServerAPI } from '../common/ServerAPI';
import { useAppStateAPI } from '../common/AppStateAPI';
import toast from 'react-hot-toast';

const UploadUserPriceEntryDialog: React.FC<{ showDialog: boolean, closeDialog: () => void, reloadData: () => void }> = ({ showDialog, closeDialog, reloadData }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Check if files are selected and if so, update the selectedFile state
        if (event.target.files?.length) {
            setSelectedFile(event.target.files[0]);
        } else {
            setSelectedFile(null);
        }
    };

    const handleUploadClick = async () => {
        if (!selectedFile) {
            alert('Please select a file first.');
            return;
        }
        // addProcessingRequests();
        // ServerAPI.employeeAttendanceBulkUpload(selectedFile, getLoginCompanyID()).then((response: any) => {
        //     if (response) {
        //         console.log(response);
        //         if (response.message) {
        //             toast.success(response.message);
        //         }
        //         closeDialog();
        //         reloadData();
        //     }
        // }).finally(() => {
        //     reduceProcessingRequests();
        // });

    };

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-min">
            <div className="tabdetails">
                <h4 className="title-text text-center"> Upload Inventory Price</h4>
                <div className="custom-border-grey my-2"></div>
                <div className="px-2 form-field-container">
                    <div className="row py-3">
                        <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom-user">
                            <input type="file" onChange={handleFileChange} />
                        </div>
                    </div>
                </div>
                <div className="row btn-form-submit">
                    <button type="button" className="button1" onClick={closeDialog}>Cancel</button>
                    <button type="button" className="button2" onClick={handleUploadClick} disabled={!selectedFile}>Upload</button>
                </div>
            </div>
        </Drawer>
    );
};

export default UploadUserPriceEntryDialog;