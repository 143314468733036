import { APIData } from "../common/DataTypes";


export namespace InitialData {

    export const Company: APIData.Company = {
        company_name: "",
        company_code: "",
        contact_phone: "",
        contact_email: "",
        company_logo: "",
        url_context_path: "",
        business_id: [],
        status: 1,
        password: "",
    }

    export const User: APIData.User = {
        uuid: "",
        name: "",
        emp_code: "",
        contact_number: "",
        contact_email: "",
        designation_uuid: "",
        department_uuid: [],
        role_uuid: "",
        reporting_to: [],
        business_type_uuid: [],
        showroom_uuid: [],
        category_uuid: [],
        category_type_uuid: [],
        dealer_code: "",
        state_uuid: "",
        status: 1,
        company_code: ""
    }

    export const Team: APIData.Team = {
        showroom_uuid: "",
        team_name: "",
        user_uuid: [],
        team_lead_uuid: [],
        manager_uuid: [],
        status: 1
    }

    export const Dealer: APIData.Dealer = {
        company_id: "",
        dealer: {
            name: "",
            code: "",
            contact_phone: "",
            contact_email: "",
            dealers_logo: "",
            mobile_logo: "",
            url_context_path: "",
            password: "",
        },
        settings: {
            db_host: "",
            db_username: "",
            db_password: "",
            db_name: "",
            email_host: "",
            email_username: "",
            email_password: "",
            email_sender_id: "",
        },
        menus: [],
        provider: []
    }

    export const Showroom: APIData.Showroom = {
        uuid: "",
        showroom_name: "",
        showroom_code: "",
        showroom_address: "",
        location_type: "",
        state_uuid: "",
        city_uuid: "",
        status: 1
    }

    export const Model: APIData.Model = {
        body_type_uuid: "",
        model_name: "",
        model_code: "",
        model_status: 1,
    }

    export const ModelVariantColor: APIData.ModelVariantColor = {
        interior_uuid: "",
        exterior_uuid: ""
    }

    export const ModelVariant: APIData.ModelVariant = {
        model_uuid: "",
        variant_name: "",
        variant_code: "",
        fuel_type_uuid: "",
        transmission_type_uuid: "",
        variant_status: 1,
        color: [{
            interior_uuid: "",
            exterior_uuid: ""
        }]
    }

    export const MenuEntry: APIData.MenuEntry = {
        name: "",
        code: "",
        icon: "",
        status: 1,
        order: 0,
        company_id: ""
    }

    export const ProviderEntry: APIData.ProviderEntry = {
        provider_category_id: "",
        provider_name: "",
        status: 1,
        order: 0,
        company_id: ""
    }

    export const ProviderCategoryEntry: APIData.ProviderCategoryEntry = {
        provider_category_name: "",
        status: 1
    }

    export const BusinessEntry: APIData.BusinessEntry = {
        business_name: "",
        status: 1
    }

    export const BusinessModeEntry: APIData.BusinessModeEntry = {
        business_mode_name: "",
        status: 1
    }

    export const CategoryEntry: APIData.CategoryEntry = {
        category_name: "",
        status: 1
    }

    export const UpdateDeliveryDateEntry: APIData.UpdateDeiveryDateEntry = {
        lead_uuid: "",
        invoice_uuid: "",
        actual_delivery_date_time: "",
    }

    export const CategoryTypeEntry: APIData.CategoryTypeEntry = {
        category_type_name: "",
        status: 1
    }

    export const Role: APIData.Role = {
        uuid: "",
        role_name: "",
        department_uuid: "",
        hierarchy_level: 0,
        status: 1
    }

    export const TargetRules: APIData.TargetRules = {
        target_stages_uuid: "",
        showroom_uuid: "",
        rules: "",
    }

    export const State: APIData.State = {
        uuid: "",
        state_name: "",
        state_status: 1
    }

    export const City: APIData.City = {
        uuid: "",
        state_uuid: "",
        city_name: "",
        city_status: 1
    }

    export const Pincode: APIData.Pincode = {
        uuid: "",
        state_uuid: "",
        city_uuid: "",
        pincode: "",
        landmark: "",
    }

    export const LeadCategory: APIData.LeadCategory = {
        uuid: "",
        lead_category_name: "",
        lead_category_status: 1
    }

    export const CustomerType: APIData.CustomerType = {
        uuid: "",
        customer_type_name: "",
        customer_type_status: 1
    }

    export const LeadSource: APIData.LeadSource = {
        uuid: "",
        lead_source_cat_uuid: "",
        lead_source_name: "",
        lead_sources_status: 1
    }

    export const LeadSourceCategory: APIData.LeadSourceCategory = {
        uuid: "",
        lead_source_category_name: "",
        status: 1
    }

    export const LeadStatus: APIData.LeadStatus = {
        uuid: "",
        lead_status_name: "",
        lead_status_code: "",
    }

    export const InsuranceType: APIData.InsuranceType = {
        uuid: "",
        insurance_type_name: "",
    }

    export const InsuranceCompany: APIData.InsuranceCompany = {
        uuid: "",
        insurance_company_name: "",
    }

    export const LeadType: APIData.LeadType = {
        uuid: "",
        vehicle_lead_type_name: "",
    }

    export const LeadTask: APIData.LeadTask = {
        uuid: "",
        lead_task_name: "",
        visiable_to_sale_support: 0
    }

    export const LeadProspectType: APIData.LeadProspectType = {
        uuid: "",
        prospect_type_name: "",
    }

    export const LeadReason: APIData.LeadReason = {
        uuid: "",
        lead_task_uuid: "",
        lead_reason_name: "",
    }

    export const LeadTitle: APIData.LeadTitle = {
        uuid: "",
        lead_title: "",
    }

    export const PaymentMode: APIData.PaymentMode = {
        uuid: "",
        payment_mode_name: "",
    }

    export const Color: APIData.Color = {
        uuid: "",
        color_name: "",
        color_status: 1,
    }

    export const FuelType: APIData.FuelType = {
        uuid: "",
        fuel_name: "",
        fuel_status: 1,
    }

    export const DemoCar: APIData.DemoCar = {
        uuid: "",
        showroom_uuid: "",
        car_manufacture_year: "",
        car_vin_number: "",
        car_tc_number: "",
        car_model_uuid: "",
        car_variant_uuid: "",
        car_color_uuid: "",
        car_fuel_uuid: "",
        car_transmission_type_uuid: "",
        car_image_url: "",
        status: 1,
    }

    export const BodyType: APIData.BodyType = {
        uuid: "",
        body_type_name: "",
        body_type_status: ""
    }

    export const TransmissionType: APIData.TransmissionType = {
        uuid: "",
        transmission_type_name: "",
        transmission_type_status: ""
    }

    export const InteriorColor: APIData.InteriorColor = {
        uuid: "",
        interior_color_name: "",
        interior_color_status: ""
    }

    export const ExteriorColor: APIData.ExteriorColor = {
        uuid: "",
        exterior_color_name: "",
        exterior_color_status: ""
    }

    export const Version: APIData.Version = {
        uuid: "",
        version: "",
        status: 1
    }

    export const LeadInterestedVehicleEntry: APIData.LeadInterestedVehicleEntry = {
        uuid: "",
        lead_uuid: "",
        vehicle_model_uuid: "",
        vehicle_variant_uuid: "",
        vehicle_exterior_color_uuid: "",
        vehicle_interior_color_uuid: "",
        vehicle_fuel_uuid: "",
    }
    export const UpdateLeadFollowUp: APIData.UpdateLeadFollowUp = {
        lead_uuid: "",
        did_you_speak_to_the_customer: "",
        lead_status_uuid: "",
        reschedule_this_task: 0,
        reschedule_date_time: "",
        lead_reason_uuid: "",
        lead_followup_remarks: "",
        date_time: "",
    }
    export const LeadPlanNextTask: APIData.LeadPlanNextTask = {
        demo_car_uuid: '',
        lead_uuid: '',
        lead_task_uuid: '',
        assign_to_user_uuid: '',
        date_time: '',
        testdrive_location_type_uuid: '',
        remarks: '',
        lead_status_uuid: '',
        lead_followup_uuid: '',
        showroom_uuid: '',
        lead_qualify: 0
    }

    export const BookACar: APIData.BookACar = {
        lead_uuid: '',
        interested_car_uuid: '',
        next_followup_date_time: '',
        car_details: {
            model_uuid: '',
            variant_uuid: '',
            exterior_uuid: '',
            interior_uuid: '',
            vin_number: '',
            booking_amount: '',
            booking_amount_payment_mode_uuid: ''
        },
        customer_details: {
            booking_name: '',
            mobile_number: '',
            email_id: '',
            customer_type_uuid: '',
            date_of_birth: '',
            linked: '1',
            pan_number: '',
            aadhaar_gst_number: '',
            address: '',
            pincode: '',
            landmark: '',
            state_uuid: '',
            city_uuid: ''
        },
        price_breakup: {
            ex_showroom_price: '',
            insurance: '',
            registration_charges: '',
            accessories_charges: '',
            tcs_charges: '',
            vas_charges: '',
            logistics_handling_charges: '',
            extended_warranty: '',
            csvp_service_plan: '',
            others: ''
        },
        discount: {
            exchange_loyalty_bonus: '',
            corporate: '',
            dealer_discount: '',
            referral_bonus: '',
            oem_scheme: '',
            others: ''
        },
        exchange_finance_ew: {
            want_to_change_vehicle: '',
            added_exchange_vehicle_details_for_this_lead: '',
            did_customer_opt_for_finance: '',
            financier_name: '',
            loan_amount: '',
            loan_tenure: '',
            has_customer_bought_extended_warranty: '',
            extended_warranty_end_date: ''
        },
        final_booking_details: {
            final_on_road_price: '',
            booking_amount: '',
            pending_total_invoice_amount: '',
            payment_mode_uuid: ''
        }
    }

    export const InvoiceDetails: APIData.InvoiceDetails = {
        lead_uuid: "",
        interested_car_uuid: "",
        car_details: {
            model_uuid: "",
            variant_uuid: "",
            exterior_uuid: "",
            interior_uuid: "",
            vin_number: "",
            booking_amount: "",
            booking_amount_payment_mode_uuid: "",
        },
        customer_details: {
            booking_name: "",
            mobile_number: "",
            email_id: "",
            customer_type_uuid: "",
            date_of_birth: "",
            pan_number: "",
            aadhaar_gst_number: "",
            address: "",
            pincode: "",
            landmark: "",
            state_uuid: "",
            city_uuid: "",
        },
        price_breakup: {
            ex_showroom_price: "",
            insurance: "",
            registration_charges: "",
            accessories_charges: "",
            tcs_charges: "",
            vas_charges: "",
            logistics_handling_charges: "",
            extended_warranty: "",
            csvp_service_plan: "",
            others: "",
        },
        discount: {
            exchange_loyalty_bonus: "",
            corporate: "",
            dealer_discount: "",
            referral_bonus: "",
            oem_scheme: "",
            others: "",
        },
        exchange_finance_ew: {
            want_to_change_vehicle: "",
            brand_name: "",
            model_name: "",
            model_year: "",
            registration_date: "",
            registration_number: "",
            did_customer_opt_for_finance: "",
            financier_name: "",
            loan_amount: "",
            loan_tenure: "",
            has_customer_bought_extended_warranty: "",
            extended_warranty_end_date: "",
        },
        final_booking_details: {
            final_on_road_price: "",
            booking_amount: "",
            pending_total_invoice_amount: "",
            payment_mode_uuid: "",
            insurance_type_uuid: "",
            insurance_company_uuid: "",
            delivery_date_time: "",
        }
    }

    export const CreateLead: APIData.CreateLead = {
        customer_details: {
            title: "",
            first_name: "",
            last_name: "",
            date_of_birth: "",
            primary_mobile_number: "",
            secondary_mobile_number: null,
            email_id: "",
            other_email_ids: [],
            customer_type_uuid: "",
            address: "",
            pin_code: "",
            state_uuid: "",
            city_uuid: "",
            landmark: "",
            billing_landmark: "",
            billing_as_shipping: 0,
            billing_address: "",
            billing_pincode: "",
            billing_address_state_uuid: "",
            billing_address_city_uuid: ""
        },
        lead_details: {
            lead_type_uuid: "",
            lead_source_category_uuid: "",
            lead_source_uuid: "",
            lead_status_uuid: ""
        },
        interested_vehicle_details: {
            vehicle_model_uuid: "",
            vehicle_variant_uuid: "",
            vehicle_exterior_uuid: "",
            vehicle_interior_uuid: "",
            vehicle_fuel_type: ""
        },
        task_assign_details: {
            add_task_uuid: "",
            testdrive_location_type_uuid: "",
            showroom_uuid: "",
            assiged_to_uuid: "",
            date_time: "",
            lead_qualify: 0,
            demo_car_uuid: ""
        }
    }

    export const UpdateLead: APIData.UpdateLead = {
        lead_uuid: "",
        customer_details: {
            title: "",
            first_name: "",
            last_name: "",
            date_of_birth: "",
            primary_mobile_number: "",
            secondary_mobile_number: null,
            email_id: "",
            other_email_ids: [],
            customer_type_uuid: "",
            address: "",
            pin_code: "",
            state_uuid: "",
            city_uuid: "",
            landmark: "",
            billing_landmark: "",
            billing_as_shipping: 0,
            billing_address: "",
            billing_pincode: "",
            billing_address_state_uuid: "",
            billing_address_city_uuid: ""
        },
        lead_details: {
            lead_type_uuid: "",
            lead_source_category_uuid: "",
            lead_source_uuid: "",
            lead_status_uuid: ""
        },
    }

    export const LostDrop: APIData.LostDrop = {
        lead_uuid: "",
        do_you_want_to_close_this_lead: "",
        closing_lead_type: "0", //1 lost ,2 drop
        parent_option_uuid: "",
        sub_option_uuid: "",
        lost_to_brand: "",
        lost_to_delear_name: "",
        lost_to_car_model: "",
        customer_tentative_purchase_date: "",
        other_reason: "",
        assign_to_another_executive: "",
    }

    export const PriceQuotes: APIData.PriceQuotes = {
        models_uuid: '',
        variant_uuid: '',
        mf_year: '',
        model_year: '',
        ex_showroom_price: '',
        tcs: '',
        insurance: '',
        registration_charges: '',
        state_uuid: '',
        logistic_handing: '',
        vas_charge: '',
        extend_warrenty: '',
        service_plan: '',
        others: '',
        exchange_loyalty_bonus: '',
        corporate_discount: '',
        delear_discount: '',
        referral_bonus: '',
        oem_scheme: '',
        accessories: '',
        discount_others: '',
        customer_name: '',
        mobile_number: '',
        customer_address: '',
        email_address: '',
    }

    export const RequestApproval: APIData.RequestApproval = {
        models_uuid: '',
        variant_uuid: '',
        mf_year: '',
        model_year: '',
        booking_uuid: '',
        lead_uuid: '',
        approval_user_uuid: '',
        ex_showroom_price: '',
        tcs: '',
        insurance: '',
        registration_charges: '',
        state_uuid: '',
        logistic_handing: '',
        vas_charge: '',
        extend_warrenty: '',
        service_plan: '',
        others: '',
        exchange_loyalty_bonus: '',
        corporate_discount: '',
        delear_discount: '',
        referral_bonus: '',
        oem_scheme: '',
        discount_others: '',
        accessories: '',
    }

    export const InventoryAdd: APIData.InventoryAdd = {
        showroom_uuid: "",
        WHS_code: "",
        models_uuid: "",
        models_group_uuid: "",
        variant_uuid: "",
        interior_uuid: "",
        exterior_uuid: "",
        fuel_type_uuid: "",
        vehicle_status_uuid: "",
        VIN_number: "",
        engine_number: "",
        model_year: "",
        mf_year: "",
        purchase_date: "",
        recevied_date: "",
        expected_recevied_date: "",
        state_uuid: "",
        invoice_number: "",
        status: "",
        buying_price: "",
        vehicle_cost: "",
        gst_amount_on_vehicle: "",
        vehicle_purchse_cost: "",
        vehicle_revaluation_cost: "0",
        funding_type: "",
        self_funding: "",
        self_funded_amount: "",
        bank_funded_amount: "",
        funding_bank_partner_uuid: "",
        interest: "",
        image: ""
    }


    export const OperationalCost: APIData.OperationalCost = {
        operational_cost: "",
        operational_cost_description: ""
    }


    export const InventoryPriceAdd: APIData.InventoryPriceAdd = {
        models_uuid: "",
        variant_uuid: "",
        mf_year: "",
        model_year: "",
        ex_showroom_price: "",
        tcs: "",
        insurance: "",
        registration_charges: "",
        state_uuid: "",
        logistic_handing: "",
        vas_charge: "",
        extend_warrenty: "",
        service_plan: "",
        others: "",
        exchange_loyalty_bonus: "",
        corporate_discount: "",
        delear_discount: "",
        referral_bonus: "",
        oem_scheme: "",
        discount_others: ""
    }

    export const InterestedVehicle: APIData.InterestedVehicle = {
        lead_uuid: '',
        vehicle_model_uuid: '',
        vehicle_variant_uuid: '',
        vehicle_exterior_color_uuid: '',
        vehicle_interior_color_uuid: '',
        vehicle_fuel_uuid: ''
    }
    export const GenerateReport: APIData.GenerateReport = {
        from_date: '',
        to_date: '',
        month: '',
        export_type: '',
        showroom_uuid: [],
        email_id: [],
    }
    export const HelpAndSupport: APIData.HelpAndSupport = {
        issue: "",
        image_url: "",
    }
    export const ScheduleTestdrive: APIData.ScheduleTestdrive = {
        test_drive_location_type_uuid: "",
        demo_car_uuid: "",
        customer_name: "",
        mobile_number: "",
        dms_lead_id: 0,
        test_drive_home_address: "",
        test_drive_showroom_uuid: "",
        test_drive_date_time: "",
    }

}