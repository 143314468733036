import axios from "axios";
import { API } from "./Constants";
import toast from 'react-hot-toast';
import * as Utilities from "./Utilities";

export namespace ServerAPI {

    export enum APIMethod {
        GET = "GET",
        POST = "POST",
        PUT = "PUT",
        DELETE = "DELETE"
    };

    export const executeAPI = async (
        endpoint: string,
        method: APIMethod,
        authRequired?: boolean,
        data?: any,
        params?: any,
        suppressError?: boolean
    ) => {

        let headers: { [k: string]: any } = {};
        if (authRequired) {
            const userToken = await Utilities.getUserToken();
            if (userToken) {
                headers["Authorization"] = "Bearer " + userToken;
            }
        }

        if (method === APIMethod.POST || method === APIMethod.PUT) {
            headers["Content-Type"] = "application/json";
        }
        try {
            const response = await axios({
                method: method,
                url: endpoint,
                data: JSON.stringify(data),
                headers: headers,
                params: params,
            });
            if (response.status == 201 || response.status == 200) {
                return response.data;
            } else {
                if (response.data.message) {
                    toast.error(response.data.message);
                }
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response && !suppressError) {
                let errorMsg = '';
                if (error.response.data) {
                    const errorData: any = error.response.data;
                    if (errorData.message) {
                        errorMsg = errorData.message;
                    } else if (errorData.error) {
                        errorMsg = errorData.error;
                    } else if (errorData.error_messages) {
                        errorMsg = errorData.error_messages
                    }
                }
                if (errorMsg == '') {
                    errorMsg = "Error Occurred in the Request!";
                }
                toast.error(errorMsg);
            }
        }
    };


    export const uploadFile = async (
        endpoint: string,
        file: any,
        authRequired?: boolean,
        params?: any,
        suppressError?: boolean
    ) => {

        let headers: { [k: string]: any } = {};
        headers['Content-Type'] = 'multipart/form-data';
        if (authRequired) {
            const userToken = await Utilities.getUserToken();
            if (userToken) {
                headers["Authorization"] = userToken;
            }
        }
        const config = { headers: headers };

        let fd = new FormData();
        fd.append('file', file);

        if (params) {
            endpoint = `${endpoint}?company_id=${params}`;
        }

        try {
            const response = await axios.post(endpoint, fd, config);

            if (response.status == 201 || response.status == 200) {
                return response.data;
            } else {
                if (response.data.message) {
                    toast.error(response.data.message);
                } else {
                    toast.error('Something went wrong!');
                }
            }
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response && !suppressError) {
                let errorMsg = '';
                if (error.response.data) {
                    const errorData: any = error.response.data;
                    if (errorData.message) {
                        errorMsg = errorData.message;
                    } else if (errorData.error) {
                        errorMsg = errorData.error;
                    }
                }
                if (errorMsg == '') {
                    errorMsg = "Error Occurred in the Request!";
                }
                toast.error(errorMsg);
            }
        }

    }

    export const uploadImage = async (file: any, imageType: number) => {
        let data: { [k: string]: any } = {};
        let params = undefined;
        data["logo"] = file;
        let headers: { [k: string]: any } = {};
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let fd = new FormData();
        fd.append('logo', file);

        try {
            const response = await axios.post(API.EndPoint.ImageUpload + '?type=' + imageType, fd, config);
            return response.data;
        } catch (error: any) {
            return { success: false, message: "Error Occurred in the Request!" };
        }

    }

    export const inventoryImport = async (file: any) => {
        return uploadFile(API.EndPoint.UserInventoryImports, file, true);
    }


    export const superAdminLogin = async (email: string, password: string) => {
        let data: { [k: string]: any } = {};
        data["email"] = email;
        data["password"] = password;
        return executeAPI(API.EndPoint.SuperAdminLogin, APIMethod.POST, false, data);
    }

    export const superAdminVerifyOtp = async (email: string, otp: string) => {
        let data: { [k: string]: any } = {};
        data["email"] = email;
        data["otp"] = otp;
        return executeAPI(API.EndPoint.SuperAdminVerifyOtp, APIMethod.POST, false, data);
    }

    export const dealerLogin = async (email: string, password: string) => {
        let data: { [k: string]: any } = {};
        data["email"] = email;
        data["password"] = password;
        return executeAPI(API.EndPoint.DealerLogin, APIMethod.POST, false, data);
    }

    export const dealerVerifyOtp = async (email: string, otp: string) => {
        let data: { [k: string]: any } = {};
        data["email"] = email;
        data["otp"] = otp;
        return executeAPI(API.EndPoint.DealerVerifyOtp, APIMethod.POST, false, data);
    }

    export const companyLogin = async (email: string, password: string) => {
        let data: { [k: string]: any } = {};
        data["email"] = email;
        data["password"] = password;
        return executeAPI(API.EndPoint.CompanyLogin, APIMethod.POST, false, data);
    }

    export const companyVerifyOtp = async (email: string, otp: string) => {
        let data: { [k: string]: any } = {};
        data["email"] = email;
        data["otp"] = otp;
        return executeAPI(API.EndPoint.CompanyVerifyOtp, APIMethod.POST, false, data);
    }

    export const userLogin = async (companyCode: string, phone: string) => {
        let data: { [k: string]: any } = {};
        data["company_code"] = companyCode;
        data["phone_no"] = phone;
        return executeAPI(API.EndPoint.UserLogin, APIMethod.POST, false, data);
    }

    export const userSendOtp = async (companyCode: string, dealerCode: string, phone: string) => {
        let data: { [k: string]: any } = {};
        data["company_code"] = companyCode;
        data["dealer_code"] = dealerCode;
        data["phone_no"] = phone;
        return executeAPI(API.EndPoint.UserSendOtp, APIMethod.POST, false, data);
    }

    export const userVerifyOtp = async (companyCode: string, dealerCode: string, phone: string, otp: string) => {
        let data: { [k: string]: any } = {};
        data["company_code"] = companyCode;
        data["dealer_code"] = dealerCode;
        data["phone_no"] = phone;
        data["otp"] = otp;
        data["device"] = { 'device_type': 'Web', 'device_token': '2423424234234' };
        return executeAPI(API.EndPoint.UserVerifyOtp, APIMethod.POST, false, data);
    }

    export const getCompanies = async () => {
        let params: { [k: string]: any } = {};
        return executeAPI(API.EndPoint.CompanyList, APIMethod.GET, true, null, params);
    }
    export const getTestdriveDashboard = async () => {
        let params: { [k: string]: any } = {};
        return executeAPI(API.EndPoint.TestDriveDashboard, APIMethod.GET, true, null, params);
    }

    export const getCompany = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.CompanyDetail, APIMethod.GET, true, null, params);
    }

    export const addCompany = async (data: any) => {
        return executeAPI(API.EndPoint.CompanyAdd, APIMethod.POST, true, data);
    }

    export const updateCompany = async (data: any) => {
        return executeAPI(API.EndPoint.CompanyUpdate, APIMethod.POST, true, data);
    }

    export const getMenus = async () => {
        let params: { [k: string]: any } = {};
        return executeAPI(API.EndPoint.MenuList, APIMethod.GET, true, null, params);
    }

    export const getMenu = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.MenuDetail, APIMethod.GET, true, null, params);
    }

    export const addMenu = async (data: any) => {
        return executeAPI(API.EndPoint.MenuAdd, APIMethod.POST, true, data);
    }

    export const updateMenu = async (data: any) => {
        return executeAPI(API.EndPoint.MenuUpdate, APIMethod.POST, true, data);
    }

    export const getProviders = async () => {
        let params: { [k: string]: any } = {};
        return executeAPI(API.EndPoint.ProviderList, APIMethod.GET, true, null, params);
    }

    export const getProvider = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.ProviderDetail, APIMethod.GET, true, null, params);
    }

    export const addProvider = async (data: any) => {
        return executeAPI(API.EndPoint.ProviderAdd, APIMethod.POST, true, data);
    }

    export const updateProvider = async (data: any) => {
        return executeAPI(API.EndPoint.ProviderUpdate, APIMethod.POST, true, data);
    }

    export const getProviderCategories = async () => {
        let params: { [k: string]: any } = {};
        return executeAPI(API.EndPoint.ProviderCategoryList, APIMethod.GET, true, null, params);
    }

    export const getProviderCategory = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.ProviderCategoryDetail, APIMethod.GET, true, null, params);
    }

    export const addProviderCategory = async (data: any) => {
        return executeAPI(API.EndPoint.ProviderCategoryAdd, APIMethod.POST, true, data);
    }

    export const updateProviderCategory = async (data: any) => {
        return executeAPI(API.EndPoint.ProviderCategoryUpdate, APIMethod.POST, true, data);
    }

    export const getBusinesses = async () => {
        let params: { [k: string]: any } = {};
        return executeAPI(API.EndPoint.BusinessList, APIMethod.GET, true, null, params);
    }

    export const getBusiness = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.BusinessDetail, APIMethod.GET, true, null, params);
    }

    export const addBusiness = async (data: any) => {
        return executeAPI(API.EndPoint.BusinessAdd, APIMethod.POST, true, data);
    }

    export const updateBusiness = async (data: any) => {
        return executeAPI(API.EndPoint.BusinessUpdate, APIMethod.POST, true, data);
    }

    export const getBusinessModes = async () => {
        let params: { [k: string]: any } = {};
        return executeAPI(API.EndPoint.BusinessModeList, APIMethod.GET, true, null, params);
    }

    export const getBusinessMode = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.BusinessModeDetail, APIMethod.GET, true, null, params);
    }

    export const addBusinessMode = async (data: any) => {
        return executeAPI(API.EndPoint.BusinessModeAdd, APIMethod.POST, true, data);
    }

    export const updateBusinessMode = async (data: any) => {
        return executeAPI(API.EndPoint.BusinessModeUpdate, APIMethod.POST, true, data);
    }

    export const getCategories = async () => {
        let params: { [k: string]: any } = {};
        return executeAPI(API.EndPoint.CategoryList, APIMethod.GET, true, null, params);
    }

    export const getCategory = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.CategoryDetail, APIMethod.GET, true, null, params);
    }

    export const addCategory = async (data: any) => {
        return executeAPI(API.EndPoint.CategoryAdd, APIMethod.POST, true, data);
    }

    export const updateCategory = async (data: any) => {
        return executeAPI(API.EndPoint.CategoryUpdate, APIMethod.POST, true, data);
    }

    export const getCategoryTypes = async () => {
        let params: { [k: string]: any } = {};
        return executeAPI(API.EndPoint.CategoryTypeList, APIMethod.GET, true, null, params);
    }

    export const getAssignToList = async () => {
        let params: { [k: string]: any } = {};
        return executeAPI(API.EndPoint.LeadAssignToList, APIMethod.GET, true, null, params);
    }

    export const getCategoryType = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.CategoryTypeDetail, APIMethod.GET, true, null, params);
    }

    export const addCategoryType = async (data: any) => {
        return executeAPI(API.EndPoint.CategoryTypeAdd, APIMethod.POST, true, data);
    }

    export const updateCategoryType = async (data: any) => {
        return executeAPI(API.EndPoint.CategoryTypeUpdate, APIMethod.POST, true, data);
    }

    export const getStates = async () => {
        return executeAPI(API.EndPoint.StateList, APIMethod.GET, true);
    }

    export const getState = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.StateDetail, APIMethod.GET, true, null, params);
    }

    export const addState = async (data: any) => {
        return executeAPI(API.EndPoint.StateAdd, APIMethod.POST, true, data);
    }

    export const updateState = async (data: any) => {
        return executeAPI(API.EndPoint.StateUpdate, APIMethod.POST, true, data);
    }

    export const getCities = async (state_id?: string) => {
        let params: { [k: string]: any } = {};
        if (state_id) {
            params["state_uuid"] = state_id;
        }
        return executeAPI(API.EndPoint.CityList, APIMethod.GET, true, null, params);
    }

    export const getCity = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.CityDetail, APIMethod.GET, true, null, params);
    }

    export const addCity = async (data: any) => {
        return executeAPI(API.EndPoint.CityAdd, APIMethod.POST, true, data);
    }

    export const updateCity = async (data: any) => {
        return executeAPI(API.EndPoint.CityUpdate, APIMethod.POST, true, data);
    }

    export const getPincodes = async (state_id?: string) => {
        let params: { [k: string]: any } = {};
        if (state_id) {
            params["state_uuid"] = state_id;
        }
        return executeAPI(API.EndPoint.PincodeList, APIMethod.GET, true, null, params);
    }

    export const getPincode = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.PincodeDetail, APIMethod.GET, true, null, params);
    }

    export const getBookCarPincode = async (id: number) => {
        let params: { [k: string]: any } = {};
        params["pincode"] = id;
        return executeAPI(API.EndPoint.PincodeList, APIMethod.GET, true, null, params);
    }

    export const addPincode = async (data: any) => {
        return executeAPI(API.EndPoint.PincodeAdd, APIMethod.POST, true, data);
    }

    export const updatePincode = async (data: any) => {
        return executeAPI(API.EndPoint.PincodeUpdate, APIMethod.POST, true, data);
    }

    export const getLeadCategories = async () => {
        return executeAPI(API.EndPoint.LeadCategoryList, APIMethod.GET, true);
    }

    export const getLeadCategory = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.LeadCategoryDetail, APIMethod.GET, true, null, params);
    }

    export const addLeadCategory = async (data: any) => {
        return executeAPI(API.EndPoint.LeadCategoryAdd, APIMethod.POST, true, data);
    }

    export const updateLeadCategory = async (data: any) => {
        return executeAPI(API.EndPoint.LeadCategoryUpdate, APIMethod.POST, true, data);
    }

    export const getCustomerTypes = async () => {
        return executeAPI(API.EndPoint.CustomerTypeList, APIMethod.GET, true);
    }

    export const getCustomerType = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.CustomerTypeDetail, APIMethod.GET, true, null, params);
    }

    export const addCustomerType = async (data: any) => {
        return executeAPI(API.EndPoint.CustomerTypeAdd, APIMethod.POST, true, data);
    }

    export const updateCustomerType = async (data: any) => {
        return executeAPI(API.EndPoint.CustomerTypeUpdate, APIMethod.POST, true, data);
    }

    export const getLeadSources = async (leadSourceCategoryUUID?: string) => {
        let params: { [k: string]: any } = {};
        if (leadSourceCategoryUUID) {
            params["lead_source_cat_uuid"] = leadSourceCategoryUUID;
        }
        return executeAPI(API.EndPoint.LeadSourceList, APIMethod.GET, true, null, params);
    }

    export const getLeadSource = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.LeadSourceDetail, APIMethod.GET, true, null, params);
    }

    export const addLeadSource = async (data: any) => {
        return executeAPI(API.EndPoint.LeadSourceAdd, APIMethod.POST, true, data);
    }

    export const updateLeadSource = async (data: any) => {
        return executeAPI(API.EndPoint.LeadSourceUpdate, APIMethod.POST, true, data);
    }

    export const getLeadSourceCategories = async () => {
        return executeAPI(API.EndPoint.LeadSourceCategoryList, APIMethod.GET, true);
    }

    export const getLeadSourceCategory = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.LeadSourceCategoryDetail, APIMethod.GET, true, null, params);
    }

    export const addLeadSourceCategory = async (data: any) => {
        return executeAPI(API.EndPoint.LeadSourceCategoryAdd, APIMethod.POST, true, data);
    }

    export const updateLeadSourceCategory = async (data: any) => {
        return executeAPI(API.EndPoint.LeadSourceCategoryUpdate, APIMethod.POST, true, data);
    }

    export const getLeadStatuses = async () => {
        return executeAPI(API.EndPoint.LeadStatusList, APIMethod.GET, true);
    }

    export const getLeadStatus = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.LeadStatusDetail, APIMethod.GET, true, null, params);
    }

    export const addLeadStatus = async (data: any) => {
        return executeAPI(API.EndPoint.LeadStatusAdd, APIMethod.POST, true, data);
    }

    export const updateLeadStatus = async (data: any) => {
        return executeAPI(API.EndPoint.LeadStatusUpdate, APIMethod.POST, true, data);
    }

    export const getInsuranceTypes = async () => {
        return executeAPI(API.EndPoint.InsuranceTypeList, APIMethod.GET, true);
    }

    export const getInsuranceType = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.InsuranceTypeDetail, APIMethod.GET, true, null, params);
    }

    export const addInsuranceType = async (data: any) => {
        return executeAPI(API.EndPoint.InsuranceTypeAdd, APIMethod.POST, true, data);
    }

    export const updateInsuranceType = async (data: any) => {
        return executeAPI(API.EndPoint.InsuranceTypeUpdate, APIMethod.POST, true, data);
    }

    export const getInsuranceCompanies = async () => {
        return executeAPI(API.EndPoint.InsuranceCompanyList, APIMethod.GET, true);
    }

    export const getInsuranceCompany = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.InsuranceCompanyDetail, APIMethod.GET, true, null, params);
    }

    export const addInsuranceCompany = async (data: any) => {
        return executeAPI(API.EndPoint.InsuranceCompanyAdd, APIMethod.POST, true, data);
    }

    export const updateInsuranceCompany = async (data: any) => {
        return executeAPI(API.EndPoint.InsuranceCompanyUpdate, APIMethod.POST, true, data);
    }

    export const getLeadTypes = async () => {
        return executeAPI(API.EndPoint.LeadTypeList, APIMethod.GET, true);
    }

    export const getLeadType = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.LeadTypeDetail, APIMethod.GET, true, null, params);
    }

    export const addLeadType = async (data: any) => {
        return executeAPI(API.EndPoint.LeadTypeAdd, APIMethod.POST, true, data);
    }

    export const updateLeadType = async (data: any) => {
        return executeAPI(API.EndPoint.LeadTypeUpdate, APIMethod.POST, true, data);
    }

    export const getLeadTasks = async () => {
        return executeAPI(API.EndPoint.LeadTaskList, APIMethod.GET, true);
    }

    export const getLeadTask = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.LeadTaskDetail, APIMethod.GET, true, null, params);
    }

    export const addLeadTask = async (data: any) => {
        return executeAPI(API.EndPoint.LeadTaskAdd, APIMethod.POST, true, data);
    }

    export const updateLeadTask = async (data: any) => {
        return executeAPI(API.EndPoint.LeadTaskUpdate, APIMethod.POST, true, data);
    }

    export const getLeadProspectTypes = async () => {
        return executeAPI(API.EndPoint.LeadProspectTypeList, APIMethod.GET, true);
    }

    export const getLeadProspectType = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.LeadProspectTypeDetail, APIMethod.GET, true, null, params);
    }

    export const addLeadProspectType = async (data: any) => {
        return executeAPI(API.EndPoint.LeadProspectTypeAdd, APIMethod.POST, true, data);
    }

    export const updateLeadProspectType = async (data: any) => {
        return executeAPI(API.EndPoint.LeadProspectTypeUpdate, APIMethod.POST, true, data);
    }

    export const LeadUpdateFollowUp = async (data: any) => {
        return executeAPI(API.EndPoint.LeadUpdateSaleFollowUp, APIMethod.POST, true, data);
    }

    export const LeadUpdateTestdrive = async (data: any) => {
        return executeAPI(API.EndPoint.LeadUpdateSaleTestdrive, APIMethod.POST, true, data);
    }

    export const LeadUpdateVisit = async (data: any) => {
        return executeAPI(API.EndPoint.LeadUpdateSaleVisit, APIMethod.POST, true, data);
    }

    export const LeadRescheduleTestdrive = async (data: any) => {
        return executeAPI(API.EndPoint.LeadRescheduleSaleTestdrive, APIMethod.POST, true, data);
    }

    export const LeadPlanTaskV2 = async (data: any) => {
        return executeAPI(API.EndPoint.LeadPlanNextTask, APIMethod.POST, true, data);
    }

    export const CreateLead = async (data: any) => {
        return executeAPI(API.EndPoint.CreateLead, APIMethod.POST, true, data);
    }

    export const UpdateLead = async (data: any) => {
        return executeAPI(API.EndPoint.UpdateLead, APIMethod.POST, true, data);
    }

    export const LostDrop = async (data: any) => {
        return executeAPI(API.EndPoint.LostDrop, APIMethod.POST, true, data);
    }

    export const getLeadReasons = async () => {
        return executeAPI(API.EndPoint.LeadReasonList, APIMethod.GET, true);
    }

    export const getLeadReason = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.LeadReasonDetail, APIMethod.GET, true, null, params);
    }

    export const getUnallocatedleadLists = async (modelUUID: string, variantUUID: string, interiorUUID: string, exteriorUUID: string, fuelTypeUUID: string) => {
        let params: { [k: string]: any } = {};
        params["models_uuid"] = modelUUID;
        params["variant_uuid"] = variantUUID;
        params["interior_uuid"] = interiorUUID;
        params["exterior_uuid"] = exteriorUUID;
        params["fuel_type_uuid"] = fuelTypeUUID;
        return executeAPI(API.EndPoint.UnallocatedLeadList, APIMethod.GET, true, null, params);
    }

    export const addLeadReason = async (data: any) => {
        return executeAPI(API.EndPoint.LeadReasonAdd, APIMethod.POST, true, data);
    }

    export const UserPriceQuotes = async (data: any) => {
        return executeAPI(API.EndPoint.UserPriceQuotes, APIMethod.POST, true, data);
    }

    export const UserAllocatePrice = async (data: any) => {
        return executeAPI(API.EndPoint.UserPriceApproval, APIMethod.POST, true, data);
    }

    export const updateLeadReason = async (data: any) => {
        return executeAPI(API.EndPoint.LeadReasonUpdate, APIMethod.POST, true, data);
    }

    export const getLeadTitles = async () => {
        return executeAPI(API.EndPoint.LeadTitleList, APIMethod.GET, true);
    }

    export const getLeadTitle = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.LeadTitleDetail, APIMethod.GET, true, null, params);
    }

    export const addLeadTitle = async (data: any) => {
        return executeAPI(API.EndPoint.LeadTitleAdd, APIMethod.POST, true, data);
    }

    export const updateLeadTitle = async (data: any) => {
        return executeAPI(API.EndPoint.LeadTitleUpdate, APIMethod.POST, true, data);
    }

    export const getPaymentModes = async () => {
        return executeAPI(API.EndPoint.PaymentModeList, APIMethod.GET, true);
    }

    export const getPaymentMode = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.PaymentModeDetail, APIMethod.GET, true, null, params);
    }

    export const addPaymentMode = async (data: any) => {
        return executeAPI(API.EndPoint.PaymentModeAdd, APIMethod.POST, true, data);
    }

    export const updatePaymentMode = async (data: any) => {
        return executeAPI(API.EndPoint.PaymentModeUpdate, APIMethod.POST, true, data);
    }

    export const getShowrooms = async () => {
        let params: { [k: string]: any } = {};
        return executeAPI(API.EndPoint.ShowroomList, APIMethod.GET, true, null, params);
    }

    export const getShowroom = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.ShowroomDetail, APIMethod.GET, true, null, params);
    }

    export const addShowroom = async (data: any) => {
        return executeAPI(API.EndPoint.ShowroomAdd, APIMethod.POST, true, data);
    }

    export const updateShowroom = async (data: any) => {
        return executeAPI(API.EndPoint.ShowroomUpdate, APIMethod.POST, true, data);
    }

    export const getModels = async () => {
        let params: { [k: string]: any } = {};
        return executeAPI(API.EndPoint.ModelList, APIMethod.GET, true, null, params);
    }

    export const getModel = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.ModelDetail, APIMethod.GET, true, null, params);
    }

    export const addModel = async (data: any) => {
        return executeAPI(API.EndPoint.ModelAdd, APIMethod.POST, true, data);
    }

    export const updateModel = async (data: any) => {
        return executeAPI(API.EndPoint.ModelUpdate, APIMethod.POST, true, data);
    }

    export const getModelVariants = async (modelID?: string) => {
        let params: { [k: string]: any } = {};
        if (modelID) {
            params["model_uuid"] = modelID;
        }
        return executeAPI(API.EndPoint.ModelVariantList, APIMethod.GET, true, null, params);
    }

    export const getModelVariant = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.ModelVariantDetail, APIMethod.GET, true, null, params);
    }

    export const addModelVariant = async (data: any) => {
        return executeAPI(API.EndPoint.ModelVariantAdd, APIMethod.POST, true, data);
    }

    export const updateModelVariant = async (data: any) => {
        return executeAPI(API.EndPoint.ModelVariantUpdate, APIMethod.POST, true, data);
    }

    export const getModelVariantColors = async (modelVariantID: string) => {
        let params: { [k: string]: any } = {};
        params['uuid'] = modelVariantID;
        params["limit"] = 10000;
        params["status"] = 1;
        return executeAPI(API.EndPoint.ModelVariantColorList, APIMethod.GET, true, null, params);
    }

    export const getColors = async () => {
        return executeAPI(API.EndPoint.ColorList, APIMethod.GET, true);
    }

    export const getColor = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.ColorDetail, APIMethod.GET, true, null, params);
    }

    export const addColor = async (data: any) => {
        return executeAPI(API.EndPoint.ColorAdd, APIMethod.POST, true, data);
    }

    export const updateColor = async (data: any) => {
        return executeAPI(API.EndPoint.ColorUpdate, APIMethod.POST, true, data);
    }

    export const getFuelTypes = async () => {
        let params: { [k: string]: any } = {};
        return executeAPI(API.EndPoint.FuelTypeList, APIMethod.GET, true, null, params);
    }

    export const getFuelType = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.FuelTypeDetail, APIMethod.GET, true, null, params);
    }

    export const addFuelType = async (data: any) => {
        return executeAPI(API.EndPoint.FuelTypeAdd, APIMethod.POST, true, data);
    }

    export const updateFuelType = async (data: any) => {
        return executeAPI(API.EndPoint.FuelTypeUpdate, APIMethod.POST, true, data);
    }

    export const getDemoCar = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.DemoCarDetail, APIMethod.GET, true, null, params);
    }

    export const addDemoCar = async (data: any) => {
        return executeAPI(API.EndPoint.DemoCarAdd, APIMethod.POST, true, data);
    }

    export const inventoryAdd = async (data: any) => {
        return executeAPI(API.EndPoint.UserInventoryAdd, APIMethod.POST, true, data);
    }

    export const inventoryPriceAdd = async (data: any) => {
        return executeAPI(API.EndPoint.UserInventoryPriceAdd, APIMethod.POST, true, data);
    }

    export const inventoryUpdate = async (data: any) => {
        return executeAPI(API.EndPoint.UserInventoryUpdate, APIMethod.POST, true, data);
    }

    export const getInventoryDetail = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.UserInventoryDetail, APIMethod.GET, true, null, params);
    }
    export const getInventoryFundType = async () => {
        return executeAPI(API.EndPoint.UserInventoryFundType, APIMethod.GET, true, null);
    }

    export const getInventoryPriceDetail = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.UserInventoryPriceDetail, APIMethod.GET, true, null, params);
    }

    export const updateDemoCar = async (data: any) => {
        return executeAPI(API.EndPoint.DemoCarUpdate, APIMethod.POST, true, data);
    }

    export const getBodyType = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.BodyTypeDetail, APIMethod.GET, true, null, params);
    }

    export const addBodyType = async (data: any) => {
        return executeAPI(API.EndPoint.BodyTypeAdd, APIMethod.POST, true, data);
    }

    export const updateBodyType = async (data: any) => {
        return executeAPI(API.EndPoint.BodyTypeUpdate, APIMethod.POST, true, data);
    }

    export const getTransmissionType = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.TransmissionTypeDetail, APIMethod.GET, true, null, params);
    }

    export const addTransmissionType = async (data: any) => {
        return executeAPI(API.EndPoint.TransmissionTypeAdd, APIMethod.POST, true, data);
    }

    export const updateTransmissionType = async (data: any) => {
        return executeAPI(API.EndPoint.TransmissionTypeUpdate, APIMethod.POST, true, data);
    }

    export const getInteriorColor = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.InteriorColorDetail, APIMethod.GET, true, null, params);
    }
    export const getInventoryList = async (modelUUID: string, variantUUID: string) => {
        let params: { [k: string]: any } = {};
        params["models_uuid"] = modelUUID;
        params["variant_uuid"] = variantUUID;
        return executeAPI(API.EndPoint.UserInventory, APIMethod.GET, true, null, params);
    }

    export const addInteriorColor = async (data: any) => {
        return executeAPI(API.EndPoint.InteriorColorAdd, APIMethod.POST, true, data);
    }

    export const updateInteriorColor = async (data: any) => {
        return executeAPI(API.EndPoint.InteriorColorUpdate, APIMethod.POST, true, data);
    }

    export const getExteriorColor = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.ExteriorColorDetail, APIMethod.GET, true, null, params);
    }

    export const addExteriorColor = async (data: any) => {
        return executeAPI(API.EndPoint.ExteriorColorAdd, APIMethod.POST, true, data);
    }

    export const updateExteriorColor = async (data: any) => {
        return executeAPI(API.EndPoint.ExteriorColorUpdate, APIMethod.POST, true, data);
    }

    export const getUser = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.UserDetail, APIMethod.GET, true, null, params);
    }

    export const addUser = async (data: any) => {
        return executeAPI(API.EndPoint.UserAdd, APIMethod.POST, true, data);
    }

    export const updateUser = async (data: any) => {
        return executeAPI(API.EndPoint.UserUpdate, APIMethod.POST, true, data);
    }

    export const getTeam = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["id"] = id;
        return executeAPI(API.EndPoint.TeamDetail, APIMethod.GET, true, null, params);
    }

    export const addTeam = async (data: any) => {
        return executeAPI(API.EndPoint.TeamAdd, APIMethod.POST, true, data);
    }

    export const updateTeam = async (data: any) => {
        return executeAPI(API.EndPoint.TeamUpdate, APIMethod.POST, true, data);
    }

    export const getDealer = async (code: string) => {
        let params: { [k: string]: any } = {};
        params["dealer_code"] = code;
        return executeAPI(API.EndPoint.DealerDetail, APIMethod.GET, true, null, params);
    }

    export const addDealer = async (data: any) => {
        return executeAPI(API.EndPoint.DealerAdd, APIMethod.POST, true, data);
    }

    export const updateDealer = async (id: string, data: any) => {
        return executeAPI(API.EndPoint.DealerUpdate + "/" + id, APIMethod.POST, true, data);
    }

    export const getRoles = async () => {
        let params: { [k: string]: any } = {};
        return executeAPI(API.EndPoint.RoleList, APIMethod.GET, true, null, params);
    }

    export const getRole = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.RoleDetail, APIMethod.GET, true, null, params);
    }

    export const addRole = async (data: any) => {
        return executeAPI(API.EndPoint.RoleAdd, APIMethod.POST, true, data);
    }

    export const generateReport = async (data: any) => {
        return executeAPI(API.EndPoint.UserGenrateReport, APIMethod.POST, true, data);
    }

    export const updateRole = async (data: any) => {
        return executeAPI(API.EndPoint.RoleUpdate, APIMethod.POST, true, data);
    }

    export const getTargetRules = async () => {
        let params: { [k: string]: any } = {};
        return executeAPI(API.EndPoint.TargetRulesList, APIMethod.GET, true, null, params);
    }

    export const getTargetRule = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.TargetRulesDetail, APIMethod.GET, true, null, params);
    }

    export const getHelpAndSupportDetail = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.UserHelpSupportDetail, APIMethod.GET, true, null, params);
    }

    export const getTargetStage = async () => {
        let params: { [k: string]: any } = {};
        return executeAPI(API.EndPoint.TargetStageList, APIMethod.GET, true, null, params);
    }

    export const addTargetRules = async (data: any) => {
        return executeAPI(API.EndPoint.TargetRulesAdd, APIMethod.POST, true, data);
    }

    export const userAddHelpAndSupport = async (data: any) => {
        return executeAPI(API.EndPoint.UserHelpSupportAdd, APIMethod.POST, true, data);
    }

    export const updateTargetRules = async (data: any) => {
        return executeAPI(API.EndPoint.TargetRulesUpdate, APIMethod.POST, true, data);
    }

    export const getVersions = async () => {
        let params: { [k: string]: any } = {};
        return executeAPI(API.EndPoint.VersionList, APIMethod.GET, true, null, params);
    }

    export const getVersion = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        return executeAPI(API.EndPoint.VersionDetail, APIMethod.GET, true, null, params);
    }

    export const addVersion = async (data: any) => {
        return executeAPI(API.EndPoint.VersionAdd, APIMethod.POST, true, data);
    }

    export const updateVersion = async (data: any) => {
        return executeAPI(API.EndPoint.VersionUpdate, APIMethod.POST, true, data);
    }

    export const getShowroomTransfer = async (id: string) => {
        return executeAPI(API.EndPoint.ShowroomTransferList + '/' + id, APIMethod.GET, true);
    }

    export const getFuelRefill = async (id: string) => {
        return executeAPI(API.EndPoint.FuelRefillList + '/' + id, APIMethod.GET, true);
    }

    export const getOthers = async (id: string) => {
        return executeAPI(API.EndPoint.OtherTripList + '/' + id, APIMethod.GET, true);
    }

    export const getTestDriveDetail = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["testdrive_uuid"] = id;
        return executeAPI(API.EndPoint.TestDriveDetail, APIMethod.GET, true, null, params);
    }

    export const cancelTestDrive = async (data: any) => {
        return executeAPI(API.EndPoint.TestDriveCancel, APIMethod.POST, true, data);
    }

    export const verifyTestDrive = async (data: any) => {
        return executeAPI(API.EndPoint.TestDriveVerify, APIMethod.POST, true, data);
    }

    export const testDriveMobileNumberUpdate = async (data: any) => {
        return executeAPI(API.EndPoint.TestDriveMobileNumberUpdate, APIMethod.POST, true, data);
    }

    export const scheduleTestdrive = async (testDriveLocation: string, demoCar: string, customerName: string, mobileNumber: string, dmsLeadID: number, address: string, showroomUUID: string, dateTime: string) => {
        let data: { [k: string]: any } = {};
        data["test_drive_location_type_uuid"] = testDriveLocation;
        data["demo_car_uuid"] = demoCar;
        data["customer_name"] = customerName;
        data["mobile_number"] = mobileNumber;
        data["dms_lead_id"] = dmsLeadID;
        data["test_drive_home_address"] = address;
        data["test_drive_showroom_uuid"] = showroomUUID;
        data["test_drive_date_time"] = dateTime;
        return executeAPI(API.EndPoint.TestDriveSchedule, APIMethod.POST, true, data);
    }

    export const scheduleShowroomTransfer = async (showroomFromUUID: string, showroomToUUID: string, demoCar: string, dateTime: string) => {
        let data: { [k: string]: any } = {};
        data["from_showroom_uuid"] = showroomFromUUID;
        data["to_showroom_uuid"] = showroomToUUID;
        data["demo_car_uuid"] = demoCar;
        data["test_drive_date_time"] = dateTime;
        return executeAPI(API.EndPoint.ShowroomTransferSchedule, APIMethod.POST, true, data);
    }

    export const proceedToStartTestdrive = async (test_driveLocation: string, demoCar: string, customerName: string, mobileNumber: string, dmsLeadID: number, address: string, showroomUUID: string, dateTime: string, odometerImage: string, odometerReading: string) => {
        let data: { [k: string]: any } = {};
        data["test_drive_location_type_uuid"] = test_driveLocation;
        data["demo_car_uuid"] = demoCar;
        data["customer_name"] = customerName;
        data["mobile_number"] = mobileNumber;
        data["dms_lead_id"] = dmsLeadID;
        data["test_drive_home_address"] = address;
        data["test_drive_showroom_uuid"] = showroomUUID;
        data["test_drive_date_time"] = dateTime;
        data["starting_image_url"] = odometerImage;
        data["starting_kilometer"] = odometerReading;
        return executeAPI(API.EndPoint.TestDriveProceedToStart, APIMethod.POST, true, data);
    }

    export const fuelRefillStart = async (fromShowroomUUID: string, demoCar: string, dateTime: string, odometerImg: string, odometerReading: string) => {
        let data: { [k: string]: any } = {};
        data["from_showroom_uuid"] = fromShowroomUUID;
        data["demo_car_uuid"] = demoCar;
        data["test_drive_date_time"] = dateTime;
        data["starting_odometer_image"] = odometerImg;
        data["starting_odometer_reading"] = odometerReading;
        return executeAPI(API.EndPoint.FuelRefillStart, APIMethod.POST, true, data);
    }


    export const otherStart = async (fromShowroomUUID: string, demoCar: string, purposeTrip: string, dateTime: string, odometerImg: string, odometerReading: string) => {
        let data: { [k: string]: any } = {};
        data["from_showroom_uuid"] = fromShowroomUUID;
        data["purpose_of_trip"] = purposeTrip;
        data["demo_car_uuid"] = demoCar;
        data["test_drive_date_time"] = dateTime;
        data["starting_odometer_image"] = odometerImg;
        data["starting_odometer_reading"] = odometerReading;
        return executeAPI(API.EndPoint.OtherTripStart, APIMethod.POST, true, data);
    }

    export const fuelRefillAmountFinish = async (testDriveUUID: string, fuelAmount: string, fuelLiter: string) => {
        let data: { [k: string]: any } = {};
        data["test_drive_uuid"] = testDriveUUID;
        data["fuel_amount"] = fuelAmount;
        data["fuel_liter"] = fuelLiter;
        return executeAPI(API.EndPoint.FuelRefillAmountFinish, APIMethod.POST, true, data);
    }

    export const helpSupportStatusUpdate = async (uuid: string, status: string) => {
        let data: { [k: string]: any } = {};
        data["uuid"] = uuid;
        data["status"] = status;
        return executeAPI(API.EndPoint.UserHelpSupportStatusUpdate, APIMethod.POST, true, data);
    }

    export const getLeadTasksLists = async (type: string, taskType?: string, mobileNumber?: string, leadID?: string, customerName?: string, userID?: string) => {
        let params: { [k: string]: any } = {};
        params['pagenumber'] = 1;
        params['limit'] = 1000;
        params["type"] = type;
        params["task_type"] = taskType;
        params["mobile_number"] = mobileNumber;
        params["customer_name"] = customerName;
        params["lead_id"] = leadID;
        params["user_uuid"] = userID;
        return executeAPI(API.EndPoint.LeadList, APIMethod.GET, true, null, params);
    }

    export const getLeadLists = async (LeadStatus: string, startDate: string, endDate: string, mobileNumber?: string, leadID?: string, customerName?: string, modelUUID?: string, leadSourseCategory?: string, task?: string) => {
        let params: { [k: string]: any } = {};
        params['pagenumber'] = 1;
        params['limit'] = 1000;
        params["startDate"] = startDate;
        params["endDate"] = endDate;
        params["lead_status"] = LeadStatus;
        params["mobile_number"] = mobileNumber;
        params["customer_name"] = customerName;
        params["lead_id"] = leadID;
        params["model_uuid"] = modelUUID;
        params["lead_source_category_uuid"] = leadSourseCategory;
        params["task_uuid"] = task;
        return executeAPI(API.EndPoint.LeadListV3, APIMethod.GET, true, null, params);
    }


    export const getLeadDetail = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["lead_uuid"] = id;
        return executeAPI(API.EndPoint.LeadDetail, APIMethod.GET, true, null, params);
    }

    export const updateLead = async (data: any) => {
        return executeAPI(API.EndPoint.LeadUpdate, APIMethod.POST, true, data);
    }

    export const getLeadInterestedVehicle = async (id: string, leadUUID: string) => {
        let params: { [k: string]: any } = {};
        params["uuid"] = id;
        params["lead_uuid"] = leadUUID;
        return executeAPI(API.EndPoint.LeadInterestedVehicleDetail, APIMethod.GET, true, null, params);
    }

    export const addLeadInterestedVehicle = async (data: any) => {
        return executeAPI(API.EndPoint.LeadInterestedVehicleAdd, APIMethod.POST, true, data);
    }

    export const updateLeadInterestedVehicle = async (data: any) => {
        return executeAPI(API.EndPoint.LeadInterestedVehicleUpdate, APIMethod.POST, true, data);
    }

    export const getLeadBookings = async () => {
        return executeAPI(API.EndPoint.LeadBookACarList, APIMethod.GET, true);
    }

    export const getLeadBookingDetail = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["lead_uuid"] = id;
        return executeAPI(API.EndPoint.LeadBookACarDetail, APIMethod.GET, true, null, params);
    }

    export const getInvoiceDetails = async (id: string, invoiceUUID: string) => {
        let params: { [k: string]: any } = {};
        params["lead_uuid"] = id;
        params["invoice_uuid"] = invoiceUUID;
        return executeAPI(API.EndPoint.InvoiceDetails, APIMethod.GET, true, null, params);
    }

    export const bookACar = async (data: any) => {
        return executeAPI(API.EndPoint.LeadBookACarAdd, APIMethod.POST, true, data);
    }

    export const invoiceDetails = async (data: any) => {
        return executeAPI(API.EndPoint.InvoiceDetailsAdd, APIMethod.POST, true, data);
    }

    export const updateDeliveryDate = async (data: any) => {
        return executeAPI(API.EndPoint.UpdateDeliveryDate, APIMethod.POST, true, data);
    }

    export const cancelBooking = async (data: any) => {
        return executeAPI(API.EndPoint.LeadBookACarCancel, APIMethod.POST, true, data);
    }

    export const getUserDashboardToday = async (uuid: string, teammUUID?: string) => {
        let params: { [k: string]: any } = {};
        params["showroom_id"] = uuid;
        params["team_uuid"] = teammUUID;
        return executeAPI(API.EndPoint.UserDashboardToday, APIMethod.GET, true, null, params);
    }

    export const getUserDashboardETVBRL = async (startDate: string, endDate: string, showroomUUID?: string) => {
        let params: { [k: string]: any } = {};
        params["startDate"] = startDate;
        params["endDate"] = endDate;
        params["showroom_id"] = showroomUUID;
        return executeAPI(API.EndPoint.UserDashboardETVBRL, APIMethod.GET, true, null, params);
    }

    export const getTargetList = async (showroomUUID: string, month: string) => {
        let params: { [k: string]: any } = {};
        params["showroom_id"] = showroomUUID;
        params["month"] = month;
        return executeAPI(API.EndPoint.UserTargetList, APIMethod.GET, true, null, params);
    }

    export const addTarget = async (showroomUUID: string, month: string, teamData: { team_uuid: string; employee: { emp_uuid: string; status_target: { status: string; target: string } }[] }[]) => {
        let data: { [k: string]: any } = {};
        data["showroom_uuid"] = showroomUUID;
        data["month"] = month;
        data["team"] = teamData;
        return executeAPI(API.EndPoint.UserTargetAdd, APIMethod.POST, true, data);
    }

    export const inventoryOperationalCostAdd = async (inventroyUUID: string, OperationalCost: string, operationalDescription: string) => {
        let data: { [k: string]: any } = {};
        data["inventory_uuid"] = inventroyUUID;
        data["operational_cost"] = OperationalCost;
        data["operational_cost_description"] = operationalDescription;
        return executeAPI(API.EndPoint.UserInventoryOperationalCostAdd, APIMethod.POST, true, data);
    }

    export const inventoryOperationalCostUpdate = async (uuid: string, inventroyUUID: string, OperationalCost: string, operationalDescription: string) => {
        let data: { [k: string]: any } = {};
        data["uuid"] = uuid;
        data["inventory_uuid"] = inventroyUUID;
        data["operational_cost"] = OperationalCost;
        data["operational_cost_description"] = operationalDescription;
        return executeAPI(API.EndPoint.UserInventoryOperationalCostUpdate, APIMethod.POST, true, data);
    }

    export const updateTarget = async (month: string, employeeUUID: string, taskTargetUUID: string, showroomUUID: string, statusTarget: { status: string; target: string }[]) => {
        let data: { [k: string]: any } = {};
        data["month"] = month;
        data["emp_uuid"] = employeeUUID;
        data["task_target_uuid"] = taskTargetUUID;
        data["showroom_uuid"] = showroomUUID;
        data["status_target"] = statusTarget;
        return executeAPI(API.EndPoint.UserTargetUpdate, APIMethod.POST, true, data);
    }

    export const freezeTarget = async (month: string, showroomUUID: string) => {
        let data: { [k: string]: any } = {};
        data["month"] = month;
        data["showroom_uuid"] = showroomUUID;
        return executeAPI(API.EndPoint.UserTargetFreeze, APIMethod.POST, true, data);
    }

    export const uploadDrivingLicence = async (id: string, dlImage: string, dlNumber: string) => {
        let data: { [k: string]: any } = {};
        data["test_drive_uuid"] = id;
        data["driving_licence_image"] = dlImage;
        data["driving_licence_number"] = dlNumber;
        return executeAPI(API.EndPoint.TestDriveUploadDrivingLicence, APIMethod.POST, true, data);
    }

    export const sentDeclarationForm = async (id: string, sentType: number, email?: string, mobileNumber?: string) => {
        let data: { [k: string]: any } = {};
        data["test_drive_uuid"] = id;
        data["declaration_form_type"] = sentType;
        data["email_id"] = email;
        data["mobile_number"] = mobileNumber;
        return executeAPI(API.EndPoint.TestDriveSentDeclarationForm, APIMethod.POST, true, data);
    }

    export const sentDeclarationEmailOTP = async (id: string, email: string) => {
        let data: { [k: string]: any } = {};
        data["test_drive_uuid"] = id;
        data["email_id"] = email;
        return executeAPI(API.EndPoint.TestDriveSentDeclarationEmailOTP, APIMethod.POST, true, data);
    }

    export const sendGatepass = async (id: string, sendingType: number) => {
        let data: { [k: string]: any } = {};
        data["test_drive_uuid"] = id;
        data["gate_pass_sending_type"] = sendingType;
        return executeAPI(API.EndPoint.TestDriveUploadGatePass, APIMethod.POST, true, data);
    }

    export const testdriveStart = async (id: string, odometerImage: string, odometerKm: string, democar?: string) => {
        let data: { [k: string]: any } = {};
        data["test_drive_uuid"] = id;
        data["starting_image_url"] = odometerImage;
        data["starting_kilometer"] = odometerKm;
        data["demo_car_uuid"] = democar;
        return executeAPI(API.EndPoint.TestDriveStart, APIMethod.POST, true, data);
    }

    export const transferStart = async (id: string, odometerImage: string, odometerKm: string) => {
        let data: { [k: string]: any } = {};
        data["test_drive_uuid"] = id;
        data["starting_odometer_image"] = odometerImage;
        data["starting_odometer_reading"] = odometerKm;
        return executeAPI(API.EndPoint.ShowroomTransferStart, APIMethod.POST, true, data);
    }

    export const testdriveComplete = async (id: string, odometerImage: string, odometerKm: string, dateTime?: string) => {
        let data: { [k: string]: any } = {};
        data["test_drive_uuid"] = id;
        data["ending_image_url"] = odometerImage;
        data["ending_kilometer"] = odometerKm;
        data["date_time"] = dateTime;
        return executeAPI(API.EndPoint.TestDriveComplete, APIMethod.POST, true, data);
    }

    export const transferComplete = async (id: string, odometerImage: string, odometerKm: string) => {
        let data: { [k: string]: any } = {};
        data["test_drive_uuid"] = id;
        data["ending_odometer_image"] = odometerImage;
        data["ending_odometer_reading"] = odometerKm;
        return executeAPI(API.EndPoint.ShowroomTransferFinish, APIMethod.POST, true, data);
    }

    export const otherComplete = async (id: string, odometerImage: string, odometerKm: string) => {
        let data: { [k: string]: any } = {};
        data["test_drive_uuid"] = id;
        data["ending_odometer_image"] = odometerImage;
        data["ending_odometer_reading"] = odometerKm;
        return executeAPI(API.EndPoint.OtherTripFinish, APIMethod.POST, true, data);
    }

    export const updateVinAllotment = async (inventoryAllotmentUUID: string, InventoryUUID: string, bookingUUID: string, leadUUID: string) => {
        let data: { [k: string]: any } = {};
        data["inventory_allotment_uuid"] = inventoryAllotmentUUID;
        data["inventory_uuid"] = InventoryUUID;
        data["booking_uuid"] = bookingUUID;
        data["lead_uuid"] = leadUUID;
        return executeAPI(API.EndPoint.UserInventoryApprovalAllotment, APIMethod.POST, true, data);
    }

    export const getETTeamList = async (showroomUUID: string) => {
        let params: { [k: string]: any } = {};
        params["showroom_id"] = showroomUUID;
        return executeAPI(API.EndPoint.UserETTeamList, APIMethod.GET, true, null, params);
    }

    export const getETToTeamList = async (showroomUUID: string, selectedUser: string, selectedModelID?: string, selectedLeadSourceID?: string) => {
        let params: { [k: string]: any } = {};
        params["from_user"] = selectedUser;
        params["showroom_id"] = showroomUUID;
        params["models_uuid"] = selectedModelID;
        params["lead_source_category_uuid"] = selectedLeadSourceID;
        return executeAPI(API.EndPoint.UserETToTeamList, APIMethod.GET, true, null, params);
    }

    export const userETLeadTransfer = async (leadUUID: string[], fromUserIds: string[], toUserIds: string[]) => {
        let data: { [k: string]: any } = {};
        data["lead_uuids"] = leadUUID;
        data["fromUserIds"] = fromUserIds;
        data["toUserIds"] = toUserIds;
        return executeAPI(API.EndPoint.UserETLeadTransfer, APIMethod.POST, true, data);
    }
    export const LeadInterestedVehicleDelete = async (uuid: string, leadUUID: string) => {
        let data: { [k: string]: any } = {};
        data["uuid"] = uuid;
        data["lead_uuid"] = leadUUID;
        return executeAPI(API.EndPoint.LeadInterestedVehicleDelete, APIMethod.POST, true, data);
    }

    export const getLostDrop = async () => {
        let params: { [k: string]: any } = {};
        params['pagenumber'] = 1;
        params['limit'] = 100;
        return executeAPI(API.EndPoint.LostDropList, APIMethod.GET, true, null, params);
    }

}