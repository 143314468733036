import { Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { ProjectStrings } from "../common/Constants";

const UpdateDeliveryDateEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, resetInvoiceState?: () => void, invoiceUUID: string, deliveryDate?: string }> = ({ showDialog, closeDialog, reloadData, id, invoiceUUID, resetInvoiceState, deliveryDate }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.UpdateDeiveryDateEntry>>(null);
    const [initialValue, setInitialValue] = useState<APIData.UpdateDeiveryDateEntry>(InitialData.UpdateDeliveryDateEntry);
    const updateDateRef = useRef<HTMLDivElement | null>(null);

    const yupSchema = Yup.object().shape({
        actual_delivery_date_time: Yup.string().required(ProjectStrings.ValidationRequired),
    });

    useEffect(() => {
        if (deliveryDate) {
            setInitialValue({
                actual_delivery_date_time: deliveryDate,
                invoice_uuid: invoiceUUID,
                lead_uuid: id ? id : ""
            })
        }
    }, [deliveryDate]);

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-min">
            <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                let submitValues: any = { ...values };
                addProcessingRequests();
                submitValues['invoice_uuid'] = invoiceUUID;
                submitValues['lead_uuid'] = id;
                ServerAPI.updateDeliveryDate(submitValues).then(response => {
                    if (response && response['success'] && response['message']) {
                        toast.success(response['message']);
                        reloadData();
                        closeDialog();
                    } else if (response && response['message']) {
                        toast.error(response['message']);
                    } else {
                        toast.error('Unknown Error Occurred');
                    }
                }).finally(() => {
                    setSubmitting(false);
                    reduceProcessingRequests();
                });
            }} >
                {(formikProps) => {
                    return (
                        <div className="tabdetails" ref={updateDateRef}>
                            <h4 className="title-text text-center">Update Delivery Date</h4>
                            <div className="custom-border-grey my-2"></div>
                            <Form>
                                <div className="px-2 form-field-container">
                                    <div className="row px-2 py-3">
                                        <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom">
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "datetimepicker", label: "Delivery Date", name: "actual_delivery_date_time", placeholder: "Delivery Date", required: true }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row btn-form-submit">
                                    <button type="button" className="button1" onClick={closeDialog}>Cancel</button>
                                    <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                        formikProps.submitForm();
                                        if (!formikProps.isValid) {
                                            toast.error("Please ensure all fields are Filled and Valid!");
                                        }
                                    }}>Submit</button>
                                </div>
                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </Drawer>
    )
}

export default UpdateDeliveryDateEntryDialog;