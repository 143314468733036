
const CellOdoMeterReadingFuel: React.FC<{ data: any }> = ({ data }) => {
    return (
        <><small>Start KM: {data['odometer_start_reading']}</small><br />
            <small> Prev Fuel Refill: {data['previous_fuel_refill_odometer_reading']}</small><br />
            <small>Kms run between refills: {data['km_run_between_refills']}</small>
        </>
    )
}

export default CellOdoMeterReadingFuel;