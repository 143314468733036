import React from 'react';
import { getFormattedLocalDate } from '../../common/Utilities';

const CellUserNestedToDate: React.FC<{ data: any }> = ({ data }) => {
    const toDate = data?.description?.to_date;

    return (
        <>{toDate ? getFormattedLocalDate(toDate) : 'N/A'}</>
    );
};

export default CellUserNestedToDate;