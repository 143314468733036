import FuelRefillDetailDialog from "../detailview/FuelRefillDetailDialog";
import OtherDetailDialog from "../detailview/OtherDetailDialog";
import StockTransferDetailDialog from "../detailview/StockTransferDetailDialog";
import TestDriveDetailDialog from "../detailview/TestDriveDetailDialog";
import BodyTypeEntryDialog from "../forms/BodyTypeEntryDialog";
import BusinessEntryDialog from "../forms/BusinessEntryDialog";
import BusinessModeEntryDialog from "../forms/BusinessModeEntryDialog";
import CityEntryDialog from "../forms/CityEntryDialog";
import CategoryEntryDialog from "../forms/CategoryEntryDialog";
import CategoryTypeEntryDialog from "../forms/CategoryTypeEntryDialog";
import ColorEntryDialog from "../forms/ColorEntryDialog";
import CompanyEntryDialog from "../forms/CompanyEntryDialog";
import DealerEntryDialog from "../forms/DealerEntryDialog";
import DemoCarEntryDialog from "../forms/DemoCarEntryDialog";
import ExteriorColorEntryDialog from "../forms/ExteriorColorEntryDialog";
import FuelEntryDialog from "../forms/FuelEntryDialog";
import InteriorColorEntryDialog from "../forms/InteriorColorEntryDialog";
import MenuEntryDialog from "../forms/MenuEntryDialog";
import ModelEntryDialog from "../forms/ModelEntryDialog";
import ModelVaraintEntryDialog from "../forms/ModelVariantEntryDialog";
import ProviderCategoryEntryDialog from "../forms/ProviderCategoryEntryDialog";
import ProviderEntryDialog from "../forms/ProviderEntryDialog";
import RoleEntryDialog from "../forms/RoleEntryDialog";
import ShowroomEntryDialog from "../forms/ShowroomEntryDialog";
import StateEntryDialog from "../forms/StateEntryDialog";
import TransmissionTypeEntryDialog from "../forms/TransmissionTypeEntryDialog";
import UserEntryDialog from "../forms/UserEntryDialog";
import VersionEntryDialog from "../forms/VersionEntryDialog";
import { DateFilterType, Endpoints, PageLinks } from "./Constants";
import { TableViewMappings } from "./TableViewMappings";
import PincodeEntryDialog from "../forms/PincodeEntryDialog";
import LeadCategoryEntryDialog from "../forms/LeadCategoryEntryDialog";
import CustomerTypeEntryDialog from "../forms/CustomerTypeEntryDialog";
import LeadSourceEntryDialog from "../forms/LeadSourceEntryDialog";
import LeadSourceCategoryEntryDialog from "../forms/LeadSourceCategoryEntryDialog";
import LeadStatusEntryDialog from "../forms/LeadStatusEntryDialog";
import InsuranceTypeEntryDialog from "../forms/InsuranceTypeEntry";
import InsuranceCompanyEntryDialog from "../forms/InsuranceCompanyEntry";
import LeadTypeEntryDialog from "../forms/LeadTypeEntryDialog";
import LeadTaskEntryDialog from "../forms/LeadTaskEntryDialog";
import LeadProspectTypeEntryDialog from "../forms/ProspectTypeEntryDialog";
import LeadReasonEntryDialog from "../forms/LeadReasonEntryDialog";
import PaymentModeEntryDialog from "../forms/PaymentModeEntryDialog";
import TeamEntryDialog from "../forms/TeamEntryDialog";
import { FormDataTypes, UIDataTypes } from "./DataTypes";
import LeadDetailDialog from "../detailview/LeadDetailDialog";
import LeadTitleEntryDialog from "../forms/LeadTitleEntryDialog";
import TargetRulesEntryDialog from "../forms/TargetRulesEntryDialog";
import InventoryEntryDialog from "../forms/InventoryEntryDialog";
import UploadUserPriceEntryDialog from "../forms/UploadUserPriceEntryDialog";
import UploadUserInventoryEntryDialog from "../forms/UploadUserInventoryEntryDialog";
import Icon from '@mdi/react';
import { mdiTrayArrowUp } from '@mdi/js';
import InventoryPriceEntryDialog from "../forms/InventoryPriceEntryDialog";
import AvailableInventoryEntryDialog from "../forms/AvailableInventoryEntryDialog";
import HelpAndSupportEntryDialog from "../forms/HelpAndSupportEntryDialog";
import GenerateReportEntryDialog from "../forms/GenerateReportEntryDialog";
import UserTestdriveEntryDialog from "../forms/userTestdriveEntryDialog";
import UserShowroomTransferEntryDialog from "../forms/UserShowroomTransferEntryDialog";
import UserFuelRefillEntryDialog from "../forms/UserfuelRefillEntryDialog";
import UserOtherEntryDialog from "../forms/UserOtherEntryDialog";



export namespace PageMappings {

  interface AdditionalFilter {
    label: string;
    paramName: string;
    optional: boolean;
    options: FormDataTypes.SelectOption[];
  }

  interface ListButtons {
    type: 'form-control' | 'custom',
    customComponent?: any;
    iconStart?: string;
    iconEnd?: any;
    title?: string;
    linkedForm?: any;
  }

  export interface PageDetail {
    pageLink: string,
    title: string,
    titleSingular: string,
    listEndpoint: string,
    listAddlParams?: {
      param_name: string;
      param_value: string | number;
    }[]
    listResponseParam?: string,
    deleteEndpoint?: string,
    statusUpdate?: { endpoint: string, paramName: string },
    form?: any,
    tabs?: UIDataTypes.TabItem[];
    additionalFilter?: AdditionalFilter,
    detailView?: any,
    detailViewIsForm?: boolean,
    additionalListButtons?: ListButtons[],
    addAvailable?: boolean,
    searchAvailable?: boolean,
    exportAvailable?: boolean,
    exportColumns?: TableViewMappings.TableColumn[],
    dateFilterType?: number,
    dateFilterRequired?: boolean,
    noPagination?: boolean,
    filterPopup?: boolean,
    tableFilter?: boolean,
    searchPopup?: boolean
  }

  const TabsTestDrive: UIDataTypes.TabItem[] = [
    {
      tabLabel: "Test Drive",
      tabLink: PageLinks.DEALER_TESTDRIVE,
    },
    {
      tabLabel: "Stock Transfer",
      tabLink: PageLinks.DEALER_SHOWROOM_TRANSFER,
    },
    {
      tabLabel: "Fuel Refill",
      tabLink: PageLinks.DEALER_FUEL_REFILL,
    },
    {
      tabLabel: "Others",
      tabLink: PageLinks.DEALER_OTHER_TRIP,
    },
  ]

  const TabsReportTestDrive: UIDataTypes.TabItem[] = [
    {
      tabLabel: "Test Drive",
      tabLink: PageLinks.DEALER_REPORT_TESTDRIVE,
    },
    {
      tabLabel: "Stock Transfer",
      tabLink: PageLinks.DEALER_REPORT_STOCKTRANSFER,
    },
    {
      tabLabel: "Fuel Refill",
      tabLink: PageLinks.DEALER_REPORT_FUELREFILL,
    },
    {
      tabLabel: "Others",
      tabLink: PageLinks.DEALER_REPORT_OTHERTRIPS,
    },
  ]

  const TabsUserTestDrive: UIDataTypes.TabItem[] = [
    {
      tabLabel: "Test Drive",
      tabLink: PageLinks.USER_TESTDRIVE,
    },
    {
      tabLabel: "Showroom Transfer",
      tabLink: PageLinks.USER_TESTDRIVE_TRANSFER,
    },
    {
      tabLabel: "Fuel Refill",
      tabLink: PageLinks.USER_TESTDRIVE_REFILL,
    },
    {
      tabLabel: "Others",
      tabLink: PageLinks.USER_TESTDRIVE_OTHERS,
    },
  ]

  const TabsLeads: UIDataTypes.TabItem[] = [
    {
      tabLabel: "Today Leads",
      tabLink: PageLinks.DEALER_LEADS_TODAY,
    },
    {
      tabLabel: "Future Leads",
      tabLink: PageLinks.DEALER_LEADS_FUTURE,
    },
    {
      tabLabel: "Completed Leads",
      tabLink: PageLinks.DEALER_LEADS_COMPLETE,
    },
  ]

  const TabsUserDashboard: UIDataTypes.TabItem[] = [
    {
      tabLabel: "Todays Task",
      tabLink: PageLinks.USER_DASHBOARD_TODAY,
    },
    {
      tabLabel: "ETVBRL",
      tabLink: PageLinks.USER_DASHBOARD_ETVBRL,
    },
  ]

  const AdditionalFilterTodayLeads: AdditionalFilter = {
    label: 'Task Type',
    paramName: 'task_type',
    optional: false,
    options: [
      {
        label: "Call",
        value: "CALL"
      },
      {
        label: "Test Drive",
        value: "TD"
      },
      {
        label: "Visit",
        value: "VISIT"
      },
      {
        label: "Delivery",
        value: "DELIVERY"
      },
      {
        label: "Pending",
        value: "PENDING"
      },
    ]
  }

  const exportColumnsReportTestDrive: TableViewMappings.TableColumn[] = [
    { label: "Showroom Location", data_key: "showroom_location", type: "text" },
    { label: "Team Leader", data_key: "team_leader", type: "text" },
    { label: "Sales Consultant", data_key: "sales_consultant", type: "text" },
    { label: "Demo Car Model", data_key: "demo_car_model", type: "text" },
    { label: "Demo Car Variant", data_key: "demo_car_variant", type: "text" },
    { label: "Mobile Number", data_key: "mobile_number", type: "text" },
    { label: "Customer Name", data_key: "customer_name", type: "text" },
    { label: "DMS Lead Id", data_key: "dms_lead_id", type: "text" },
    { label: "Test Drive At", data_key: "testdrive_at", type: "text" },
    { label: "Test Drive Date", data_key: "testdrive_date", type: "text" },
    { label: "Start Time", data_key: "start_time", type: "text" },
    { label: "End Time", data_key: "end_time", type: "text" },
    { label: "Duration", data_key: "duration", type: "text" },
    { label: "Odometer Start Reading", data_key: "odometer_start_reading", type: "text" },
    { label: "Odometer End Reading", data_key: "odometer_end_reading", type: "text" },
    { label: "KM Run", data_key: "km_run", type: "text" },
    { label: "Status", data_key: "status", type: "text" },
    { label: "Demo Car Fuel Type", data_key: "demo_car_fule_type", type: "text" },
    { label: "Demo Car TC Number", data_key: "demo_car_tc_number", type: "text" },
    { label: "Demo Car VIN Number", data_key: "demo_car_vin_number", type: "text" },
  ]

  const exportColumnsReportStockTransfer: TableViewMappings.TableColumn[] = [
    { label: "Transfer Date", data_key: "transfer_date", type: "text" },
    { label: "From Showroom", data_key: "from_showroom", type: "text" },
    { label: "To Showroom", data_key: "to_showroom", type: "text" },
    { label: "Employee Name", data_key: "employee_name", type: "text" },
    { label: "Demo Car Model", data_key: "demo_car_model", type: "text" },
    { label: "Demo Car Variant", data_key: "demo_car_variant", type: "text" },
    { label: "Start Time", data_key: "start_time", type: "text" },
    { label: "End Time", data_key: "end_time", type: "text" },
    { label: "Duration", data_key: "duration", type: "text" },
    { label: "Status", data_key: "status", type: "text" },
    { label: "Odometer Start Reading", data_key: "odometer_start_reading", type: "text" },
    { label: "Odometer End Reading", data_key: "odometer_end_reading", type: "text" },
    { label: "KM Run", data_key: "km_run", type: "text" },
    { label: "Demo Car Fuel Type", data_key: "demo_car_fule_type", type: "text" },
    { label: "Demo Car TC Number", data_key: "demo_car_tc_number", type: "text" },
    { label: "Demo Car VIN Number", data_key: "demo_car_vin_number", type: "text" },
  ]

  const exportColumnsReportFuelRefill: TableViewMappings.TableColumn[] = [
    { label: "Fuel Refill Date", data_key: "fuel_refill_date", type: "text" },
    { label: "Showroom Name", data_key: "showroom_name", type: "text" },
    { label: "Employee Name", data_key: "employee_name", type: "text" },
    { label: "Demo Car Model", data_key: "demo_car_model", type: "text" },
    { label: "Demo Car Variant", data_key: "demo_car_variant", type: "text" },
    { label: "Start Time", data_key: "start_time", type: "text" },
    { label: "End Time", data_key: "end_time", type: "text" },
    { label: "Duration", data_key: "duration", type: "text" },
    { label: "Status", data_key: "status", type: "text" },
    { label: "Odometer Start Reading", data_key: "odometer_start_reading", type: "text" },
    { label: "Previous Fuel Refill Odometer Reading", data_key: "previous_fuel_refill_odometer_reading", type: "text" },
    { label: "KM Run Between Refills", data_key: "km_run_between_refills", type: "text" },
    { label: "Last Refill Amount", data_key: "last_refill_amount", type: "text" },
    { label: "Current Refill Amount", data_key: "current_refill_amount", type: "text" },
    { label: "No of Liters", data_key: "no_of_liters", type: "text" },
    { label: "Demo Car Fuel Type", data_key: "demo_car_fule_type", type: "text" },
    { label: "Demo Car TC Number", data_key: "demo_car_tc_number", type: "text" },
    { label: "Demo Car VIN Number", data_key: "demo_car_vin_number", type: "text" },
  ]

  const exportColumnsReportOther: TableViewMappings.TableColumn[] = [
    { label: "Trip Date", data_key: "trip_date", type: "text" },
    { label: "Purpose of Trip", data_key: "purpose_of_trip", type: "text" },
    { label: "Showroom Name", data_key: "showroom_name", type: "text" },
    { label: "Employee Name", data_key: "employee_name", type: "text" },
    { label: "Demo Car Model", data_key: "demo_car_model", type: "text" },
    { label: "Demo Car Variant", data_key: "demo_car_variant", type: "text" },
    { label: "Start Time", data_key: "start_time", type: "text" },
    { label: "End Time", data_key: "end_time", type: "text" },
    { label: "Duration", data_key: "duration", type: "text" },
    { label: "Status", data_key: "status", type: "text" },
    { label: "Odometer Start Reading", data_key: "odometer_start_reading", type: "text" },
    { label: "Odometer End Reading", data_key: "odometer_end_reading", type: "text" },
    { label: "KM Run", data_key: "km_run", type: "text" },
    { label: "Demo Car Fuel Type", data_key: "demo_car_fule_type", type: "text" },
    { label: "Demo Car TC Number", data_key: "demo_car_tc_number", type: "text" },
    { label: "Demo Car VIN Number", data_key: "demo_car_vin_number", type: "text" },
  ]

  const pageList: PageDetail[] = [
    {
      pageLink: PageLinks.DEALER_USERS,
      title: "All Users",
      titleSingular: "User",
      listEndpoint: Endpoints.UserList,
      form: UserEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      statusUpdate: { endpoint: Endpoints.UserStatusUpdate, paramName: 'uuid' },
      detailViewIsForm: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_TEAMS,
      title: "Teams",
      titleSingular: "Team",
      listEndpoint: Endpoints.TeamList,
      form: TeamEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      statusUpdate: { endpoint: Endpoints.UserStatusUpdate, paramName: 'uuid' },
      detailViewIsForm: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_LOCATIONS,
      title: "Locations",
      titleSingular: "Location",
      listEndpoint: Endpoints.ShowroomList,
      deleteEndpoint: Endpoints.ShowroomDelete,
      form: ShowroomEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_MODEL,
      title: "Models",
      titleSingular: "Model",
      listEndpoint: Endpoints.ModelList,
      deleteEndpoint: Endpoints.ModelDelete,
      form: ModelEntryDialog,
      searchAvailable: true,
      addAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_MODEL_VARIANT,
      title: "Model Variants",
      titleSingular: "Model Variant",
      listEndpoint: Endpoints.ModelVariantList,
      deleteEndpoint: Endpoints.ModelVariantDelete,
      form: ModelVaraintEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_COLOR,
      title: "Color [ Interior & Exterior ]",
      titleSingular: "Color",
      listEndpoint: Endpoints.ColorList,
      deleteEndpoint: Endpoints.ColorDelete,
      form: ColorEntryDialog,
      addAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_FUEL_TYPE,
      title: "Fuel Types",
      titleSingular: "Fuel Type",
      listEndpoint: Endpoints.FuelTypeList,
      deleteEndpoint: Endpoints.FuelTypeDelete,
      form: FuelEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_DEMO_CAR,
      title: "Demo Cars",
      titleSingular: "Demo Car",
      listEndpoint: Endpoints.DemoCarList,
      deleteEndpoint: Endpoints.DemoCarDelete,
      form: DemoCarEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_BODY_TYPE,
      title: "Body Types",
      titleSingular: "Body Type",
      listEndpoint: Endpoints.BodyTypeList,
      deleteEndpoint: Endpoints.BodyTypeDelete,
      form: BodyTypeEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_TRANSMISSION_TYPE,
      title: "Transmission Types",
      titleSingular: "Transmission Type",
      listEndpoint: Endpoints.TransmissionTypeList,
      deleteEndpoint: Endpoints.TransmissionTypeDelete,
      form: TransmissionTypeEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_INTERIOR_COLOR,
      title: "Interior Colors",
      titleSingular: "Interior Color",
      listEndpoint: Endpoints.InteriorColorList,
      deleteEndpoint: Endpoints.InteriorColorDelete,
      form: InteriorColorEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_EXTERIOR_COLOR,
      title: "Exterior Colors",
      titleSingular: "Exterior Color",
      listEndpoint: Endpoints.ExteriorColorList,
      deleteEndpoint: Endpoints.ExteriorColorDelete,
      form: ExteriorColorEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_ROLE,
      title: "Roles",
      titleSingular: "Role",
      listEndpoint: Endpoints.RoleList,
      deleteEndpoint: Endpoints.RoleDelete,
      form: RoleEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_TARGET_RULES,
      title: "Target Rules",
      titleSingular: "Target Rule",
      listEndpoint: Endpoints.TargetRulesList,
      form: TargetRulesEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_CATEGORY,
      title: "Category",
      titleSingular: "Category",
      listEndpoint: Endpoints.CategoryList,
      deleteEndpoint: Endpoints.CategoryDelete,
      form: CategoryEntryDialog,
      addAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_STATE,
      title: "States",
      titleSingular: "State",
      listEndpoint: Endpoints.StateList,
      deleteEndpoint: Endpoints.StateDelete,
      form: StateEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_CITY,
      title: "Cities",
      titleSingular: "City",
      listEndpoint: Endpoints.CityList,
      deleteEndpoint: Endpoints.CityDelete,
      form: CityEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_PINCODE,
      title: "Pincodes",
      titleSingular: "Pincode",
      listEndpoint: Endpoints.PincodeList,
      deleteEndpoint: Endpoints.PincodeDelete,
      form: PincodeEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_LEAD_CATEGORY,
      title: "Lead Categories",
      titleSingular: "Lead Category",
      listEndpoint: Endpoints.LeadCategoryList,
      deleteEndpoint: Endpoints.LeadCategoryDelete,
      form: LeadCategoryEntryDialog,
      addAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_CUSTOMER_TYPE,
      title: "Customer Types",
      titleSingular: "Customer Type",
      listEndpoint: Endpoints.CustomerTypeList,
      deleteEndpoint: Endpoints.CustomerTypeDelete,
      form: CustomerTypeEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_LEAD_SOURCE,
      title: "Lead Sources",
      titleSingular: "Lead Source",
      listEndpoint: Endpoints.LeadSourceList,
      deleteEndpoint: Endpoints.LeadSourceDelete,
      form: LeadSourceEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_LEAD_SOURCE_CATEGORY,
      title: "Lead Source Categories",
      titleSingular: "Lead Source Category",
      listEndpoint: Endpoints.LeadSourceCategoryList,
      deleteEndpoint: Endpoints.LeadSourceCategoryDelete,
      form: LeadSourceCategoryEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_LEAD_STATUS,
      title: "Lead Status",
      titleSingular: "Lead Status",
      listEndpoint: Endpoints.LeadStatusList,
      deleteEndpoint: Endpoints.LeadStatusDelete,
      form: LeadStatusEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_INSURANCE_TYPE,
      title: "Insurance Types",
      titleSingular: "Insurance Type",
      listEndpoint: Endpoints.InsuranceTypeList,
      deleteEndpoint: Endpoints.InsuranceTypeDelete,
      form: InsuranceTypeEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_INSURANCE_COMPANIES,
      title: "Insurance Companies",
      titleSingular: "Insurance Company",
      listEndpoint: Endpoints.InsuranceCompanyList,
      deleteEndpoint: Endpoints.InsuranceCompanyDelete,
      form: InsuranceCompanyEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_LEAD_TYPE,
      title: "Lead Types",
      titleSingular: "Lead Type",
      listEndpoint: Endpoints.LeadTypeList,
      deleteEndpoint: Endpoints.LeadTypeDelete,
      form: LeadTypeEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_LEAD_TASK,
      title: "Lead Tasks",
      titleSingular: "Lead Task",
      listEndpoint: Endpoints.LeadTaskList,
      deleteEndpoint: Endpoints.LeadTaskDelete,
      form: LeadTaskEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_LEAD_PROSPECT_TYPE,
      title: "Lead Prospect Types",
      titleSingular: "Lead Prospect Type",
      listEndpoint: Endpoints.LeadProspectTypeList,
      deleteEndpoint: Endpoints.LeadProspectTypeDelete,
      form: LeadProspectTypeEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_LEAD_REASON,
      title: "Lead Reasons",
      titleSingular: "Lead Reason",
      listEndpoint: Endpoints.LeadReasonList,
      deleteEndpoint: Endpoints.LeadReasonDelete,
      form: LeadReasonEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_LEAD_TITLE,
      title: "Lead Titles",
      titleSingular: "Lead Title",
      listEndpoint: Endpoints.LeadTitleList,
      deleteEndpoint: Endpoints.LeadTitleDelete,
      form: LeadTitleEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_PAYMENT_MODE,
      title: "Payment Modes",
      titleSingular: "Payment Mode",
      listEndpoint: Endpoints.PaymentModeList,
      deleteEndpoint: Endpoints.PaymentModeDelete,
      form: PaymentModeEntryDialog,
      addAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.DEALER_CATEGORY_TYPE,
      title: "Category Types",
      titleSingular: "Category Type",
      listEndpoint: Endpoints.CategoryTypeList,
      deleteEndpoint: Endpoints.CategoryTypeDelete,
      form: CategoryTypeEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },



    {
      pageLink: PageLinks.DEALER_TESTDRIVE,
      title: "Testdrives",
      titleSingular: "Testdrive",
      listEndpoint: Endpoints.TestDriveList,
      listAddlParams: [
        {
          param_name: "testdrive_type",
          param_value: 1
        }
      ],
      listResponseParam: 'testdrive',
      form: DemoCarEntryDialog,
      tabs: TabsTestDrive,
      detailView: TestDriveDetailDialog,
      addAvailable: false,
      searchAvailable: true,
      dateFilterType: DateFilterType.RangePicker,
    },
    {
      pageLink: PageLinks.DEALER_SHOWROOM_TRANSFER,
      title: "Stock Transfers",
      titleSingular: "Stock Transfer",
      listEndpoint: Endpoints.TestDriveList,
      listAddlParams: [
        {
          param_name: "testdrive_type",
          param_value: 2
        }
      ],
      listResponseParam: 'testdrive',
      form: DemoCarEntryDialog,
      tabs: TabsTestDrive,
      detailView: StockTransferDetailDialog,
      addAvailable: false,
      searchAvailable: true,
      dateFilterType: DateFilterType.RangePicker,
    },
    {
      pageLink: PageLinks.DEALER_FUEL_REFILL,
      title: "Fuel Refills",
      titleSingular: "Fuel Refill",
      listEndpoint: Endpoints.TestDriveList,
      listAddlParams: [
        {
          param_name: "testdrive_type",
          param_value: 3
        }
      ],
      listResponseParam: 'testdrive',
      form: DemoCarEntryDialog,
      tabs: TabsTestDrive,
      detailView: FuelRefillDetailDialog,
      addAvailable: false,
      searchAvailable: true,
      dateFilterType: DateFilterType.RangePicker,
    },
    {
      pageLink: PageLinks.DEALER_OTHER_TRIP,
      title: "Others",
      titleSingular: "Other",
      listEndpoint: Endpoints.TestDriveList,
      listAddlParams: [
        {
          param_name: "testdrive_type",
          param_value: 4
        }
      ],
      listResponseParam: 'testdrive',
      form: DemoCarEntryDialog,
      tabs: TabsTestDrive,
      detailView: OtherDetailDialog,
      addAvailable: false,
      searchAvailable: true,
      dateFilterType: DateFilterType.RangePicker,
    },

    {
      pageLink: PageLinks.DEALER_LEADS_TODAY,
      title: "Today Leads",
      titleSingular: "Lead",
      listEndpoint: Endpoints.LeadList,
      listAddlParams: [
        {
          param_name: "type",
          param_value: "today"
        }
      ],
      listResponseParam: 'list',
      tabs: TabsLeads,
      additionalFilter: AdditionalFilterTodayLeads,
      detailView: LeadDetailDialog,
      addAvailable: false,
      searchAvailable: true,
    },
    {
      pageLink: PageLinks.DEALER_LEADS_FUTURE,
      title: "Future Leads",
      titleSingular: "Lead",
      listEndpoint: Endpoints.LeadList,
      listAddlParams: [
        {
          param_name: "type",
          param_value: "future"
        }
      ],
      listResponseParam: 'list',
      tabs: TabsLeads,
      detailView: LeadDetailDialog,
      addAvailable: false,
      searchAvailable: true,
    },
    {
      pageLink: PageLinks.DEALER_LEADS_COMPLETE,
      title: "Completed Leads",
      titleSingular: "Lead",
      listEndpoint: Endpoints.LeadList,
      listAddlParams: [
        {
          param_name: "type",
          param_value: "complete"
        }
      ],
      listResponseParam: 'list',
      tabs: TabsLeads,
      detailView: LeadDetailDialog,
      addAvailable: false,
      searchAvailable: true,
    },

    {
      pageLink: PageLinks.DEALER_REPORT_TESTDRIVE,
      title: "Test Drive Report",
      titleSingular: "Test Drive Report",
      listEndpoint: Endpoints.ReportDemoCarMovement,
      listAddlParams: [
        {
          param_name: "testdrive_type",
          param_value: 1
        }
      ],
      tabs: TabsReportTestDrive,
      addAvailable: false,
      searchAvailable: true,
      exportAvailable: true,
      exportColumns: exportColumnsReportTestDrive,
      dateFilterType: DateFilterType.RangePicker,
      dateFilterRequired: true
    },
    {
      pageLink: PageLinks.DEALER_REPORT_STOCKTRANSFER,
      title: "Stock Transfer Report",
      titleSingular: "Stock Transfer Report",
      listEndpoint: Endpoints.ReportDemoCarMovement,
      listAddlParams: [
        {
          param_name: "testdrive_type",
          param_value: 2
        }
      ],
      tabs: TabsReportTestDrive,
      addAvailable: false,
      searchAvailable: true,
      exportAvailable: true,
      exportColumns: exportColumnsReportStockTransfer,
      dateFilterType: DateFilterType.RangePicker,
      dateFilterRequired: true
    },
    {
      pageLink: PageLinks.DEALER_REPORT_FUELREFILL,
      title: "Fuel Refill Report",
      titleSingular: "Fuel Refill Report",
      listEndpoint: Endpoints.ReportDemoCarMovement,
      listAddlParams: [
        {
          param_name: "testdrive_type",
          param_value: 3
        }
      ],
      tabs: TabsReportTestDrive,
      addAvailable: false,
      searchAvailable: true,
      exportAvailable: true,
      exportColumns: exportColumnsReportFuelRefill,
      dateFilterType: DateFilterType.RangePicker,
      dateFilterRequired: true
    },
    {
      pageLink: PageLinks.DEALER_REPORT_OTHERTRIPS,
      title: "Other Report",
      titleSingular: "Other Report",
      listEndpoint: Endpoints.ReportDemoCarMovement,
      listAddlParams: [
        {
          param_name: "testdrive_type",
          param_value: 4
        }
      ],
      tabs: TabsReportTestDrive,
      addAvailable: false,
      searchAvailable: true,
      exportAvailable: true,
      exportColumns: exportColumnsReportOther,
      dateFilterType: DateFilterType.RangePicker,
      dateFilterRequired: true
    },







    {
      pageLink: PageLinks.COMPANY_USERS,
      title: "All Users",
      titleSingular: "User",
      listEndpoint: Endpoints.UserList,
      form: UserEntryDialog,
      statusUpdate: { endpoint: Endpoints.UserStatusUpdate, paramName: 'uuid' },
      detailViewIsForm: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.COMPANY_DEALERS,
      title: "Dealers",
      titleSingular: "Dealer",
      listEndpoint: Endpoints.DealerList,
      form: DealerEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
      statusUpdate: { endpoint: Endpoints.DealerStatusUpdate, paramName: 'dealer_code' }
    },
    {
      pageLink: PageLinks.ADMIN_COMPANIES,
      title: "All Companies",
      titleSingular: "Company",
      listEndpoint: Endpoints.CompanyList,
      deleteEndpoint: Endpoints.CompanyDelete,
      form: CompanyEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      statusUpdate: { endpoint: Endpoints.CompanyStatusUpdate, paramName: 'uuid' },
      //detailViewIsForm: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.ADMIN_DEALERS,
      title: "Dealers",
      titleSingular: "Dealer",
      listEndpoint: Endpoints.DealerList,
      // form: DealerEntryDialog,
      // addAvailable: true,
      statusUpdate: { endpoint: Endpoints.DealerStatusUpdate, paramName: 'dealer_code' },
      listResponseParam: 'list',
      searchAvailable: true,
    },
    {
      pageLink: PageLinks.ADMIN_MENUS,
      title: "Menu",
      titleSingular: "Menu",
      listEndpoint: Endpoints.MenuList,
      deleteEndpoint: Endpoints.MenuDelete,
      form: MenuEntryDialog,
      addAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.ADMIN_PROVIDERS,
      title: "Provider",
      titleSingular: "Provider",
      listEndpoint: Endpoints.ProviderList,
      deleteEndpoint: Endpoints.ProviderDelete,
      form: ProviderEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.ADMIN_PROVIDER_CATEGORY,
      title: "Provider",
      titleSingular: "Provider",
      listEndpoint: Endpoints.ProviderCategoryList,
      deleteEndpoint: Endpoints.ProviderCategoryDelete,
      form: ProviderCategoryEntryDialog,
      addAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.ADMIN_BUSINESS,
      title: "Business",
      titleSingular: "Business",
      listEndpoint: Endpoints.BusinessList,
      deleteEndpoint: Endpoints.BusinessDelete,
      form: BusinessEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.ADMIN_BUSINESS_MODE,
      title: "Provider",
      titleSingular: "Provider",
      listEndpoint: Endpoints.BusinessModeList,
      deleteEndpoint: Endpoints.BusinessModeDelete,
      form: BusinessModeEntryDialog,
      addAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.ADMIN_CATEGORY,
      title: "Category",
      titleSingular: "Category",
      listEndpoint: Endpoints.CategoryList,
      deleteEndpoint: Endpoints.CategoryDelete,
      form: CategoryEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.ADMIN_CATEGORY_TYPE,
      title: "Category Type",
      titleSingular: "Category Type",
      listEndpoint: Endpoints.CategoryTypeList,
      deleteEndpoint: Endpoints.CategoryTypeDelete,
      form: CategoryTypeEntryDialog,
      searchAvailable: true,
      addAvailable: true,
      listResponseParam: 'list',
    },
    {
      pageLink: PageLinks.ADMIN_VERSION,
      title: "Version",
      titleSingular: "Version",
      listEndpoint: Endpoints.VersionList,
      deleteEndpoint: Endpoints.VersionDelete,
      form: VersionEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      listResponseParam: 'list',
    },


    {
      pageLink: PageLinks.USER_INVENTORY,
      title: "Inventory - List",
      titleSingular: "Inventory",
      listEndpoint: Endpoints.UserInventory,
      additionalListButtons: [
        { type: "form-control", iconEnd: <Icon path={mdiTrayArrowUp} style={{ fontWeight: "400", width: "20px", height: "20px", color: "#14408b" }} />, title: "Upload Inventory", linkedForm: UploadUserInventoryEntryDialog },
      ],
      listResponseParam: 'list',
      form: InventoryEntryDialog,
      addAvailable: true,
      tableFilter: true,
      searchAvailable: false,
      detailViewIsForm: true,
    },
    {
      pageLink: PageLinks.USER_AVAILABLE_INVENTORY,
      title: "Inventory - List",
      titleSingular: "Inventory",
      listEndpoint: Endpoints.UserAvailableInventory,
      // additionalListButtons: [
      //   { type: "form-control", iconEnd: <Icon path={mdiTrayArrowUp} style={{ fontWeight: "400", width: "20px", height: "20px", color: "#14408b" }} />, title: "Upload Inventory", linkedForm: UploadUserInventoryEntryDialog },
      // ],
      listResponseParam: 'list',
      form: AvailableInventoryEntryDialog,
      addAvailable: false,
      searchAvailable: false,
      tableFilter: true,
      detailViewIsForm: true,
    },

    {
      pageLink: PageLinks.USER_INVENTORY_PRICE,
      title: "Inventory Price List",
      titleSingular: "Price",
      listEndpoint: Endpoints.UserInventoryPriceList,
      additionalListButtons: [
        {
          type: "form-control", iconEnd: <Icon path={mdiTrayArrowUp} style={{ fontWeight: "400", width: "20px", height: "20px", color: "#14408b" }} />
          , title: "Upload Price", linkedForm: UploadUserPriceEntryDialog
        },
      ],
      listResponseParam: 'list',
      form: InventoryPriceEntryDialog,
      addAvailable: true,
      tableFilter: true,
      searchAvailable: false,
      detailViewIsForm: true,

    },
    {
      pageLink: PageLinks.USER_ALLOTMENT_LIST,
      title: "Allotment List",
      titleSingular: "Allotment",
      listEndpoint: Endpoints.UserInventoryAllotmentList,
      listResponseParam: 'list',
      form: InventoryPriceEntryDialog,
      searchAvailable: true,
    },
    {
      pageLink: PageLinks.USER_REPORT,
      title: "Report",
      titleSingular: "Allotment",
      additionalListButtons: [
        {
          type: "form-control", title: "Generate Report", linkedForm: GenerateReportEntryDialog,
        },
      ],
      listEndpoint: Endpoints.UserReport,
      listResponseParam: 'list',
      form: InventoryPriceEntryDialog,
      searchAvailable: true,
    },
    {
      pageLink: PageLinks.USER_HELP_SUPPORT,
      title: "Help & Support",
      titleSingular: "Help & Support",
      listEndpoint: Endpoints.UserHelpSupport,
      listResponseParam: 'list',
      form: HelpAndSupportEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      detailViewIsForm: true,
    },
    {
      pageLink: PageLinks.USER_TESTDRIVE,
      title: "Testdrives",
      titleSingular: "Testdrive",
      listEndpoint: Endpoints.TestDriveList,
      deleteEndpoint: Endpoints.TestDriveCancel,
      listResponseParam: 'testdrive',
      listAddlParams: [
        {
          param_name: "testdrive_type",
          param_value: 1
        }
      ],
      tabs: TabsUserTestDrive,
      dateFilterType: DateFilterType.RangePicker,
      form: UserTestdriveEntryDialog,
      filterPopup: true,
      searchPopup: true,
      addAvailable: true,
      searchAvailable: true,
      detailViewIsForm: true,
    },
    {
      pageLink: PageLinks.USER_TESTDRIVE_TRANSFER,
      title: "Showroom Transfers",
      titleSingular: "Showroom Transfer",
      listEndpoint: Endpoints.TestDriveList,
      deleteEndpoint: Endpoints.TestDriveCancel,
      listResponseParam: 'testdrive',
      listAddlParams: [
        {
          param_name: "testdrive_type",
          param_value: 2
        }
      ],
      tabs: TabsUserTestDrive,
      form: UserShowroomTransferEntryDialog,
      filterPopup: true,
      searchPopup: true,
      dateFilterType: DateFilterType.RangePicker,
      addAvailable: true,
      searchAvailable: true,
      detailViewIsForm: true,
    },
    {
      pageLink: PageLinks.USER_TESTDRIVE_REFILL,
      title: "Fuel Refills",
      titleSingular: "Fuel Refill",
      listEndpoint: Endpoints.TestDriveList,
      deleteEndpoint: Endpoints.TestDriveCancel,
      listResponseParam: 'testdrive',
      listAddlParams: [
        {
          param_name: "testdrive_type",
          param_value: 3
        }
      ],
      tabs: TabsUserTestDrive,
      filterPopup: true,
      searchPopup: true,
      dateFilterType: DateFilterType.RangePicker,
      form: UserFuelRefillEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      detailViewIsForm: true,
    },
    {
      pageLink: PageLinks.USER_TESTDRIVE_OTHERS,
      title: "Others",
      titleSingular: "Other",
      dateFilterType: DateFilterType.RangePicker,
      listEndpoint: Endpoints.TestDriveList,
      deleteEndpoint: Endpoints.TestDriveCancel,
      listResponseParam: 'testdrive',
      listAddlParams: [
        {
          param_name: "testdrive_type",
          param_value: 4
        }
      ],
      tabs: TabsUserTestDrive,
      filterPopup: true,
      searchPopup: true,
      form: UserOtherEntryDialog,
      addAvailable: true,
      searchAvailable: true,
      detailViewIsForm: true,
    },
  ]


  export const getPageDetail = (pageLink: string): PageDetail | undefined => {
    const pageDetail = pageList.find((page) => {
      return page.pageLink === pageLink;
    });

    return pageDetail;
  };
} 