import { useEffect, useState } from "react";
import { TestDriveStatusValues } from "../../common/Constants";


const TestDriveStatusBadge: React.FC<{ testDriveStatus: string }> = ({ testDriveStatus }) => {

    const [statusClass, setStatusClass] = useState('');
    const [testDriveStatusString, setTestDriveStatusString] = useState('');

    useEffect(() => {
        if (testDriveStatus == TestDriveStatusValues.SCHEDULED) {
            setStatusClass("badge-soft-scheduled");
            setTestDriveStatusString("Scheduled");
        } else if (testDriveStatus == TestDriveStatusValues.COMPLETED) {
            setStatusClass("badge-soft-success");
            setTestDriveStatusString("Completed");
        } else if (testDriveStatus == TestDriveStatusValues.ON_GOING) {
            setStatusClass("badge-soft-info");
            setTestDriveStatusString("Ongoing");
        } else if (testDriveStatus == TestDriveStatusValues.CANCELLED) {
            setStatusClass("badge-soft-danger");
            setTestDriveStatusString("Cancelled");
        }
    }, [testDriveStatus]);

    return (
        <span className={'badge ' + statusClass}>{testDriveStatusString}</span>
    )

}

export default TestDriveStatusBadge;