import React from 'react';
import { getFormattedLocalDate } from '../../common/Utilities';

const CellUserNestedFromDate: React.FC<{ data: any }> = ({ data }) => {
    const fromDate = data?.description?.from_date;

    return (
        <>{fromDate ? getFormattedLocalDate(fromDate) : 'N/A'}</>
    );
};

export default CellUserNestedFromDate;
