import { Drawer, InputLabel } from "@mui/material";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import { API, Endpoints, TestDriveStatusValues, uploadImageType } from "../common/Constants";
import { getDataFromAPI } from "../common/Utilities";
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { date } from "yup";

const UserFuelRefillEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, detailView?: boolean }> = ({ showDialog, closeDialog, reloadData, id, detailView }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [showroomOptions, setShowroomOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [demoCarOptions, setDemoCarOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [buttonText, setButtonText] = useState("Start");
    const [step, setStep] = useState(0);
    const [demoCarUUID, setDemoCarUUID] = useState<string>("");
    const [fromShowroomUUID, setFromShowroomUUID] = useState<string>("");
    const [testdriveUUID, setTestdriveUUID] = useState("");
    const [fuelAmount, setFuelAmount] = useState("");
    const [fuelQuantity, setFuelQuantity] = useState("");
    const [startReading, setStartReading] = useState<string>("");
    const [selectedDate, setSelectedDate] = useState<string>("");
    const [uploading, setUploading] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [changeFile, setChangeFile] = useState(false);
    const [fieldPreviewImage, setFieldPreviewImage] = useState('');
    const [testdriveDetails, setTestdriveDetails] = useState<APIData.TestDriveDetail>();
    const [imageType, setImageType] = useState<number>();
    const [testdriveStatus, setTestdriveStatus] = useState<number>();
    const currentDate = new Date(); // Current date
    const futureDate = new Date();
    const pastDate = new Date();
    pastDate.setFullYear(currentDate.getFullYear() - 100);
    futureDate.setFullYear(futureDate.getFullYear() + 100);

    useEffect(() => {
        if (id) {
            setTestdriveUUID(id);
        }
    }, [id])


    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const uploadedFile = event.target.files[0];
            const imgType = imageType ? imageType : uploadImageType.DEMO_VEHICLE; // Replace 1 with your default image type if needed
            setUploading(true);

            ServerAPI.uploadImage(uploadedFile, imgType).then(response => {
                if (response.success && response.logo_url) {
                    setPreviewImage(response.full_logo_url);
                    setFieldPreviewImage(response.logo_url);
                    setChangeFile(false);
                } else if (response.message) {
                    toast.error(response.message);
                } else {
                    toast.error('Could not upload image!');
                }
            }).finally(() => {
                setUploading(false);
            });
        }
    };


    const customStyles = { control: (provided: any) => ({ ...provided, border: '2px solid #D3D3D3', '&:hover': { borderColor: '#14408B' }, borderRadius: '10px' }) };
    useEffect(() => {
        if (testdriveUUID !== undefined && testdriveUUID !== '') {
            addProcessingRequests();
            ServerAPI.getTestDriveDetail(testdriveUUID).then((response: any) => {
                if (response.data.details && response.success && response.message) {
                    // toast.success(response.message);
                    const testdriveDetails = response.data.details;
                    setTestdriveDetails(testdriveDetails);
                    setTestdriveStatus(testdriveDetails.status);
                } else if (response && response.message) {
                    toast.error(response.message);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }, [testdriveUUID]);


    const handleChange = (date: any) => {
        let formattedDateTime = "";
        if (date) {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');

            formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

            const currentDate = new Date();
            const timeDifference = date.getTime() - currentDate.getTime();

            if (timeDifference > 600000) {
                setButtonText("Start");
            } else {
                setButtonText("Start");
            }
        }
        setSelectedDate(formattedDateTime);
        console.log('Selected Date:', formattedDateTime);
    };

    useEffect(() => {
        getDataFromAPI(API.EndPoint.UserDemoCarList, setDemoCarOptions, addProcessingRequests, reduceProcessingRequests, null, true, 'uuid', 'model_name', 'data', 'demo_car');
        getDataFromAPI(API.EndPoint.ShowroomList, setShowroomOptions, addProcessingRequests, reduceProcessingRequests, null, true, 'uuid', 'showroom_name', 'data', 'list');
    }, [])


    const handleDemoCarChange = (selectedOption: FormDataTypes.SelectOption | null) => {
        setDemoCarUUID(selectedOption?.value || "");
    };

    const handleFromShowroomChange = (selectedOption: FormDataTypes.SelectOption | null) => {
        setFromShowroomUUID(selectedOption?.value || "");
    };


    const validateForm = () => {


        if (!fromShowroomUUID) {
            toast.error("From Showroom is required");
            return false;
        }

        if (!demoCarUUID) {
            toast.error("Demo Car is required");
            return false;
        }

        if (!selectedDate) {
            toast.error("Date Time is required");
            return false;
        }

        return true;
    };

    const fuelRefillStart = () => {
        if (!validateForm()) return;
        addProcessingRequests();
        ServerAPI.fuelRefillStart(fromShowroomUUID, demoCarUUID, selectedDate ? selectedDate : "", fieldPreviewImage, startReading).then((response: any) => {
            if (response && response.success && response.message) {
                toast.success(response.message);
                setTestdriveUUID(response.testdrive_id);
                setStep(2);
                reloadData();
            } else if (response && response.message) {
                toast.error(response.message);
            }
        }).finally(() => {
            reduceProcessingRequests()
        });
    };

    const fuelRefillComplete = () => {
        addProcessingRequests();
        ServerAPI.fuelRefillAmountFinish(testdriveUUID, fuelAmount, fuelQuantity).then((response: any) => {
            if (response && response.success && response.message) {
                toast.success(response.message);
                closeDialog();
                reloadData();
            } else if (response && response.message) {
                toast.error(response.message);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    };


    const handleSubmitPage1 = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        fuelRefillStart();
    };

    const handleSubmitPage2 = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        fuelRefillComplete();

    };

    const renderStep = () => {
        if (step === 1) {
            return 1;
        }
        if (step === 2 || (testdriveStatus?.toString() === TestDriveStatusValues.ON_GOING)) {
            return 2;
        }
        return null;
    };

    const currentStep = renderStep();

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-min">
            <div className="tabdetails">
                {step === 0 && testdriveStatus === undefined && <> <h4 className="title-text text-center ml-5">{step === 0 ? "Add Fuel Refill" : "Fuel Refill Details"}</h4>
                    <div className="custom-border-grey"></div>
                    <div className="tabpage">
                        <form>
                            <div className="tabdetails create-new-lead-section">
                                <div className="tabdetails-1" style={{ overflowY: "auto", height: "90vh" }}>
                                    <div className="tabpage">
                                        <div className="form-field-container-tabs">
                                            <div className="row py-3 px-2">
                                                <div className="form-group-1 text-left mb-4 col-lg-12 col-md-12 col-xl-12">
                                                    <div className="ew-section-2">
                                                        <div className="text-field-empty-custom-user lead-select">
                                                            <label className='label-custom'>From Showroom</label>
                                                            <Select
                                                                styles={customStyles}
                                                                onChange={handleFromShowroomChange}
                                                                value={showroomOptions.find(option => option.value === fromShowroomUUID) || null}
                                                                options={showroomOptions}
                                                                placeholder={"Select Showroom"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group-1 text-left mb-4 col-lg-12 col-md-12 col-xl-12">
                                                    <div className="ew-section-2">
                                                        <div className="text-field-empty-custom-user lead-select">
                                                            <label className='label-custom'>Demo Car</label>
                                                            <Select
                                                                styles={customStyles}
                                                                onChange={handleDemoCarChange}
                                                                value={demoCarOptions.find(option => option.value === demoCarUUID) || null}
                                                                options={demoCarOptions}
                                                                placeholder={"Select Demo car"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-xl-12 mb-4 text-field-empty-custom-user-1 book-final">
                                                    <div className='mb-24'>
                                                        <label className="label-custom">Date & Time <span className="text-danger me-4">*</span></label>
                                                        <DatePicker
                                                            value={selectedDate}
                                                            selected={selectedDate ? new Date(selectedDate) : null}
                                                            onChange={handleChange}
                                                            dateFormat="dd/MM/yyyy"
                                                            className="form-control"
                                                            showTimeSelect
                                                            placeholderText={"Select Date"}
                                                            minDate={pastDate}
                                                            maxDate={currentDate}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                <button className="button2" onClick={() => setStep(1)}>{buttonText}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div></>}
                {/* {(step === 1 || (getSelectedTestdriveLocation === "Home" && testdriveStatus && testdriveStatus.toString() === TestDriveStatusValues.SCHEDULED) || (getSelectedTestdriveLocation === "Showroom" && testdriveStatus && testdriveStatus.toString() === TestDriveStatusValues.ON_GOING)) && <> <h4 className="title-text text-center ml-5">Testdrive Details</h4> */}
                {currentStep === 1 && <> <h4 className="title-text text-center ml-5">Fuel Refill Details</h4>
                    <div className="custom-border-grey"></div>
                    <div className="tabpage mt-3">
                        <form onSubmit={handleSubmitPage1}>
                            <div className="tabdetails create-new-lead-section">
                                <div className="tabdetails-1" style={{ overflowY: "auto", height: "90vh" }}>
                                    <div className="tabpage">
                                        <div className="form-field-container-tabs">
                                            {demoCarOptions && demoCarOptions.filter((demoCar) => demoCar.value === demoCarUUID).map((demoModel) =>
                                                <div className='table-container pl-2 pr-2'>
                                                    <div className='table-scroll'>
                                                        <div className='table-responsive'>
                                                            <div>
                                                                <div className="d-flex justify-content-between align-items-center p-2" style={{ height: "auto" }}>
                                                                    <p className="m-0" style={{ fontSize: "18px", color: "#14408B" }}>Demo Car Details</p>
                                                                </div>
                                                                <div className='row user-leads-second d-flex m-0' style={{ backgroundColor: "#fff" }}>
                                                                    {/* <div className="col-5">
                                                                        <img src={testdriveDetails.car_image_url} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                                                                    </div> */}
                                                                    <div className="col-7">
                                                                        <div className='row user-leads-second m-0' style={{ backgroundColor: "#fff" }}>
                                                                            <div className='col-12 user-lead-details mb-1 p-0'>
                                                                                <div className='leads-table-content'>
                                                                                    <p className='mb-0 userlead-numkey'>Demo Car Model</p>
                                                                                    <p className='mb-0 userlead-numval'>{demoModel.label}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="col-lg-12 col-md-12 col-xl-12 mb-4 pl-3">
                                                <div className="mb-24">
                                                    <InputLabel className='form-upload-label' style={{ fontSize: "12px", color: "rgba(37, 37, 37, 0.5)" }}>Odometer Image</InputLabel>
                                                    {uploading && (
                                                        <img src={`${window.location.origin}/assets/images/uploading.gif`} style={{ maxWidth: '50px', maxHeight: '50px', marginRight: '10px' }} alt="Uploading" />
                                                    )}
                                                    {!uploading && (previewImage || fieldPreviewImage) && !changeFile && (
                                                        <>
                                                            <img src={previewImage || fieldPreviewImage} style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '10px' }} alt="Preview" />
                                                            <a href="#" onClick={(e) => { e.preventDefault(); setChangeFile(true); }}><i className="fa fa-edit text-info font-16" aria-hidden="true"></i></a>
                                                        </>
                                                    )}
                                                    {!uploading && ((!previewImage && !fieldPreviewImage) || changeFile) && (
                                                        <input type="file" className="form-file-upload" placeholder="Enter Odometer Image" onChange={handleFileChange} />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group-1 text-left col-lg-12 col-md-12 col-xl-12 mb-4">
                                                <div className="input-group">
                                                    <label className='label-custom'>Odometer Reading</label>
                                                    <input type="text" className="form-control" placeholder="Enter Odometer Reading" value={startReading} onChange={(e) => setStartReading(e.target.value)} />
                                                </div>
                                            </div>
                                            {showroomOptions && showroomOptions.filter((showroom) => showroom.value === fromShowroomUUID).map((showroom) => (
                                                <div className='table-container pl-2 pr-2' key={showroom.value}>
                                                    <div className='table-scroll'>
                                                        <div className='table-responsive'>
                                                            <div style={{ height: "auto" }}>
                                                                <div className="d-flex justify-content-between align-items-center p-2" style={{ height: "43px" }}>
                                                                    <p className="mt-2 mb-0" style={{ fontSize: "18px", color: "#14408B" }}>Transfer Details</p>
                                                                </div>
                                                                <div className='row user-leads-second' style={{ backgroundColor: "#fff" }}>
                                                                    <div className='col-12 user-lead-details mb-1'>
                                                                        <div className='leads-table-content'>
                                                                            <p className='mb-0 userlead-numkey'>From Showroom</p>
                                                                            <p className='mb-0 userlead-numval'>{showroom.label}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                <button type="submit" className="button2" >Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div></>}
                {(currentStep === 2 || (testdriveStatus?.toString() === TestDriveStatusValues.ON_GOING)) && <> <h4 className="title-text text-center ml-5">Fuel Refill Details</h4>
                    <div className="custom-border-grey"></div>
                    <div className="tabpage">
                        <form onSubmit={handleSubmitPage2}>
                            <div className="tabdetails create-new-lead-section">
                                <div className="tabdetails-1" style={{ overflowY: "auto", height: "90vh" }}>
                                    <div className="tabpage">
                                        <div className="form-field-container-tabs">
                                            {testdriveDetails && (<>
                                                <div className='table-container pl-2 pr-2'>
                                                    <div className='table-scroll'>
                                                        <div className='table-responsive'>
                                                            <div>
                                                                <div className="d-flex justify-content-between align-items-center p-2" style={{ height: "auto" }}>
                                                                    <p className="m-0" style={{ fontSize: "18px", color: "#14408B" }}>Demo Car Details</p>
                                                                </div>
                                                                <div className='row user-leads-second d-flex m-0' style={{ backgroundColor: "#fff" }}>
                                                                    <div className="col-5">
                                                                        <img src={testdriveDetails.car_image_url} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <div className='row user-leads-second m-0' style={{ backgroundColor: "#fff" }}>
                                                                            <div className='col-12 user-lead-details mb-1'>
                                                                                <div className='leads-table-content'>
                                                                                    <p className='mb-0 userlead-numkey'>Demo Car Model</p>
                                                                                    <p className='mb-0 userlead-numval'>{testdriveDetails.model_name}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                                            <div className="row py-3 px-2">
                                                <div className="form-group-1 text-left col-lg-12 col-md-12 col-xl-12 mb-4">
                                                    <div className="input-group">
                                                        <label className='label-custom'>Fuel Amount</label>
                                                        <input type="text" className="form-control" placeholder="Enter Fuel Amount" value={fuelAmount} onChange={(e) => setFuelAmount(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group-1 text-left col-lg-12 col-md-12 col-xl-12 mb-4">
                                                    <div className="input-group">
                                                        <label className='label-custom'>Fuel Quantity (in litres)</label>
                                                        <input type="text" className="form-control" placeholder="Enter Fuel Quantity" value={fuelQuantity} onChange={(e) => setFuelQuantity(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            {testdriveDetails && (
                                                <div className='table-container pl-2 pr-2'>
                                                    <div className='table-scroll'>
                                                        <div className='table-responsive'>
                                                            <div style={{ height: "auto" }}>
                                                                <div className="d-flex justify-content-between align-items-center p-2" style={{ height: "43px" }}>
                                                                    <p className="mt-2" style={{ fontSize: "18px", color: "#14408B" }}>Transfer Details</p>
                                                                </div>
                                                                <div className='row user-leads-second' style={{ backgroundColor: "#fff" }}>
                                                                    <div className='col-12 user-lead-details mb-1'>
                                                                        <div className='leads-table-content'>
                                                                            <p className='mb-0 userlead-numkey'>From Showroom</p>
                                                                            <p className='mb-0 userlead-numval'>{testdriveDetails.showroom_name}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                <button type="submit" className="button2">Finish</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div></>
                }
                {testdriveStatus?.toString() === TestDriveStatusValues.COMPLETED && (
                    <div className='custom-tablescroll-1'>
                        <div className="table-container">
                            <div className="table-scroll">
                                <div className="table-responsive">
                                    <div className="completion-notice-container">
                                        <div className="completion-notice">
                                            <h4 className="completion-notice-title">Test Drive Completed</h4>
                                            <p className="completion-notice-message">The test drive was successfully completed. Thank you for choosing our service! If you have any feedback or questions, please contact our support team.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* {testdriveStatus?.toString() === TestDriveStatusValues.CANCELLED && <h4 className="text-center">Testdrive is already Cancelled.</h4>} */}
                {testdriveStatus?.toString() === TestDriveStatusValues.CANCELLED && (
                    <div className='custom-tablescroll-1'>
                        <div className="table-container">
                            <div className="table-scroll">
                                <div className="table-responsive">
                                    <div className="cancel-notice-container">
                                        <div className="cancel-notice">
                                            <h4 className="cancel-notice-title">Test Drive Cancelled</h4>
                                            <p className="cancel-notice-message">The test drive you scheduled has already been cancelled. If you have any questions or need further assistance, please contact our support team.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div >
        </Drawer >
    );
};

export default UserFuelRefillEntryDialog;
