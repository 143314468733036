import { Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { API, ProjectStrings, uploadImageType } from "../common/Constants";
import { getDataFromAPI } from "../common/Utilities";


const ProviderEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, detailView?: boolean }> = ({ showDialog, closeDialog, reloadData, id, detailView }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.ProviderEntry>>(null);
    const [initialValue, setInitialValue] = useState<APIData.ProviderEntry>(InitialData.ProviderEntry);
    const [formDataSet, setFormDataSet] = useState(false);
    const [providerCategoryOptions, setProviderCategoryOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [companyOptions, setCompanyOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.ProviderCategoryList, setProviderCategoryOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'provider_category_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.CompanyList, setCompanyOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'company_name', 'data', 'list');
    }, []);

    useEffect(() => {
        if (id !== undefined && id !== '') {
            addProcessingRequests();
            ServerAPI.getProvider(id).then(response => {
                if (response && response.data && response.data.details) {
                    setInitialValue(response.data.details);
                    setFormDataSet(true);
                    setEditMode(true);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else {
            setFormDataSet(true);
        }
    }, [id]);

    const yupSchema = Yup.object().shape({
        provider_category_id: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        provider_name: Yup.string().required(ProjectStrings.ValidationRequired),
        company_id: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
    });

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-min">
            {formDataSet && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };
                    if (id != undefined && id != '') {
                        addProcessingRequests();
                        ServerAPI.updateProvider(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            } else {
                                toast.error('Unknown Error Occurred');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    } else {
                        addProcessingRequests();
                        ServerAPI.addProvider(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            } else {
                                toast.error('Unknown Error Occurred');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    }
                }} >
                    {(formikProps) => {
                        return (
                            <div className="tabdetails">
                                <h4 className="title-text text-center">{editMode ? 'Edit' : 'Add New'} Provider</h4>
                                <div className="custom-border-grey my-2"></div>
                                <p className="detailed-heading">Provider Details</p>
                                <div className="tabpage">
                                    <div className="custom-border-grey my-2"></div>
                                    <div className="tab-details-section p-0">
                                        <Form>
                                            <div className="form-field-container">
                                                <div className="row px-2 py-3">
                                                    <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom-user">
                                                        {/* <label className="label-custom-user"><p>Provider Category</p></label> */}
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Provider Category", name: "provider_category_id", placeholder: "Provider Category", required: true, options: providerCategoryOptions, readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-3">
                                                    <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", name: "provider_name", label: "Provider Name", placeholder: "Provider Name", required: true }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-3">
                                                    <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom-user">
                                                        {/* <label className="label-custom-user"><p>Company</p></label> */}
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Company", name: "company_id", placeholder: "Company", required: true, options: companyOptions, readOnly: detailView ? true : false }} />
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-3">
                                                    <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom-user">
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "switch", label: "Status", name: "status" }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row btn-form-submit">
                                                <button type="button" className="button1" onClick={closeDialog}>Cancel</button>
                                                <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                    formikProps.submitForm();
                                                    if (!formikProps.isValid) {
                                                        toast.error("Please ensure all fields are Filled and Valid!");
                                                    }
                                                }}>Submit</button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                </Formik>
            )
            }
        </Drawer>
    )
}

export default ProviderEntryDialog;