import React from "react";

const Footer: React.FC = () => {
    return (
        <div>
            <div>
                <nav className="footer-navbar ml-0">
                    <div className="footer-style">
                        COPYRIGHT &#169; 2023 Jubilant Motor Works, &nbsp;All rights Reserved
                    </div>
                </nav>

            </div>
        </div>
    );
};

export default Footer;
