import { Drawer, Tabs, Tab, Step } from "@mui/material";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import * as Yup from 'yup';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import FormField from "../components/form_items/FormField";
import { InitialData } from "./InitialData";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { API, Endpoints, ProjectStrings, uploadImageType } from "../common/Constants";
import { getDataFromAPI } from "../common/Utilities";
import { useAppStateAPI } from "../common/AppStateAPI";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import debounce from 'lodash/debounce';
import { Api } from "@mui/icons-material";

const InventoryEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, id?: string, detailView?: boolean, reloadData?: any }> = ({ showDialog, closeDialog, id, detailView, reloadData }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.InventoryAdd>>(null);
    const [formDataSet, setFormDataSet] = useState(false);
    const [operationalReset, setOperationReset] = useState(false);
    const [modelOptions, setModelOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [variantOptions, setVariantOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [exteriorColorOptions, setExteriorColorOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [interiorColorOptions, setInteriorColorOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [fuelTypeOptions, setFuelTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [customerStateOptions, setCustomerStateOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [showroomOptions, setShowroomOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [statusOptions, setStatusOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedStatusOptions, setSelectedStatusOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [bankPartnerOptions, setBankPartnerOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [fundTypeOptions, setFundTyperOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedFundTypeOptions, setSelectedFundTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const bookACarRef = useRef<HTMLDivElement | null>(null);
    const [availableModelVariants, setAvailablemodelVariants] = useState<APIData.AvailableModelVariant[]>([]);
    const [selectedModelUUID, setSelectedModelUUID] = useState('');
    const [selectedVariantUUID, setSelectedVariantUUID] = useState('');
    const [selectedExteriorUUID, setSelectedExteriorUUID] = useState('');
    const [selectedStatusUUID, setSelectedStatusUUID] = useState('');
    const [selectedFundType, setSelectedFundType] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [initialValue, setInitialValue] = useState<APIData.InventoryAdd>(InitialData.InventoryAdd);
    const [vehicleCost, setVehicleCost] = useState("");
    const [vehicleGst, setVehicleGst] = useState("");
    const [purchaseCost, setPurchaseCost] = useState("");
    const [revaluationCost, setRevaluationCost] = useState("");
    const [selectedModelYear, setSelectedModelYear] = useState('');
    const [selectedShowroomUUID, setSelectedShowroomUUID] = useState('');
    const [selectedBankPartner, setSelectedBankPartner] = useState('');
    const [bankinterest, setBankInterest] = useState('');
    const [selfInterest, setSelfInterest] = useState('');
    const [imagepath, setImagepath] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);
    const selectedCurrentYear = selectedModelYear === new Date().getFullYear().toString();
    console.log(formikRef.current?.values.image, "image");
    //operational cost trail
    const [operationalCosts, setOperationalCosts] = useState<APIData.OperationalCost[]>([]);
    console.log(operationalCosts, "cost");
    const inputCostRefs = useRef<(HTMLInputElement | null)[]>([]);
    const inputDescriptionRefs = useRef<(HTMLInputElement | null)[]>([]);
    const focusedInputRef = useRef<{ type: 'cost' | 'description', index: number } | null>(null);
    const [reloadDetails, setReloadDetails] = useState(false);
    console.log(formikRef.current?.values.image, "imagepath")

    useEffect(() => {
        const focusedInput = focusedInputRef.current;
        if (focusedInput) {
            if (focusedInput.type === 'cost' && inputCostRefs.current[focusedInput.index]) {
                inputCostRefs.current[focusedInput.index]?.focus();
            } else if (focusedInput.type === 'description' && inputDescriptionRefs.current[focusedInput.index]) {
                inputDescriptionRefs.current[focusedInput.index]?.focus();
            }
        }
    });

    const handleFocus = (type: 'cost' | 'description', index: number) => {
        focusedInputRef.current = { type, index };
    };

    const addOperationalCost = () => {
        setOperationalCosts([{ operational_cost: '', operational_cost_description: '' }, ...operationalCosts]);
    };

    const handleCostChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const newOperationalCosts = operationalCosts.map((cost, i) => {
            if (i === index) {
                return { ...cost, operational_cost: event.target.value };
            }
            return cost;
        });
        setOperationalCosts(newOperationalCosts);
    };

    const handleDescriptionChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const newOperationalCosts = operationalCosts.map((cost, i) => {
            if (i === index) {
                return { ...cost, operational_cost_description: event.target.value };
            }
            return cost;
        });
        setOperationalCosts(newOperationalCosts);
    };

    const removeOperationalCost = (index: number) => {
        const newOperationalCosts = operationalCosts.filter((_, i) => i !== index);
        setOperationalCosts(newOperationalCosts);
    };
    //end

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [vehicleCost, purchaseCost, vehicleGst]);



    useEffect(() => {
        if (selectedFundTypeOptions[0] && (selectedFundTypeOptions[0].label === "Self Funding" || selectedFundTypeOptions[0].label === "Bank Funding")) {
            if (purchaseCost || revaluationCost) {
                formikRef.current?.setFieldValue("self_funded_amount", parseInt(revaluationCost) !== 0 ? revaluationCost : purchaseCost);
                formikRef.current?.setFieldValue("bank_funded_amount", purchaseCost);
            }
        }
    }, [purchaseCost, revaluationCost, selectedFundTypeOptions]);

    useEffect(() => {
        if (selectedFundTypeOptions[0] && selectedFundTypeOptions[0].label === "Partial Split") {
            if (purchaseCost && selfInterest) {
                formikRef.current?.setFieldValue("self_funded_amount", (parseInt(purchaseCost) * parseInt(selfInterest)) / 100)
                formikRef.current?.setFieldValue("bank_funded_amount", (parseInt(purchaseCost) * (100 - parseInt(selfInterest))) / 100);
            }
        }
    }, [purchaseCost, selfInterest, selectedFundTypeOptions]);


    useEffect(() => {
        if (selectedFundTypeOptions[0] && selectedFundTypeOptions[0].label === "Self Funding") {
            formikRef.current?.setFieldValue("self_funding", "100");
        }
    }, [selectedFundTypeOptions]);

    const calculateGst = useCallback(
        debounce((cost: string) => {
            const Gst = (parseInt(cost) * 18) / 100;
            setVehicleGst(Gst + "");
        }, 300),
        []
    );


    const calculatePurchaseCost = useCallback(
        debounce((cost: string, gst: string) => {
            const vehiclePurchaseCost = parseInt(cost) + parseInt(gst);
            setPurchaseCost(vehiclePurchaseCost + "");
        }, 300),
        []
    );

    useEffect(() => {
        if (vehicleCost !== "") {
            calculateGst(vehicleCost);
        }
    }, [vehicleCost, calculateGst]);

    useEffect(() => {
        if (vehicleCost !== "" && vehicleGst !== "") {
            calculatePurchaseCost(vehicleCost, vehicleGst);
        }
    }, [vehicleCost, vehicleGst, calculatePurchaseCost]);

    const currentYear = new Date().getFullYear();
    const last50Years = Array.from({ length: 50 }, (_, index) => currentYear - index);


    const modelYearOptions = last50Years.map(year => ({
        value: year.toString(),
        label: year.toString(),
    }));

    const yupSchema = Yup.object().shape({
        showroom_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        models_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        variant_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        interior_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        exterior_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        fuel_type_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        vehicle_status_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        model_year: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        state_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        funding_type: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        mf_year: Yup.string().required(ProjectStrings.ValidationRequired),
        WHS_code: Yup.string().required(ProjectStrings.ValidationRequired),
        // VIN_number: Yup.string().required(ProjectStrings.ValidationRequired),
        // engine_number: Yup.string().required(ProjectStrings.ValidationRequired),
        purchase_date: Yup.string().required(ProjectStrings.ValidationRequired),
        // recevied_date: Yup.string().required(ProjectStrings.ValidationRequired),
        invoice_number: Yup.string().required(ProjectStrings.ValidationRequired),
        // vehicle_cost: Yup.string().required(ProjectStrings.ValidationRequired),
        // gst_amount_on_vehicle: Yup.string().required(ProjectStrings.ValidationRequired),
        // interest: Yup.string().required(ProjectStrings.ValidationRequired),
        // vehicle_purchse_cost: Yup.string().required(ProjectStrings.ValidationRequired),
    });

    useEffect(() => {
        let params: { [k: string]: any } = {};
        getDataFromAPI(API.EndPoint.ModelList, setModelOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'model_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.ShowroomList, setShowroomOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'showroom_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.UserInventoryVehicleStatus, setStatusOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'status_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.UserInventoryBankInterest, setBankPartnerOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'bank_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.UserInventoryFundType, setFundTyperOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'name', 'data', 'list');
        // getDataFromAPI(API.EndPoint.StateList, setCustomerStateOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'state_name', 'data', 'list');
        setFormDataSet(true);
        setCurrentTab(0);
    }, []);

    useEffect(() => {
        if (showDialog === false) {
            setCurrentTab(0);
            setSelectedFundTypeOptions([]);
            setSelectedFundType("");
            setVehicleCost("");
            setVehicleGst("");
            setPurchaseCost("");
            setSelectedStatusOptions([]);
            setSelectedStatusUUID("");
            setSelectedBankPartner("");
            setSelectedShowroomUUID("");
            setBankInterest("");
            setSelfInterest("")
        }

    }, [showDialog]);

    useEffect(() => {
        addProcessingRequests();
        ServerAPI.executeAPI(Endpoints.UserInventoryBankInterest, ServerAPI.APIMethod.GET, true).then(response => {
            if (response && response.data && response.data.list.length > 0) {
                const selectedBankInterest = response.data.list.filter((selectedItem: any) => selectedItem.uuid === selectedBankPartner)
                    .map((item: any) => (item.bank_interest))
                setBankInterest(selectedBankInterest[0]);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }, [selectedBankPartner]);

    useEffect(() => {
        if (selectedFundTypeOptions[0] && selectedFundTypeOptions[0].label === "Bank Funding") {
            if (purchaseCost && bankinterest) {
                formikRef.current?.setFieldValue("interest", (parseInt(purchaseCost) * parseInt(bankinterest)) / 100)
            } else {
                formikRef.current?.setFieldValue("interest", "")
            }
        } else if (selectedFundTypeOptions[0] && selectedFundTypeOptions[0].label === "Partial Split") {
            if (formikRef.current?.values.bank_funded_amount) {
                formikRef.current?.setFieldValue("interest", (parseInt(formikRef.current?.values.bank_funded_amount) * parseInt(bankinterest)) / 100)
            }
        }
    }, [purchaseCost, bankinterest, selectedFundTypeOptions, formikRef.current?.values]);

    useEffect(() => {
        addProcessingRequests();
        ServerAPI.getModelVariants(selectedModelUUID).then(response => {
            if (response && response.data && response.data.list.length > 0) {
                setAvailablemodelVariants(response.data.list)
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
        //model_group
        addProcessingRequests();
        ServerAPI.getModels().then(response => {
            if (response && response.data && response.data.list.length > 0) {
                response.data.list.filter((filterModel: any) => filterModel.uuid === selectedModelUUID)
                    .map((item: any) => formikRef.current?.setFieldValue('models_group_uuid', item.model_code));
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }, [selectedModelUUID]);

    useEffect(() => {
        addProcessingRequests();
        ServerAPI.getInventoryFundType().then(response => {
            if (response && response.data && response.data.list.length > 0) {
                const selectedFundTypeOption = response.data.list.filter((selectedItem: any) => selectedItem.uuid === selectedFundType)
                    .map((item: any) => ({
                        label: item.name,
                        value: item.uuid
                    }))
                setSelectedFundTypeOptions(selectedFundTypeOption);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }, [selectedFundType]);


    useEffect(() => {
        addProcessingRequests();
        ServerAPI.executeAPI(Endpoints.ShowroomList, ServerAPI.APIMethod.GET, true).then((response: any) => {
            if (response && response.data && response.data.list.length > 0) {
                const selectedShowroomState = response.data.list.filter((selectedItem: any) => selectedItem.uuid === selectedShowroomUUID)
                    .map((item: any) => ({
                        label: item.state_name,
                        value: item.state_uuid
                    }))
                setCustomerStateOptions(selectedShowroomState);
                if (selectedShowroomState.length === 1) {
                    formikRef.current?.setFieldValue('state_uuid', selectedShowroomState[0].value);
                }
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }, [selectedShowroomUUID]);

    useEffect(() => {
        addProcessingRequests();
        ServerAPI.executeAPI(Endpoints.UserInventoryVehicleStatus, ServerAPI.APIMethod.GET, true).then(response => {
            if (response && response.data && response.data.list.length > 0) {
                const selectedStatusOption = response.data.list.filter((selectedItem: any) => selectedItem.uuid === selectedStatusUUID)
                    .map((item: any) => ({
                        label: item.status_name,
                        value: item.uuid
                    }))
                setSelectedStatusOptions(selectedStatusOption);
                console.log(selectedStatusOption, "selectedStatus");
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }, [selectedStatusUUID]);


    useEffect(() => {
        if (availableModelVariants) {
            const modelVariants = availableModelVariants.map((item: any) => ({
                label: item.variant_name,
                value: item.uuid
            }));
            setVariantOptions(modelVariants);

            // Filter interior colors based on selected variant and exterior color
            const selectedVariant = availableModelVariants.find((item: any) => item.uuid === selectedVariantUUID);
            if (selectedVariant) {
                const uniqueExteriorColors = Array.from(
                    new Map(selectedVariant.colors.map((color: any) => [color.exterior_colors_uuid, {
                        label: color.exterior_color_name,
                        value: color.exterior_colors_uuid
                    }])).values()
                );
                setExteriorColorOptions(uniqueExteriorColors);
            }

            // Filter interior colors based on selected variant and interior color
            const selectedVariantWithColors = availableModelVariants.find((item: any) => item.uuid === selectedVariantUUID);
            if (selectedVariantWithColors) {
                const selectedExteriorColors = selectedVariantWithColors.colors.filter((color: any) => color.exterior_colors_uuid === selectedExteriorUUID);
                const uniqueInteriorColors = Array.from(
                    new Map(selectedExteriorColors.map((color: any) => [color.interior_color_uuid, {
                        label: color.interior_color_name,
                        value: color.interior_color_uuid
                    }])).values()
                );
                setInteriorColorOptions(uniqueInteriorColors);
            }

            // Filter fuel types based on selected variant
            const selectedVariantFuelType = availableModelVariants.find((item: any) => item.uuid === selectedVariantUUID);
            if (selectedVariantFuelType) {
                const fuelType = [{
                    label: selectedVariantFuelType.fuel_name,
                    value: selectedVariantFuelType.fuel_type_uuid
                }];
                setFuelTypeOptions(fuelType);
                formikRef.current?.setFieldValue('fuel_type_uuid', selectedVariantFuelType.fuel_type_uuid);
            }
        }
    }, [selectedModelUUID, selectedExteriorUUID, selectedVariantUUID, availableModelVariants]);


    useEffect(() => {
        if (id) {
            addProcessingRequests();
            ServerAPI.getInventoryDetail(id).then((response: any) => {
                if (response && response.data && response.data.details) {
                    const inventoryDetail = response.data.details;
                    setEditMode(true)
                    setVehicleCost(inventoryDetail.vehicle_cost);
                    setVehicleGst(inventoryDetail.gst_amount_on_vehicle);
                    setPurchaseCost(inventoryDetail.vehicle_purchse_cost);
                    setOperationReset(false);
                    setImagepath(inventoryDetail.image_path);
                    setOperationalCosts(inventoryDetail.operational_data ? inventoryDetail.operational_data : []);
                    setInitialValue({
                        showroom_uuid: inventoryDetail.showroom_uuid,
                        WHS_code: inventoryDetail.WHS_code,
                        models_uuid: inventoryDetail.models_uuid,
                        models_group_uuid: inventoryDetail.models_group_uuid,
                        variant_uuid: inventoryDetail.variant_uuid,
                        interior_uuid: inventoryDetail.interior_uuid,
                        exterior_uuid: inventoryDetail.exterior_uuid,
                        fuel_type_uuid: inventoryDetail.fuel_type_uuid,
                        vehicle_status_uuid: inventoryDetail.vehicle_status_uuid,
                        VIN_number: inventoryDetail.VIN_number,
                        engine_number: inventoryDetail.engine_number,
                        model_year: inventoryDetail.model_year,
                        mf_year: inventoryDetail.mf_year,
                        purchase_date: inventoryDetail.purchase_date,
                        recevied_date: inventoryDetail.recevied_date,
                        expected_recevied_date: inventoryDetail.expected_recevied_date,
                        status: inventoryDetail.status,
                        invoice_number: inventoryDetail.invoice_number,
                        state_uuid: inventoryDetail.state_uuid,
                        buying_price: inventoryDetail.buying_price,
                        vehicle_cost: inventoryDetail.vehicle_cost,
                        gst_amount_on_vehicle: inventoryDetail.gst_amount_on_vehicle,
                        vehicle_purchse_cost: inventoryDetail.vehicle_purchse_cost,
                        vehicle_revaluation_cost: inventoryDetail.vehicle_revaluation_cost,
                        funding_type: inventoryDetail.funding_type,
                        self_funding: inventoryDetail.self_funding,
                        self_funded_amount: inventoryDetail.self_funded_amount,
                        bank_funded_amount: inventoryDetail.bank_funded_amount,
                        funding_bank_partner_uuid: inventoryDetail.funding_bank_partner_uuid,
                        interest: inventoryDetail.interest,
                        image: inventoryDetail.image,
                        final_landing_cost: inventoryDetail.final_landing_cost
                    });
                    setFormDataSet(true);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }, [id, reloadDetails]);

    // , editMode ? availableModelVariants : null



    function TabPanel(props: { children: any; value: any; index: any; }) {
        const { children, value, index } = props;
        return (
            <div>
                {
                    value === index && (
                        <h1>{children}</h1>
                    )
                }
            </div>
        )
    }


    const handelTabChange = (e: any, val: React.SetStateAction<number>) => {
        setCurrentTab(val)
    }

    const addUserOperationalCost = () => {
        addProcessingRequests();
        ServerAPI.inventoryOperationalCostAdd(id ? id : "", operationalCosts[0].operational_cost, operationalCosts[0].operational_cost_description).then(response => {
            if (response && response.success && response.message) {
                toast.success(response.message);
                setReloadDetails(!reloadDetails);
            } else if (response && response.message) {
                toast.error(response.message);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    };

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="book-a-car-section drawer-fit">
            {formDataSet && initialValue !== undefined && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };
                    delete submitValues['final_landing_cost']
                    if (selectedFundTypeOptions[0].label) {
                        if (selectedFundTypeOptions[0].label === "Self Funding" || selectedFundTypeOptions[0].label === "Partial Split") {
                            if (!submitValues['self_funding']) {
                                toast.error("Self Funding % is Required");
                                setSubmitting(false);
                                return
                            }
                        }
                        if (selectedFundTypeOptions[0].label !== "Self Funding") {
                            if (!submitValues['interest']) {
                                toast.error("Interest % is Required");
                                setSubmitting(false);
                                return
                            }
                        }
                        if (selectedFundTypeOptions[0].label === "Self Funding" || selectedFundTypeOptions[0].label === "Partial Split") {
                            if (!submitValues['self_funded_amount']) {
                                toast.error("Self Funded Amount is Required");
                                setSubmitting(false);
                                return
                            }
                        }
                        if (selectedFundTypeOptions[0].label === "Bank Funding" || selectedFundTypeOptions[0].label === "Partial Split") {
                            if (!submitValues['bank_funded_amount']) {
                                toast.error("Bank Funded Amount is Required");
                                setSubmitting(false);
                                return
                            }
                        }
                        if (selectedFundTypeOptions[0].label === "Bank Funding" || selectedFundTypeOptions[0].label === "Partial Split") {
                            if (!submitValues['funding_bank_partner_uuid']) {
                                toast.error("Bank Funding Partner is Required");
                                setSubmitting(false);
                                return
                            }
                        }
                    }
                    if (selectedStatusOptions[0].label) {
                        if (selectedStatusOptions[0].label !== "Ordered" && selectedStatusOptions[0].label !== "InTransit") {
                            if (!submitValues['engine_number']) {
                                toast.error("Engine Number is Required");
                                setSubmitting(false);
                                return
                            }
                            if (!submitValues['VIN_number']) {
                                toast.error("VIN Number is Required");
                                setSubmitting(false);
                                return
                            }
                        }
                        if (selectedStatusOptions[0].label !== "Ordered") {
                            if (!submitValues['recevied_date']) {
                                toast.error("Received Date is Required");
                                setSubmitting(false);
                                return
                            }
                        }
                    }

                    if (vehicleCost === "") {
                        toast.error("Vehicle Cost is Required");
                        setSubmitting(false);
                        return
                    }

                    submitValues['bank_funded_amount'] = submitValues['bank_funded_amount'] ? submitValues['bank_funded_amount'].toString() : null;
                    submitValues['self_funded_amount'] = submitValues['self_funded_amount'] ? submitValues['self_funded_amount'].toString() : null;
                    submitValues['self_funding'] = submitValues['self_funding'] ? submitValues['self_funding'].toString() : null;
                    submitValues['vehicle_revaluation_cost'] = submitValues['vehicle_revaluation_cost'].toString();
                    submitValues['vehicle_purchse_cost'] = purchaseCost.toString();
                    submitValues['gst_amount_on_vehicle'] = vehicleGst.toString();
                    submitValues['vehicle_cost'] = vehicleCost.toString();
                    submitValues['interest'] = submitValues['interest'].toString();
                    if (editMode === false) {
                        delete submitValues['operational_cost']
                    }
                    if (editMode === true && formikRef.current?.values.image.startsWith("https")) {
                        submitValues['image'] = imagepath;
                    }

                    if (id != undefined && id != '') {
                        addProcessingRequests();
                        submitValues['uuid'] = id;
                        ServerAPI.inventoryUpdate(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                { currentTab === 2 && toast.success(response['message']); }
                                reloadData();
                                // closeDialog();
                                if (currentTab === 1) {
                                    setCurrentTab(2);
                                } else if (currentTab === 2) {
                                    closeDialog();
                                }

                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    } else {
                        addProcessingRequests();
                        ServerAPI.inventoryAdd(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    }

                }}>
                    {(formikProps) => {
                        if (formikProps.values.exterior_uuid !== selectedExteriorUUID) {
                            setSelectedExteriorUUID(formikProps.values.exterior_uuid);
                        }
                        if (formikProps.values.vehicle_status_uuid !== selectedStatusUUID) {
                            setSelectedStatusUUID(formikProps.values.vehicle_status_uuid);
                        }
                        if (formikProps.values.variant_uuid !== selectedVariantUUID) {
                            setSelectedVariantUUID(formikProps.values.variant_uuid);
                        }
                        if (formikProps.values.models_uuid !== selectedModelUUID) {
                            setSelectedModelUUID(formikProps.values.models_uuid);
                        }
                        if (formikProps.values.funding_type !== selectedFundType) {
                            setSelectedFundType(formikProps.values.funding_type);
                        }
                        if (formikProps.values.model_year !== selectedModelYear) {
                            setSelectedModelYear(formikProps.values.model_year);
                        }
                        if (formikProps.values.vehicle_revaluation_cost !== revaluationCost) {
                            setRevaluationCost(formikProps.values.vehicle_revaluation_cost);
                        }
                        if (formikProps.values.showroom_uuid !== selectedShowroomUUID) {
                            setSelectedShowroomUUID(formikProps.values.showroom_uuid);
                        }
                        if (formikProps.values.funding_bank_partner_uuid !== selectedBankPartner) {
                            setSelectedBankPartner(formikProps.values.funding_bank_partner_uuid);
                        }
                        if (formikProps.values.self_funding !== selfInterest) {
                            setTimeout(() => {
                                setSelfInterest(formikProps.values.self_funding);
                            }, 1000);
                        }

                        return (
                            <Form>
                                <div className="tabdetails" style={{ overflowY: "auto", height: "90vh" }} ref={bookACarRef}>
                                    <h4 className="title-text ml-5 text-center">{editMode && !detailView ? "Edit" : detailView ? "View" : "Add"} Inventory</h4>
                                    <div className="tabpage">
                                        <div className="form-field-container-tabs px-4">
                                            <Tabs value={currentTab} onChange={handelTabChange}
                                                sx={{ backgroundColor: "white", borderTop: "2px solid #e2e2e2", borderBottom: "2px solid #e2e2e2", "& button": { width: (editMode || detailView) ? "33%" : "50%" }, justifyContent: "space-between" }}
                                                TabIndicatorProps={{
                                                    style: {
                                                        backgroundColor: "#14408B",
                                                    },
                                                }}
                                            >
                                                <Tab label="Vehicle Details" className="tab-headers" />
                                                <Tab label="Pricing Details " className="tab-headers" />
                                                {(editMode || detailView) && <Tab label="Landing | Logs" className="tab-headers" />}

                                            </Tabs>
                                            <TabPanel value={currentTab} index={0}>
                                                <>
                                                    <div className="row py-3">
                                                        <div className="col-lg-6 col-md-6 col-xl-6 pb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Showroom", name: "showroom_uuid", placeholder: "Select Showroom", options: showroomOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-xl-6 pb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "State", name: "state_uuid", placeholder: "Select State", options: customerStateOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "WHS Code", name: "WHS_code", placeholder: "Enter WHS code", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-xl-6 pb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Model", name: "models_uuid", placeholder: "Select Model", options: modelOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Model Group", name: "models_group_uuid", placeholder: "Enter Group", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-xl-6 pb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Variant", name: "variant_uuid", placeholder: "Select Variant", options: variantOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-xl-6 pb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Fuel Type", name: "fuel_type_uuid", placeholder: "Select Fuel Type", options: fuelTypeOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-xl-6 pb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Exterior Color", name: "exterior_uuid", placeholder: "Select Exterior Color", options: exteriorColorOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-xl-6 pb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Interior Color", name: "interior_uuid", placeholder: "Select Interior Color", options: interiorColorOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "VIN", name: "VIN_number", placeholder: "Enter Vin Number", readOnly: detailView ? true : false, required: true }} />
                                                        </div>
                                                        <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Engine Number", name: "engine_number", placeholder: "Enter Engine Number", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-xl-6 mt-2 pb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Model Year", name: "model_year", placeholder: "Select Model Year", options: modelYearOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-xl-6 pb-4 text-field-empty-custom-user-1 date-field">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "monthyearpicker", label: "Manufacturing month & Year", name: "mf_year", placeholder: "Select Month & Year", required: true, readOnly: detailView ? true : false, allowFutureMonth: selectedCurrentYear ? false : true }} />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-xl-6 mt-2 pb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Vehicle Status", name: "vehicle_status_uuid", placeholder: "Select Vehicle Status", options: statusOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        {selectedStatusOptions && selectedStatusOptions.length > 0 && (
                                                            (selectedStatusOptions[0].label === "Ordered" || selectedStatusOptions[0].label === "InTransit") && (
                                                                <>
                                                                    <div className="col-lg-6 col-md-6 col-xl-6 pb-4 text-field-empty-custom-user-1 date-field">
                                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "datepicker", label: "Expected Received Date", name: "expected_received_date", placeholder: "Select Expected Received Date", required: true, readOnly: detailView ? true : false, allowFutureDate: true }} />
                                                                    </div>
                                                                </>
                                                            )
                                                        )}
                                                        <div className="col-lg-6 col-md-6 col-xl-6 pb-4 text-field-empty-custom-user-1 date-field">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "datepicker", label: "Purchase / Funding Date", name: "purchase_date", placeholder: "Select Purchase Date", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-xl-6 pb-4 text-field-empty-custom-user-1 date-field">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "datepicker", label: "Receive Date", name: "recevied_date", placeholder: "Select Receive Date", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "fileupload", name: "image", label: "Image", placeholder: "Image", required: true, accept: "image/*", imageType: uploadImageType.LEAD_UPLOAD }} />
                                                        </div>
                                                    </div>
                                                    <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                        <button type="button" className="button1" onClick={() => closeDialog()}>Back</button>
                                                        <button type="button" className="button2" onClick={() => setCurrentTab(1)}>Next</button>
                                                    </div>
                                                </>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={1}>
                                                <>
                                                    <div className="row py-3">
                                                        <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom-1 mb-4">
                                                            <div className="mb-24 custom-iadd">
                                                                <label className="label-custom-1" style={{ top: "-21px", height: "27px" }}>Vehicle Cost <span className="text-danger">*</span></label>
                                                                <input type="text" className="form-control" id="vehicle_cost" placeholder="Enter Vehicle Cost" name="vehicle_cost" ref={inputRef} value={vehicleCost} readOnly={detailView ? true : false} onChange={(e) => setVehicleCost(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom-1 mb-4">
                                                            <div className="mb-24 custom-iadd">
                                                                <label className="label-custom-1" style={{ top: "-21px", height: "27px" }}>GST Amount on Vehicle <span className="text-danger">*</span></label>
                                                                <input type="text" className="form-control" id="vehicle_cost" placeholder="Enter GST Amount on Vehicle" name="gst_amount_on_vehicle" readOnly value={vehicleGst} />
                                                            </div>
                                                        </div>
                                                        <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Invoice Number", name: "invoice_number", placeholder: "Enter Invoice Number", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom-1 mt-1">
                                                            <div className="mb-24 custom-iadd">
                                                                <label className="label-custom-1" style={{ top: "-25px", height: "27px", marginTop: "5px" }}>Vehicle Purchase Cost (with GST) <span className="text-danger">*</span></label>
                                                                <input type="text" className="form-control" id="vehicle_cost" placeholder="Enter Vehicle Purchase Cost" name="vehicle_purchse_cost" readOnly value={purchaseCost} />
                                                            </div>
                                                        </div>
                                                        <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom holiday-group-3${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Vehicle Revaluation Cost (with GST)", name: "vehicle_revaluation_cost", placeholder: "Enter Vehicle Revaluation Cost", readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-xl-6 mb-4 text-field-empty-custom-user-1 mt-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Funding Type", name: "funding_type", placeholder: "Select Funding Type *", options: fundTypeOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        {selectedFundTypeOptions && selectedFundTypeOptions.length > 0 && (
                                                            (selectedFundTypeOptions[0].label === "Self Funding" || selectedFundTypeOptions[0].label === "Partial Split") && (
                                                                <>
                                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom mt-1${detailView ? " detail-view-num" : ""}`}>
                                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Self Funding %", name: "self_funding", placeholder: "Enter Self Funding %", readOnly: detailView ? true : false }} />
                                                                    </div>
                                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom mt-1${detailView ? " detail-view-num" : ""}`}>
                                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Self Funded Amount", name: "self_funded_amount", placeholder: "Enter Self Funded Amount", readOnly: detailView ? true : false }} />
                                                                    </div>
                                                                </>
                                                            )
                                                        )}
                                                        {selectedFundTypeOptions && selectedFundTypeOptions.length > 0 && (
                                                            (selectedFundTypeOptions[0].label === "Bank Funding" || selectedFundTypeOptions[0].label === "Partial Split") && (
                                                                <>
                                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom holiday-group-3${detailView ? " detail-view-num" : ""}`}>
                                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Bank Funded Amount", name: "bank_funded_amount", placeholder: "Enter Bank Funded Amount*", readOnly: detailView ? true : false }} />
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-xl-6 mb-4 text-field-empty-custom-user-1 mt-1">
                                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Funding Bank Partner", name: "funding_bank_partner_uuid", placeholder: "Select Funding Bank Partner", options: bankPartnerOptions, required: true, readOnly: detailView ? true : false }} />
                                                                    </div>
                                                                    <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Interest % (with Bank)", name: "interest", placeholder: "Enter Interest %", required: true, readOnly: detailView ? true : false }} />
                                                                    </div>
                                                                </>
                                                            )
                                                        )}
                                                    </div>
                                                    <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                        <button type="button" className="button1" onClick={() => setCurrentTab(0)}>Previous</button>
                                                        {!detailView && <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                            formikProps.submitForm();
                                                            if (!formikProps.isValid) {
                                                                console.log('Errors', formikProps.errors);
                                                                toast.error("Please ensure all fields are Filled and Valid!");
                                                            }
                                                        }}>{editMode ? "Next" : "Submit"}</button>}
                                                        {detailView && <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                            setCurrentTab(2)
                                                        }}>Next</button>}
                                                    </div>
                                                </>
                                            </TabPanel >
                                            {(editMode || detailView) && <TabPanel value={currentTab} index={2}>
                                                <>
                                                    <div className="row  py-3">
                                                        <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom holiday-group-3${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Vehicle Purchase Cost (with GST)", name: "vehicle_purchse_cost", placeholder: "Enter Vehicle Purchase Cost", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom holiday-group-3${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Interest incurred on this Vehicle", name: "interest", placeholder: "Enter Interest", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        {/* <div className="w-100">
                                                            <FieldArray name="operational_cost">
                                                                {({ insert, remove, push }) => (
                                                                    <div className='individual_sub_content pl-3 mb-4'>
                                                                        {!detailView && <button type="button" className="mb-2 mr-2 btn" style={{ backgroundColor: "#14408b", color: "#FFF" }} onClick={() => push(InitialData.OperationalCost)}>Add Operational Cost</button>}
                                                                        {formikProps.values.operational_cost !== undefined && formikProps.values.operational_cost.length > 0 && (
                                                                            <>
                                                                                {formikProps.values.operational_cost && formikProps.values.operational_cost.map((data, index) => {
                                                                                    const uniqueKey = `${index}`;
                                                                                    return (
                                                                                        <div key={uniqueKey} className="row py-3">
                                                                                            <div className="col-lg-5 col-md-5 col-xl-5 text-field-empty-custom-user operational-cost p-0 ml-2 mr-3">
                                                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Operational Cost", name: `operational_cost.${index}.operational_cost`, placeholder: "Operational Cost", required: true, readOnly: detailView ? true : false }} />
                                                                                            </div>
                                                                                            <div className="col-lg-5 col-md-5 col-xl-5 text-field-empty-custom-user operational-cost p-0 mr-2 ml-2">
                                                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Operational Cost Description", name: `operational_cost.${index}.operational_cost_description`, placeholder: "Operational Cost Description", required: true }} />
                                                                                            </div>
                                                                                            <div className="col-lg-1 col-md-1 col-xl-1 text-field-empty-custom-user">
                                                                                                <button type="button" className={`btn btn-sm ml-1`} style={{ backgroundColor: "#f14b4b" }} onClick={() => { remove(index); console.log("delete button clicked") }}><i className="text-white fas fa-trash pr-1 pl-1"></i></button>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </FieldArray>
                                                        </div> */}
                                                        <div className="w-100">
                                                            <div className="individual_sub_content pl-3 mb-4">
                                                                {!detailView && (
                                                                    <button
                                                                        type="button"
                                                                        className="mb-2 mr-2 btn"
                                                                        style={{ backgroundColor: "#14408b", color: "#FFF" }}
                                                                        onClick={addOperationalCost}
                                                                    >
                                                                        Add Operational Cost
                                                                    </button>
                                                                )}
                                                                {operationalCosts.length > 0 && operationalCosts.map((data, index) => (
                                                                    <div key={index} className="row py-3">
                                                                        <div className="col-lg-5 col-md-5 col-xl-5 text-field-empty-custom-user operational-cost p-0 ml-2">
                                                                            <div className="form-group-1 text-left">
                                                                                <label className='label-custom' style={{ top: "-18px" }}>Operational Cost</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="Operational Cost"
                                                                                    value={data.operational_cost}
                                                                                    onChange={(e) => handleCostChange(index, e)}
                                                                                    ref={(el) => (inputCostRefs.current[index] = el)}
                                                                                    onFocus={() => handleFocus('cost', index)}
                                                                                    style={{ width: "100%" }}
                                                                                    readOnly={data.operational_data_uuid ? true : false}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-5 col-md-5 col-xl-5 text-field-empty-custom-user operational-cost p-0 ml-2">
                                                                            <div className="form-group-1 text-left">
                                                                                <label className='label-custom' style={{ top: "-18px" }}>Operational Cost Description</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="Operational Cost Description"
                                                                                    value={data.operational_cost_description}
                                                                                    onChange={(e) => handleDescriptionChange(index, e)}
                                                                                    ref={(el) => (inputDescriptionRefs.current[index] = el)}
                                                                                    onFocus={() => handleFocus('description', index)}
                                                                                    style={{ width: "100%" }}
                                                                                    readOnly={data.operational_data_uuid ? true : false}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-1 col-md-1 col-xl-1 text-field-empty-custom-user d-flex" style={{ height: "44px" }}>
                                                                            < button
                                                                                type="button"
                                                                                className="btn btn-sm mr-1"
                                                                                style={{ backgroundColor: "#f14b4b" }}
                                                                                onClick={() => removeOperationalCost(index)}
                                                                            >
                                                                                <i className="text-white fas fa-trash pr-1 pl-1"></i>
                                                                            </button>
                                                                            {/* {data.operational_data_uuid && < button
                                                                                type="button"
                                                                                className="btn btn-sm mr-1"
                                                                                style={{ backgroundColor: "#f14b4b" }}
                                                                                onClick={() => removeOperationalCost(index)}
                                                                            >
                                                                                <i className="text-white fas fa-trash pr-1 pl-1"></i>
                                                                            </button>} */}
                                                                            {!data.operational_data_uuid && <button
                                                                                type="button"
                                                                                className="btn btn-sm ml-1"
                                                                                style={{ backgroundColor: "#008000" }}
                                                                                onClick={addUserOperationalCost}
                                                                            >
                                                                                <i className="text-white fas fa-check font-16 pr-1 pl-1"></i>
                                                                            </button>}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <div className={`col-md-12 col-lg-6 col-xl-6 px-3 text-field-empty-custom holiday-group-3${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Final Landing Cost", name: "final_landing_cost", placeholder: "Enter Landing Cost", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                    </div>
                                                    <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                        <button type="button" className="button1" onClick={() => setCurrentTab(1)} style={detailView ? { textAlign: "center" } : {}}>Previous</button>
                                                        {!detailView && <button type="button" className="button2" onClick={() => closeDialog()}>Submit</button>}
                                                    </div>
                                                </>
                                            </TabPanel>}
                                        </div >
                                    </div >
                                </div >
                            </Form >
                        )
                    }}
                </Formik >
            )
            }
        </Drawer >
    )
}

export default InventoryEntryDialog;
