import { Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";

const AllocateVinEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, modelUUID: string, variantUUID: string, inventoryAllotmentUUID: string, bookingUUID: string, leadUUID: string }> = ({ showDialog, closeDialog, modelUUID, variantUUID, inventoryAllotmentUUID, bookingUUID, leadUUID }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [inventoryList, setInventoryList] = useState<any>([]);
    const [selectedInventoryUUID, setSelectedInventoryUUID] = useState<string>("");

    useEffect(() => {
        if (modelUUID !== undefined && modelUUID !== '' && variantUUID !== undefined && variantUUID !== '') {
            addProcessingRequests();
            ServerAPI.getInventoryList(modelUUID, variantUUID).then(response => {
                if (response && response.data.list) {
                    setInventoryList(response.data.list);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }, [modelUUID, variantUUID]);

    const allocateVin = () => {
        if(selectedInventoryUUID) {
            addProcessingRequests();
            ServerAPI.updateVinAllotment(inventoryAllotmentUUID, selectedInventoryUUID, bookingUUID, leadUUID)
                .then((response: any) => {
                    if (response && response.success === true) {
                        if (response.message) {
                            toast.success(response.message);
                            closeDialog();
                        }
                    } else if (response.error_messages) {
                        toast.error(response.error_messages);
                    }
                }).finally(() => {
                    reduceProcessingRequests()
                });
        } else {
            toast.error('Please Select Inventory');
        }
    }

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-fit">
            <div className="px-3 py-3">
                <h4 className="title-text ml-5 text-center">Vin Allotment</h4>
                <div className="custom-border-grey my-2"></div>
                {inventoryList !== undefined && inventoryList.length > 0 &&
                    <div className="table-container">
                        <div className="table-scroll">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead className="color-native-blue">
                                        <tr>
                                            <th></th>
                                            <th>Model Name</th>
                                            <th>Variant Name</th>
                                            <th>Vin Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><input type="checkbox" onChange={e => {
                                                if (e.target.checked) {
                                                    setSelectedInventoryUUID(inventoryList[0].uuid)
                                                } else {
                                                    setSelectedInventoryUUID('')
                                                }
                                            }} checked={selectedInventoryUUID === inventoryList[0].uuid} /></td>
                                            <td>{inventoryList[0].model_name}</td>
                                            <td>{inventoryList[0].variant_name}</td>
                                            <td>{inventoryList[0].VIN_number}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                <button type="button" className="button1" onClick={() => closeDialog()}>Back</button>
                                <button type="button" className="button2" onClick={allocateVin}>Allocate</button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </Drawer>
    )
}

export default AllocateVinEntryDialog;