import { TableViewMappings } from "../common/TableViewMappings";
import React, { useState } from "react";
import Pagination from '@mui/material/Pagination';
import { PageMappings } from "../common/PageMappings";
import ConfirmDialog from "./ConfirmDialog";
import { getFormattedLocalDate, getFormattedLocalDateTime, getFormattedLocalTime, getStringTruncated } from "../common/Utilities";
import { ProjectConfig, StatusValues } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { useAppStateAPI } from "../common/AppStateAPI";
import toast from "react-hot-toast";
import { ServerAPI } from "../common/ServerAPI";

interface TableMainProps {
    tableDetail: TableViewMappings.TableDetail,
    tableRows: any[],
    totalPages: number,
    currentPage: number,
    setCurrentPage: (number: number) => void,
    pageMappingDetail: PageMappings.PageDetail,
    setDataID: (id: string) => void,
    setCopyID: (id: string) => void,
    setInventoryParams: (id: (APIData.FilterInventory | undefined)) => void,
    showInventoryFilter?: boolean,
    inventoryFilterApplied: (show: boolean) => void,
    setShowForm: (show: boolean) => void,
    setShowDetail: (show: boolean) => void,
    hidePagination?: boolean,
    deleteTableEntry: (params: { [k: string]: any; }) => void,
    reloadData: () => void
}

const TableMain: React.FC<TableMainProps> = (props) => {

    const { tableDetail, tableRows, totalPages, currentPage, setCurrentPage, pageMappingDetail, setShowForm, setShowDetail, setDataID, setCopyID, hidePagination, deleteTableEntry, reloadData, setInventoryParams, showInventoryFilter, inventoryFilterApplied } = props;
    const [deleteParams, setDeleteParams] = useState<{ [k: string]: any }>({});
    const [statusUUID, setStatusUUID] = useState("");
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showStatusUpdateConfirm, setShowStatusUpdateConfirm] = useState(false);

    const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const updateStatus = (uuid: string, status: string) => {
        addProcessingRequests();
        ServerAPI.helpSupportStatusUpdate(uuid, status).then(response => {
            if (response && response['success'] && response['message']) {
                reloadData();
                toast.success(response['message']);
            } else if (response && response['message']) {
                toast.error(response['message']);
            } else {
                toast.error('Unknown Error Occurred');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }
    return (
        <div>
            <div className="table-container">
                <div className="table-scroll">
                    <div className="table-responsive">
                        <table className="table">
                            <thead className="color-native-blue">
                                <tr>
                                    <th>S.no</th>
                                    {tableDetail.columns.map((column, index) => {
                                        return (
                                            <th key={index}>
                                                {column.label}
                                            </th>
                                        )
                                    })}
                                    {tableDetail.actions !== undefined && tableDetail.actions.length > 0 && (
                                        <th scope="col">Action</th>
                                    )}
                                </tr>
                            </thead>
                            {(tableRows !== undefined && tableRows.length > 0) ?
                                <tbody>
                                    {tableRows.map((row: any, rowIndex) => {
                                        return (
                                            <>
                                                <tr key={rowIndex}>
                                                    <td>{((currentPage - 1) * ProjectConfig.EntriesPerPage) + rowIndex + 1}</td>
                                                    {tableDetail.columns.map((column, colIndex) => {
                                                        if (column.type == 'image') {
                                                            return (
                                                                <td key={rowIndex + '_' + colIndex}>
                                                                    <img className='table-image' src={row[column.data_key]}></img>
                                                                </td>
                                                            )
                                                        } else {
                                                            let cellData: any = '';
                                                            if (row[column.data_key] != null && row[column.data_key] != undefined) {
                                                                cellData = row[column.data_key];
                                                            } if (column.mapped == true) {
                                                                const mappedData = TableViewMappings.getMappedData(tableDetail.pageLink, column.data_key, cellData, row);
                                                                cellData = mappedData.mappingName;
                                                            }
                                                            if (column.type == 'date') {
                                                                cellData = getFormattedLocalDate(cellData);
                                                            } else if (column.type == 'datetime') {
                                                                cellData = getFormattedLocalDateTime(cellData);
                                                            } else if (column.type == 'time') {
                                                                cellData = getFormattedLocalTime(cellData);
                                                            }
                                                            let title = '';
                                                            return (
                                                                <td key={rowIndex + '_' + colIndex} title={title}>
                                                                    {column.type == 'number' && (cellData)}
                                                                    {column.type != 'number' && column.type != 'custom' && (
                                                                        getStringTruncated(cellData)
                                                                    )}
                                                                    {column.type == 'custom' &&
                                                                        React.createElement(
                                                                            column.component,
                                                                            {
                                                                                data: row,
                                                                                data_key: column.data_key,
                                                                                pageMapping: pageMappingDetail,
                                                                                tableDetail: tableDetail,
                                                                                reloadData: reloadData
                                                                            }
                                                                        )
                                                                    }
                                                                </td>
                                                            )
                                                        }
                                                    })}
                                                    {tableDetail.actions !== undefined && tableDetail.actions.length > 0 && (
                                                        <td className="text-nowrap">
                                                            {tableDetail.actions.map((action, actionIndex) => {
                                                                if (action.type === "edit" && (tableDetail.status_column === undefined || (row[tableDetail.status_column] !== StatusValues.DELETED))) {
                                                                    return (
                                                                        <button type="button" className="btn btn-no-style px-3" title="Edit" key={actionIndex} onClick={() => {
                                                                            setDataID(row[tableDetail.primary_column]);
                                                                            setCopyID('');
                                                                            setShowForm(true);
                                                                        }}>
                                                                            <i className="fas fa-edit text-info font-16"></i>
                                                                        </button>
                                                                    )
                                                                } else if (action.type === "mark" && (tableDetail.status_column === undefined || (row[tableDetail.status_column] !== StatusValues.DELETED))) {
                                                                    return (
                                                                        <button type="button" className="btn btn-no-style px-3" title="Mark as Complete" key={actionIndex} onClick={() => { setShowStatusUpdateConfirm(true); setStatusUUID(row[tableDetail.primary_column]) }}>
                                                                            <i className="fas fa-check text-info font-16"></i>
                                                                        </button>
                                                                    )
                                                                } else if (action.type === "copy" && (tableDetail.status_column === undefined || (row[tableDetail.status_column] !== StatusValues.DELETED))) {
                                                                    return (
                                                                        <button type="button" className="btn btn-no-style px-3" title="Copy" key={actionIndex} onClick={() => {
                                                                            setCopyID(row[tableDetail.primary_column]);
                                                                            setDataID('');
                                                                            setShowForm(true);
                                                                        }}>
                                                                            <i className="fas fa-copy text-info font-16"></i>
                                                                        </button>
                                                                    )
                                                                } else if (action.type === "delete" && (tableDetail.status_column === undefined || (row[tableDetail.status_column] !== StatusValues.DELETED))) {
                                                                    return (
                                                                        <button type="button" className="btn btn-no-style px-3" title="Delete" key={actionIndex} onClick={() => {
                                                                            //setDeleteEntryID(row[tableDetail.primary_column]);
                                                                            let delParams: { [k: string]: any } = {};
                                                                            if (action.params !== undefined) {
                                                                                action.params.forEach(param => {
                                                                                    console.log(param, "inside parm")
                                                                                    delParams[param.param_key] = row[param.data_key];
                                                                                })
                                                                            }
                                                                            console.log(delParams, "deleyeparams")
                                                                            console.log(action.params, "deleyeparams")
                                                                            setDeleteParams(delParams);
                                                                            setShowDeleteConfirm(true);
                                                                        }}>
                                                                            <i className="fas fa-trash-alt text-danger font-16" aria-hidden="true"></i>
                                                                        </button>
                                                                    )
                                                                } else if (action.type === "view") {
                                                                    return (
                                                                        <button type="button" className="btn btn-no-style px-3" title="View Detail" key={actionIndex} onClick={() => {
                                                                            setDataID(row[tableDetail.primary_column]);
                                                                            setShowDetail(true);
                                                                        }}>
                                                                            <i className="fas fa-eye font-16" aria-hidden="true"></i>
                                                                        </button>
                                                                    )
                                                                } else if (action.type === "custom") {
                                                                    return React.createElement(
                                                                        action.component,
                                                                        {
                                                                            data: row,
                                                                            params: action.params,
                                                                            pageMapping: pageMappingDetail,
                                                                            tableDetail: tableDetail,
                                                                            reloadData: reloadData,
                                                                            key: actionIndex
                                                                        }
                                                                    )
                                                                }

                                                            })}
                                                        </td>
                                                    )}
                                                </tr>
                                            </>
                                        )
                                    })}
                                </tbody> :
                                <tbody>
                                    <tr>
                                        <td className="text-align-l">No Data Available</td>
                                    </tr>
                                </tbody>
                            }
                        </table>
                        <ConfirmDialog showConfirm={showDeleteConfirm} confirmHeading={'Delete Confirm'} confirmMsg={'Are you sure you want to delete this entry?'} handleNo={() => {
                            //setDeleteEntryID('');
                            setDeleteParams({});
                            setShowDeleteConfirm(false);
                        }} handleYes={() => {
                            deleteTableEntry(deleteParams);
                            setShowDeleteConfirm(false);
                        }} />
                        <ConfirmDialog showConfirm={showStatusUpdateConfirm} confirmHeading={'Update Confirm'} confirmMsg={'Are you sure you want to update the status?'} handleNo={() => {
                            setShowStatusUpdateConfirm(false);
                            setStatusUUID("");
                        }} handleYes={() => {
                            updateStatus(statusUUID, "2")
                            setShowStatusUpdateConfirm(false);
                        }} />
                        {/* <div className="col-12 mt-24 hljs-container">
                            <pre>
                                <code
                                    className="html"
                                    data-component="table"
                                    data-code="responsive"
                                ></code>
                            </pre>
                        </div> */}
                    </div>
                </div>
            </div>
            {!hidePagination &&
                <div style={{ float: "right", marginTop: '10px', height: "50px" }}>
                    <Pagination count={totalPages} onChange={handlePagination} page={currentPage} variant="outlined" color="primary" shape="rounded" />
                </div>
            }
        </div>
    )
}

export default TableMain;