import { Drawer } from "@mui/material";
import { useAppStateAPI } from "../common/AppStateAPI";
import { ServerAPI } from "../common/ServerAPI";
import { useEffect, useState } from "react";
import { APIData } from "../common/DataTypes";
import { getFormattedLocalDateTime } from "../common/Utilities";

const StockTransferDetailDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, }> = ({ showDialog, closeDialog, reloadData, id }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [testDrive, setTestDrive] = useState<APIData.TestDrive>();

    useEffect(() => {
        if (id !== undefined && id !== '') {
            addProcessingRequests();
            ServerAPI.getTestDriveDetail(id).then(response => {
                if (response && response.data && response.data.details) {
                    setTestDrive(response.data.details);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }, [id]);

    return (
        <Drawer
            anchor="right"
            open={showDialog}
            onClose={closeDialog}
            className="drawer-fit"
        >
            {testDrive !== undefined && (
                <div className="tabdetails">
                    <h4 className="title-text ml-5">Stock Transfer Details</h4>
                    <div className="tabpage">
                        <div className="custom-border-grey my-2"></div>
                        <div className="tab-details-section">
                            <div className="row px-2 py-3">
                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-custom">
                                    <label className="label-custom">From Showroom</label>
                                    <input type="text" className="form-control" readOnly={true} value={testDrive.from_showroom_name} />
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-custom">
                                    <label className="label-custom">To Showroom</label>
                                    <input type="text" className="form-control" readOnly={true} value={testDrive.to_showroom_name} />
                                </div>
                            </div>
                            <div className="row px-2 py-3">
                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-custom">
                                    <label className="label-custom">Employee Name</label>
                                    <input type="text" className="form-control" readOnly={true} value={testDrive.assign_to_name} />
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-custom">
                                    <label className="label-custom">Employee Phone</label>
                                    <input type="text" className="form-control" readOnly={true} value={testDrive.assign_to_phone} />
                                </div>
                            </div>
                            <div className="row px-2 py-3">
                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-custom">
                                    <label className="label-custom">Vehicle VIN Number</label>
                                    <input type="text" className="form-control" readOnly={true} value={testDrive.car_vin_number} />
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-custom">
                                    <label className="label-custom">Vehicle TC Number</label>
                                    <input type="text" className="form-control" readOnly={true} value={testDrive.car_tc_number} />
                                </div>
                            </div>
                            <div className="row px-2 py-3">
                                <div className="col-md-12 col-lg-12 col-xl-12 text-field-custom">
                                    <label className="label-custom">Vehicle Details</label>
                                    <input type="text" className="form-control" readOnly={true} value={testDrive.model_name + ' ' + testDrive.variant_name + ' ' + testDrive.color_name + ' ' + testDrive.fuel_name} />
                                </div>
                            </div>
                            <div className="row px-2 py-3">
                                <div className="col-md-12 col-lg-12 col-xl-12 text-field-custom">
                                    <label className="label-custom">Testdrive Date & Time</label>
                                    <input type="text" className="form-control" readOnly={true} value={getFormattedLocalDateTime(testDrive.test_drive_date_time)} />
                                </div>
                            </div>
                            <div className="row px-2 py-3">
                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-custom">
                                    <label className="label-custom">Start Km</label>
                                    <input type="text" className="form-control" readOnly={true} value={testDrive.starting_odometer_reading} />
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-custom">
                                    <label className="label-custom">End Km</label>
                                    <input type="text" className="form-control" readOnly={true} value={testDrive.ending_odometer_reading} />
                                </div>
                            </div>
                            <div className="row px-2 py-3">
                                <div className="col-md-12 col-lg-6 col-xl-6">
                                    <img src={testDrive.starting_image_url} style={{ maxWidth: '200px' }} />
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-6">
                                    <img src={testDrive.ending_image_url} style={{ maxWidth: '200px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Drawer>
    )
}

export default StockTransferDetailDialog;