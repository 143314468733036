import { useState } from "react";
import { PageMappings } from "../../common/PageMappings";
import { TableViewMappings } from "../../common/TableViewMappings";
import TestDriveCancelDialog from "../../forms/TestDriveCancelDialog";
import { TestDriveStatusValues } from "../../common/Constants";

const ActionTestDriveCancel: React.FC<{ data: any, params: { [k: string]: any }, pageMapping: PageMappings.PageDetail, tableDetail: TableViewMappings.TableDetail, reloadData: any }> = ({ data, reloadData }) => {

    const [showCancel, setShowCancel] = useState(false);

    return (
        <>
            {(data['status'] == TestDriveStatusValues.SCHEDULED || data['status'] == TestDriveStatusValues.ON_GOING) && (
                <button type="button" className="btn btn-no-style px-6" title="Cancel" onClick={() => {
                    setShowCancel(true);
                }}>
                    <i className="fa-solid fa-ban font-16"></i>
                </button>
            )}
            <TestDriveCancelDialog showDialog={showCancel} closeDialog={() => setShowCancel(false)} reloadData={reloadData} testDriveUUID={data['uuid']} />
        </>
    )
}

export default ActionTestDriveCancel;