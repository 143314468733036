import React, { useEffect, useRef, useState } from 'react';
import { APIData } from '../common/DataTypes';
import { ServerAPI } from '../common/ServerAPI';
import { useAppStateAPI } from '../common/AppStateAPI';
import { LeadTaskTypeNames } from '../common/Constants';
import { Drawer, Tab, Tabs } from "@mui/material";
import { getFormattedLocalDate, getFormattedLocalDateTime } from '../common/Utilities';
import LeadTaskUpdateDialog from '../forms/LeadTaskUpdateDialog';
import InterestedVehicleEntryDialog from '../forms/InterestedVehicleEntryDialog';
import BookaCarEntryDialog from '../forms/BookaCarEntryDialog';
import toast from 'react-hot-toast';
import ConfirmDialog from '../components/ConfirmDialog';
import InvoiceDetailsEntryDialog from '../forms/InvoiceDetailsEntryDialog';
import UpdateDeliveryDateEntryDialog from '../forms/UpdateDeliveryDateEntryDialog';
import UpdateLeadDialogEntry from '../forms/UpdateLeadEntryDialog';

const UserLeadDetailDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, detailView?: boolean }> = ({ showDialog, closeDialog, reloadData, id, detailView }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [leadDetail, setLeadDetail] = useState<APIData.LeadDetail>();
    const [primaryVehicle, setPrimaryVehicle] = useState<APIData.LeadDetailVehicle>();

    const leadPopupTabs = [{ tabLabel: "Tasks" }, { tabLabel: "Vehicle Details" }, { tabLabel: "Lead Details" }]
    const [selectedTab, setSelectedTab] = useState(0);
    const [plannedTaskView, setPlannedTaskView] = useState(true);
    const [invoiceDetaiilView, setInvoiceDetailView] = useState(false);
    const [bookACarDetaiilView, setBookACarDetailView] = useState(false);
    const [pastTasksView, setPastTasksView] = useState<number[]>([]);
    const [intVehiclesMenuView, setIntVehiclesMenuView] = useState<number[]>([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isVisit, setIsVisit] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showInvoicePopup, setShowInvoicePopup] = useState(false);
    const customTableViewRef = useRef<HTMLDivElement | null>(null);


    const [showUpdatePopup, setShowUpdatePopup] = useState(false);
    const [loadBookingDetails, setLoadBookingDetails] = useState(false);
    const [loadInterstedDetails, setLoadInterstedDetails] = useState(false);
    const [showBookaCarPopup, setShowBookaCarPopup] = useState(false);
    const [showUpdateDate, setShowUpdateDate] = useState(false);
    const [showUpdateLead, setShowUpdateLead] = useState(false);
    const [showUpdateLeadPopup, setShowUpdateLeadPopup] = useState(false);
    const [interestedUUID, setInterestedUUID] = useState("");
    const [leadUUID, setLeadUUID] = useState("");
    const [testdriveUUID, setTestdriveUUID] = useState("");
    const [leadFollowupUUID, setLeadFollowupUUID] = useState("");
    const [showInterestedVehiclePopup, setShowInterestedVehiclePopup] = useState(false);
    const [updateLeadDetail, setUpdateLeadDetail] = useState(false);
    const [leadTaskUUID, setLeadTaskUUID] = useState('');
    const [invoiceUUID, setInvoiceUUID] = useState('');
    const [updateHeading, setupdateHeading] = useState('');
    const [updatePageTitle, setUpdatePageTitle] = useState('');
    const [invoiceDeliveryDate, setInvoiceDeliveryDate] = useState('');
    const [leadQualify, setLeadQualify] = useState(0);

    useEffect(() => {
        fetchLeadDetail();
        setInterestedUUID("");
        setUpdateLeadDetail(false);
    }, [id, updateLeadDetail]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (customTableViewRef.current && !customTableViewRef.current.contains(event.target as Node)) {
                setIsUpdate(false);
                setIntVehiclesMenuView([]);
                // setInterestedUUID("");
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const fetchLeadDetail = () => {
        if (id !== undefined && id !== '') {
            addProcessingRequests();
            ServerAPI.getLeadDetail(id).then(response => {
                if (response && response.data && response.data.details && response.data.details.lead_details) {
                    const lead = response.data.details;
                    const leadTask = lead.task.planned_task.add_task_uuid;
                    const leadTestdriveUUID = lead.task.planned_task.testdrive_uuid;
                    const leadFollowupUUID = lead.task.planned_task.lead_followup_uuid;
                    const leadQualify = response.data.details.lead_details;
                    const leadUUID = response.data.details.customer_details;
                    setLeadTaskUUID(leadTask);
                    setTestdriveUUID(leadTestdriveUUID);
                    setLeadFollowupUUID(leadFollowupUUID);
                    setLeadUUID(leadUUID.lead_uuid);
                    setLeadQualify(leadQualify.lead_qualify);
                    setLeadDetail({ ...lead, lead_status_details: lead['lead_status_details'] });
                    const primVehicle = lead.interested_vehicle_details.find((vehicle: any) => vehicle.primary_status === 1);
                    const invoiceVehicle = lead.interested_vehicle_details.find((vehicle: any) => {
                        return vehicle.invoice_details && vehicle.invoice_details.invoice_car_details && Object.keys(vehicle.invoice_details.invoice_car_details).length > 0 && vehicle.invoice_details.lead_invoice_final_details && Object.keys(vehicle.invoice_details.lead_invoice_final_details).length > 0;
                    });

                    if (invoiceVehicle) {
                        const invoiceUUID = invoiceVehicle.invoice_details.invoice_car_details.invoice_uuid;
                        const invoiceDeliveryDate = invoiceVehicle.invoice_details.lead_invoice_final_details.delivery_date_time;
                        setInvoiceUUID(invoiceUUID);
                        setInvoiceDeliveryDate(invoiceDeliveryDate);
                    } else {
                        console.log('No invoice vehicle found.');
                    }
                    if (primVehicle) {
                        setPrimaryVehicle(primVehicle);
                    }
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    const interestedVehicleDelete = (uuid: string, leadID: string) => {
        addProcessingRequests();
        ServerAPI.LeadInterestedVehicleDelete(uuid, leadID).then(response => {
            if (response && response.success) {
                toast.success(response.message);
                setUpdateLeadDetail(true);
            } else {
                toast.error(response.success);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const handleUpdateClick = () => {
        setIsUpdate(!isUpdate);
    }

    const handelTabs = (e: any, val: React.SetStateAction<number>) => {
        setSelectedTab(val)
    }

    const togglePlannedTaskView = () => {
        setPlannedTaskView(!plannedTaskView);
    }

    const togglePastTasksView = (taskIndex: number) => {
        if (pastTasksView.includes(taskIndex)) {
            setPastTasksView(tasksView => tasksView.filter(index => index !== taskIndex));
        } else {
            setPastTasksView(tasksView => [...tasksView, taskIndex]);
        }
    }

    const toggleIntVehiclesMenu = (vehicleIndex: number) => {
        if (intVehiclesMenuView.includes(vehicleIndex)) {
            setIntVehiclesMenuView(menusView => menusView.filter(index => index !== vehicleIndex));
        } else {
            setIntVehiclesMenuView(menusView => [...menusView, vehicleIndex]);
        }
    }

    const handleInterestedVehicleDialog = () => {
        setShowInterestedVehiclePopup(!showInterestedVehiclePopup);
        setLoadInterstedDetails(false);
        setInterestedUUID("")
    }
    // const handleBookaCarDialog = () => {
    //     setShowBookaCarPopup(true);
    // }

    const handleUpdateDialog = () => {
        if (leadDetail?.task.planned_task.task_name === LeadTaskTypeNames.FollowupCallScheduled) {
            setUpdatePageTitle("Updating Follow-up Call");
            setupdateHeading("Did you speak to the customer?");
            setShowUpdatePopup(true);
            setIsVisit(false);
        }
        else if (leadDetail?.task.planned_task.task_name === LeadTaskTypeNames.VisitScheduled) {
            setUpdatePageTitle("Updating a Visit");
            setupdateHeading("Did you meet the customer?");
            setShowUpdatePopup(true);
            setIsVisit(true);
        }
        else if (leadDetail?.task.planned_task.task_name === LeadTaskTypeNames.TestDriveScheduled || leadDetail?.task.planned_task.task_name === LeadTaskTypeNames.TestDriveRescheduled) {
            setShowUpdatePopup(true);
            setUpdatePageTitle("Updating a Test Drive");
            setupdateHeading("Was the customer given Test Drive?");
            setIsVisit(false);
        }
    }

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-max">
            {!!leadDetail &&
                <div id='lead-popup'>
                    <div className='custom-tableview'>
                        <div className='lead-popup-wrapper row '>
                            <div className='lead-popup-left col-3'>
                                <div className='lead-popup-left-container'>
                                    <div>
                                        <div className='hot-leeds-section'>
                                            <div className='lead-popup-profile'>
                                                <div className='lead-heading-14-400'>Lead ID</div>
                                                <div className='lead-value-16-500'>{leadDetail.customer_details.lead_id}</div>
                                            </div>
                                            <div>
                                                <button className={`lead-type-btn ${leadDetail.customer_details.current_lead_status.toLowerCase()}`}>{leadDetail.customer_details.current_lead_status} LEAD</button>
                                            </div>
                                        </div>
                                        <div className='lead-popup-border-top'>
                                            <div className='lead-popup-profile'>
                                                <div className='lead-heading-14-400'>Lead Type</div>
                                                <div className='lead-value-16-500'>{leadDetail.lead_details.vehicle_lead_type_name}</div>
                                            </div>
                                            <div className='lead-popup-profile'>
                                                <div className='lead-heading-14-400'>Lead Source Category</div>
                                                <div className='lead-value-16-500'>{leadDetail.lead_details.lead_source_category_name}</div>
                                            </div>
                                            <div className='lead-popup-profile'>
                                                <div className='lead-heading-14-400'>Lead Source</div>
                                                <div className='lead-value-16-500'>{leadDetail.lead_details.lead_source_name}</div>
                                            </div>
                                        </div>

                                        <div className='lead-contact-container'>
                                            <div className='lead-popup-profile'>
                                                <div className='lead-heading-18-500'>Contact Details</div>
                                            </div>
                                            <div className='lead-popup-border-top'>
                                                <div className='lead-popup-profile'>
                                                    <div className='lead-popup-profile-details'>
                                                        <div className='short-circle'><span>{leadDetail.customer_details.short_name}</span></div>
                                                        <div className='lead-value-16-500-blue'>{leadDetail.customer_details.lead_cust_first_name} {leadDetail.customer_details.lead_cust_last_name}</div>
                                                    </div>
                                                </div>
                                                <div className='lead-popup-profile'>
                                                    <div className='lead-popup-profile-details'>
                                                        <div><img src="/assets/svg/mail.svg" /></div>
                                                        <div>
                                                            <div className='lead-heading-14-400'>Email Address</div>
                                                            <div className='lead-value-16-500'>{leadDetail.customer_details.lead_cust_email}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='lead-popup-profile'>
                                                    <div className='lead-popup-profile-details'>
                                                        <div><img src="/assets/svg/mobile.svg" /></div>
                                                        <div>
                                                            <div className='lead-heading-14-400'>Mobile Number</div>
                                                            <div className='lead-value-16-500'>{leadDetail.customer_details.lead_cust_mobile_number}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='lead-contact-container'>
                                        <div className='lead-popup-profile'>
                                            <div className='lead-heading-18-500'>Sales Person</div>
                                        </div>
                                        <div className='lead-popup-border-top'>
                                            <div className='lead-popup-profile'>
                                                <div className='lead-popup-profile-details'>
                                                    <div className='short-circle'><span>{leadDetail.customer_details.short_name}</span></div>
                                                    <div className='lead-value-16-500'>{leadDetail.task_assign_details.assigned_to}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div className='lead-popup-right col-9' style={{ height: "97vh", overflowY: "auto" }}>
                                <div className='hot-leeds-section'>
                                    <div className='bread-crumb-container'>
                                        {!!leadDetail.lead_status_details && leadDetail.lead_status_details.map((status, statusIdx) =>
                                            <div className={`bread-crumb ${status.lead_confirm_status ? 'lead-value-12-500-white bread-crumb-green' : 'bread-crumb lead-value-12-500-light'}${statusIdx === 0 ? '-start' : ''}`} key={statusIdx}>{status.lead_status_name}</div>
                                        )}
                                    </div>
                                </div>
                                <div className='hot-leeds-section-right'>
                                    <div className='lead-popup-profile'>
                                        <div className='lead-heading-14-400'>Model</div>
                                        <div className='lead-value-16-500'>{!!primaryVehicle ? primaryVehicle.model_name : ''}&nbsp;</div>
                                    </div>
                                    <div className='lead-popup-profile'>
                                        <div className='lead-heading-14-400'>Showroom</div>
                                        <div className='lead-value-16-500'>{leadDetail.task_assign_details.showroom_name}&nbsp;</div>
                                    </div>
                                    <div className='lead-popup-profile'>
                                        <div className='lead-heading-14-400'>Lead Age</div>
                                        <div className='lead-value-16-500'>{leadDetail.customer_details.lead_age}&nbsp;</div>
                                    </div>
                                    <div className='lead-popup-profile'>
                                        <div className='lead-heading-14-400'>BKG Age</div>
                                        <div className='lead-value-16-500'>{leadDetail.customer_details.BKG_age}&nbsp;</div>
                                    </div>
                                </div>

                                <div className='lead-popup-tab'>
                                    <Tabs value={selectedTab}
                                        onChange={handelTabs}
                                        sx={{ backgroundColor: "white" }}
                                        TabIndicatorProps={{
                                            style: {
                                                backgroundColor: "#052160"
                                            }
                                        }}
                                    >
                                        {leadPopupTabs.map((tab, index) => {
                                            return <Tab key={index} label={tab.tabLabel} className="tab-headers" /> //onClick={() => navigate(tab.tabLink)}
                                        })}

                                    </Tabs>
                                </div>

                                {selectedTab === 0 ? (
                                    <div className='test-drive-scheduled'>
                                        {leadDetail.task.planned_task && Object.keys(leadDetail.task.planned_task).length > 0 &&
                                            <div className='lead-popup-profile'>
                                                <div className='lead-heading-18-500'>Planned Tasks</div>
                                                <div className={`lead-popup-task-detail-container ${leadDetail.task.planned_task && leadDetail.task.planned_task.lead_type ? leadDetail.task.planned_task.lead_type.toLowerCase() : ""}`}>
                                                    <div className='lead-popup-task-detail'>
                                                        <div className='lead-popup-task-detail-header'>
                                                            <div>
                                                                <div className='lead-popup-profile-details' style={{ cursor: "pointer" }} onClick={togglePlannedTaskView}>
                                                                    <div><img src="/assets/svg/down-arrow.svg" /></div>
                                                                    <div className='lead-value-16-500-blue'>{leadDetail.task.planned_task.task_name}</div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className='lead-popup-profile-details'>
                                                                    <div className='lead-heading-14-400-light-gray'>{leadDetail.task.planned_task.created_at}</div>
                                                                    <div ref={customTableViewRef} style={{ cursor: "pointer", overflow: isUpdate ? "hidden" : "" }}>
                                                                        <img src="/assets/svg/horizontal-dot.svg" onClick={() => { handleUpdateClick(); }} />
                                                                        {isUpdate && <>
                                                                            <div className='inner-three-dot-popup-container' onClick={() => { handleUpdateDialog(); setIsUpdate(false) }}>
                                                                                <div className='inner-three-dot-popup'>
                                                                                    <div><img src="/assets/svg/update.svg" alt="" /></div>
                                                                                    <div className='lead-value-16-500' >Update</div>
                                                                                </div>
                                                                            </div>
                                                                        </>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {plannedTaskView && <>
                                                            <div>
                                                                <div className='lead-popup-profile-details-bottom '>
                                                                    <div className='lead-heading-14-400'>Remarks</div>
                                                                    <div className='lead-value-16-500'>{leadDetail.task.planned_task.remarks}</div>
                                                                    <div className='assign-section-container'>
                                                                        <div className='assign-section '>
                                                                            <div className='assign-section-left'>
                                                                                <div className='lead-heading-14-400'>Assigned To</div>
                                                                                <div className='lead-popup-profile-details'>
                                                                                    <div className='short-circle-1'><span>{leadDetail.customer_details.short_name}</span></div>
                                                                                    <div className='lead-value-16-500'>{leadDetail.task.planned_task.assigned_to}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='assign-section-right'>
                                                                                <div className='lead-heading-14-400'>Date & Time</div>
                                                                                <div className='lead-popup-profile-details'>
                                                                                    <div className='lead-value-16-500'>{getFormattedLocalDateTime(leadDetail.task.planned_task.date_time)}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {!!leadDetail.task.past_task && leadDetail.task.past_task.length > 0 &&
                                            <>
                                                <div className='lead-popup-profile'>
                                                    <div className='lead-heading-18-500'>Past Tasks</div>
                                                </div>
                                                {leadDetail.task.past_task.map((task, tskIdx) =>
                                                    <div className='lead-popup-profile' key={tskIdx}>
                                                        <div className={`lead-popup-task-detail-container ${task.lead_type.toLowerCase()}`}>
                                                            <div className='lead-popup-task-detail'>
                                                                <div className='lead-popup-task-detail-header'>
                                                                    <div>
                                                                        <div className='lead-popup-profile-details' style={{ cursor: "pointer" }} onClick={() => togglePastTasksView(tskIdx)}>
                                                                            <div><img src="/assets/svg/down-arrow.svg" /></div>
                                                                            <div className='lead-value-16-500-blue'>{task.task_name}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className='lead-popup-profile-details'>
                                                                            <div className='lead-heading-14-400-light-gray'>{task.created_at}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {pastTasksView.includes(tskIdx) &&
                                                                    <div>
                                                                        <div className='lead-popup-profile-details-bottom '>
                                                                            <div style={{ paddingBottom: "5px" }}>
                                                                                <div className='lead-heading-14-400'>Remarks</div>
                                                                                <div className='lead-value-16-500'>{task.remarks}</div>
                                                                            </div>
                                                                            <div className='assign-section-container'>
                                                                                <div className='assign-section '>
                                                                                    <div className='assign-section-left'>
                                                                                        <div className='lead-heading-14-400'>Assigned To</div>
                                                                                        <div className='lead-popup-profile-details'>
                                                                                            <div><img src="/assets/svg/profile-img.svg" width={24} height={24} /></div>
                                                                                            <div className='lead-value-16-500'>{task.assigned_to}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='assign-section-right'>
                                                                                        <div className='lead-heading-14-400'>Date & Time</div>
                                                                                        <div className='lead-popup-profile-details'>
                                                                                            <div className='lead-value-16-500'>{getFormattedLocalDateTime(task.date_time)}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </>
                                        }
                                    </div>
                                ) : selectedTab === 1 ? <>
                                    <div className='vehicle-details-container'>
                                        <div className='test-drive-scheduled'>
                                            <div className='lead-popup-profile'>
                                                <div className='interested-vehicel-section'>
                                                    <div className='lead-heading-18-500'>Interested Vehicles</div>
                                                    <div className='interested-btn' style={{ cursor: "pointer" }} onClick={handleInterestedVehicleDialog}>
                                                        <div><img src="/assets/svg/addvehicle-icon.svg" alt="" /></div>
                                                        <div>Add Vehicle</div>
                                                    </div>
                                                </div>
                                            </div>

                                            {!!leadDetail.interested_vehicle_details && leadDetail.interested_vehicle_details.map((vehicle, vehicleIdx) =>
                                                <div className='lead-popup-profile' key={vehicleIdx}>
                                                    <div className='lead-popup-task-detail-container'>
                                                        <div className='lead-popup-task-detail'>
                                                            <div className='lead-popup-task-detail-header'>
                                                                <div>
                                                                    <div className='lead-popup-profile-details' style={{ cursor: "pointer" }}  >
                                                                        <div className='lead-value-16-500-blue'>{`${vehicle.model_name} | ${vehicle.variant_name}`}</div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className='lead-popup-profile-details'>
                                                                        {!!vehicle.booked_status && vehicle.invoiced_status === 0 &&
                                                                            <>
                                                                                {vehicle?.booking_details?.booking_car_details?.vin_number ? (
                                                                                    <div className='lead-popup-profile-details'>
                                                                                        <div className='lead-heading-14-400-green'>VIN Allocated</div>
                                                                                        <div><img src="/assets/svg/unallocate.svg" alt="" /></div>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className='lead-popup-profile-details'>
                                                                                        <div className='lead-heading-14-400-red'>VIN Unallocated</div>
                                                                                        <div><img src="/assets/svg/unallocate.svg" alt="" /></div>
                                                                                    </div>
                                                                                )}
                                                                            </>

                                                                        }
                                                                        {!!vehicle.invoiced_status &&
                                                                            <div className='lead-popup-profile-details'>
                                                                                <div className='lead-heading-14-400-green '>Invoiced</div>
                                                                                <div><img src="/assets/svg/invoice.svg" alt="" /></div>
                                                                            </div>
                                                                        }
                                                                        <div style={{ cursor: "pointer" }}>
                                                                            <img src="/assets/svg/horizontal-dot.svg" onClick={() => toggleIntVehiclesMenu(vehicleIdx)} />
                                                                            {intVehiclesMenuView.includes(vehicleIdx) && <>
                                                                                {!vehicle.booked_status &&
                                                                                    <div ref={customTableViewRef} className='inner-three-dot-popup-container'>
                                                                                        <div className='inner-three-dot-popup'>
                                                                                            <div className='inner-three-dot-popup-img'>
                                                                                                <img src="/assets/svg/book.svg" alt="" /></div>
                                                                                            <div style={{ cursor: "pointer" }} onClick={() => { setShowBookaCarPopup(true); setInterestedUUID(vehicle.interested_vehicle_uuid); setIntVehiclesMenuView([]); setLoadBookingDetails(false); setInvoiceDetailView(false); setLoadInterstedDetails(false); setBookACarDetailView(false) }} className='lead-value-16-500'>Book</div>
                                                                                        </div>
                                                                                        <div className='inner-three-dot-popup'>
                                                                                            <div className='inner-three-dot-popup-img'>
                                                                                                <img src="/assets/svg/edit.svg" alt="" /></div>
                                                                                            <div style={{ cursor: "pointer" }} onClick={() => { setShowInterestedVehiclePopup(true); setInterestedUUID(vehicle.interested_vehicle_uuid); setIntVehiclesMenuView([]); setLoadInterstedDetails(true); setLoadBookingDetails(false); setInvoiceDetailView(false); setBookACarDetailView(false) }} className='lead-value-16-500'>Edit</div>
                                                                                        </div>
                                                                                        <div className='inner-three-dot-popup'>
                                                                                            <div className='inner-three-dot-popup-img'>
                                                                                                <img src="/assets/svg/delete.svg" alt="" /></div>
                                                                                            <div style={{ cursor: "pointer" }} onClick={() => { setInterestedUUID(vehicle.interested_vehicle_uuid); setShowConfirm(true); setBookACarDetailView(false) }} className='lead-value-16-500-red'>Delete</div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                {!!vehicle.booked_status && !vehicle.invoiced_status &&
                                                                                    <div className='inner-three-dot-popup-container' ref={customTableViewRef}>
                                                                                        <div className='inner-three-dot-popup' style={{ cursor: "pointer" }} onClick={() => { setShowInvoicePopup(true); setInterestedUUID(vehicle.interested_vehicle_uuid); setIntVehiclesMenuView([]); setInvoiceDetailView(false); setLoadInterstedDetails(false); setLoadBookingDetails(true); setBookACarDetailView(false) }}>
                                                                                            <div className='inner-three-dot-popup-img'><img src="/assets/svg/invoiceblack.svg" alt="" /></div>
                                                                                            <div className='lead-value-16-500'>Invoice Vehicle</div>
                                                                                        </div>
                                                                                        <div className='inner-three-dot-popup' style={{ cursor: "pointer" }} onClick={() => { setShowBookaCarPopup(true); setInterestedUUID(vehicle.interested_vehicle_uuid); setIntVehiclesMenuView([]); setLoadBookingDetails(true); setInvoiceDetailView(false); setLoadInterstedDetails(false); setBookACarDetailView(true) }}>
                                                                                            <div className='inner-three-dot-popup-img'><img src="/assets/svg/eye.svg" alt="" /></div>
                                                                                            <div className='lead-value-16-500'>View Booking Details</div>
                                                                                        </div>
                                                                                        <div className='inner-three-dot-popup' style={{ cursor: "pointer" }}>
                                                                                            <div className='inner-three-dot-popup-img'><img src="/assets/svg/close.svg" alt="" /></div>
                                                                                            <div className='lead-value-16-500'>Cancel Booking</div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                {!!vehicle.invoiced_status && !!vehicle.booked_status &&
                                                                                    <div className='inner-three-dot-popup-container'>
                                                                                        <div className='inner-three-dot-popup' style={{ cursor: "pointer" }} onClick={() => { setShowInvoicePopup(true); setInterestedUUID(vehicle.interested_vehicle_uuid); setIntVehiclesMenuView([]); setInvoiceDetailView(true); setLoadBookingDetails(false); setLoadInterstedDetails(false); setBookACarDetailView(false) }}>
                                                                                            <div><img src="/assets/svg/invoiceblack.svg" alt="" /></div>
                                                                                            <div className='lead-value-16-500'>View Invoice Details</div>
                                                                                        </div>
                                                                                        <div className='inner-three-dot-popup' style={{ cursor: "pointer" }} onClick={() => { setShowUpdateDate(true); setIntVehiclesMenuView([]) }}>
                                                                                            <div><img src="/assets/svg/invoiceblack.svg" alt="" /></div>
                                                                                            <div className='lead-value-16-500'>Update Delivery Date</div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div className='vehicle-assign-section-container'>
                                                                    <div className='assign-section-container'>
                                                                        <div className='assign-section '>
                                                                            <div className='assign-section-left'>
                                                                                <div className='lead-heading-14-400'>Exterior Color</div>
                                                                                <div className='lead-popup-profile-details'>
                                                                                    <div className='lead-value-16-500'>{vehicle.exterior_color_name}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='assign-section-left'>
                                                                                <div className='lead-heading-14-400'>Interior Color</div>
                                                                                <div className='lead-popup-profile-details'>
                                                                                    <div className='lead-value-16-500'>{vehicle.interior_color_name}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='assign-section-left-1'>
                                                                                <div className='lead-heading-14-400'>Fuel Type</div>
                                                                                <div className='lead-popup-profile-details'>
                                                                                    <div className='lead-value-16-500'>{vehicle.fuel_name}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </> : selectedTab === 2 ? <>
                                    <div className='lead-tab-details'>
                                        <div className='test-drive-scheduled'>
                                            <div className='lead-popup-profile'>
                                                <div className='interested-vehicel-section'>
                                                    <div className='lead-heading-18-500'>Customer Details</div>
                                                </div>
                                            </div>
                                            <div className='lead-popup-profile'>
                                                <div className='lead-popup-task-detail-container'>
                                                    <div className='lead-popup-task-detail'>
                                                        <div className='lead-popup-task-detail-header'>
                                                            <div>
                                                                <div className='lead-popup-profile-details' style={{ cursor: "pointer" }}  >
                                                                    <div className='lead-value-16-500-blue'>{leadDetail.customer_details.lead_cust_first_name} {leadDetail.customer_details.lead_cust_last_name}</div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className='lead-popup-profile-details' style={{ cursor: "pointer" }} onClick={() => { setShowUpdateLeadPopup(!showUpdateLeadPopup); setUpdateLeadDetail(true) }}>
                                                                    <div style={{ cursor: "pointer" }}><img src="/assets/svg/horizontal-dot.svg" /></div>
                                                                    {showUpdateLeadPopup && <div className='inner-three-dot-popup-container' style={{ marginTop: "6rem" }} onClick={() => { setShowUpdateLead(true); setShowUpdateLeadPopup(false) }}>
                                                                        <div className='inner-three-dot-popup'>
                                                                            <div className='inner-three-dot-popup-img'>
                                                                                <img src="/assets/svg/edit.svg" alt="" /></div>
                                                                            <div className='lead-value-16-500'>Update Lead</div>
                                                                        </div>
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className='vehicle-assign-section-container'>
                                                                <div className='assign-section-container'>
                                                                    <div className='assign-section '>
                                                                        <div className='assign-section-left'>
                                                                            <div className='lead-heading-14-400'>DOB</div>
                                                                            <div className='lead-popup-profile-details'>
                                                                                <div className='lead-value-16-500'>{getFormattedLocalDate(leadDetail.customer_details.lead_cust_dob)}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='assign-section-left'>
                                                                            <div className='lead-heading-14-400'>Mobile Number</div>
                                                                            <div className='lead-popup-profile-details'>
                                                                                <div className='lead-value-16-500'>{leadDetail.customer_details.lead_cust_mobile_number}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='assign-section-right'>
                                                                            <div className='lead-heading-14-400'>Secondary Number</div>
                                                                            <div className='lead-popup-profile-details'>
                                                                                <div className='lead-value-16-500'>{leadDetail.customer_details.lead_cust_secondary_number}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className='vehicle-assign-section-container'>
                                                                <div className='assign-section-container'>
                                                                    <div className='assign-section '>
                                                                        <div className='assign-section-left'>
                                                                            <div className='lead-heading-14-400'>Email ID</div>
                                                                            <div className='lead-popup-profile-details'>
                                                                                <div className='lead-value-16-500'>{leadDetail.customer_details.lead_cust_email}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='assign-section-right'>
                                                                            <div className='lead-heading-14-400'>Secondary Email ID</div>
                                                                            <div className='lead-popup-profile-details'>
                                                                                <div className='lead-value-16-500'>&nbsp;</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='customer-address-section-container'>
                                                            <div className='vehicle-assign-section-container'>
                                                                <div className='assign-section-container'>
                                                                    <div className='assign-section '>

                                                                        <div className='customer-address-section'>
                                                                            <div className='assign-section-left'>
                                                                                <div className='lead-heading-14-400'>Home Address</div>
                                                                                <div className='lead-popup-profile-details'>
                                                                                    <div className='lead-value-16-500'>
                                                                                        {leadDetail.customer_details.lead_cust_address}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='assign-section-left'>
                                                                                <div className='lead-heading-14-400'>Land Mark</div>
                                                                                <div className='lead-popup-profile-details'>
                                                                                    <div className='lead-value-16-500'>{leadDetail.customer_details.lead_cust_landmark}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='assign-section-left-1'>
                                                                                <div className='lead-heading-14-400'>Pin Code</div>
                                                                                <div className='lead-popup-profile-details'>
                                                                                    <div className='lead-value-16-500'>{leadDetail.customer_details.lead_cust_pincode}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='assign-section-left '>
                                                                                <div className='lead-heading-14-400'>City</div>
                                                                                <div className='lead-popup-profile-details'>
                                                                                    <div className='lead-value-16-500'>{leadDetail.customer_details.city_name}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='assign-section-right '>
                                                                                <div className='lead-heading-14-400'>State</div>
                                                                                <div className='lead-popup-profile-details'>
                                                                                    <div className='lead-value-16-500'>{leadDetail.customer_details.state_name}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='customer-address-section-container'>
                                                            <div className='vehicle-assign-section-container'>
                                                                <div className='assign-section-container'>
                                                                    <div className='assign-section '>
                                                                        <div>
                                                                            <div className='customer-address-section'>
                                                                                <div className='assign-section-left '>
                                                                                    <div className='lead-heading-14-400'>Home Address</div>
                                                                                    <div className='lead-popup-profile-details'>
                                                                                        <div className='lead-value-16-500'>{leadDetail.customer_details.lead_cust_bill_address}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='assign-section-left '>
                                                                                    <div className='lead-heading-14-400'>Land Mark</div>
                                                                                    <div className='lead-popup-profile-details'>
                                                                                        <div className='lead-value-16-500'>&nbsp;</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='assign-section-left-1'>
                                                                                    <div className='lead-heading-14-400'>Pin Code</div>
                                                                                    <div className='lead-popup-profile-details'>
                                                                                        <div className='lead-value-16-500'>{leadDetail.customer_details.lead_cust_bill_pincode}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='assign-section-left '>
                                                                                    <div className='lead-heading-14-400'>City</div>
                                                                                    <div className='lead-popup-profile-details'>
                                                                                        <div className='lead-value-16-500'>{leadDetail.customer_details.billing_city_name}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='assign-section-right '>
                                                                                    <div className='lead-heading-14-400'>State</div>
                                                                                    <div className='lead-popup-profile-details'>
                                                                                        <div className='lead-value-16-500'>{leadDetail.customer_details.billing_state_name}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </> : null}
                            </div>
                        </div>
                    </div>
                </div>
            }
            <LeadTaskUpdateDialog showDialog={showUpdatePopup} closeDialog={() => setShowUpdatePopup(false)} updateLead={() => { setUpdateLeadDetail(true) }} pageTitle={updatePageTitle} q1Heading={updateHeading} leadTaskUUID={leadTaskUUID} details={leadDetail} leadUUID={id} leadQualify={leadQualify} isVisit={isVisit} testdriveUUID={testdriveUUID} leadFollowupUUID={leadFollowupUUID} />
            <InterestedVehicleEntryDialog showDialog={showInterestedVehiclePopup} closeDialog={() => setShowInterestedVehiclePopup(false)} reloadData={() => { setUpdateLeadDetail(true) }} id={id} interestedUUID={interestedUUID} resetState={() => { setInterestedUUID(""); setLoadInterstedDetails(false) }} loadDetail={loadInterstedDetails} />
            <BookaCarEntryDialog showDialog={showBookaCarPopup} closeDialog={() => setShowBookaCarPopup(false)} id={id} reloadData={() => setUpdateLeadDetail(true)} resetState={() => setUpdateLeadDetail(true)} interestedUUID={interestedUUID} resetInterestedState={() => { setInterestedUUID(""); setLoadBookingDetails(false) }} loadDetail={loadBookingDetails} detailView={bookACarDetaiilView} />
            <ConfirmDialog showConfirm={showConfirm} confirmHeading={"Delete Interested Vehicle"} confirmMsg={'Are you sure to delete this interested vehicle?'} handleNo={() => {
                setInterestedUUID('');
                setShowConfirm(false);
            }} handleYes={() => {
                interestedVehicleDelete(interestedUUID, id ? id : "")
                setShowConfirm(false);
                setInterestedUUID('');
                setIntVehiclesMenuView([]);
            }} />
            <InvoiceDetailsEntryDialog showDialog={showInvoicePopup} closeDialog={() => setShowInvoicePopup(false)} id={id} interestedUUID={interestedUUID} reloadData={() => setUpdateLeadDetail(true)} invoiceUUID={invoiceUUID} resetState={() => { setInterestedUUID(""); setLoadBookingDetails(false) }} detailView={invoiceDetaiilView} loadBookDetail={loadBookingDetails} />
            <UpdateDeliveryDateEntryDialog showDialog={showUpdateDate} closeDialog={() => setShowUpdateDate(false)} reloadData={() => setUpdateLeadDetail(true)} invoiceUUID={invoiceUUID} id={id} resetInvoiceState={() => setInvoiceUUID("")} deliveryDate={invoiceDeliveryDate} />
            <UpdateLeadDialogEntry showDialog={showUpdateLead} closeDialog={() => setShowUpdateLead(false)} id={id} reloadData={() => setUpdateLeadDetail(true)} leadDetails={leadDetail} />
        </Drawer >
    )
}

export default UserLeadDetailDialog;

