import './App.css';
import AppNavigate from "./AppNavigate";
import { AppStateProvider } from './common/AppState';
import { ProSidebarProvider } from 'react-pro-sidebar';

function App() {
  return (
    <div className="App">
      <ProSidebarProvider>
        <AppStateProvider>
          <AppNavigate />
        </AppStateProvider>
      </ProSidebarProvider>
    </div>
  );
}

export default App;