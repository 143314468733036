import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { EndUser, PageLinks } from "../common/Constants";
import { persistLoginDetail, persistSelectedDealer } from "../common/Utilities";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import { useAppStateAPI } from "../common/AppStateAPI";
import OtpInput from 'react-otp-input';
import { APIData } from "../common/DataTypes";
// import Header from "../components/Header";

const Login: React.FC = () => {
    const location: any = useLocation();
    // const initialStep = location.state ? location.state.step : 0;
    // const initialEndUser = location.state ? location.state.endUser : EndUser.Dealer;
    const [endUser, setEndUser] = useState(EndUser.Dealer);
    const [step, setStep] = useState(0);
    const [dealer, setDealer] = useState(0);
    const [selectedDealer, setSelectedDealer] = useState('');
    const [otp, setOtp] = useState('');
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [companyCode, setCompanyCode] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [dealerDetails, setDealerDetails] = useState<APIData.DealerSelection>();
    const loginRole = localStorage.getItem("short_name");

    const navigate = useNavigate();
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const validateLogin = () => {

        if (endUser === EndUser.User) {
            if (!companyCode) {
                toast.error('Please Enter Company Code');
            } else if (!userPhone) {
                toast.error('Please Enter Phone Number');
            } else {
                addProcessingRequests();
                ServerAPI.userLogin(companyCode, userPhone).then((response: any) => {
                    if (response['success'] === true) {
                        toast.success(response['message']);
                        if (response.data && response.data.login_otp) {
                            setOtp(response.data.login_otp);
                        }
                        if (response.data && response.data.dealer_details) {
                            const dealer_details = response.data.dealer_details;
                            setDealerDetails(dealer_details);
                            if (dealer_details.dealers.length > 1) {
                                setStep(1);
                            } else {
                                setSelectedDealer(dealer_details.dealers[0]['dealer_code']);
                                setStep(2);
                            }
                        }
                    } else if (response['message']) {
                        toast.error(response['message']);
                    } else {
                        toast.error('Something Went Wrong');
                    }
                }).finally(() => {
                    reduceProcessingRequests();
                })
            }
        } else if (endUser === EndUser.Dealer) {
            if (!email) {
                toast.error('Please Enter Email');
            } else if (!password) {
                toast.error('Please Enter Password');
            } else {
                addProcessingRequests();
                ServerAPI.dealerLogin(email, password).then((response: any) => {
                    if (response['success'] === true) {
                        toast.success(response['message']);
                        if (response.data && response.data.login_otp) {
                            setOtp(response.data.login_otp);
                        }
                        setStep(2);
                    } else if (response['message']) {
                        toast.error(response['message']);
                    } else {
                        toast.error('Something Went Wrong');
                    }
                }).finally(() => {
                    reduceProcessingRequests();
                })
            }
        } else if (endUser === EndUser.Company) {
            if (!email) {
                toast.error('Please Enter Email');
            } else if (!password) {
                toast.error('Please Enter Password');
            } else {
                addProcessingRequests();
                ServerAPI.companyLogin(email, password).then((response: any) => {
                    if (response['success'] === true) {
                        toast.success(response['message']);
                        if (response.data && response.data.login_otp) {
                            setOtp(response.data.login_otp);
                        }
                        setStep(2);
                    } else if (response['message']) {
                        toast.error(response['message']);
                    } else {
                        toast.error('Something Went Wrong');
                    }
                }).finally(() => {
                    reduceProcessingRequests();
                })
            }
        }

    }

    const executeSendOTP = () => {
        if (endUser === EndUser.User) {
            if (!selectedDealer) {
                toast.error('Please select a Dealer');
            } else {
                addProcessingRequests();
                ServerAPI.userSendOtp(companyCode, selectedDealer, userPhone).then((response: any) => {
                    if (response['success'] === true) {
                        toast.success(response['message']);
                        setStep(2);
                    } else if (response['message']) {
                        toast.error(response['message']);
                    } else {
                        toast.error('Something Went Wrong');
                    }
                }).finally(() => {
                    reduceProcessingRequests();
                });
            }
        }
    }

    const executeVerifyOtp = () => {
        if (!otp) {
            toast.error('Enter OTP');
        } else {
            if (endUser === EndUser.User) {
                addProcessingRequests();
                ServerAPI.userVerifyOtp(companyCode, selectedDealer, userPhone, otp).then((response: any) => {
                    if (response['success'] === true) {
                        toast.success(response['message']);
                        persistSelectedDealer(selectedDealer);
                        const userDetail = response['data'];
                        persistLoginDetail(true, userDetail['uuid'], userDetail['dealer_code'], userDetail['name'], userDetail['contact_email'], userDetail['login_token'], userDetail['profile_image'], endUser, userDetail['company_code'], userDetail['contact_phone'], userDetail['short_name']);
                        if (userDetail['short_name'] === "SM") {
                            navigate(PageLinks.USER_INVENTORY);
                        } else {
                            navigate(PageLinks.USER_DASHBOARD_TODAY);
                        }
                    } else if (response['message']) {
                        toast.error(response['message']);
                    } else {
                        toast.error('Something Went Wrong');
                    }
                }).finally(() => {
                    reduceProcessingRequests();
                });
            } else if (endUser === EndUser.Dealer) {
                addProcessingRequests();
                ServerAPI.dealerVerifyOtp(email, otp).then((response: any) => {
                    if (response['success'] === true) {
                        toast.success(response['message']);
                        const userDetail = response['data'];
                        persistLoginDetail(true, userDetail['login_id'], userDetail['dealer_code'], userDetail['login_username'], userDetail['login_email'], userDetail['login_token'], userDetail['dealer_logo'], endUser, userDetail['company_code']);
                        navigate(PageLinks.DEALER_USERS);
                    } else if (response['message']) {
                        toast.error(response['message']);
                    } else {
                        toast.error('Something Went Wrong');
                    }
                }).finally(() => {
                    reduceProcessingRequests();
                });
            } else if (endUser === EndUser.Company) {
                addProcessingRequests();
                ServerAPI.companyVerifyOtp(email, otp).then((response: any) => {
                    if (response['success'] === true) {
                        toast.success(response['message']);
                        const userDetail = response['data'];
                        persistLoginDetail(true, userDetail['login_id'], userDetail['dealer_code'], userDetail['login_username'], userDetail['login_email'], userDetail['login_token'], userDetail['company_logo'], endUser);
                        navigate(PageLinks.COMPANY_DEALERS);
                    } else if (response['message']) {
                        toast.error(response['message']);
                    } else {
                        toast.error('Something Went Wrong');
                    }
                }).finally(() => {
                    reduceProcessingRequests();
                });
            }
        }
    }

    return (
        <>
            <div className="account-body">
                <div className="row vh-100 ">
                    <div className="col-12 align-self-center d-flex vh-100">
                        <div className="col-6 p-0">
                            <div className={endUser === EndUser.User ? "color-native-blue vh-100 custom-padding-login-left" : "color-native-light-blue vh-100 custom-padding-login-left"}>
                                <div>
                                    {endUser === EndUser.User ? <img src={window.location.origin + "/assets/images/logo512_white.png"} height="70" alt="logo" className="auth-logo login-logo" /> : <img src={window.location.origin + "/assets/images/logo512.png"} height="70" alt="logo" className="auth-logo login-logo" />}
                                </div>
                                <div className="small-spacer"></div>
                                <p className={endUser === EndUser.User ? "title-large" : "title-large text-black"}>Select Your Category</p>
                                <div className="py-2"></div>
                                {endUser === EndUser.User && (
                                    <div className="d-flex align-self-center justify-content-between w-70 m-auto">
                                        <button className="img-bg" onClick={() => { setEndUser(EndUser.User); setStep(0); setDealer(0); }} >
                                            <div className="logo logo-admin"><img src={window.location.origin + "/assets/images/user_blue.png"} height={180} width={150} alt="logo" className="auth-logo" />
                                            </div>
                                        </button>
                                        <button className="img-bg" onClick={() => { setEndUser(EndUser.Dealer); setStep(0); setDealer(0); }} >
                                            <div className="logo logo-admin"><img src={window.location.origin + "/assets/images/dealer_grey.png"} height={180}
                                                width={150} alt="logo" className="auth-logo" />
                                            </div>
                                        </button>
                                        <button className="img-bg" onClick={() => { setEndUser(EndUser.Company); setStep(0); setDealer(0); }} >
                                            <div className="logo logo-admin"><img src={window.location.origin + "/assets/images/company_grey.png"} height={180} width={150} alt="logo" className="auth-logo" />
                                            </div>
                                        </button>
                                    </div>
                                )}
                                {endUser === EndUser.Dealer && (
                                    <div className="d-flex align-self-center justify-content-between w-70 m-auto">
                                        <button className="img-bg border-grey" onClick={() => { setEndUser(EndUser.User); setStep(0); setDealer(0); }} >
                                            <div className="logo logo-admin"><img src={window.location.origin + "/assets/images/user_grey.png"} height={180} width={150} alt="logo" className="auth-logo" />
                                            </div>
                                        </button>
                                        <button className="img-bg border-blue" onClick={() => { setEndUser(EndUser.Dealer); setStep(0); setDealer(0); }} >
                                            <div className="logo logo-admin"><img src={window.location.origin + "/assets/images/dealer_blue.png"} height={180} width={150} alt="logo" className="auth-logo" />
                                            </div>
                                        </button>
                                        <button className="img-bg border-grey" onClick={() => { setEndUser(EndUser.Company); setStep(0); setDealer(0); }} >
                                            <div className="logo logo-admin"><img src={window.location.origin + "/assets/images/company_grey.png"} height={180} width={150} alt="logo" className="auth-logo" />
                                            </div>
                                        </button>
                                    </div>
                                )}
                                {endUser === EndUser.Company && (
                                    <div className="d-flex align-self-center justify-content-between w-70 m-auto">
                                        <button className="img-bg border-grey" onClick={() => { setEndUser(EndUser.User); setStep(0); setDealer(0); }} >
                                            <div className="logo logo-admin"><img src={window.location.origin + "/assets/images/user_grey.png"} height={180} width={150} alt="logo" className="auth-logo" />
                                            </div>
                                        </button>
                                        <button className="img-bg border-grey" onClick={() => { setEndUser(EndUser.Dealer); setStep(0); setDealer(0); }} >
                                            <div className="logo logo-admin"><img src={window.location.origin + "/assets/images/dealer_grey.png"} height={180} width={150} alt="logo" className="auth-logo" />
                                            </div>
                                        </button>
                                        <button className="img-bg border-blue" onClick={() => { setEndUser(EndUser.Company); setStep(0); setDealer(0); }} >
                                            <div className="logo logo-admin"><img src={window.location.origin + "/assets/images/company_blue.png"} height={180} width={150} alt="logo" className="auth-logo" />
                                            </div>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-6 p-0 d-flex-justify-center">
                            <div className="auth-card">
                                <div className="card-body p-0">
                                    {step === 0 && (
                                        <div>
                                            <div className="text-center auth-logo-text">
                                                <h4 className="mt-0 mb-3 mt-5">Welcome Back</h4>
                                                <p className="text-muted text-muted-user-action mb-0">Please Sign in to access your account</p>
                                            </div>
                                            <div className="py-2"></div>
                                            <form className="form-horizontal auth-form my-4" onSubmit={
                                                e => { e.preventDefault(); validateLogin(); }}>
                                                <div className="form-group text-left">
                                                    <div className="input-group mb-3">
                                                        {endUser === EndUser.User && (
                                                            <span className="auth-form-icon">
                                                                <i className="dripicons-store"></i>
                                                            </span>
                                                        )}
                                                        {endUser === EndUser.Dealer && (
                                                            <span className="auth-form-icon">
                                                                <i className="dripicons-user"></i>
                                                            </span>
                                                        )}
                                                        {endUser === EndUser.Company && (
                                                            <span className="auth-form-icon">
                                                                <i className="dripicons-user"></i>
                                                            </span>
                                                        )}
                                                        {endUser === EndUser.User && (
                                                            <input type="text" className="form-control" id="company_code" placeholder="Enter Company Code" onChange={(e) => setCompanyCode(e.target.value)} />
                                                        )}
                                                        {endUser === EndUser.Dealer && (
                                                            <input type="text" className="form-control" id="email" placeholder="Enter Dealer Email" onChange={(e) => setEmail(e.target.value)} />
                                                        )}
                                                        {endUser === EndUser.Company && (
                                                            <input type="text" className="form-control" id="email" placeholder="Enter Company Email" onChange={(e) => setEmail(e.target.value)} />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="py-1"></div>
                                                <div className="form-group text-left">
                                                    <div className="input-group mb-3">
                                                        {endUser === EndUser.User && (
                                                            <span className="auth-form-icon">
                                                                <i className="dripicons-phone"></i>
                                                            </span>
                                                        )}
                                                        {endUser === EndUser.Dealer && (
                                                            <span className="auth-form-icon">
                                                                <i className="dripicons-lock"></i>
                                                            </span>
                                                        )}
                                                        {endUser === EndUser.Company && (
                                                            <span className="auth-form-icon">
                                                                <i className="dripicons-lock"></i>
                                                            </span>
                                                        )}
                                                        {endUser === EndUser.User && (
                                                            <input type="text" className="form-control" id="phone" placeholder="Enter Phone No" onChange={(e) => setUserPhone(e.target.value)} />
                                                        )}
                                                        {endUser === EndUser.Dealer && (
                                                            <input type="password" className="form-control" id="password" placeholder="Enter Password" onChange={(e) => setPassword(e.target.value)} />
                                                        )}
                                                        {endUser === EndUser.Company && (
                                                            <input type="password" className="form-control" id="password" placeholder="Enter Password" onChange={(e) => setPassword(e.target.value)} />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="py-1"></div>
                                                <div className="form-group mb-0 row">
                                                    <div className="col-12 mt-2">
                                                        <button className="btn btn-login-primary btn-round btn-block waves-effect waves-light">Next</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    )}
                                    {step === 1 && (
                                        <div className="px-3">
                                            {dealerDetails !== undefined ?
                                                <>
                                                    <div className="text-center auth-logo-text">
                                                        <h4 className="mt-0 mb-3 mt-5">{dealerDetails.title}</h4>
                                                        <p className="text-muted text-muted-user-action mb-0">{dealerDetails.description}</p>
                                                    </div>
                                                    <div className="d-flex align-self-center justify-content-between w-70 m-auto py-4 dealer-select">
                                                        {dealerDetails.dealers.map((dealer, dealerIdx) => (
                                                            <button className={`img-bg ${dealer.dealer_code === selectedDealer ? 'border-blue' : 'border-grey'}`} onClick={() => { setSelectedDealer(dealer.dealer_code); }} key={dealerIdx} >
                                                                <div className="logo logo-admin"><img src={dealer.image_url} height={150} width={150} alt="logo" className="auth-logo" />
                                                                </div>
                                                            </button>
                                                        ))}
                                                    </div>
                                                    <div className="form-group mb-0 row">
                                                        <div className="col-12 mt-2">
                                                            <button onClick={executeSendOTP} className="btn btn-login-primary w-70 btn-block waves-effect waves-light">Next</button>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <div className="text-center auth-logo-text">
                                                    <h4 className="mt-0 mb-3 mt-5">Dealer Details not found</h4>
                                                </div>
                                            }
                                        </div>
                                    )}
                                    {step === 2 && (
                                        <div className="px-3">
                                            <div className="text-center auth-logo-text">
                                                <h4 className="mt-0 mb-3 mt-5">Verify OTP</h4>
                                                <p className="text-muted text-muted-user-action mb-0">We Send you a 4 digit code to associated mobile number with your account</p>
                                            </div>
                                            <form className="form-horizontal auth-form" onSubmit={e => {
                                                e.preventDefault();
                                                executeVerifyOtp();
                                            }}>
                                                <div className="d-flex justify-content-center mt-4 mb-0">
                                                    <OtpInput
                                                        value={otp}
                                                        onChange={setOtp}
                                                        numInputs={4}
                                                        renderSeparator={<span> </span>}
                                                        renderInput={(props) => <input {...props} className="otp-box" />}
                                                    />
                                                </div>
                                                <div className="form-group mb-0 row">
                                                    <div className="col-12 mt-2">
                                                        <button type="submit" className="btn btn-login-primary btn-round btn-block waves-effect waves-light w-70">Verify</button>
                                                    </div>
                                                </div>
                                                <div className="text-center auth-logo-text mt-4">
                                                    <p className="text-muted text-muted-user-action mb-0">Did'nt get a OTP? <span className="color-orange">Resend OTP</span></p>
                                                </div>
                                            </form>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;