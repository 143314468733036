import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { PageLinks } from '../common/Constants';
import Header from "../components/Header";
import ListTemplate from "./ListTemplate";
import AppSidebar from '../components/SideBar';
import Footer from "../components/Footer";
import { useEffect, useState } from 'react';

const PageTemplate: React.FC = () => {
    const location = useLocation();
    const [pageClass, setPageClass] = useState('');
    const navigate = useNavigate();
    const isAuthenticated = (): boolean => {
        const loginStatus = localStorage.getItem('login');
        if (loginStatus === null || loginStatus === undefined || loginStatus === "0") {
            return false;
        }
        return loginStatus === '1';
    }

    useEffect(() => {
        if (!isAuthenticated()) {
            navigate(PageLinks.LOGIN)
        }
    }, [isAuthenticated()])

    useEffect(() => {
        const pathname = location.pathname;
        if (pathname.includes(PageLinks.USER_DASHBOARD_TODAY) || pathname.includes(PageLinks.USER_DASHBOARD_ETVBRL) || pathname.includes(PageLinks.USER_TARGETS) || pathname.includes(PageLinks.USER_ENQUIRY_TRANSFER) || pathname.includes(PageLinks.USER_MY_LEADS) || pathname.includes(PageLinks.USER_MY_TASKS)) {
            setPageClass("page-content-1");
        } else {
            setPageClass("page-content");
        }
    }, [location]);

    return (
        <div>
            <div className="horizontal-active">
                <main className="hp-bg-color-dark-90 d-flex min-vh-100">
                    <AppSidebar />
                    <div className="hp-main-layout">
                        <div style={{ display: "flex" }}>
                            <div className="page-wrapper">
                                <div className={pageClass}>
                                    <div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card mb-0">
                                                    <div className="card-body">
                                                        <Header />
                                                        <Routes>
                                                            <Route path={PageLinks.LIST + "/*"} element={<ListTemplate />} />
                                                        </Routes>
                                                    </div>
                                                </div>
                                                <Footer />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                {/* <ScrollToTop /> */}
            </div>
        </div>
    )
}

export default PageTemplate;