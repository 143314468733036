import { toast } from "react-hot-toast";
import exportFromJSON from 'export-from-json';
import { FormDataTypes } from "./DataTypes";
import { ServerAPI } from "./ServerAPI";
import moment from "moment";
import { EndUser, PageLinks, ProjectConfig } from "./Constants";
import { TableViewMappings } from "./TableViewMappings";

export const getFormattedLocalDate = (utcDateTime: string) => {
    if (utcDateTime !== undefined && utcDateTime !== '' && utcDateTime != null) {
        return moment.utc(utcDateTime).local().format('DD/MM/YYYY');
    } else {
        return 'N/A';
    }
}

export const getFormattedLocalDateTime = (utcDateTime: string) => {
    if (utcDateTime && utcDateTime !== '') {
        return moment.utc(utcDateTime).utcOffset('05:30').format('DD/MM/YYYY hh:mm a');
        // return moment.utc(utcDateTime).local().format('DD/MM/YYYY hh:mm a');
    }
    return 'N/A';
}

export const getFormattedLocalTime = (utcDateTime: string) => {
    if (utcDateTime && utcDateTime !== '') {
        return moment.utc(utcDateTime).utcOffset('05:30').format('hh:mm a');
        // return moment.utc(utcDateTime).local().format('hh:mm a');
    }
    return 'N/A';
}

// export const formatNumberToIndianStyle = (number: number): string => {
//     const [integerPart, decimalPart] = number.toString().split('.');
//     let formattedNumber = '';

//     // Handle integer part formatting
//     const lastThreeDigits = integerPart.slice(-3);
//     const otherDigits = integerPart.slice(0, -3);

//     if (otherDigits !== '') {
//         formattedNumber = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + ',' + lastThreeDigits;
//     } else {
//         formattedNumber = lastThreeDigits;
//     }

//     // Add decimal part if exists
//     if (decimalPart) {
//         formattedNumber += '.' + decimalPart;
//     }

//     return formattedNumber;
// }

export const formatNumberToIndianStyle = (number: number | null | undefined): string => {
    if (number === null || number === undefined) {
        return '';
    }

    const [integerPart, decimalPart] = number.toString().split('.');
    let formattedNumber = '';

    // Handle integer part formatting
    const lastThreeDigits = integerPart.slice(-3);
    const otherDigits = integerPart.slice(0, -3);

    if (otherDigits !== '') {
        formattedNumber = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + ',' + lastThreeDigits;
    } else {
        formattedNumber = lastThreeDigits;
    }

    // Add decimal part if exists
    if (decimalPart) {
        formattedNumber += '.' + decimalPart;
    }

    return formattedNumber;
}


export const getStringTruncated = (data: string) => {
    if (data !== undefined && data !== '' && data !== null) {
        let txt = data;
        if (txt.length > ProjectConfig.tableCellTruncateLength) {
            let newText = txt.substr(0, ProjectConfig.tableCellTruncateLength) + ' ...';
            data = newText;
        }
        return data;
    } else {
        return ''
    }
}

export const persistLoginDetail = (login: boolean, loginID: string, dealerCode: string, username: string, emailID: string, token: string, logo: string, userType: number, companyCode?: string, userPhone?: string, shortName?: string) => {
    localStorage.setItem('login', login ? '1' : '0');
    localStorage.setItem('login_id', loginID);
    localStorage.setItem('dealer_code', dealerCode);
    localStorage.setItem('login_username', username);
    localStorage.setItem('login_email', emailID);
    localStorage.setItem('login_token', login && token ? token : '');
    localStorage.setItem('login_logo', logo);
    localStorage.setItem('user_type', userType + "");
    localStorage.setItem('company_code', companyCode ? companyCode : '');
    localStorage.setItem('login_number', userPhone ? userPhone : '');
    localStorage.setItem('short_name', shortName ? shortName : '');
}

export const getLoginUserID = () => {
    let UserID = localStorage.getItem('login_id');
    return UserID ? UserID : "";
}

export const persistSelectedDealer = (dealer: string) => {
    localStorage.setItem("login_dealer", dealer)
}

export const logout = () => {
    localStorage.removeItem('login');
    localStorage.removeItem('login_id');
    localStorage.removeItem('dealer_code');
    localStorage.removeItem('login_username');
    localStorage.removeItem('login_email');
    localStorage.removeItem('login_token');
    localStorage.removeItem('login_logo');
    localStorage.removeItem('login_number');
    localStorage.removeItem('login_dealer')
    localStorage.removeItem('profile_image')
    localStorage.removeItem('company_logo')
}

export const getUserToken = () => {
    let token = localStorage.getItem('login_token');
    if (token === null) {
        token = "";
    }
    return token;
}

export const getDealerCode = () => {
    let dealerCode = localStorage.getItem('dealer_code');
    if (dealerCode === null) {
        dealerCode = "";
    }
    return dealerCode;
}

export const getCompanyCode = () => {
    let companyCode = localStorage.getItem('company_code');
    if (companyCode === null) {
        companyCode = "";
    }
    return companyCode;
}

export const getSelectedDealer = () => {
    let dealer = localStorage.getItem('login_dealer')
    if (dealer === null) {
        dealer = ''
    }
    return dealer
}

export const getLoginUserName = () => {
    let loginUserName = localStorage.getItem('login_username');
    if (loginUserName === null) {
        loginUserName = "";
    }
    return loginUserName;
}
export const getLoginUserNumber = () => {
    let loginUserNumber = localStorage.getItem('login_number');
    if (loginUserNumber === null) {
        loginUserNumber = "";
    }
    return loginUserNumber;
}

export const isUserLoggedIn = () => {
    const loginData = localStorage.getItem('login') === '1' ? true : false;
    return loginData;
}

export const getLoginUserType = () => {
    const userType = localStorage.getItem('user_type');
    return userType ? parseInt(userType) : 0;
}

export const getLoginLogo = () => {
    const loginLogo = localStorage.getItem('login_logo');
    return loginLogo ? loginLogo : window.location.origin + "/assets/images/blank-profile.png";
}

export const getUserTypeDashboardLink = () => {
    const userType = getLoginUserType();
    if (userType === EndUser.Dealer) {
        return PageLinks.DEALER_USERS;
    } else if (userType === EndUser.Company) {
        return PageLinks.COMPANY_DEALERS;
    } else if (userType === EndUser.SuperAdmin) {
        return PageLinks.ADMIN_USERS;
    }
    return '';
}

export const frameSelectOptions = (data: any[], value_param: string, label_param: string, add_labels?: string[], separator?: string, separatorEnd?: string) => {
    const selectOptions: FormDataTypes.SelectOption[] = data.map((entry) => {
        let labelData = entry[label_param];
        if (add_labels) {
            add_labels.forEach(add_label => {
                if (entry[add_label]) {
                    labelData += (separator ? separator : '-') + entry[add_label] + (separatorEnd ? separatorEnd : '');
                }
            })
        }

        return (
            {
                value: entry[value_param] + "",
                label: labelData
            }
        )
    });
    return selectOptions;
}

export const getDataFromAPI = (apiEndPoint: string, stateSetter: any, addProcessing: any, reduceProcessing: any, params?: any, convertToSelectOptions?: boolean, key?: string, value?: string, responseKeyLevel1?: string, responseKeyLevel2?: string) => {
    const respKeyLevel1 = responseKeyLevel1 !== undefined ? responseKeyLevel1 : 'data';
    addProcessing();
    ServerAPI.executeAPI(apiEndPoint, ServerAPI.APIMethod.GET, true, null, params).then(response => {
        let responseData: any[] = [];
        if (responseKeyLevel2 !== undefined) {
            responseData = response[respKeyLevel1][responseKeyLevel2];
        } else {
            responseData = response[respKeyLevel1];
        }
        if (responseData !== undefined) {
            if (convertToSelectOptions) {
                let options = frameSelectOptions(responseData, key ? key : '', value ? value : '');
                stateSetter(options);
            } else {
                stateSetter(responseData);
            }

        } else {
            toast.error('Could not fetch the data');
        }
    }).finally(() => {
        reduceProcessing();
    });
}

export const ExportExcel = (data: any, fileName: string, exportFields: TableViewMappings.TableColumn[] = []) => {
    const exportType = exportFromJSON.types.csv;
    var exportData: any = [];
    for (let index in data) {
        var excelData: any = {
            "SNo": parseInt(index) + 1
        };
        exportFields.forEach(exportField => {
            excelData[exportField.label] = '';

            if (data[index][exportField.data_key]) {
                excelData[exportField.label] = data[index][exportField.data_key];
            }
            if (excelData[exportField.label]) {
                if (exportField.type === 'date') {
                    excelData[exportField.label] = getFormattedLocalDate(excelData[exportField.label]);
                } else if (exportField.type === 'datetime') {
                    excelData[exportField.label] = getFormattedLocalDateTime(excelData[exportField.label]);
                }
            }
        });
        exportData.push(excelData);
    }
    exportFromJSON({ data: exportData, fileName, exportType })
}

export const getFileTypeIcon = (fileType: string) => {
    if (fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        return window.location.origin + "/assets/images/file_types/xls.png";
    } else if (fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        return window.location.origin + "/assets/images/file_types/doc.png";
    } else if (fileType === 'application/pdf') {
        return window.location.origin + "/assets/images/file_types/pdf.png";
    } else if (fileType === 'application/vnd.ms-powerpoint' || fileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
        return window.location.origin + "/assets/images/file_types/ppt.png";
    } else {
        return window.location.origin + "/assets/images/file_types/unknown.png";
    }
}

export const getURLExtension = (url: string) => {
    if (typeof url === 'string') {
        return url.split(/[#?]/)[0].split('.').pop()?.trim();
    }
    return '';
}

export const getDisplayImageByURL = (url: string) => {
    const extn = getURLExtension(url);
    if (extn === 'png' || extn === 'jpg' || extn === 'jpeg') {
        return url;
    } else if (extn === 'xls' || extn === 'xlsx' || extn === 'xlsm' || extn === 'xlsb' || extn === 'xltx' || extn === 'csv') {
        return window.location.origin + "/assets/images/file_types/xls.png";
    } else if (extn === 'doc' || extn === 'docx' || extn === 'odt') {
        return window.location.origin + "/assets/images/file_types/doc.png";
    } else if (extn === 'pdf') {
        return window.location.origin + "/assets/images/file_types/pdf.png";
    } else if (extn === 'ppt' || extn === 'pptx' || extn === 'pptm') {
        return window.location.origin + "/assets/images/file_types/ppt.png";
    } else {
        return window.location.origin + "/assets/images/file_types/unknown.png";
    }
}

// Helper function to compare arrays
export const isArrayItemsMatches = (array1: string[], array2: string[]) => {
    return array1.length === array2.length && array1.every((value, index) => value === array2[index]);
}

export const frameAddress = (addressComponents: string[], pincode?: string): string => {
    let dataComponents = addressComponents.filter((value, index) => !!value);
    let returnAddress = dataComponents.join(', ');
    returnAddress += pincode ? '-' + pincode : '';
    return returnAddress;
}