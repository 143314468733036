import { Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { API, ProjectStrings } from "../common/Constants";
import { getDataFromAPI } from "../common/Utilities";

const LeadIntVehicleBookEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, detailView?: boolean }> = ({ showDialog, closeDialog, reloadData, id, detailView }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.VehicleBookingDetail>>(null);
    const [initialValue, setInitialValue] = useState<APIData.VehicleBookingDetail>();
    const [formDataSet, setFormDataSet] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [modelOptions, setModelOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [variantOptions, setVariantOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [exteriorColorOptions, setExteriorColorOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [interiorColorOptions, setInteriorColorOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [fuelTypeOptions, setFuelTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [customerTypeOptions, setCustomerTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [customerCityOptions, setCustomerCityOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [customerStateOptions, setCustomerStateOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [customerPaymentOptions, setCustomerPaymentOptions] = useState<FormDataTypes.SelectOption[]>([]);

    const exchangeOptions: FormDataTypes.SelectOption[] = [{ label: 'Yes', value: "1" }, { label: "No", value: "0" }];
    const warrantyOptions: FormDataTypes.SelectOption[] = [{ label: 'Yes', value: "1" }, { label: "No", value: "0" }];
    const financeOptions: FormDataTypes.SelectOption[] = [{ label: 'In House Finance', value: "1" }, { label: "Out House Finance", value: "2" }, { label: "No Finance", value: "3" }];

    const [isCarDetailsVisible, setCarDetailsVisible] = useState(false);
    const [isCustomerDetailsVisible, setCustomerDetailsVisible] = useState(false);
    const [isPriceBreakupVisible, setPriceBreakupVisible] = useState(false);
    const [isDiscountVisible, setDiscountVisible] = useState(false);
    const [isExchangeFinanceEWVisible, setExchangeFinanceEWVisible] = useState(false);
    const [isFinalBookingDetailsVisible, setFinalBookingDetailsVisible] = useState(false);
    const [selectedCustomerStateID, setSelectedCustomerStateID] = useState('');
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.ModelList, setModelOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'model_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.ModelVariantList, setVariantOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'variant_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.InteriorColorList, setInteriorColorOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'interior_color_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.ExteriorColorList, setExteriorColorOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'exterior_color_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.FuelTypeList, setFuelTypeOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'fuel_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.CustomerTypeList, setCustomerTypeOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'customer_type_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.StateList, setCustomerStateOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'state_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.PaymentModeList, setCustomerPaymentOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'payment_mode_name', 'data', 'list');
    }, []);

    useEffect(() => {
        if (id) {
            addProcessingRequests();
            ServerAPI.getLeadBookingDetail(id).then(response => {
                if (response && response.data && response.data.details && response.data.details.length > 0) {
                    const bookingDetail = response.data.details[0];
                    bookingDetail.car_details.vin_number = (bookingDetail.car_details.vin_number !== null) ? bookingDetail.car_details.vin_number : '';
                    setInitialValue({
                        booking_car_details: bookingDetail.car_details,
                        booking_customer_details: bookingDetail.customer_details,
                        price_breakup: bookingDetail.price_breakup,
                        booking_discounts: bookingDetail.discount,
                        exchange_finance_ew: bookingDetail.exchange_finance_ew,
                        lead_booking_final_details: bookingDetail.lead_booking_final_details
                    });
                    setFormDataSet(true);
                    setEditMode(true);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }, [id]);

    useEffect(() => {
        if (initialValue) {
            setSelectedCustomerStateID(initialValue.booking_customer_details.state_uuid);
        }
    }, [initialValue]);

    useEffect(() => {
        if (selectedCustomerStateID) {
            let params: { [k: string]: any } = {};
            params["limit"] = 10000;
            params["status"] = 1;
            params["state_uuid"] = selectedCustomerStateID;
            getDataFromAPI(API.EndPoint.CityList, setCustomerCityOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'city_name', 'data', 'list');
        } else {
            setCustomerCityOptions([]);
        }
    }, [selectedCustomerStateID]);

    const yupSchema = Yup.object().shape({
        vehicle_model_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        vehicle_variant_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        vehicle_exterior_color_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        vehicle_interior_color_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        vehicle_fuel_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        carDetailsInput1: Yup.string().required('Required'),
        carDetailsInput2: Yup.string().required('Required'),
    });

    // sum of discount
    function calculateDiscountSum(values: APIData.VehicleBookingDetail): string {
        const discountFields: (keyof APIData.VehicleBookingDetail['booking_discounts'])[] = [
            'exchange_loyalty_bonus',
            'corporate',
            'dealer_discount',
            'referral_bonus',
            'oem_scheme',
            'others',
        ];

        const sum = discountFields.reduce((acc, field) => {
            const fieldValue = parseFloat(values.booking_discounts[field]) || 0;
            return acc + fieldValue;
        }, 0);

        return sum.toLocaleString('en-IN');
    }


    //sum of price
    function calculatePriceSum(values: APIData.VehicleBookingDetail): string {
        const priceFields: (keyof APIData.VehicleBookingDetail['price_breakup'])[] = [
            'ex_showroom_price',
            'insurance',
            'registration_charges',
            'accessories_charges',
            'tcs_charges',
            'vas_charges',
            'logistics_handling_charges',
            'extended_warranty',
            'csvp_service_plan',
            'others',
        ];

        const sum = priceFields.reduce((acc, field) => {
            const fieldValue = parseFloat(values.price_breakup[field]) || 0;
            return acc + fieldValue;
        }, 0);

        return sum.toLocaleString('en-IN');
    }

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-sub">
            {formDataSet && initialValue !== undefined && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };
                    if (id != undefined && id != '') {
                        addProcessingRequests();
                        ServerAPI.updateLeadInterestedVehicle(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            } else {
                                toast.error('Unknown Error Occurred');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    } else {
                        addProcessingRequests();
                        ServerAPI.addLeadInterestedVehicle(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            } else {
                                toast.error('Unknown Error Occurred');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    }
                }} >
                    {(formikProps) => {
                        return (
                            <div className="secondary-popup">
                                <div className="secondary-popup-header">
                                    <span className="auth-form-icon">
                                        <i className="dripicons-chevron-left"></i>
                                    </span>
                                    <p className="secondary-popup-heading">Book a Car</p>
                                    <p>&nbsp;</p>
                                    {/* <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18C0 8.05887 8.05887 0 18 0C27.9411 0 36 8.05887 36 18ZM2.7 18C2.7 26.45 9.55004 33.3 18 33.3C26.45 33.3 33.3 26.45 33.3 18C33.3 9.55004 26.45 2.7 18 2.7C9.55004 2.7 2.7 9.55004 2.7 18Z" fill="white" />
                                        <path d="M34.65 18C35.3956 18 36.0053 18.6053 35.9494 19.3488C35.7473 22.0383 34.9428 24.6543 33.5885 27C32.2341 29.3457 30.371 31.3505 28.1428 32.8703C27.5268 33.2904 26.6978 33.065 26.325 32.4193C25.9522 31.7736 26.1776 30.9527 26.7879 30.5245C28.6082 29.2473 30.1329 27.5852 31.2502 25.65C32.3675 23.7148 33.0445 21.5634 33.2405 19.3483C33.3062 18.6056 33.9044 18 34.65 18Z" fill="#F88906" />
                                        <path d="M14.108 22.184V23H9.5V22.184H11.342V16.322C11.342 16.146 11.348 15.968 11.36 15.788L9.83 17.102C9.778 17.146 9.726 17.174 9.674 17.186C9.622 17.194 9.574 17.194 9.53 17.186C9.486 17.178 9.444 17.162 9.404 17.138C9.368 17.114 9.34 17.088 9.32 17.06L8.984 16.598L11.546 14.384H12.416V22.184H14.108Z" fill="white" />
                                        <path d="M16.3949 23.126C16.3429 23.266 16.2609 23.37 16.1489 23.438C16.0409 23.506 15.9289 23.54 15.8129 23.54H15.3629L18.9689 14.558C19.0169 14.43 19.0889 14.332 19.1849 14.264C19.2849 14.196 19.3989 14.162 19.5269 14.162H19.9829L16.3949 23.126ZM23.8547 22.208C24.1427 22.208 24.4027 22.162 24.6347 22.07C24.8707 21.978 25.0707 21.85 25.2347 21.686C25.4027 21.522 25.5307 21.328 25.6187 21.104C25.7107 20.88 25.7567 20.636 25.7567 20.372C25.7567 20.092 25.7127 19.84 25.6247 19.616C25.5367 19.388 25.4107 19.196 25.2467 19.04C25.0867 18.88 24.8947 18.758 24.6707 18.674C24.4467 18.59 24.2007 18.548 23.9327 18.548C23.6447 18.548 23.3847 18.598 23.1527 18.698C22.9207 18.794 22.7227 18.926 22.5587 19.094C22.3987 19.258 22.2747 19.45 22.1867 19.67C22.1027 19.886 22.0607 20.112 22.0607 20.348C22.0607 20.624 22.1007 20.876 22.1807 21.104C22.2607 21.332 22.3767 21.528 22.5287 21.692C22.6847 21.856 22.8727 21.984 23.0927 22.076C23.3167 22.164 23.5707 22.208 23.8547 22.208ZM23.2307 17.624C23.1547 17.72 23.0807 17.814 23.0087 17.906C22.9407 17.994 22.8747 18.082 22.8107 18.17C23.0147 18.03 23.2387 17.922 23.4827 17.846C23.7267 17.77 23.9927 17.732 24.2807 17.732C24.6287 17.732 24.9567 17.79 25.2647 17.906C25.5727 18.018 25.8407 18.184 26.0687 18.404C26.3007 18.62 26.4847 18.888 26.6207 19.208C26.7567 19.524 26.8247 19.886 26.8247 20.294C26.8247 20.69 26.7527 21.058 26.6087 21.398C26.4647 21.738 26.2627 22.034 26.0027 22.286C25.7467 22.538 25.4367 22.736 25.0727 22.88C24.7127 23.024 24.3147 23.096 23.8787 23.096C23.4467 23.096 23.0547 23.028 22.7027 22.892C22.3507 22.752 22.0507 22.556 21.8027 22.304C21.5587 22.048 21.3687 21.74 21.2327 21.38C21.1007 21.016 21.0347 20.61 21.0347 20.162C21.0347 19.786 21.1167 19.386 21.2807 18.962C21.4487 18.534 21.7147 18.078 22.0787 17.594L24.2627 14.66C24.3187 14.588 24.3947 14.528 24.4907 14.48C24.5907 14.428 24.7047 14.402 24.8327 14.402H25.7807L23.2307 17.624Z" fill="white" fill-opacity="0.6" />
                                    </svg> */}
                                </div>
                                <div>

                                    {renderSection("Car Details", isCarDetailsVisible, setCarDetailsVisible, [
                                        <>
                                            <div className="text-field-empty-custom-user-3">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Model", name: "booking_car_details.model_uuid", placeholder: "Model", options: modelOptions, required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            </div>
                                            <div className="text-field-empty-custom-user-3">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Variant", name: "booking_car_details.variant_uuid", placeholder: "Variant", options: variantOptions, required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            </div>
                                            <div className="text-field-empty-custom-user-3">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Exterior Color", name: "booking_car_details.exterior_color_uuid", placeholder: "Exterior Color", options: exteriorColorOptions, required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            </div>
                                            <div className="text-field-empty-custom-user-3">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Interior Color", name: "booking_car_details.interior_color_uuid", placeholder: "Interior Color", options: interiorColorOptions, required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            </div>
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "VIN", name: "booking_car_details.vin_number", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Booking Amount", name: "booking_car_details.booking_amount", placeholder: "Enter Booking Amount", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Booking Amount Payment Mode*", name: "booking_car_details.payment_mode_name", placeholder: "Enter Booking Amount Payment Mode", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                        </>
                                        // Add more input fields for Car Details...
                                    ])}
                                    {renderSection("Customer Details", isCustomerDetailsVisible, setCustomerDetailsVisible, [
                                        <>
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Booking Name", name: "booking_customer_details.booking_name", placeholder: "Booking Name", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Mobile Number", name: "booking_customer_details.mobile_number", placeholder: "Mobile Number", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Email Id", name: "booking_customer_details.email_id", placeholder: "Email Id", required: true, readOnly: (editMode || detailView) ? true : false }} />

                                            <div className="row">
                                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom-user-3">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Customer Type", name: "booking_customer_details.customer_type_uuid", placeholder: "Type", options: customerTypeOptions, required: true, readOnly: (editMode || detailView) ? true : false }} />
                                                </div>
                                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-custom">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Date of Birth", name: "booking_customer_details.date_of_birth", placeholder: "Date of Birth", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                                </div>
                                            </div>
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "PAN Number", name: "booking_customer_details.pan_number", placeholder: "PAN Number", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Aadhaar/GST Number", name: "booking_customer_details.aadhaar_gst_number", placeholder: "Aadhaar/GST Number*", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Address", name: "booking_customer_details.address", placeholder: "Address", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <div className="row">
                                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Pincode", name: "booking_customer_details.pincode", placeholder: "Pincode", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                                </div>
                                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom-user-3">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "City", name: "booking_customer_details.city_uuid", placeholder: "City", options: customerCityOptions, required: true, readOnly: (editMode || detailView) ? true : false }} />
                                                </div>
                                                <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom-user-3">
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "State", name: "booking_customer_details.state_uuid", placeholder: "State", options: customerStateOptions, required: true, readOnly: (editMode || detailView) ? true : false }} />
                                                </div>
                                            </div>


                                        </>
                                        // Add more input fields for Car Details...
                                    ])}

                                    {renderSection("Price Break-Up", isPriceBreakupVisible, setPriceBreakupVisible, [
                                        <>
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Ex Showroom Price", name: "price_breakup.ex_showroom_price", placeholder: "Enter Ex Showroom Price", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Insurance", name: "price_breakup.insurance", placeholder: "Enter Insurance", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Registration Charges", name: "price_breakup.registration_charges", placeholder: "Enter Registration Amount", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Accessories Charges", name: "price_breakup.accessories_charges", placeholder: "Enter Accessories Amount", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "TCS Charges@__%", name: "price_breakup.tcs_charges", placeholder: "Enter TCS Amount", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "VAS Charges", name: "price_breakup.vas_charges", placeholder: "Enter Logistics & Handling Amount", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Logistics & Handling Charges", name: "price_breakup.logistics_handling_charges", placeholder: "Enter Logistics & Handling Amount", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Extended Warranty", name: "price_breakup.extended_warranty", placeholder: "Enter Extended Warranty Amount", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "CSVP/Service Plan", name: "price_breakup.csvp_service_plan", placeholder: "Enter CSVP/Service Plan Amount", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Others", name: "price_breakup.others", placeholder: "Enter Sum Total of all Other Amounts", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                        </>
                                        // Add more input fields for Car Details...
                                    ], undefined, calculatePriceSum(formikProps.values))}

                                    {renderSection("Discount", isDiscountVisible, setDiscountVisible, [
                                        <>
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Exchange / Loyalty Bonus", name: "booking_discounts.exchange_loyalty_bonus", placeholder: "Enter Exchange/Loyalty Bonus Amount", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Corporate", name: "booking_discounts.corporate", placeholder: "Enter Corporate Discount", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Dealer Discount", name: "booking_discounts.dealer_discount", placeholder: "Enter Dealer Discount", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Referral Bonus", name: "booking_discounts.referral_bonus", placeholder: "Enter Referral Bonus Amount", required: true, readOnly: (editMode || detailView) ? true : false }} />

                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "OEM Scheme", name: "booking_discounts.oem_scheme", placeholder: "Enter OEM Scheme Amount", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Others", name: "booking_discounts.others", placeholder: "Enter Any Other Discount Amount", required: true, readOnly: (editMode || detailView) ? true : false }} />  </>
                                        // Add more input fields for Car Details...
                                    ], calculateDiscountSum(formikProps.values))}

                                    {renderSection("Exchange / Finance / EW", isExchangeFinanceEWVisible, setExchangeFinanceEWVisible, [
                                        <>
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "radio", label: "Did customer exchange any vehicle?", name: "exchange_finance_ew.want_to_change_vehicle", options: exchangeOptions, required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            {formikProps.values.exchange_finance_ew.want_to_change_vehicle === "1" && (
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "checkbox", label: "Added \"Exchange Vehicle Details\" for this Lead", name: "exchange_finance_ew.added_exchange_vehicle_details_for_this_lead", readOnly: (editMode || detailView) ? true : false }} />
                                            )}
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "radio", label: "Did customer opt for finance?", name: "exchange_finance_ew.did_customer_opt_for_finance", options: financeOptions, required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            {(formikProps.values.exchange_finance_ew.did_customer_opt_for_finance === "1" || formikProps.values.exchange_finance_ew.did_customer_opt_for_finance === "2") && (
                                                <>
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Financier Name", name: "exchange_finance_ew.financier_name", placeholder: "Enter Financier Name", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Loan Amount", name: "exchange_finance_ew.loan_amount", placeholder: "Enter Loan amount", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Loan Tenure", name: "exchange_finance_ew.loan_tenure", placeholder: "Enter Loan Tenure", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                                </>
                                            )}
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "radio", label: "Has customer bought extended warranty?", name: "exchange_finance_ew.has_customer_bought_extended_warranty", options: warrantyOptions, required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            {formikProps.values.exchange_finance_ew.has_customer_bought_extended_warranty === "1" && (
                                                // <FormField formik={formikProps} fieldProps={{ fieldType: "datepicker", label: "Extended Warranty End Date", name: "exchange_finance_ew.extended_warranty_end_date", placeholder: "Enter EW End Date", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                                <div className="ew-section">
                                                    <div className="text-field-empty-custom-user" style={{ marginTop: "12px" }}>
                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "datepicker", label: "Extended Warranty End Date", name: "exchange_finance_ew.extended_warranty_end_date", placeholder: "Enter EW End Date", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ])}

                                    {renderSection("Final Booking Details", isFinalBookingDetailsVisible, setFinalBookingDetailsVisible, [
                                        <>
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Final On Road Price", name: "lead_booking_final_details.final_on_road_price", placeholder: "Enter Final On Road Price", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Booking Amount", name: "lead_booking_final_details.booking_amount", placeholder: "Enter Booking Amount", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Pending Total Invoice Amount", name: "lead_booking_final_details.pending_total_invoice_amount", placeholder: "Enter Pending Total Invoice Amount*", required: true, readOnly: (editMode || detailView) ? true : false }} />
                                            <div className="text-field-empty-custom-user-3">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Booking Amount Payment Mode*", name: "lead_booking_final_details.payment_mode_uuid", placeholder: "Enter Booking Amount Payment Mode*", required: true, options: customerPaymentOptions, readOnly: (editMode || detailView) ? true : false }} />
                                            </div>
                                        </>
                                        // Add more input fields for Car Details...
                                    ])}
                                </div>
                                <div className="row btn-form-submit">
                                    <button className="btn-back" onClick={closeDialog}>Back</button>
                                    <button className="btn-submit" onClick={() => { }}>Save & Next</button>
                                </div>
                            </div>
                        )
                    }}
                </Formik>
            )}
        </Drawer >
    )

    function renderSection(sectionName: string, isVisible: boolean, setVisible: React.Dispatch<React.SetStateAction<boolean>>, inputFields: JSX.Element[], discountValue?: string, priceValue?: string) {
        return (
            <div>
                <div className="d-flex-justify-between" style={{ padding: "15px" }}>
                    <p className="vehicle-detail-heading">{sectionName}</p>
                    {/* {sectionName === "Price Break-Up" || sectionName === "Discount" ? <span style={{ position: "absolute", right: "4rem" }}>hello</span> : ""} */}
                    {sectionName === "Discount" && discountValue !== undefined ? <span style={{ position: "absolute", right: "4rem" }}>-{discountValue}</span> : ""}
                    {sectionName === "Price Break-Up" && priceValue !== undefined ? <span style={{ position: "absolute", right: "4rem" }}>{priceValue}</span> : ""}
                    <div className="d-flex-justify-between" style={{ width: "50px" }}>
                        {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z" fill="#E84743" />
                        </svg> */}
                        <p>&nbsp;</p>
                        <button className="vehicle-detail-open-close-btn" onClick={() => setVisible(!isVisible)}>
                            <span className="auth-form-icon">
                                {inputFields.map((field, index) => (
                                    <>
                                        <i key={`icon-${index}`} className={`fa-solid ${field.props.value === "" || field.props.value === 0 ? "fa-circle-xmark" : "fa-circle-check"}`} style={{ color: field.props.value === "" || field.props.value === 0 ? "red" : "green", marginRight: "10px", marginTop: "-10px", }}></i>
                                    </>
                                ))}
                                <i className={`dripicons-chevron-${isVisible ? 'up' : 'down'}`}></i>
                            </span>
                        </button>
                    </div>
                </div>
                {isVisible && (
                    <div>
                        {inputFields.map((field, index) => (
                            <div key={index} style={{ margin: '0 10px', padding: "10px" }} className="vehicle-detail-textbox">
                                {field}
                                <ErrorMessage name={`error-${index}`} component="div" className="error-message" />
                            </div>
                        ))}
                    </div>
                )}
                <div className="border-line-split-two"></div>

            </div>
        );
    }
}

export default LeadIntVehicleBookEntryDialog;