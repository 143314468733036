import { useState } from "react";
import TestDriveMobileEditDialog from "../../forms/TestDriveMobileEditDialog";

const CellCustomerDetailWithEdit: React.FC<{ data: any, reloadData: () => void }> = ({ data, reloadData }) => {

    const [showMobileEdit, setShowMobileEdit] = useState(false);
    return (
        <>
            {data['customer_name']}<br /><small style={{ display: "flex", alignItems: "center" }}>
                {data['mobile_number']} <button type="button" className="btn" title="Edit" onClick={() => setShowMobileEdit(true)}><i className="fas fa-edit text-info font-5"></i></button>
            </small>
            {showMobileEdit &&
                <TestDriveMobileEditDialog showDialog={showMobileEdit} closeDialog={() => setShowMobileEdit(false)} reloadData={reloadData} id={data.uuid} mobileNumber={data.mobile_number} />
            }
        </>
    )
}

export default CellCustomerDetailWithEdit;