import { Drawer, Tabs, Tab, Button } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from 'yup';
import React, { useEffect, useRef, useState } from "react";
import FormField from "../components/form_items/FormField";
import { InitialData } from "./InitialData";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { API, ProjectStrings } from "../common/Constants";
import { getDataFromAPI } from "../common/Utilities";
import { useAppStateAPI } from "../common/AppStateAPI";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";

const InvoiceDetailsEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, resetInterestedState?: () => void, id?: string, detailView?: boolean, resetState: () => void, invoiceUUID?: string, interestedUUID?: string, loadBookDetail?: boolean }> = ({ showDialog, closeDialog, id, interestedUUID, invoiceUUID, resetState, reloadData, detailView, loadBookDetail }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [initialValue, setInitialValue] = useState<APIData.InvoiceDetails>(InitialData.InvoiceDetails);
    const formikRef = useRef<FormikProps<APIData.InvoiceDetails>>(null);
    const [formDataSet, setFormDataSet] = useState(false);
    const [modelOptions, setModelOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [insuranceTypeOptions, setInsuranceTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [insuranceCompaniesOptions, setInsuranceCompaniesOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [variantOptions, setVariantOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [exteriorColorOptions, setExteriorColorOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [interiorColorOptions, setInteriorColorOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [fuelTypeOptions, setFuelTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [customerTypeOptions, setCustomerTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [customerCityOptions, setCustomerCityOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [customerStateOptions, setCustomerStateOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [customerPaymentOptions, setCustomerPaymentOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedCustomerStateID, setSelectedCustomerStateID] = useState('');
    const [selectedStateID, setSelectedStateID] = useState('');
    const [selectedCityID, setSelectedCityID] = useState('');
    const [selectedPincode, setSelectedPincode] = useState<number | undefined>();
    const [landmark, setlandmark] = useState<FormDataTypes.SelectOption[] | undefined>([]);
    const invoiceDetailsRef = useRef<HTMLDivElement | null>(null);

    const currentYear = new Date().getFullYear();
    const last50Years = Array.from({ length: 50 }, (_, index) => currentYear - index);


    const modelYearOptions = last50Years.map(year => ({
        value: year.toString(),
        label: year.toString(),
    }));

    const yupSchema = Yup.object().shape({
        car_details: Yup.object().shape({
            model_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            variant_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            exterior_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            interior_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            booking_amount: Yup.string().required(ProjectStrings.ValidationRequired),
            booking_amount_payment_mode_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        }),
        customer_details: Yup.object().shape({
            booking_name: Yup.string().required(ProjectStrings.ValidationRequired),
            mobile_number: Yup.string().matches(/^[6-9]\d{9}$/, { message: "Please enter valid number.", excludeEmptyString: false }).required(ProjectStrings.ValidationRequired),
            email_id: Yup.string().matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, { message: "Please enter valid Email.", excludeEmptyString: false }).required(ProjectStrings.ValidationRequired),
            customer_type_uuid: Yup.string().required(ProjectStrings.ValidationRequired),
            date_of_birth: Yup.string().required(ProjectStrings.ValidationRequired),
            pan_number: Yup.string().required(ProjectStrings.ValidationRequired),
            aadhaar_gst_number: Yup.string().required(ProjectStrings.ValidationRequired),
            address: Yup.string().required(ProjectStrings.ValidationRequired),
            pincode: Yup.string().required(ProjectStrings.ValidationRequired),
            landmark: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            state_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            city_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        }),
        price_breakup: Yup.object().shape({
            ex_showroom_price: Yup.string().required(ProjectStrings.ValidationRequired),
            insurance: Yup.string().required(ProjectStrings.ValidationRequired),
            registration_charges: Yup.string().required(ProjectStrings.ValidationRequired),
            accessories_charges: Yup.string().required(ProjectStrings.ValidationRequired),
            tcs_charges: Yup.string().required(ProjectStrings.ValidationRequired),
            vas_charges: Yup.string().required(ProjectStrings.ValidationRequired),
            logistics_handling_charges: Yup.string().required(ProjectStrings.ValidationRequired),
            extended_warranty: Yup.string().required(ProjectStrings.ValidationRequired),
            csvp_service_plan: Yup.string().required(ProjectStrings.ValidationRequired),
            others: Yup.string().required("Others is required"),
        }),
        discount: Yup.object().shape({
            exchange_loyalty_bonus: Yup.string().required(ProjectStrings.ValidationRequired),
            corporate: Yup.string().required(ProjectStrings.ValidationRequired),
            dealer_discount: Yup.string().required(ProjectStrings.ValidationRequired),
            referral_bonus: Yup.string().required(ProjectStrings.ValidationRequired),
            oem_scheme: Yup.string().required(ProjectStrings.ValidationRequired),
            others: Yup.string().required("Others is required"),
        }),
        exchange_finance_ew: Yup.object().shape({
            want_to_change_vehicle: Yup.string().required(ProjectStrings.ValidationRequired),
            brand_name: Yup.string().when('want_to_change_vehicle', ([want_to_change_vehicle], schema) => {
                if (parseInt(want_to_change_vehicle) === 1)
                    return Yup.string().required(ProjectStrings.ValidationRequired);
                return schema;
            }),
            model_name: Yup.string().when('want_to_change_vehicle', ([want_to_change_vehicle], schema) => {
                if (parseInt(want_to_change_vehicle) === 1)
                    return Yup.string().required(ProjectStrings.ValidationRequired);
                return schema;
            }),
            registration_number: Yup.string().when('want_to_change_vehicle', ([want_to_change_vehicle], schema) => {
                if (parseInt(want_to_change_vehicle) === 1)
                    return Yup.number().required(ProjectStrings.ValidationRequired);
                return schema;
            }),
            registration_date: Yup.string().when('want_to_change_vehicle', ([want_to_change_vehicle], schema) => {
                if (parseInt(want_to_change_vehicle) === 1)
                    return Yup.string().required(ProjectStrings.ValidationRequired);
                return schema;
            }),
            model_year: Yup.string().when('want_to_change_vehicle', ([want_to_change_vehicle], schema) => {
                if (parseInt(want_to_change_vehicle) === 1)
                    return Yup.number().required(ProjectStrings.ValidationRequired);
                return schema;
            }),
            did_customer_opt_for_finance: Yup.string().required(ProjectStrings.ValidationRequired),
            // financier_name: Yup.string().when('did_customer_opt_for_finance', ([did_customer_opt_for_finance], schema) => {
            //     if (parseInt(did_customer_opt_for_finance) === 1 || parseInt(did_customer_opt_for_finance) === 2)
            //         return Yup.string().required(ProjectStrings.ValidationRequired);
            //     return schema;
            // }),
            // loan_tenure: Yup.string().when('did_customer_opt_for_finance', ([did_customer_opt_for_finance], schema) => {
            //     if (parseInt(did_customer_opt_for_finance) === 1 || parseInt(did_customer_opt_for_finance) === 2)
            //         return Yup.string().required(ProjectStrings.ValidationRequired);
            //     return schema;
            // }),
            // loan_amount: Yup.string().when('did_customer_opt_for_finance', ([did_customer_opt_for_finance], schema) => {
            //     if (parseInt(did_customer_opt_for_finance) === 1 || parseInt(did_customer_opt_for_finance) === 2)
            //         return Yup.string().required(ProjectStrings.ValidationRequired);
            //     return schema;
            // }),
            has_customer_bought_extended_warranty: Yup.string().required(ProjectStrings.ValidationRequired),
            // extended_warranty_end_date: Yup.string().when('has_customer_bought_extended_warranty', ([has_customer_bought_extended_warranty], schema) => {
            //     if (parseInt(has_customer_bought_extended_warranty) === 1)
            //         return Yup.string().required(ProjectStrings.ValidationRequired);
            //     return schema;
            // }),
        }),
        final_booking_details: Yup.object().shape({
            final_on_road_price: Yup.string().required(ProjectStrings.ValidationRequired),
            booking_amount: Yup.string().required(ProjectStrings.ValidationRequired),
            pending_total_invoice_amount: Yup.string().required(ProjectStrings.ValidationRequired),
            payment_mode_uuid: Yup.string().required(ProjectStrings.ValidationRequired),
            insurance_company_uuid: Yup.string().required(ProjectStrings.ValidationRequired),
            delivery_date_time: Yup.string().required(ProjectStrings.ValidationRequired),
            insurance_type_uuid: Yup.string().required(ProjectStrings.ValidationRequired),
        })
    });


    const exchangeOptions: FormDataTypes.SelectOption[] = [{ label: 'Yes', value: "1" }, { label: "No", value: "0" }];
    const warrantyOptions: FormDataTypes.SelectOption[] = [{ label: 'Yes', value: "1" }, { label: "No", value: "0" }];
    const financeOptions: FormDataTypes.SelectOption[] = [{ label: 'In House Finance', value: "1" }, { label: "Out House Finance", value: "2" }, { label: "No Finance", value: "3" }];

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.ModelList, setModelOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'model_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.ModelVariantList, setVariantOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'variant_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.InteriorColorList, setInteriorColorOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'interior_color_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.ExteriorColorList, setExteriorColorOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'exterior_color_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.FuelTypeList, setFuelTypeOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'fuel_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.CustomerTypeList, setCustomerTypeOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'customer_type_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.StateList, setCustomerStateOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'state_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.PaymentModeList, setCustomerPaymentOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'payment_mode_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.InsuranceCompanyList, setInsuranceCompaniesOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'insurance_company_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.InsuranceTypeList, setInsuranceTypeOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'insurance_type_name', 'data', 'list');
        setFormDataSet(true);
        setCurrentTab(0);
        // const handleClickOutside = (event: MouseEvent) => {
        //     if (invoiceDetailsRef.current && !invoiceDetailsRef.current.contains(event.target as Node)) {
        //         closeDialog()
        //         resetState();
        //         setSelectedCustomerStateID("");
        //         setSelectedPincode(undefined);
        //         setlandmark(undefined);
        //         setSelectedStateID("");
        //         setSelectedCityID("");
        //         setCurrentTab(0);
        //         console.log("clcike event")
        //     }
        // };
        // document.addEventListener("mousedown", handleClickOutside);

        // return () => {
        //     document.removeEventListener("mousedown", handleClickOutside);
        // };
    }, []);


    useEffect(() => {
        if (showDialog === false) {
            closeDialog()
            resetState();
            setSelectedCustomerStateID("");
            setSelectedPincode(undefined);
            setlandmark(undefined);
            setSelectedStateID("");
            setSelectedCityID("");
            setCurrentTab(0);
        }

    }, [showDialog]);

    useEffect(() => {
        if (id) {
            if (invoiceUUID && detailView) {
                addProcessingRequests();
                ServerAPI.getInvoiceDetails(id, invoiceUUID).then(response => {
                    if (response && response.data && response.data.details) {
                        const bookingDetail = response.data.details[0];
                        bookingDetail.car_details.vin_number = (bookingDetail.car_details.vin_number !== null) ? bookingDetail.car_details.vin_number : '';
                        setInitialValue({
                            lead_uuid: bookingDetail.lead_uuid,
                            interested_car_uuid: bookingDetail.interested_car_uuid,
                            car_details: {
                                model_uuid: bookingDetail.car_details.model_uuid,
                                variant_uuid: bookingDetail.car_details.variant_uuid,
                                exterior_uuid: bookingDetail.car_details.exterior_color_uuid,
                                interior_uuid: bookingDetail.car_details.interior_color_uuid,
                                vin_number: bookingDetail.car_details.vin_number,
                                booking_amount: bookingDetail.car_details.booking_amount,
                                booking_amount_payment_mode_uuid: bookingDetail.car_details.payment_mode_uuid,
                            },
                            customer_details: {
                                booking_name: bookingDetail.customer_details.booking_name,
                                mobile_number: bookingDetail.customer_details.mobile_number,
                                email_id: bookingDetail.customer_details.email_id,
                                customer_type_uuid: bookingDetail.customer_details.customer_type_uuid,
                                date_of_birth: bookingDetail.customer_details.date_of_birth,
                                pan_number: bookingDetail.customer_details.pan_number,
                                aadhaar_gst_number: bookingDetail.customer_details.aadhaar_gst_number,
                                address: bookingDetail.customer_details.address,
                                pincode: bookingDetail.customer_details.pincode,
                                landmark: bookingDetail.customer_details.landmark,
                                state_uuid: bookingDetail.customer_details.state_uuid,
                                city_uuid: bookingDetail.customer_details.city_uuid,
                            },
                            price_breakup: {
                                ex_showroom_price: bookingDetail.price_breakup.ex_showroom_price,
                                insurance: bookingDetail.price_breakup.insurance,
                                registration_charges: bookingDetail.price_breakup.registration_charges,
                                accessories_charges: bookingDetail.price_breakup.accessories_charges,
                                tcs_charges: bookingDetail.price_breakup.tcs_charges,
                                vas_charges: bookingDetail.price_breakup.vas_charges,
                                logistics_handling_charges: bookingDetail.price_breakup.logistics_handling_charges,
                                extended_warranty: bookingDetail.price_breakup.extended_warranty,
                                csvp_service_plan: bookingDetail.price_breakup.csvp_service_plan,
                                others: bookingDetail.price_breakup.others,
                            },
                            discount: {
                                exchange_loyalty_bonus: bookingDetail.discount.exchange_loyalty_bonus,
                                corporate: bookingDetail.discount.corporate,
                                dealer_discount: bookingDetail.discount.dealer_discount,
                                referral_bonus: bookingDetail.discount.referral_bonus,
                                oem_scheme: bookingDetail.discount.oem_scheme,
                                others: bookingDetail.discount.others,
                            },
                            exchange_finance_ew: {
                                want_to_change_vehicle: bookingDetail.exchange_finance_ew.want_to_change_vehicle,
                                brand_name: bookingDetail.exchange_finance_ew.brand_name,
                                model_name: bookingDetail.exchange_finance_ew.model_name,
                                model_year: bookingDetail.exchange_finance_ew.model_year,
                                registration_date: bookingDetail.exchange_finance_ew.registration_date,
                                registration_number: bookingDetail.exchange_finance_ew.registration_number,
                                did_customer_opt_for_finance: bookingDetail.exchange_finance_ew.did_customer_opt_for_finance,
                                financier_name: bookingDetail.exchange_finance_ew.financier_name !== null ? bookingDetail.exchange_finance_ew.financier_name : "",
                                loan_amount: bookingDetail.exchange_finance_ew.loan_amount !== null ? bookingDetail.exchange_finance_ew.loan_amount : "",
                                loan_tenure: bookingDetail.exchange_finance_ew.loan_tenure !== null ? bookingDetail.exchange_finance_ew.loan_tenure : "",
                                has_customer_bought_extended_warranty: bookingDetail.exchange_finance_ew.has_customer_bought_extended_warranty,
                                extended_warranty_end_date: bookingDetail.exchange_finance_ew.extended_warranty_end_date !== null ? bookingDetail.exchange_finance_ew.extended_warranty_end_date : "",
                            },
                            final_booking_details: {
                                final_on_road_price: bookingDetail.lead_booking_final_details.final_on_road_price,
                                booking_amount: bookingDetail.lead_booking_final_details.booking_amount,
                                pending_total_invoice_amount: bookingDetail.lead_booking_final_details.pending_total_invoice_amount,
                                payment_mode_uuid: bookingDetail.lead_booking_final_details.payment_mode_uuid,
                                insurance_type_uuid: bookingDetail.lead_booking_final_details.insurance_type_uuid,
                                insurance_company_uuid: bookingDetail.lead_booking_final_details.insurance_company_uuid,
                                delivery_date_time: bookingDetail.lead_booking_final_details.delivery_date_time,
                            }
                        });
                        setFormDataSet(true);
                    }
                }).finally(() => {
                    reduceProcessingRequests();
                });
            }
            else if (!detailView && loadBookDetail) {
                addProcessingRequests();
                ServerAPI.getLeadBookingDetail(id).then(response => {
                    if (response && response.data && response.data.details && response.data.details.length > 0) {
                        const bookingDetail = response.data.details[0];
                        bookingDetail.car_details.vin_number = (bookingDetail.car_details.vin_number !== null) ? bookingDetail.car_details.vin_number : '';
                        setInitialValue({
                            lead_uuid: bookingDetail.lead_uuid,
                            interested_car_uuid: bookingDetail.interested_car_uuid,
                            car_details: {
                                model_uuid: bookingDetail.car_details.model_uuid,
                                variant_uuid: bookingDetail.car_details.variant_uuid,
                                exterior_uuid: bookingDetail.car_details.exterior_color_uuid,
                                interior_uuid: bookingDetail.car_details.interior_color_uuid,
                                vin_number: bookingDetail.car_details.vin_number,
                                booking_amount: bookingDetail.car_details.booking_amount,
                                booking_amount_payment_mode_uuid: bookingDetail.car_details.payment_mode_uuid,
                            },
                            customer_details: {
                                booking_name: bookingDetail.customer_details.booking_name,
                                mobile_number: bookingDetail.customer_details.mobile_number,
                                email_id: bookingDetail.customer_details.email_id,
                                customer_type_uuid: bookingDetail.customer_details.customer_type_uuid,
                                date_of_birth: bookingDetail.customer_details.date_of_birth,
                                pan_number: bookingDetail.customer_details.pan_number,
                                aadhaar_gst_number: bookingDetail.customer_details.aadhaar_gst_number,
                                address: bookingDetail.customer_details.address,
                                pincode: bookingDetail.customer_details.pincode,
                                landmark: bookingDetail.customer_details.landmark,
                                state_uuid: bookingDetail.customer_details.state_uuid,
                                city_uuid: bookingDetail.customer_details.city_uuid,
                            },
                            price_breakup: {
                                ex_showroom_price: bookingDetail.price_breakup.ex_showroom_price,
                                insurance: bookingDetail.price_breakup.insurance,
                                registration_charges: bookingDetail.price_breakup.registration_charges,
                                accessories_charges: bookingDetail.price_breakup.accessories_charges,
                                tcs_charges: bookingDetail.price_breakup.tcs_charges,
                                vas_charges: bookingDetail.price_breakup.vas_charges,
                                logistics_handling_charges: bookingDetail.price_breakup.logistics_handling_charges,
                                extended_warranty: bookingDetail.price_breakup.extended_warranty,
                                csvp_service_plan: bookingDetail.price_breakup.csvp_service_plan,
                                others: bookingDetail.price_breakup.others,
                            },
                            discount: {
                                exchange_loyalty_bonus: bookingDetail.discount.exchange_loyalty_bonus,
                                corporate: bookingDetail.discount.corporate,
                                dealer_discount: bookingDetail.discount.dealer_discount,
                                referral_bonus: bookingDetail.discount.referral_bonus,
                                oem_scheme: bookingDetail.discount.oem_scheme,
                                others: bookingDetail.discount.others,
                            },
                            exchange_finance_ew: {
                                want_to_change_vehicle: bookingDetail.exchange_finance_ew.want_to_change_vehicle,
                                brand_name: "",
                                model_name: "",
                                model_year: "",
                                registration_date: "",
                                registration_number: "",
                                did_customer_opt_for_finance: bookingDetail.exchange_finance_ew.did_customer_opt_for_finance,
                                financier_name: bookingDetail.exchange_finance_ew.financier_name !== null ? bookingDetail.exchange_finance_ew.financier_name : "",
                                loan_amount: bookingDetail.exchange_finance_ew.loan_amount !== null ? bookingDetail.exchange_finance_ew.loan_amount : "",
                                loan_tenure: bookingDetail.exchange_finance_ew.loan_tenure !== null ? bookingDetail.exchange_finance_ew.loan_tenure : "",
                                has_customer_bought_extended_warranty: bookingDetail.exchange_finance_ew.has_customer_bought_extended_warranty,
                                extended_warranty_end_date: bookingDetail.exchange_finance_ew.extended_warranty_end_date !== null ? bookingDetail.exchange_finance_ew.extended_warranty_end_date : "",
                            },
                            final_booking_details: {
                                final_on_road_price: bookingDetail.lead_booking_final_details.final_on_road_price,
                                booking_amount: bookingDetail.lead_booking_final_details.booking_amount,
                                pending_total_invoice_amount: bookingDetail.lead_booking_final_details.pending_total_invoice_amount,
                                payment_mode_uuid: bookingDetail.lead_booking_final_details.payment_mode_uuid,
                                insurance_type_uuid: "",
                                insurance_company_uuid: "",
                                delivery_date_time: "",
                            }
                        });
                        setFormDataSet(true);
                    }
                }).finally(() => {
                    reduceProcessingRequests();
                });
            }
        }
    }, [id, invoiceUUID, detailView, loadBookDetail]);




    function TabPanel(props: { children: any; value: any; index: any; }) {
        const { children, value, index } = props;
        return (
            <div>
                {
                    value === index && (
                        <h1>{children}</h1>
                    )
                }
            </div>
        )
    }

    useEffect(() => {
        if (selectedPincode && selectedPincode.toString().length === 6) {
            addProcessingRequests();
            ServerAPI.getBookCarPincode(selectedPincode).then(response => {
                if (response && response.data && response.data.list.length > 0) {
                    const landmarkData = response.data.list[0].landmark.map((item: any) => ({
                        label: item,
                        value: item
                    }));
                    const newLandmark = landmarkData;
                    const newStateID = response.data.list[0].state_uuid;
                    const newCityID = response.data.list[0].city_uuid;
                    setlandmark(newLandmark);
                    setSelectedStateID(newStateID);
                    setSelectedCityID(newCityID);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }, [selectedPincode]);

    useEffect(() => {
        if (selectedCityID !== "" && selectedStateID !== "") {
            formikRef.current?.setFieldValue('customer_details.state_uuid', selectedStateID);
            formikRef.current?.setFieldValue('customer_details.city_uuid', selectedCityID);
        }
    }, [selectedCityID, selectedStateID]);

    useEffect(() => {
        if (selectedCustomerStateID) {
            let params: { [k: string]: any } = {};
            params["limit"] = 10000;
            params["status"] = 1;
            params["state_uuid"] = selectedCustomerStateID;
            getDataFromAPI(API.EndPoint.CityList, setCustomerCityOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'city_name', 'data', 'list');
        } else {
            setCustomerCityOptions([]);
        }
    }, [selectedCustomerStateID]);


    const handelTabChange = (e: any, val: React.SetStateAction<number>) => {
        setCurrentTab(val)
    }



    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="book-a-car-section drawer-max">
            {formDataSet && initialValue !== undefined && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };
                    //submitvalues
                    submitValues['lead_uuid'] = id;
                    submitValues['interested_car_uuid'] = interestedUUID;
                    submitValues.car_details.booking_amount = submitValues.car_details.booking_amount.toString();
                    submitValues.final_booking_details.final_on_road_price = submitValues.final_booking_details.final_on_road_price.toString();
                    submitValues.final_booking_details.pending_total_invoice_amount = submitValues.final_booking_details.pending_total_invoice_amount.toString();
                    submitValues.final_booking_details.booking_amount = submitValues.final_booking_details.booking_amount.toString();
                    submitValues.price_breakup.ex_showroom_price = submitValues.price_breakup.ex_showroom_price.toString();
                    submitValues.price_breakup.insurance = submitValues.price_breakup.insurance.toString();
                    submitValues.price_breakup.registration_charges = submitValues.price_breakup.registration_charges.toString();
                    submitValues.price_breakup.accessories_charges = submitValues.price_breakup.accessories_charges.toString();
                    submitValues.price_breakup.tcs_charges = submitValues.price_breakup.tcs_charges.toString();
                    submitValues.price_breakup.logistics_handling_charges = submitValues.price_breakup.logistics_handling_charges.toString();
                    submitValues.price_breakup.vas_charges = submitValues.price_breakup.vas_charges.toString();
                    submitValues.price_breakup.extended_warranty = submitValues.price_breakup.extended_warranty.toString();
                    submitValues.price_breakup.csvp_service_plan = submitValues.price_breakup.csvp_service_plan.toString();
                    submitValues.price_breakup.others = submitValues.price_breakup.others.toString();
                    submitValues.exchange_finance_ew.loan_tenure = submitValues.exchange_finance_ew.loan_tenure ? submitValues.exchange_finance_ew.loan_tenure.toString() : null;
                    submitValues.exchange_finance_ew.loan_amount = submitValues.exchange_finance_ew.loan_amount ? submitValues.exchange_finance_ew.loan_amount.toString() : null;
                    submitValues.discount.others = submitValues.discount.others.toString();
                    submitValues.discount.oem_scheme = submitValues.discount.oem_scheme.toString();
                    submitValues.discount.referral_bonus = submitValues.discount.referral_bonus.toString();
                    submitValues.discount.dealer_discount = submitValues.discount.dealer_discount.toString();
                    submitValues.discount.corporate = submitValues.discount.corporate.toString();
                    submitValues.discount.exchange_loyalty_bonus = submitValues.discount.exchange_loyalty_bonus.toString();
                    submitValues.customer_details.mobile_number = submitValues.customer_details.mobile_number.toString();
                    submitValues.customer_details.pincode = submitValues.customer_details.pincode.toString();
                    submitValues.exchange_finance_ew.registration_number = submitValues.exchange_finance_ew.registration_number ? submitValues.exchange_finance_ew.registration_number.toString() : null;

                    if (submitValues.exchange_finance_ew) {
                        if (submitValues.exchange_finance_ew.did_customer_opt_for_finance === "1" || submitValues.exchange_finance_ew.did_customer_opt_for_finance === "2") {
                            if (!submitValues.exchange_finance_ew.loan_tenure) {
                                toast.error("Loan Tenure is Required")
                                setSubmitting(false);
                                return
                            }
                        }
                    }
                    if (submitValues.exchange_finance_ew) {
                        if (submitValues.exchange_finance_ew.did_customer_opt_for_finance === "1" || submitValues.exchange_finance_ew.did_customer_opt_for_finance === "2") {
                            if (!submitValues.exchange_finance_ew.financier_name) {
                                toast.error("Financier Name is Required")
                                setSubmitting(false);
                                return
                            }
                        }
                    }
                    if (submitValues.exchange_finance_ew) {
                        if (submitValues.exchange_finance_ew.did_customer_opt_for_finance === "1" || submitValues.exchange_finance_ew.did_customer_opt_for_finance === "2") {
                            if (!submitValues.exchange_finance_ew.loan_amount) {
                                toast.error("Loan Amount is Required")
                                setSubmitting(false);
                                return
                            }
                        }
                    }
                    if (submitValues.exchange_finance_ew) {
                        if (submitValues.exchange_finance_ew.has_customer_bought_extended_warranty === "1") {
                            if (submitValues.exchange_finance_ew.extended_warranty_end_date) {
                                toast.error("Extended Warranty Date is Required")
                                setSubmitting(false);
                                return
                            }
                        }
                    }


                    addProcessingRequests();
                    ServerAPI.invoiceDetails(submitValues).then(response => {
                        if (response && response['success'] && response['message']) {
                            toast.success(response['message']);
                            closeDialog();
                            resetState();
                            reloadData();
                            setCurrentTab(0)
                        } else if (response && response['message']) {
                            toast.error(response['message']);
                        }
                    }).finally(() => {
                        setSubmitting(false);
                        reduceProcessingRequests();
                    });

                }}>
                    {(formikProps) => {
                        if (formikProps.values.customer_details.state_uuid && formikProps.values.customer_details.state_uuid !== selectedCustomerStateID) {
                            setSelectedCustomerStateID(formikProps.values.customer_details.state_uuid);
                        }
                        if (
                            formikProps.values.customer_details.pincode &&
                            formikProps.values.customer_details.pincode.toString().length === 6 &&
                            parseInt(formikProps.values.customer_details.pincode) !== selectedPincode
                        ) {
                            const newPincode = parseInt(formikProps.values.customer_details.pincode);
                            setSelectedPincode(newPincode);
                        }

                        return (
                            <Form>
                                <div className="tabdetails" style={{ overflowY: "auto", height: "90vh" }} ref={invoiceDetailsRef}>
                                    <h4 className="title-text ml-5 text-center">Invoice Details</h4>
                                    <div className="custom-border-grey"></div>
                                    <div className="tabpage">
                                        <div className="form-field-container-tabs">
                                            <Tabs value={currentTab} onChange={handelTabChange}
                                                sx={{ backgroundColor: "white", borderTop: "2px solid #e2e2e2", borderBottom: "2px solid #e2e2e2", "& button": { width: "auto" }, justifyContent: "space-between" }}
                                                TabIndicatorProps={{
                                                    style: {
                                                        backgroundColor: "#14408B",
                                                    },
                                                }}
                                            >
                                                <Tab label="Car Details" className="tab-headers" />
                                                <Tab label="Customer Details " className="tab-headers" />
                                                <Tab label="Price Break-up" className="tab-headers" />
                                                <Tab label="Discount" className="tab-headers" />
                                                <Tab label="Exchange / Finance / EW" className="tab-headers" />
                                                <Tab label="Final Booking Details" className="tab-headers" />

                                            </Tabs>
                                            <TabPanel value={currentTab} index={0}>
                                                <>
                                                    <div className="row  py-3">
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Model", name: "car_details.model_uuid", placeholder: "Select Model", options: modelOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Variant", name: "car_details.variant_uuid", placeholder: "Select Variant", options: variantOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Exterior Color", name: "car_details.exterior_uuid", placeholder: "Select Exterior Color", options: exteriorColorOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Interior Color", name: "car_details.interior_uuid", placeholder: "Select Interior Color", options: interiorColorOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "VIN", name: "car_details.vin_number", placeholder: "Enter Vin Number", readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Booking Amount", name: "car_details.booking_amount", placeholder: "Enter Booking Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Booking Amount Payment Mode", name: "car_details.booking_amount_payment_mode_uuid", placeholder: "Select Booking Amount Payment Mode", options: customerPaymentOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                    </div>
                                                    <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                        <button type="button" className="button1" onClick={() => closeDialog()}>Back</button>
                                                        <button type="button" className="button2" onClick={() => setCurrentTab(1)}>Next</button>
                                                    </div>
                                                </>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={1}>
                                                <>
                                                    <div className="row  py-3">
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Booking Name", name: "customer_details.booking_name", placeholder: "Enter Booking Name", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Mobile Number", name: "customer_details.mobile_number", placeholder: "Enter Mobile Number", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Email Id", name: "customer_details.email_id", placeholder: "Enter Email Id", required: true }} />
                                                        </div>

                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "datepicker", label: "Date of Birth", name: "customer_details.date_of_birth", placeholder: "Enter Date of Birth *", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1 mt-2">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Customer Type", name: "customer_details.customer_type_uuid", placeholder: "Select Customer Type", options: customerTypeOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 mt-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "PAN Number", name: "customer_details.pan_number", placeholder: "Enter PAN Number", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 text-field-empty-custom-1 holiday-group-3${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Aadhaar/GST Number", name: "customer_details.aadhaar_gst_number", placeholder: "Enter Aadhaar/GST Number*", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Pincode", name: "customer_details.pincode", placeholder: "Enter Pincode", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "State", name: "customer_details.state_uuid", placeholder: "Select State", options: customerStateOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "City", name: "customer_details.city_uuid", placeholder: "Select City", options: customerCityOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Landmark", name: "customer_details.landmark", placeholder: "Select Landmark", required: true, options: landmark, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 text-field-empty-custom-1 text-area${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textarea", label: "Address", name: "customer_details.address", placeholder: "Enter Address", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                    </div>
                                                    <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                        <button type="button" className="button1" onClick={() => setCurrentTab(0)}>Previous</button>
                                                        <button type="button" className="button2" onClick={() => setCurrentTab(2)}>Next</button>
                                                    </div>
                                                </>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={2}>
                                                <>
                                                    <div className="row  py-3">
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 ${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Ex Showroom Price", name: "price_breakup.ex_showroom_price", placeholder: "Enter Ex Showroom Price", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 ${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Insurance", name: "price_breakup.insurance", placeholder: "Enter Insurance", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 ${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Registration Charges", name: "price_breakup.registration_charges", placeholder: "Enter Registration Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 ${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Accessories Charges", name: "price_breakup.accessories_charges", placeholder: "Enter Accessories Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 ${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "TCS Charges@__%", name: "price_breakup.tcs_charges", placeholder: "Enter TCS Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 ${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "VAS Charges", name: "price_breakup.vas_charges", placeholder: "Enter Logistics & Handling Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 holiday-group-3${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Logistics & Handling Charges", name: "price_breakup.logistics_handling_charges", placeholder: "Enter Logistics & Handling Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 text-field-empty-custom-1 holiday-group-3${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Extended Warranty", name: "price_breakup.extended_warranty", placeholder: "Enter Extended Warranty Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 ${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "CSVP/Service Plan", name: "price_breakup.csvp_service_plan", placeholder: "Enter CSVP/Service Plan Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 ${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Others", name: "price_breakup.others", placeholder: "Enter Sum Total of all Other Amounts", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                    </div>
                                                    <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                        <button type="button" className="button1" onClick={() => setCurrentTab(1)}>Previous</button>
                                                        <button type="button" className="button2" onClick={() => setCurrentTab(3)}>Next</button>
                                                    </div>
                                                </>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={3}>
                                                <>
                                                    <div className="row  py-3">
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 holiday-group-3${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Exchange / Loyalty Bonus", name: "discount.exchange_loyalty_bonus", placeholder: "Enter Exchange/Loyalty Bonus Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Corporate", name: "discount.corporate", placeholder: "Enter Corporate Discount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Dealer Discount", name: "discount.dealer_discount", placeholder: "Enter Dealer Discount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Referral Bonus", name: "discount.referral_bonus", placeholder: "Enter Referral Bonus Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "OEM Scheme", name: "discount.oem_scheme", placeholder: "Enter OEM Scheme Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Others", name: "discount.others", placeholder: "Enter Any Other Discount Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                    </div>
                                                    <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                        <button type="button" className="button1" onClick={() => setCurrentTab(2)}>Previous</button>
                                                        <button type="button" className="button2" onClick={() => setCurrentTab(4)}>Next</button>
                                                    </div>
                                                </>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={4}>
                                                <>
                                                    <div className="row  py-3">
                                                        <div className="col-lg-6 col-md-6 col-xl-6 exchange-radio">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "radio", label: "Did customer exchange any vehicle?", name: "exchange_finance_ew.want_to_change_vehicle", options: exchangeOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        {formikProps.values.exchange_finance_ew.want_to_change_vehicle.toString() === "1" && (
                                                            <>
                                                                <div className={`col-md-6 col-lg-6 col-xl-6 mt-3 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`} style={{ paddingTop: "5px" }}>
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Brand", name: "exchange_finance_ew.brand_name", placeholder: "Enter Brand", required: true, readOnly: detailView ? true : false }} />
                                                                </div>
                                                                <div className={`col-md-6 col-lg-6 col-xl-6 mt-3 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Model", name: "exchange_finance_ew.model_name", placeholder: "Enter Model", required: true, readOnly: detailView ? true : false }} />
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-xl-6 mt-3 text-field-empty-custom-user-1">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Model year", name: "exchange_finance_ew.model_year", placeholder: "Select Model Year", required: true, options: modelYearOptions, readOnly: detailView ? true : false }} />
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-xl-6 mt-3 text-field-empty-custom-user">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "datepicker", label: "Registration Date", name: "exchange_finance_ew.registration_date", placeholder: "Select Registration Date", required: true, readOnly: detailView ? true : false }} />
                                                                </div>
                                                                <div className={`col-md-6 col-lg-6 col-xl-6 mt-3 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`} style={{ paddingTop: "5px" }}>
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Registration Number", name: "exchange_finance_ew.registration_number", placeholder: "Enter Registration Number", required: true, readOnly: detailView ? true : false }} />
                                                                </div>
                                                            </>
                                                        )}
                                                        <div className="col-lg-6 col-md-6 col-xl-6 exchange-radio">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "radio", label: "Did customer opt for finance?", name: "exchange_finance_ew.did_customer_opt_for_finance", options: financeOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        {(formikProps.values.exchange_finance_ew.did_customer_opt_for_finance.toString() === "1" || formikProps.values.exchange_finance_ew.did_customer_opt_for_finance.toString() === "2") && (
                                                            <>
                                                                <div className={`col-md-6 col-lg-6 col-xl-6 mb-4 mt-3 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Financier Name", name: "exchange_finance_ew.financier_name", placeholder: "Enter Financier Name", required: true, readOnly: detailView ? true : false }} />
                                                                </div>
                                                                <div className={`col-md-6 col-lg-6 col-xl-6 mb-4 mt-3 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Loan Amount", name: "exchange_finance_ew.loan_amount", placeholder: "Enter Loan amount", required: true, readOnly: detailView ? true : false }} />
                                                                </div>
                                                                <div className={`col-md-6 col-lg-6 col-xl-6 mb-4 mt-3 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Loan Tenure", name: "exchange_finance_ew.loan_tenure", placeholder: "Enter Loan Tenure", required: true, readOnly: detailView ? true : false }} />
                                                                </div>
                                                            </>
                                                        )}

                                                        <div className="col-lg-6 col-md-6 col-xl-6 exchange-radio">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "radio", label: "Has customer bought extended warranty?", name: "exchange_finance_ew.has_customer_bought_extended_warranty", options: warrantyOptions, required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        {formikProps.values.exchange_finance_ew.has_customer_bought_extended_warranty.toString() === "1" && (
                                                            <div className="col-lg-6 col-md-6 col-xl-6 text-field-empty-custom-user mt-3">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "datepicker", label: "Extended Warranty End Date", name: "exchange_finance_ew.extended_warranty_end_date", placeholder: "Enter EW End Date", required: true, readOnly: detailView ? true : false }} />
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                        <button type="button" className="button1" onClick={() => setCurrentTab(3)}>Previous</button>
                                                        <button type="button" className="button2" onClick={() => setCurrentTab(5)}>Next</button>
                                                    </div>
                                                </>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={5}>
                                                <>
                                                    <div className="row  py-3">
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Final On Road Price", name: "final_booking_details.final_on_road_price", placeholder: "Enter Final On Road Price", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Booking Amount", name: "final_booking_details.booking_amount", placeholder: "Enter Booking Amount", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className={`col-md-4 col-lg-4 col-xl-4 mb-4 text-field-empty-custom-1 holiday-group-3${detailView ? " detail-view-num" : ""}`}>
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Pending Total Invoice Amount", name: "final_booking_details.pending_total_invoice_amount", placeholder: "Enter Pending Total Invoice Amount*", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 mt-2 text-field-empty-custom-user-1">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Booking Amount Payment Mode", name: "final_booking_details.payment_mode_uuid", placeholder: "Enter Booking Amount Payment Mode*", required: true, options: customerPaymentOptions, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        {/* <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user book-final">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "datetimepicker", label: "Next Followup Date Time", name: "next_followup_date_time", placeholder: "Enter Next Followup Date Time*", required: true }} />
                                                        </div> */}
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 mt-2 text-field-empty-custom-user-1 book-final">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Insurance Type", name: "final_booking_details.insurance_type_uuid", placeholder: "Select Insurance Type*", required: true, options: insuranceTypeOptions, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 mt-2 text-field-empty-custom-user-1 book-final">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Insurance Company", name: "final_booking_details.insurance_company_uuid", placeholder: "Select Insurance Company*", required: true, options: insuranceCompaniesOptions, readOnly: detailView ? true : false }} />
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user book-final">
                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "datetimepicker", label: "Delivery Date/ Time", name: "final_booking_details.delivery_date_time", placeholder: "Select Delivery Date/ Time*", required: true, readOnly: detailView ? true : false }} />
                                                        </div>
                                                    </div>
                                                    <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                        <button type="button" className="button1" style={detailView ? { textAlign: "center" } : {}} onClick={() => setCurrentTab(4)}>Previous</button>
                                                        {!detailView && <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                            formikProps.submitForm();
                                                            if (!formikProps.isValid) {
                                                                console.log('Errors', formikProps.errors);
                                                                toast.error("Please ensure all fields are Filled and Valid!");
                                                            }
                                                        }}>{detailView ? "Next" : "Submit"}</button>}
                                                    </div>
                                                </>
                                            </TabPanel>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            )
            }
        </Drawer >
    )
}

export default InvoiceDetailsEntryDialog;
