import React, { useEffect, useState } from "react";
import { useAppStateAPI } from "../../common/AppStateAPI";
import { ServerAPI } from "../../common/ServerAPI";
import { APIData } from "../../common/DataTypes";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonIcon from '@mui/icons-material/Person';
import { array } from "yup";


const UserDashboardETVBRLTable: React.FC<{ startDate: string, endDate: string }> = ({ startDate, endDate }) => {
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [userDashBoardETVBRL, setUserDashboardETVBRL] = useState<APIData.UserDashboardETVBRL>();
    const [teamMembersVisibility, setTeamMembersVisibility] = useState<{ [key: string]: boolean }>({});
    const [showroomVisibility, setShowroomVisibility] = useState<{ [key: string]: boolean }>({});
    const [selectedTeam, setSelectedTeam] = useState<string | null>(null);
    const [selectedShowroomUUID, setSelectedShowroomUUID] = useState<string>("");

    const toggleTeamMembersVisibility = (teamId: string) => {
        setTeamMembersVisibility((prevVisibility) => {
            const newVisibility = { [teamId]: !prevVisibility[teamId] };
            Object.keys(prevVisibility).forEach((prevTeamId) => {
                if (prevTeamId !== teamId) {
                    newVisibility[prevTeamId] = false;
                }
            });
            setSelectedTeam(newVisibility[teamId] ? teamId : null);
            return newVisibility;
        });
    };

    const toggleShowroomVisibility = (showroomId: string) => {
        setShowroomVisibility((prevVisibility) => {
            const newVisibility = { [showroomId]: !prevVisibility[showroomId] };
            Object.keys(prevVisibility).forEach((prevShowroomId) => {
                if (prevShowroomId !== showroomId) {
                    newVisibility[prevShowroomId] = false;
                }
            });
            // setSelectedShowroom(newVisibility[showroomId] ? showroomId : null);
            return newVisibility;
        });
    };


    const getUserDashboardETVBRL = () => {
        addProcessingRequests();
        ServerAPI.getUserDashboardETVBRL(startDate, endDate, selectedShowroomUUID ? selectedShowroomUUID : "").then((response: any) => {
            setUserDashboardETVBRL({ data: response.data, total_counts: response.total_counts });
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    useEffect(() => {
        getUserDashboardETVBRL();
    }, [startDate, endDate, selectedShowroomUUID])


    return (
        <>
            {userDashBoardETVBRL && userDashBoardETVBRL.data && userDashBoardETVBRL.total_counts ? (
                <>
                    <div className="table-responsive color-native-blue mt-3 table-noborder" style={{ height: '50px', overflowY: 'hidden', width: '99%' }}>
                        <table className="table table-1 custom-border">
                            <thead className="color-native-blue pl-2 pr-2">
                                <tr>
                                    <th className="custom-width"> </th>
                                    {userDashBoardETVBRL.total_counts.map((tl, tlIdx) => (
                                        <th style={{ width: "10%" }} key={tlIdx}>{tl.lead_status}</th>
                                    ))}
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="custom-tablescroll-1">
                        <div className="table-container">
                            <div className="table-scroll">
                                {userDashBoardETVBRL.data.map((data, dataIdx) => (
                                    <>
                                        <div className="table-responsive" style={{ padding: '0 8px' }} key={dataIdx}>
                                            {/* <div className="d-flex align-items-center justify-content-between" style={{ cursor: 'pointer' }} onClick={() => { toggleShowroomVisibility(data.showroom_name); showroomVisibility ? setShowroomVisibility({}); setTeamMembersVisibility({}) : '' }}> */}
                                            <div className="d-flex align-items-center justify-content-between" style={{ cursor: 'pointer' }} onClick={() => {
                                                if (selectedShowroomUUID === data.showroom_uuid) {
                                                    setSelectedShowroomUUID("");
                                                } else {
                                                    setSelectedShowroomUUID(data.showroom_uuid);
                                                }
                                                toggleShowroomVisibility(data.showroom_name);
                                                if (showroomVisibility[data.showroom_name]) {
                                                    setShowroomVisibility({});
                                                    setTeamMembersVisibility({});
                                                }
                                            }}>

                                                <div className="d-flex align-items-center" style={{ padding: '2.8px', gap: '5px', height: "50px" }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="19" viewBox="0 0 36 19" fill="none">
                                                        <path d="M36 18.5H0V17.3052C1.48148 17.3035 2.96331 17.3006 4.44479 17.3003C14.8988 17.3003 25.3525 17.3003 35.8066 17.3006C35.8709 17.3006 35.9353 17.3035 35.9996 17.3049V18.4996L36 18.5Z" fill="#14408B" />
                                                        <path d="M18.0028 16.7059C12.6472 16.7059 7.29204 16.7024 1.93649 16.7122C1.47267 16.7129 1.18854 16.4275 1.18995 15.9751C1.2019 12.4548 1.19628 8.93444 1.19663 5.41444C1.19663 4.92585 1.42625 4.69701 1.91645 4.69666C2.40277 4.69631 2.88909 4.68928 3.37542 4.70053C3.5421 4.7044 3.60364 4.67627 3.59766 4.48857C3.58148 3.98521 3.59133 3.48115 3.59238 2.97744C3.59344 2.54895 3.84065 2.30114 4.26649 2.30114C6.22339 2.30079 8.18065 2.30079 10.1376 2.30114C10.5578 2.30114 10.7976 2.54016 10.8001 2.96478C10.8029 3.48607 10.8067 4.00736 10.7976 4.52864C10.7951 4.67206 10.8408 4.70158 10.9745 4.69947C11.394 4.69315 11.394 4.69842 11.394 4.28786C11.394 3.25689 11.4035 2.22592 11.3887 1.1953C11.3834 0.814613 11.6521 0.497554 12.0857 0.500014C14.236 0.513723 16.3863 0.50599 18.5366 0.506341C18.9645 0.506341 19.1994 0.738688 19.1998 1.16471C19.2008 2.27759 19.2026 3.39046 19.197 4.50333C19.1963 4.6464 19.2149 4.7065 19.3823 4.70123C19.7948 4.68752 19.7951 4.69772 19.7948 4.28118C19.7948 3.83617 19.7937 3.39081 19.7951 2.9458C19.7965 2.55282 20.0494 2.30149 20.4453 2.30149C22.6132 2.30079 24.7811 2.30079 26.9493 2.30149C27.3499 2.30149 27.5971 2.54438 27.5988 2.94158C27.6013 3.45127 27.5995 3.96061 27.5995 4.47029C27.5995 4.62097 27.6778 4.69643 27.8344 4.69666C28.1333 4.69666 28.4322 4.69526 28.7308 4.69701C29.1531 4.69947 29.3961 4.93815 29.401 5.3582C29.4042 5.65698 29.3996 5.95576 29.4024 6.25419C29.4052 6.5368 29.3627 6.49919 29.6398 6.49954C31.1163 6.50095 32.5929 6.50939 34.0695 6.49462C34.553 6.4897 34.8118 6.78427 34.8097 7.2363C34.7981 10.1591 34.8037 13.0819 34.8033 16.0047C34.8033 16.4718 34.5702 16.7056 34.1039 16.7059C28.7368 16.7059 23.3696 16.7059 18.0024 16.7059H18.0028Z" fill="#14408B" />
                                                        <path d="M15.3039 5.91169C19.5398 5.91169 23.7757 5.91274 28.0116 5.90747C28.1681 5.90747 28.1998 5.94614 28.1998 6.09799C28.1948 9.167 28.1948 12.236 28.1991 15.305C28.1991 15.4516 28.1748 15.5005 28.0123 15.4998C26.5358 15.4924 25.0596 15.4931 23.583 15.4998C23.4318 15.5005 23.4047 15.4534 23.4051 15.3131C23.4097 13.0171 23.4082 10.7214 23.4079 8.42532C23.4079 7.92829 23.186 7.70262 22.6951 7.70262C17.9553 7.70227 13.2155 7.70192 8.47564 7.70227C8.02554 7.70227 7.79978 7.9311 7.79943 8.38454C7.79943 10.6922 7.79767 12.9998 7.80295 15.3075C7.8033 15.4625 7.76602 15.5001 7.6113 15.4994C5.92973 15.4934 4.24817 15.4945 2.56695 15.4987C2.44001 15.4987 2.39816 15.473 2.39816 15.3363C2.40238 12.2497 2.40309 9.16313 2.39746 6.07655C2.39746 5.90571 2.47271 5.90993 2.59579 5.90993C6.8317 5.91204 11.0676 5.91169 15.3035 5.91169H15.3039Z" fill="white" />
                                                        <path d="M33.5979 11.5999C33.5979 12.8407 33.5958 14.0819 33.6007 15.3227C33.6011 15.4563 33.5733 15.5009 33.4291 15.5002C32.1467 15.4943 30.8646 15.4943 29.5821 15.4999C29.4372 15.5006 29.3982 15.4619 29.3982 15.3164C29.4028 12.8464 29.4031 10.3763 29.3982 7.90591C29.3982 7.74774 29.4355 7.7038 29.5972 7.70485C30.8737 7.71188 32.1502 7.71083 33.4266 7.70556C33.5624 7.70485 33.6014 7.73719 33.6007 7.87709C33.5947 9.11791 33.5972 10.3591 33.5972 11.5999H33.5979Z" fill="white" />
                                                        <path d="M12.5922 12.2005C12.5922 13.2424 12.5897 14.2839 12.5954 15.3258C12.5961 15.4625 12.562 15.5009 12.4231 15.5001C11.334 15.4942 10.2453 15.4952 9.15627 15.4991C9.03953 15.4994 8.99733 15.4773 8.99768 15.3486C9.0019 13.2477 9.0019 11.1464 8.99768 9.04542C8.99768 8.92907 9.02933 8.90271 9.14221 8.90306C10.2429 8.90728 11.3435 8.90798 12.4442 8.90235C12.57 8.90165 12.5954 8.9375 12.5947 9.05737C12.5897 10.1049 12.5918 11.1527 12.5918 12.2002L12.5922 12.2005Z" fill="white" />
                                                        <path d="M13.8097 12.2097C13.8097 11.1611 13.8125 10.1129 13.8062 9.0644C13.8055 8.93223 13.8378 8.90165 13.9686 8.90236C15.0584 8.90833 16.1481 8.90763 17.2379 8.90306C17.3617 8.90236 17.4053 8.92204 17.4049 9.06124C17.3996 11.152 17.4 13.2431 17.4042 15.3339C17.4042 15.4629 17.3771 15.5012 17.2424 15.5005C16.1587 15.4945 15.0746 15.4938 13.9908 15.5005C13.8466 15.5012 13.8051 15.4682 13.8062 15.3202C13.8129 14.2836 13.8097 13.247 13.8097 12.21V12.2097Z" fill="white" />
                                                        <path d="M22.1927 12.1946C22.1927 13.2312 22.1899 14.2681 22.1959 15.3047C22.1966 15.4502 22.1737 15.503 22.0092 15.5016C20.931 15.4928 19.8532 15.4949 18.7751 15.4994C18.646 15.5001 18.5968 15.4787 18.5972 15.3314C18.6028 13.2403 18.6021 11.1495 18.5982 9.05842C18.5982 8.93891 18.6218 8.90165 18.7491 8.90235C19.8504 8.90798 20.9521 8.90763 22.0535 8.90235C22.1723 8.90165 22.1956 8.93821 22.1952 9.04893C22.191 10.0975 22.1927 11.1457 22.1927 12.1942V12.1946Z" fill="white" />
                                                        <path d="M15.3049 4.69534C14.4561 4.69534 13.6076 4.69288 12.7587 4.69816C12.6384 4.69886 12.6018 4.67215 12.6025 4.54595C12.6085 3.65066 12.6078 2.75537 12.6033 1.86008C12.6025 1.74163 12.6286 1.70156 12.7559 1.70191C14.4533 1.70648 16.151 1.70648 17.8484 1.70191C17.9722 1.70191 18.0024 1.7346 18.0017 1.85657C17.9964 2.75186 17.9943 3.64715 18.0028 4.54244C18.0042 4.68796 17.9518 4.69781 17.8336 4.6971C16.9907 4.69288 16.1478 4.69464 15.3049 4.69499V4.69534Z" fill="white" />
                                                        <path d="M23.6943 3.50976C24.5376 3.50976 25.3808 3.51398 26.224 3.50589C26.3752 3.50449 26.4083 3.54913 26.4027 3.69149C26.3911 3.98359 26.3953 4.27675 26.4013 4.56955C26.4034 4.66622 26.3791 4.69856 26.2771 4.6982C24.5615 4.69539 22.8454 4.69539 21.1298 4.6982C21.0295 4.6982 20.9993 4.66938 21.0014 4.56955C21.0074 4.27112 21.0102 3.97234 21.0007 3.67391C20.9965 3.54421 21.0306 3.50519 21.1649 3.50624C22.0082 3.51257 22.8514 3.50941 23.6947 3.50941L23.6943 3.50976Z" fill="white" />
                                                        <path d="M7.21064 4.69615C6.46164 4.69615 5.71264 4.69369 4.96398 4.69897C4.84548 4.69967 4.80258 4.67682 4.8068 4.54852C4.817 4.24482 4.81383 3.94041 4.80821 3.63636C4.8061 3.53618 4.83739 3.50771 4.93691 3.50806C6.45215 3.51087 7.96774 3.51052 9.48298 3.50806C9.57475 3.50806 9.60289 3.53372 9.60148 3.62617C9.5962 3.9362 9.59374 4.24623 9.60218 4.5559C9.60535 4.67225 9.57089 4.69967 9.45766 4.69897C8.70865 4.69369 7.96 4.69615 7.211 4.69651L7.21064 4.69615Z" fill="white" />
                                                    </svg>
                                                    <h4 className="team-headers">{data.showroom_name}</h4>
                                                </div>
                                                <i className={`arrow-icon mdi ${showroomVisibility[data.showroom_name] ? "mdi-chevron-down" : "mdi-chevron-right"}`} style={{ textAlign: "end", padding: "0px 10px 0 0" }}></i>
                                            </div>
                                            {!showroomVisibility[data.showroom_name] && (
                                                <div className="table-responsive mt-0">
                                                    <table className="table table-1 custom-border">
                                                        {/* <thead className="color-native-blue">
                                                    <tr>
                                                        <th> </th>
                                                        {data.total.map((tl, tlIdx) => (
                                                            <th key={tlIdx}>{tl.lead_status}</th>
                                                        ))}
                                                    </tr>
                                                </thead> */}
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ height: "50px" }} className="text-align-l custom-width">Achievement</td>
                                                                {data.total && data.total.map((showroomCount, showroomCountIdx, array) => (
                                                                    <td style={{ width: "10%", height: "50px" }} key={showroomCountIdx} rowSpan={showroomCountIdx === array.length - 1 ? 2 : 1}>
                                                                        {showroomCount.lead_count}
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                            <tr>
                                                                <td className="text-align-l custom-width" style={{ height: "50px" }}>Target</td>
                                                                {data.total && data.total.slice(0, -1).map((showroomCount, showroomCountIdx, array) => (
                                                                    <td style={{ width: "10%", height: "50px" }} key={showroomCountIdx}>{showroomCount.lead_target}</td>
                                                                ))}
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                            {showroomVisibility[data.showroom_name] && (
                                                <>
                                                    {data.team && data.team.map((team, teamIdx) => (
                                                        <>
                                                            <div className="table-responsive table-custom-border" style={{ padding: '0 8px', marginTop: '0px', }} key={teamIdx}>
                                                                <div className='d-flex justify-content-between' style={{ cursor: 'pointer', height: "50px" }} onClick={() => { toggleTeamMembersVisibility(team.team_name) }}>
                                                                    {/* <h4 className="mt-2 ml-2">{team.team_name}</h4> */}
                                                                    <div className='d-flex align-items-center' style={{ padding: '2.8px', gap: '5px' }}>
                                                                        <PeopleAltIcon fontSize='small' />
                                                                        <h4 className="team-headers">{team.team_name}</h4>
                                                                    </div>
                                                                    <i className={`arrow-icon mdi ${teamMembersVisibility[team.team_name] ? "mdi-chevron-down" : "mdi-chevron-right"}`} style={{ textAlign: "end", padding: "14px 10px 0 0" }}></i>
                                                                    {/* className={`mdi ${teamMembersVisibility[team.id] ? "mdi-chevron-up" : "mdi-chevron-down"}` */}
                                                                </div>
                                                                <div className="table-responsive mt-0">
                                                                    <table className="table table-1 custom-border">
                                                                        {/* <thead className="color-native-blue">
                                                                        <tr>
                                                                            <th> </th>
                                                                            {team.lead_data.map((teamCount, teamCountIdx) => (
                                                                                <th key={teamCountIdx}>{teamCount.lead_status}</th>
                                                                            ))}
                                                                        </tr>
                                                                    </thead> */}
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="text-align-l custom-width" style={{ height: "50px" }}>Achievement</td>
                                                                                {team.lead_data.map((teamCount, teamCountIdx, array) => (
                                                                                    <td style={{ width: "10%", height: "50px" }} key={teamCountIdx} rowSpan={teamCountIdx === array.length - 1 ? 2 : 1}>{teamCount.lead_count}</td>
                                                                                ))}
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-align-l custom-width" style={{ height: "50px" }}>Target</td>
                                                                                {team.lead_data.slice(0, -1).map((teamCount, teamCountIdx) => (
                                                                                    <td style={{ width: "10%", height: "50px" }} key={teamCountIdx}>{teamCount.target_count}</td>
                                                                                ))}
                                                                            </tr>
                                                                            {/* <tr>
                                                                                <td className="text-align-l">Target</td>
                                                                                {team.lead_data.map((teamCount, teamCountIdx, array) => (
                                                                                    <td key={teamCountIdx} >{teamCount.target_count}</td>
                                                                                ))}
                                                                            </tr> */}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                {teamMembersVisibility[team.team_name] && (
                                                                    <>
                                                                        {data.team && data.team.filter((team) => selectedTeam ? team.team_name === selectedTeam : true).map((team, teamIdx) => (
                                                                            <>
                                                                                {team.lead_data[0].team_members.map((user, userIdx) => (
                                                                                    <div className="table-responsive table-custom-border-1">
                                                                                        <div className='d-flex align-items-center ml-2' style={{ padding: '3.5px', gap: '5px', height: "50px" }}>
                                                                                            <PersonIcon fontSize='small' />
                                                                                            <h4 className="team-headers">{user.user_name}</h4>
                                                                                        </div>
                                                                                        <table className="table table-1 custom-border">
                                                                                            <tbody>
                                                                                                <>
                                                                                                    <tr key={userIdx}>
                                                                                                        <td className="text-align-l custom-width" style={{ height: "50px" }}>Achievement</td>
                                                                                                        {team.lead_data.map((empCount, empCountIdx, array) => (
                                                                                                            <td style={{ width: "10%", height: "50px" }} key={empCountIdx} rowSpan={empCountIdx === array.length - 1 ? 2 : 1}>
                                                                                                                {empCount.team_members[userIdx].lead_count}
                                                                                                            </td>
                                                                                                        ))}
                                                                                                    </tr>
                                                                                                    <tr key={userIdx}>
                                                                                                        <td className="text-align-l custom-width" style={{ height: "50px" }}>Target</td>
                                                                                                        {team.lead_data.slice(0, -1).map((empCount, empCountIdx) => (
                                                                                                            <td style={{ width: "10%", height: "50px" }} key={empCountIdx}>
                                                                                                                {empCount.team_members[userIdx].target_count}
                                                                                                            </td>
                                                                                                        ))}
                                                                                                    </tr>
                                                                                                </>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                ))}
                                                                            </>
                                                                        ))}
                                                                    </>
                                                                )}
                                                            </div >
                                                        </>
                                                    ))}
                                                    <div className="table-responsive" style={{ padding: '0 8px' }}>
                                                        <h4 className="mt-3 ml-2 mb-3 mr-2 team-headers">Total</h4>
                                                        <div className="table-responsive mt-0">
                                                            <table className="table table-2 custom-border">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="text-align-l custom-width" style={{ height: "50px" }}>TA</td>
                                                                        {userDashBoardETVBRL.total_counts.map((dataCount, dataCountIdx, array) => (
                                                                            <td style={{ width: "10%", height: "50px" }} key={dataCountIdx} rowSpan={dataCountIdx === array.length - 1 ? 2 : 1}>{dataCount.lead_count}</td>
                                                                        ))}
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-align-l custom-width" style={{ height: "50px" }}>TT</td>
                                                                        {userDashBoardETVBRL.total_counts.slice(0, -1).map((dataCount, dataCountIdx) => (
                                                                            <td style={{ width: "10%", height: "50px" }} key={dataCountIdx}>{dataCount.target_count}</td>
                                                                        ))}
                                                                    </tr>
                                                                </tbody>
                                                            </table >
                                                        </div>
                                                    </div>
                                                </ >
                                            )}
                                        </div >
                                    </>
                                ))}
                            </div >
                        </div >
                    </div>
                </>
            ) : <div className="table-responsive">
                <table className="table table-1 custom-border">
                    <tbody>
                        <tr>
                            <td className="text-align-l">No Data Available</td>
                        </tr>
                    </tbody>

                </table>
            </div>}
        </>
    );
};

export default UserDashboardETVBRLTable;
