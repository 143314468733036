import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { APIData } from '../../common/DataTypes';
import { ServerAPI } from '../../common/ServerAPI';
import { useAppStateAPI } from '../../common/AppStateAPI';
import PersonIcon from '@mui/icons-material/Person';
import ConfirmDialog from '../ConfirmDialog';

const UserDashboardTarget: React.FC<{ dateMonth: string }> = ({ dateMonth }) => {
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [showRoomList, setShowroomList] = useState<APIData.ShowroomList>();
    const [activeShowroom, setActiveShowroom] = useState(0);
    const [targetList, setTargetList] = useState<APIData.UserTargetList>();
    const [showroomUUID, setShowroomUUID] = useState<string>("");
    const [teamMembersVisibility, setTeamMembersVisibility] = useState<{ [key: string]: boolean }>({});
    const [selectedTeam, setSelectedTeam] = useState<string | null>(null);
    const [retailCount, setRetailCount] = useState<{ [key: string]: string }>({});
    const [targetCount, setTargetCount] = useState<{ [empUuid: string]: { [userUuid: string]: string } }>({});
    const [inputValue, setInputValue] = useState('');
    const [employeeUUID, setEmployeeUUID] = useState('');
    const [tasktargetUUID, setTaskTargetUUID] = useState<string>('');
    const [isEditRetail, setIsEditRetail] = useState(false);
    const [isEditTarget, setIsEditTarget] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState<null | string>(null);
    const [employeeData, setEmployeeData] = useState({});
    const [updatedEmployeeData, setUpdatedEmployeeData] = useState({});
    const [accessAllowed, setAccessAllowed] = useState(false);
    const [showFreezeConfirm, setShowFreezeConfirm] = useState(false);

    //useEffect for get the all data of the particular employee
    useEffect(() => {
        if (targetList && targetList.data && employeeUUID !== '') {
            const employeeDataMap: { [empUuid: string]: any } = {};
            targetList.data.forEach(dataItem => {
                dataItem.team.forEach(team => {
                    team.lead_data.forEach(leadData => {
                        leadData.team_members.forEach(member => {
                            if (member.user_id === employeeUUID) {
                                if (!employeeDataMap[employeeUUID]) {
                                    employeeDataMap[employeeUUID] = {};
                                }
                                const { status_uuid, target_count } = member;
                                if (!employeeDataMap[employeeUUID].statusTarget) {
                                    employeeDataMap[employeeUUID].statusTarget = [];
                                }
                                employeeDataMap[employeeUUID].statusTarget.push({ status: status_uuid, target: target_count.toString() });
                            }
                        });
                    });
                });
            });
            setEmployeeData(employeeDataMap);
        }
    }, [targetList, employeeUUID]);

    const compareAndUpdateEmployeeData = () => {
        if (targetList && targetList.data && employeeUUID !== '') {
            const updatedEmployeeData: { [empUuid: string]: any } = { ...employeeData };

            Object.keys(updatedEmployeeData).forEach((empUuid: string) => {
                if (targetCount[empUuid]) {
                    const targetCounts = targetCount[empUuid];

                    updatedEmployeeData[empUuid].statusTarget.forEach((statusTarget: any, index: number) => {
                        const { status, target } = statusTarget;

                        if (targetCounts[status]) {
                            // Update target count in employeeData
                            updatedEmployeeData[empUuid].statusTarget[index].target = targetCounts[status];
                        }
                    });
                }
            });

            setUpdatedEmployeeData(updatedEmployeeData);
        }
    };

    useEffect(() => {
        compareAndUpdateEmployeeData();
    }, [targetCount]);

    const handleRetailCountChange = (userIdx: string, e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setRetailCount((prevTargetCounts) => ({
            ...prevTargetCounts,
            [userIdx]: newValue,
        }));
        setInputValue(e.target.value);
    };

    const handleTargetCountChange = (empUuid: string, statusUUID: string, e: React.ChangeEvent<HTMLInputElement>, taskUUID: string) => {
        const newValue = e.target.value;
        setTargetCount((prevTargetCounts) => ({
            ...prevTargetCounts,
            [empUuid]: {
                ...prevTargetCounts[empUuid],
                [statusUUID]: newValue,
            },
        }));
        setEmployeeUUID(empUuid);
        setTaskTargetUUID(taskUUID);
    };

    const toggleTeamMembersVisibility = (teamId: string) => {
        setTeamMembersVisibility((prevVisibility) => {
            const newVisibility = { [teamId]: !prevVisibility[teamId] };
            Object.keys(prevVisibility).forEach((prevTeamId) => {
                if (prevTeamId !== teamId) {
                    newVisibility[prevTeamId] = false;
                }
            });
            setSelectedTeam(newVisibility[teamId] ? teamId : null);
            return newVisibility;
        });
    };

    const handleShowroomSelection = (index: number, uuid: string) => {
        setActiveShowroom(index);
        setShowroomUUID(uuid);
    };

    const fetchShowroomLists = () => {
        addProcessingRequests();
        ServerAPI.getShowrooms()
            .then((response: any) => {
                if (response['success'] === true) {
                    setShowroomList({ data: { list: response.data.list } });
                    const firstShowRoomUUID = response.data.list[0].uuid;
                    if (firstShowRoomUUID !== "" && dateMonth !== "") {
                        setShowroomUUID(response.data.list[0].uuid);
                        fetchTargetList(firstShowRoomUUID, dateMonth);
                    }
                } else if (response['message']) {
                    toast.error(response['message']);
                } else {
                    toast.error('Something Went Wrong');
                }
            })
            .finally(() => {
                reduceProcessingRequests();
                // setShowroomUUID('');
            });
    };

    const fetchTargetList = (showroomUUID: string, dateMonth: string) => {
        addProcessingRequests()
        ServerAPI.getTargetList(showroomUUID, dateMonth)
            .then((response: any) => {
                setAccessAllowed(response.access ? true : false);
                setTargetList({ total_retail: response.total_retail, data: response.data, total_counts: response.total_counts });
            }).finally(() => {
                reduceProcessingRequests()
            });;
    };

    const addTarget = (showroomUUID: string, month: string, teamData: { team_uuid: string; employee: { emp_uuid: string; status_target: { status: string; target: string } }[] }[]) => {
        addProcessingRequests()
        ServerAPI.addTarget(showroomUUID, month, teamData)
            .then((response: any) => {
                if (response && response.success) {
                    fetchTargetList(showroomUUID, dateMonth);
                    if (response.message) {
                        toast.success(response.message);
                    }
                } else {
                    toast.error("Failed to add target");
                }
            }).finally(() => {
                reduceProcessingRequests()
            });
    };

    // const updateTarget = (month: string, employeeUUID: string, taskTargetUUID: string, showroomUUID: string, statusTarget: { status: string; target: string }[]) => {
    //     ServerAPI.updateTarget(month, employeeUUID, taskTargetUUID, showroomUUID, statusTarget)
    //         .then((response: any) => {
    //             console.log(response);
    //         });
    // };


    const updateTarget = (dateMonth: string, employeeUUID: string, taskTargetUUID: string, showroomUUID: string) => {

        const statusTargetArray: { status: string; target: string }[] = [];
        Object.values(updatedEmployeeData).forEach((employee: any) => {
            employee.statusTarget.forEach((statusTarget: any) => {
                statusTargetArray.push({
                    status: statusTarget.status,
                    target: statusTarget.target
                });
            });
        });

        addProcessingRequests();
        ServerAPI.updateTarget(dateMonth, employeeUUID, taskTargetUUID, showroomUUID, statusTargetArray)
            .then((response: any) => {
                if (response && response.success) {
                    if (response.message) {
                        toast.success(response.message);
                        fetchTargetList(showroomUUID, dateMonth);
                    }
                } else {
                    toast.error("Failed to update target");
                }
            }).finally(() => {
                reduceProcessingRequests()
            });
    };

    const freezeTarget = () => {
        addProcessingRequests();
        ServerAPI.freezeTarget(dateMonth, showroomUUID)
            .then((response: any) => {
                if (response && response.success === true) {
                    fetchTargetList(showroomUUID, dateMonth);
                    if (response.message) {
                        toast.success(response.message);
                    }
                } else if (response.error_messages) {
                    toast.error(response.error_messages);
                }
            }).finally(() => {
                reduceProcessingRequests()
            });;
    }


    useEffect(() => {
        fetchShowroomLists();
    }, []);

    useEffect(() => {
        if (showroomUUID !== "" && dateMonth !== "") {
            fetchTargetList(showroomUUID, dateMonth);
        }
    }, [showroomUUID, dateMonth]);


    return (
        <>
            {showRoomList !== undefined && (
                <div className="showroomlist-btncon">
                    {showRoomList.data.list.map((showroom, index) => (
                        <button
                            key={index}
                            className={`showroomlist-btn ${activeShowroom === index ? 'active' : ''}`}
                            onClick={() => handleShowroomSelection(index, showroom.uuid)}
                        >
                            {showroom.showroom_name}
                        </button>
                    ))}
                </div>
            )}
            <div>
                {targetList !== undefined && targetList.data && targetList.data.length > 0 ? (
                    <>
                        <div className="table-responsive color-native-blue mt-1 table-noborder" style={{ height: '47.7px', overflowY: 'hidden', width: '99%' }}>
                            <table className="table table-1 custom-border">
                                <thead className="color-native-blue" style={{ padding: "0 8px" }}>
                                    <tr>
                                        <th className='custom-width'>
                                            {targetList.data[0].freeze === 0 && <button className='showroom-freeze-btn' onClick={() => setShowFreezeConfirm(true)}>Freeze Target</button>}
                                        </th>
                                        {targetList.data[0].team[0].lead_data.slice(0, -1).map((empStatus, empStatusIdx) => (
                                            <th style={{ width: "10%" }} key={empStatusIdx}>
                                                {empStatus.team_members[0].status_name}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <p className='target-totalretails'>Overall Showroom Total Retails <span className='et-totalleads-span'>{targetList.total_retail}</span></p>
                        <div className='custom-tablescroll-1'>
                            <div className="table-container">
                                <div className="table-scroll">
                                    {targetList.data.map((data) => (
                                        <>
                                            {data.team.map((team, teamIdx) => (
                                                <div className="table-responsive pl-2 pr-2" key={teamIdx}>
                                                    <div className='d-flex justify-content-between' style={{ height: '50px', cursor: 'pointer' }} onClick={() => { toggleTeamMembersVisibility(team.team_uuid); setRetailCount({}); setIsEditRetail(false); setIsEditTarget(false) }}>
                                                        <div className='d-flex align-items-center' style={{ gap: '5px' }}>
                                                            <PeopleAltIcon fontSize='small' />
                                                            <h4 className='mt-2 team-headers'>{team.team_name}</h4>
                                                        </div>
                                                        <div className='d-flex align-items-center ml-2'>
                                                            <p className='et-totalleads' style={{ margin: '5px' }}>Overall Retails <span className='et-totalleads-span'>{team.retail_count}</span></p>
                                                            <i
                                                                className={`arrow-icon mdi ${teamMembersVisibility[team.team_uuid] ? "mdi-chevron-down" : "mdi-chevron-right"}`}
                                                                style={{ textAlign: "end" }}
                                                            ></i>
                                                        </div>
                                                    </div>
                                                    {teamMembersVisibility[team.team_uuid] && (
                                                        <table className="table table-1">
                                                            <tbody>
                                                                <div>
                                                                    {data.team.filter((team) => selectedTeam ? team.team_uuid === selectedTeam : true).map((team, teamIdx) => (
                                                                        <>
                                                                            {team.lead_data[0].team_members.map((user, userIdx) => (
                                                                                <>
                                                                                    <div className="table-responsive mt-0">
                                                                                        <div className='d-flex align-items-center justify-content-between ml-2 mr-2' style={{ height: '50px' }}>
                                                                                            <div className='d-flex align-items-center'>
                                                                                                <PersonIcon fontSize='small' style={{ color: '#14408B' }} />
                                                                                                <h4 className="team-headers">{user.user_name}</h4>
                                                                                            </div>
                                                                                            <div className='d-flex align-items-center ml-2 mr-2' style={{ height: '50px' }} >
                                                                                                <p className='et-totalleads' style={{ margin: '5px 10px', color: '#303e67' }}>
                                                                                                    Retail
                                                                                                    {(isEditTarget || isEditRetail) && selectedUserId === user.user_id ?
                                                                                                        <input
                                                                                                            className='et-totalleads-span'
                                                                                                            maxLength={4}
                                                                                                            type='text' value={isEditRetail ?
                                                                                                                (retailCount[user.user_id] !== undefined ? retailCount[user.user_id] : team.lead_data[team.lead_data.length - 1].team_members[userIdx].target_count)
                                                                                                                :
                                                                                                                (targetCount[user.user_id] !== undefined && targetCount[user.user_id][team.lead_data[team.lead_data.length - 1].team_members[userIdx].status_uuid] !== undefined) ? targetCount[user.user_id][team.lead_data[team.lead_data.length - 1].team_members[userIdx].status_uuid] : team.lead_data[team.lead_data.length - 1].team_members[userIdx].target_count
                                                                                                            }
                                                                                                            onChange={(e) => {
                                                                                                                if (isEditRetail) {
                                                                                                                    handleRetailCountChange(user.user_id, e);
                                                                                                                } else {
                                                                                                                    handleTargetCountChange(user.user_id, team.lead_data[team.lead_data.length - 1].team_members[userIdx].status_uuid, e, team.lead_data[team.lead_data.length - 1].team_members[userIdx].task_target_uuid)
                                                                                                                }
                                                                                                            }} />
                                                                                                        :
                                                                                                        <span className='et-totalleads-span'>{retailCount[user.user_id] !== undefined ? retailCount[user.user_id] : team.lead_data[team.lead_data.length - 1].team_members[userIdx].target_count}</span>
                                                                                                    }
                                                                                                    {team.lead_data[team.lead_data.length - 1].team_members[userIdx].target_count === 0 &&
                                                                                                        <i className={`fas ${isEditRetail && selectedUserId === user.user_id ? 'fa-check' : 'fa-edit'} text-info font-16`}
                                                                                                            onClick={() => {
                                                                                                                if (data.freeze) {
                                                                                                                    toast.error('The Target is Freezed');
                                                                                                                } else if (accessAllowed) {
                                                                                                                    if (isEditRetail && selectedUserId === user.user_id) {
                                                                                                                        addTarget(data.showroom_uuid, dateMonth, [{ team_uuid: team.team_uuid, employee: [{ emp_uuid: user.user_id, status_target: { status: user.status_uuid, target: inputValue, }, },], },]);
                                                                                                                        setIsEditRetail(false);
                                                                                                                    } else {
                                                                                                                        setIsEditRetail(true);
                                                                                                                        setSelectedUserId(user.user_id);
                                                                                                                    }
                                                                                                                } else {
                                                                                                                    toast.error('Edit Access Not Allowed');
                                                                                                                }
                                                                                                            }}
                                                                                                            style={{ color: '#9ba7ca', backgroundColor: '#fff', boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)', cursor: 'pointer', padding: "3px 5px" }}>
                                                                                                        </i>
                                                                                                    }
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <table className="table table-1 custom-border">
                                                                                            <tbody>
                                                                                                {team.lead_data[team.lead_data.length - 1].team_members[userIdx].target_count !== 0 ? (
                                                                                                    <>
                                                                                                        {isEditTarget && selectedUserId === user.user_id ? (
                                                                                                            <tr key={userIdx}>
                                                                                                                <td className='custom-width'>
                                                                                                                    <i className="fas fa-check text-info font-16" onClick={() => { const selectedShowroomUUID = showRoomList?.data.list[0]?.uuid || ''; updateTarget(dateMonth, employeeUUID, tasktargetUUID, showroomUUID || selectedShowroomUUID); setIsEditTarget(false) }} style={{ color: '#9ba7ca', backgroundColor: '#fff', boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)', padding: "3px 5px", cursor: 'pointer' }}></i>
                                                                                                                </td>
                                                                                                                {team.lead_data.slice(0, -1).map((empCount, empCountIdx) => (
                                                                                                                    <td key={empCountIdx} style={{ width: "10%" }}>
                                                                                                                        <input type="text" maxLength={4} value={targetCount[user.user_id]?.[empCount.team_members[userIdx].status_uuid] !== undefined ? targetCount[user.user_id]?.[empCount.team_members[userIdx].status_uuid] : empCount.team_members[userIdx].target_count} onChange={(e) => handleTargetCountChange(user.user_id, empCount.team_members[userIdx].status_uuid, e, empCount.team_members[userIdx].task_target_uuid)} style={{ border: "none", outline: 'none', width: 'fix-content', maxWidth: '40px', height: '16px', textAlign: "center" }} />
                                                                                                                    </td>
                                                                                                                ))}
                                                                                                            </tr>
                                                                                                        ) : (
                                                                                                            <tr key={userIdx}>
                                                                                                                <td className='custom-width'>
                                                                                                                    <i className="fas fa-edit text-info font-16 tagname"
                                                                                                                        onClick={() => {
                                                                                                                            if (data.freeze) {
                                                                                                                                toast.error('Target is Freezed');
                                                                                                                            }
                                                                                                                            if (accessAllowed) {
                                                                                                                                setIsEditTarget(true);
                                                                                                                                setSelectedUserId(user.user_id);
                                                                                                                            } else {
                                                                                                                                toast.error('Edit Access Not Allowed');
                                                                                                                            }
                                                                                                                        }}
                                                                                                                        style={{ color: '#9ba7ca', backgroundColor: '#fff', boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)', cursor: 'pointer', padding: "3px 5px" }} />
                                                                                                                </td>
                                                                                                                {team.lead_data.slice(0, -1).map((empCount, empCountIdx) => (
                                                                                                                    <td key={empCountIdx} style={{ width: "10%" }}>
                                                                                                                        {/* <span>{targetCount[user.user_id]?.[empCount.team_members[userIdx].status_uuid] !== undefined ? targetCount[user.user_id]?.[empCount.team_members[userIdx].status_uuid] : empCount.team_members[userIdx].target_count}</span> */}
                                                                                                                        <input type="text" maxLength={4} value={targetCount[user.user_id]?.[empCount.team_members[userIdx].status_uuid] !== undefined ? targetCount[user.user_id]?.[empCount.team_members[userIdx].status_uuid] : empCount.team_members[userIdx].target_count} onChange={(e) => handleTargetCountChange(user.user_id, empCount.team_members[userIdx].status_uuid, e, empCount.team_members[userIdx].task_target_uuid)} style={{ border: "none", outline: 'none', width: 'fix-content', maxWidth: '40px', height: '16px', textAlign: "center" }} readOnly={team.lead_data[team.lead_data.length - 1].team_members[userIdx].target_count !== 0} />
                                                                                                                    </td>
                                                                                                                ))}
                                                                                                            </tr>
                                                                                                        )}

                                                                                                    </>

                                                                                                ) : (
                                                                                                    <tr key={userIdx}>
                                                                                                        <td></td>
                                                                                                        {team.lead_data.slice(0, -1).map((empCount, empCountIdx) => (
                                                                                                            <td key={empCountIdx}>
                                                                                                                <span>{targetCount[user.user_id]?.[empCount.team_members[userIdx].status_uuid] !== undefined ? targetCount[user.user_id]?.[empCount.team_members[userIdx].status_uuid] : empCount.team_members[userIdx].target_count}</span>
                                                                                                            </td>
                                                                                                        ))}

                                                                                                    </tr>
                                                                                                )}
                                                                                            </tbody>
                                                                                        </table>

                                                                                    </div>
                                                                                </>
                                                                            ))}
                                                                        </>
                                                                    ))}
                                                                </div>
                                                            </tbody>
                                                        </table>
                                                    )}
                                                </div>
                                            ))}
                                            {/* <div className="table-responsive color-native-blue table-noborder" style={{ height: '50px', overflowY: 'hidden' }}>
                                                <table className="table table-2 custom-border">
                                                    <thead className="color-native-blue" style={{ padding: "0 8px" }}>
                                                        <tr>
                                                            <th className='text-align-l custom-width'>Total</th>
                                                            {targetList.total_counts.slice(0, -1).map((data, dataIdx) => (
                                                                <th style={{ width: "10%" }} key={dataIdx}>{data.target_count}</th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div> */}
                                            <div className="table-responsive color-native-blue table-noborder" style={{ height: '50px', overflowY: 'hidden' }}>
                                                <table className="table table-2 custom-border">
                                                    <thead className="color-native-blue" style={{ padding: "0 8px" }}>
                                                        <tr>
                                                            <th className='text-align-l custom-width'>Total</th>
                                                            {targetList.total_counts.slice(0, -1).map((data, dataIdx, array) => {
                                                                const isLast = dataIdx === array.length - 1;
                                                                const isSecondToLast = dataIdx === array.length - 2;
                                                                return (
                                                                    <th style={{ width: "10%", paddingLeft: isLast || isSecondToLast ? "0" : "" }} key={dataIdx}> {data.target_count}</th>
                                                                );
                                                            })}
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>

                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>
                ) : <div className="table-responsive">
                    <table className="table table-1 custom-border">
                        <tbody>
                            <tr>
                                <td className="text-align-l">No Data Available</td>
                            </tr>
                        </tbody>

                    </table>
                </div>}
            </div>
            <ConfirmDialog showConfirm={showFreezeConfirm} confirmHeading={'Freeze Target Confirm'} confirmMsg={'Are you sure you want to freeze the target?'} handleNo={() => {
                //setDeleteEntryID('');
                setShowFreezeConfirm(false);
            }} handleYes={() => {
                freezeTarget()
                setShowFreezeConfirm(false);
            }} />
        </>
    );
};

export default UserDashboardTarget;
