
const CellOdoMeterReading: React.FC<{ data: any }> = ({ data }) => {
    return (
        <><small>Start KM: {data['odometer_start_reading']}</small><br />
            <small>End KM: {data['odometer_end_reading']}</small><br />
            <small>Diff: {data['km_run']}</small>
        </>
    )
}

export default CellOdoMeterReading;