import { Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { API, ProjectStrings } from "../common/Constants";
import { frameSelectOptions, getDataFromAPI } from "../common/Utilities";

const PincodeEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, }> = ({ showDialog, closeDialog, reloadData, id }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.Pincode>>(null);
    const [initialValue, setInitialValue] = useState<APIData.Pincode>(InitialData.Pincode);
    const [formDataSet, setFormDataSet] = useState(false);
    const [stateOptions, setStateOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [cityOptions, setCityOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedStateID, setSelectedStateID] = useState('');
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        if (id !== undefined && id !== '') {
            addProcessingRequests();
            ServerAPI.getPincode(id).then(response => {
                if (response && response.data && response.data.details) {
                    setInitialValue(response.data.details);
                    setFormDataSet(true);
                    setEditMode(true);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else {
            setFormDataSet(true);
        }
    }, [id]);

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.StateList, setStateOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'state_name', 'data', 'list');
    }, []);

    const loadCities = () => {
        addProcessingRequests();
        ServerAPI.getCities(selectedStateID).then(response => {
            if (response && response.data && response.data.list) {
                let options = frameSelectOptions(response.data.list, 'uuid', 'city_name');
                setCityOptions(options);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    useEffect(() => {
        if (selectedStateID) {
            loadCities();
        }
    }, [selectedStateID])

    const yupSchema = Yup.object().shape({
        state_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        city_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        pincode: Yup.string().required(ProjectStrings.ValidationRequired),
    });

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-min">
            {formDataSet && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };
                    delete submitValues.state_name;
                    delete submitValues.city_name;
                    if (id != undefined && id != '') {
                        addProcessingRequests();
                        ServerAPI.updatePincode(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            } else {
                                toast.error('Unknown Error Occurred');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    } else {
                        addProcessingRequests();
                        ServerAPI.addPincode(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            } else {
                                toast.error('Unknown Error Occurred');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    }
                }} >
                    {(formikProps) => {
                        if (formikProps.values.state_uuid !== selectedStateID) {
                            setSelectedStateID(formikProps.values.state_uuid);
                        }
                        return (
                            <div className="tabdetails">
                                <h4 className="title-text text-center">{editMode ? 'Edit' : 'Add New'} Pincode</h4>
                                <div className="custom-border-grey my-2"></div>
                                <p className="detailed-heading">Pincode Details</p>
                                <div className="custom-border-grey my-2"></div>
                                <Form>
                                    <div className="px-2 form-field-container">
                                        <div className="row px-2 py-3">
                                            <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom-user">
                                                {/* <label className="label-custom-user"><p>State</p></label> */}
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "State Name", name: "state_uuid", placeholder: "State Name", options: stateOptions, required: true }} />
                                            </div>
                                        </div>
                                        <div className="row px-2 py-3">
                                            <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom-user">
                                                {/* <label className="label-custom-user"><p>City</p></label> */}
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "City Name", name: "city_uuid", placeholder: "City Name", options: cityOptions, required: true }} />
                                            </div>
                                        </div>
                                        <div className="row px-2 py-3">
                                            <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom-user">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Pincode", name: "pincode", placeholder: "Pincode", required: true }} />
                                            </div>
                                        </div>
                                        <div className="row px-2 py-3">
                                            <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom-user">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Landmark", name: "landmark", placeholder: "Landmark", required: true }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row btn-form-submit">
                                        <button type="button" className="button1" onClick={closeDialog}>Cancel</button>
                                        <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                            formikProps.submitForm();
                                            if (!formikProps.isValid) {
                                                toast.error("Please ensure all fields are Filled and Valid!");
                                            }
                                        }}>Submit</button>
                                    </div>
                                </Form>
                            </div>
                        )
                    }}
                </Formik>
            )}
        </Drawer>
    )
}

export default PincodeEntryDialog;