import { Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { ProjectStrings, uploadImageType } from "../common/Constants";

const HelpAndSupportEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, detailView: any, reloadData: any, id?: string, }> = ({ showDialog, closeDialog, reloadData, id, detailView }) => {

    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.HelpAndSupport>>(null);
    const [initialValue, setInitialValue] = useState<APIData.HelpAndSupport>(InitialData.HelpAndSupport);
    const [formDataSet, setFormDataSet] = useState(false);

    useEffect(() => {
        if (id !== undefined && id !== '') {
            addProcessingRequests();
            ServerAPI.getHelpAndSupportDetail(id).then(response => {
                if (response && response.data && response.data.details) {
                    setInitialValue(response.data.details);
                    setFormDataSet(true);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else {
            setFormDataSet(true);
        }
    }, [id]);

    const yupSchema = Yup.object().shape({
        issue: Yup.string().required(ProjectStrings.ValidationRequired),
        // image_url: Yup.string().required(ProjectStrings.ValidationRequired),
    });

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-min">
            {formDataSet && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };
                    addProcessingRequests();
                    ServerAPI.userAddHelpAndSupport(submitValues).then(response => {
                        if (response && response['success'] && response['message']) {
                            toast.success(response['message']);
                            reloadData();
                            closeDialog();
                        } else if (response && response['message']) {
                            toast.error(response['message']);
                        } else {
                            toast.error('Unknown Error Occurred');
                        }
                    }).finally(() => {
                        setSubmitting(false);
                        reduceProcessingRequests();
                    });
                }} >
                    {(formikProps) => {
                        return (
                            <div className="tabdetails">
                                <h4 className="title-text text-center">Help And Support</h4>
                                <div className="custom-border-grey my-2"></div>
                                <Form>
                                    <div className="px-2 form-field-container">
                                        <div className="row px-2 py-3">
                                            <div className={`col-md-12 col-lg-12 col-xl-12 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Issue", name: "issue", placeholder: "Enter Issue", required: true, readOnly: detailView ? true : false }} />
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-xl-12 text-field-empty-custom">
                                                <FormField formik={formikProps} fieldProps={{ fieldType: "fileupload", name: "image_url", label: "Image", placeholder: "Image", required: true, accept: "image/*", imageType: uploadImageType.DEMO_VEHICLE, readOnly: detailView ? true : false }} />
                                            </div>
                                        </div>
                                    </div>
                                    {!detailView && <div className="row btn-form-submit">
                                        <button type="button" className="button1" onClick={closeDialog}>Cancel</button>
                                        <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                            formikProps.submitForm();
                                            if (!formikProps.isValid) {
                                                toast.error("Please ensure all fields are Filled and Valid!");
                                            }
                                        }}>Submit</button>
                                    </div>}
                                </Form>
                            </div>
                        )
                    }}
                </Formik>
            )}
        </Drawer>
    )
}

export default HelpAndSupportEntryDialog;