import React, { useEffect, useState } from 'react';
import { APIData, FormDataTypes } from '../../common/DataTypes';
import { API, PageLinks } from '../../common/Constants';
import { getDataFromAPI } from '../../common/Utilities';
import { useAppStateAPI } from '../../common/AppStateAPI';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const UserInventoryFilter: React.FC<{ setInventoryParams: (id: (APIData.FilterInventory | undefined)) => void, showInventoryFilter?: boolean, inventoryFilterApplied: (show: boolean) => void, closeDialog: any }> = ({ setInventoryParams, showInventoryFilter, inventoryFilterApplied, closeDialog }) => {
    const [modelOptions, setModelOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [variantOptions, setVariantOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [statusOptions, setStatusOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [exteriorColorOptions, setExteriorColorOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [interiorColorOptions, setInteriorColorOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [fuelTypeOptions, setFuelTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [showroomOptions, setShowroomOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [stateOptions, setStateOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [interiorUUID, setInteriorUUID] = useState<string>("");
    const [exteriorUUID, setExteriorUUID] = useState<string>("");
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const customStyles = { control: (provided: any) => ({ ...provided, border: '2px solid #D3D3D3', '&:hover': { borderColor: '#14408B' }, borderRadius: '10px' }) };
    const [modelUUID, setModelUUID] = useState<string>("");
    const [variantUUID, setVariantUUID] = useState<string>("");
    const [whsCode, setWhsCode] = useState<string>("");
    const [statusUUID, setStatusUUID] = useState<string>("");
    const [showroomUUID, setShowroomUUID] = useState<string>("");
    const [stateUUID, setStateUUID] = useState<string>("");
    const [fuelTypeUUID, setFuelTypeUUID] = useState<string>("");
    const [modelYear, setModelYear] = useState<string>("");
    const [appliedFiter, setAppliedFilter] = useState(false);
    const [selectedDate, setSelectedDate] = useState<string>("");

    const handleModelChange = (selectedOption: FormDataTypes.SelectOption | null) => {
        setModelUUID(selectedOption?.value || "");
    };

    const handleVariantChange = (selectedOption: FormDataTypes.SelectOption | null) => {
        setVariantUUID(selectedOption?.value || "");
    };

    const handleStatusChange = (selectedOption: FormDataTypes.SelectOption | null) => {
        setStatusUUID(selectedOption?.value || "");
    };

    const handleInteriorChange = (selectedOption: FormDataTypes.SelectOption | null) => {
        setInteriorUUID(selectedOption?.value || "");
    };

    const handleExteriorChange = (selectedOption: FormDataTypes.SelectOption | null) => {
        setExteriorUUID(selectedOption?.value || "");
    };

    const handleFuelTypeChange = (selectedOption: FormDataTypes.SelectOption | null) => {
        setFuelTypeUUID(selectedOption?.value || "");
    };

    const handleModelYearChange = (selectedOption: FormDataTypes.SelectOption | null) => {
        setModelYear(selectedOption?.value || "");
    };

    const handleStateChange = (selectedOption: FormDataTypes.SelectOption | null) => {
        setStateUUID(selectedOption?.value || "");
    };

    const handleShowroomChange = (selectedOption: FormDataTypes.SelectOption | null) => {
        setShowroomUUID(selectedOption?.value || "");
    };

    const currentYear = new Date().getFullYear();
    const last50Years = Array.from({ length: 50 }, (_, index) => currentYear - index);


    const modelYearOptions = last50Years.map(year => ({
        value: year.toString(),
        label: year.toString(),
    }));


    const handleChange = (date: any) => {
        let formattedDateTime = "";
        if (date) {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');

            formattedDateTime = `${year}-${month}`;

        }
        setSelectedDate(formattedDateTime);
        console.log('Selected Date:', formattedDateTime);
    };

    const clearFilterParams = () => {
        setModelUUID("");
        setVariantUUID("");
        setStatusUUID("");
        setWhsCode("");
        setInteriorUUID("");
        setExteriorUUID("");
        setModelYear("");
        setFuelTypeUUID("");
        setSelectedDate("");
        setStateUUID("");
        setShowroomUUID("");
    };

    const clearFilter = () => {
        setInventoryParams(undefined);
        inventoryFilterApplied(false);
        setAppliedFilter(false);
        clearFilterParams();
        setAppliedFilter(false)
    };

    const handleFilter = () => {
        const filters: APIData.FilterInventory = {
            models_uuid: modelUUID,
            variant_uuid: variantUUID,
            vehicle_status_uuid: statusUUID,
            WHS_code: whsCode,
            interior_uuid: interiorUUID,
            exterior_uuid: exteriorUUID,
            fuel_type_uuid: fuelTypeUUID,
            model_year: modelYear,
            mf_year: selectedDate,
            showroom_uuid: showroomUUID,
            state_uuid: stateUUID
        };
        setInventoryParams(filters);
        inventoryFilterApplied(true);
        setAppliedFilter(true);
        closeDialog();
    };

    useEffect(() => {
        let params: { [k: string]: any } = {};
        getDataFromAPI(API.EndPoint.ShowroomList, setShowroomOptions, addProcessingRequests, reduceProcessingRequests, null, true, 'uuid', 'showroom_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.StateList, setStateOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'state_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.FuelTypeList, setFuelTypeOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'fuel_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.InteriorColorList, setInteriorColorOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'interior_color_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.ExteriorColorList, setExteriorColorOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'exterior_color_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.ModelList, setModelOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'model_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.ModelVariantList, setVariantOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'variant_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.UserInventoryVehicleStatus, setStatusOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'status_name', 'data', 'list');
    }, []);

    return (
        <>
            {showInventoryFilter && window.location.pathname === PageLinks.USER_INVENTORY && (
                <div className='tabdetails' style={{ height: "87vh", overflowY: "auto" }}>
                    <h4 className="title-text text-center" style={{ fontWeight: "400" }}>Filter</h4>
                    <div className="custom-border-grey my-2"></div>
                    <div className="form-group text-left mb-4 mt-2 lead-select">
                        <div className="input-group">
                            {/* <label className='label-custom' >WHS Code</label> */}
                            <input
                                type="text"
                                className="form-control"
                                id="phone"
                                placeholder="WHS Code"
                                value={whsCode}
                                onChange={(e) => setWhsCode(e.target.value)}
                                style={{ height: "47px" }}
                            />
                        </div>
                    </div>
                    <div className="form-group text-left mb-4">
                        <div className="ew-section-2">
                            <div className="text-field-empty-custom-user lead-select">
                                <label className='label-custom' style={{ left: "11px" }}>Model</label>
                                <Select
                                    styles={customStyles}
                                    onChange={handleModelChange}
                                    value={modelOptions.find((option) => option.value === modelUUID) || null}
                                    options={modelOptions}
                                    placeholder={"Select Model"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group text-left mb-4">
                        <div className="ew-section-2">
                            <div className="text-field-empty-custom-user lead-select">
                                <label className='label-custom' style={{ left: "11px" }}>Variant</label>
                                <Select
                                    styles={customStyles}
                                    onChange={handleVariantChange}
                                    value={variantOptions.find((option) => option.value === variantUUID) || null}
                                    options={variantOptions}
                                    placeholder={"Select Variant"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group text-left mb-4">
                        <div className="ew-section-2">
                            <div className="text-field-empty-custom-user lead-select">
                                <label className='label-custom' style={{ left: "11px" }}>Exterior Color</label>
                                <Select
                                    styles={customStyles}
                                    onChange={handleExteriorChange}
                                    value={exteriorColorOptions.find((option) => option.value === exteriorUUID) || null}
                                    options={exteriorColorOptions}
                                    placeholder={"Select Exterior"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group text-left mb-4">
                        <div className="ew-section-2">
                            <div className="text-field-empty-custom-user lead-select">
                                <label className='label-custom' style={{ left: "11px" }}>Interior Color</label>
                                <Select
                                    styles={customStyles}
                                    onChange={handleInteriorChange}
                                    value={interiorColorOptions.find((option) => option.value === interiorUUID) || null}
                                    options={interiorColorOptions}
                                    placeholder={"Select Interior"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group text-left mb-4">
                        <div className="ew-section-2">
                            <div className="text-field-empty-custom-user lead-select">
                                <label className='label-custom' style={{ left: "11px" }}>Fuel Type</label>
                                <Select
                                    styles={customStyles}
                                    onChange={handleFuelTypeChange}
                                    value={fuelTypeOptions.find((option) => option.value === fuelTypeUUID) || null}
                                    options={fuelTypeOptions}
                                    placeholder={"Select Fuel Type"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group text-left mb-4">
                        <div className="ew-section-2">
                            <div className="text-field-empty-custom-user lead-select">
                                <label className='label-custom' style={{ left: "11px" }}>Model Year</label>
                                <Select
                                    styles={customStyles}
                                    onChange={handleModelYearChange}
                                    value={modelYearOptions.find((option) => option.value === modelYear) || null}
                                    options={modelYearOptions}
                                    placeholder={"Select Model Year"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="tabdetails mb-4">
                        <div className="text-field-empty-custom-user-1 book-final lead-select">
                            <div className='mb-24'>
                                <DatePicker
                                    value={selectedDate}
                                    selected={selectedDate ? new Date(selectedDate) : null}
                                    onChange={handleChange}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    className="form-control height-date-picker"
                                    placeholderText={"Select MF Date"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group text-left mb-4">
                        <div className="ew-section-2">
                            <div className="text-field-empty-custom-user lead-select">
                                <label className='label-custom' style={{ left: "11px" }}>Status</label>
                                <Select
                                    styles={customStyles}
                                    onChange={handleStatusChange}
                                    value={statusOptions.find((option) => option.value === statusUUID) || null}
                                    options={statusOptions}
                                    placeholder={"Select Status"}
                                />
                            </div>
                        </div>
                    </div>
                </div >
            )}
            {
                showInventoryFilter && window.location.pathname === PageLinks.USER_INVENTORY_PRICE && (
                    <>
                        <h4 className="title-text text-center" style={{ fontWeight: "400" }}>Filter</h4>
                        <div className="custom-border-grey my-2"></div>
                        <div className="form-group text-left mb-4 mt-4">
                            <div className="ew-section-2">
                                <div className="text-field-empty-custom-user lead-select">
                                    <label className='label-custom' style={{ left: "11px" }}>Model</label>
                                    <Select
                                        styles={customStyles}
                                        onChange={handleModelChange}
                                        value={modelOptions.find((option) => option.value === modelUUID) || null}
                                        options={modelOptions}
                                        placeholder={"Select Model"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group text-left mb-4">
                            <div className="ew-section-2">
                                <div className="text-field-empty-custom-user lead-select">
                                    <label className='label-custom' style={{ left: "11px" }}>Variant</label>
                                    <Select
                                        styles={customStyles}
                                        onChange={handleVariantChange}
                                        value={variantOptions.find((option) => option.value === variantUUID) || null}
                                        options={variantOptions}
                                        placeholder={"Select Variant"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="tabdetails mb-4">
                            <div className="text-field-empty-custom-user-1 book-final lead-select">
                                <div className='mb-24'>
                                    <DatePicker
                                        value={selectedDate}
                                        selected={selectedDate ? new Date(selectedDate) : null}
                                        onChange={handleChange}
                                        dateFormat="MM/yyyy"
                                        showMonthYearPicker
                                        className="form-control height-date-picker"
                                        placeholderText={"Select Date"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group text-left mb-4">
                            <div className="ew-section-2">
                                <div className="text-field-empty-custom-user lead-select">
                                    <label className='label-custom' style={{ left: "11px" }}>Model Year</label>
                                    <Select
                                        styles={customStyles}
                                        onChange={handleModelYearChange}
                                        value={modelYearOptions.find((option) => option.value === modelYear) || null}
                                        options={modelYearOptions}
                                        placeholder={"Select Model Year"}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
            {
                showInventoryFilter && window.location.pathname === PageLinks.USER_AVAILABLE_INVENTORY && (
                    <>
                        <h4 className="title-text text-center" style={{ fontWeight: "400" }}>Filter</h4>
                        <div className="custom-border-grey my-2"></div>
                        <div className="form-group text-left mb-4 mt-4">
                            <div className="ew-section-2">
                                <div className="text-field-empty-custom-user lead-select">
                                    <label className='label-custom' style={{ left: "11px" }}>State</label>
                                    <Select
                                        styles={customStyles}
                                        onChange={handleStateChange}
                                        value={stateOptions.find((option) => option.value === stateUUID) || null}
                                        options={stateOptions}
                                        placeholder={"Select State"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group text-left mb-4">
                            <div className="ew-section-2">
                                <div className="text-field-empty-custom-user lead-select">
                                    <label className='label-custom' style={{ left: "11px" }}>Showroom</label>
                                    <Select
                                        styles={customStyles}
                                        onChange={handleShowroomChange}
                                        value={showroomOptions.find((option) => option.value === showroomUUID) || null}
                                        options={showroomOptions}
                                        placeholder={"Select Showroom"}
                                    />
                                </div>
                            </div>
                        </div>
                    </>)
            }
            <div className="row btn-form-submit" style={{ position: "absolute" }}>
                <button type="button" className="button1" onClick={closeDialog}>Cancel</button>
                <button type="button" className="button2" onClick={() => { handleFilter() }}>Filter</button>
            </div>
        </>
    );
};

export default UserInventoryFilter;