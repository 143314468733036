import { useEffect, useState } from "react";
import { StatusValues } from "../../common/Constants";

const CellStatusBadge: React.FC<{ data: any, data_key: string }> = ({ data, data_key }) => {

    const [statusClass, setStatusClass] = useState('');
    const [statusString, setStatusString] = useState('');

    useEffect(() => {
        if (data !== undefined && data[data_key] !== undefined) {
            if (data[data_key] == StatusValues.DELETED) {
                setStatusClass("badge-soft-danger");
                setStatusString("Deleted");
            } else if (data[data_key] == StatusValues.ACTIVE) {
                setStatusClass("badge-soft-success");
                setStatusString("Active");
            } else if (data[data_key] == StatusValues.INACTIVE) {
                setStatusClass("badge-soft-warning");
                setStatusString("In-Active");
            }
        }
    }, [data]);

    return (
        <span className={'badge ' + statusClass}>{statusString}</span>
    )

}

export default CellStatusBadge;