import { APIData, FormDataTypes } from '../../common/DataTypes';
import { useEffect, useState } from 'react';
import Select, { MultiValue, StylesConfig, components } from 'react-select';
import { API } from '../../common/Constants';
import { getDataFromAPI } from '../../common/Utilities';
import { useAppStateAPI } from '../../common/AppStateAPI';

interface AdditionalFilter {
    label: string;
    paramName: string;
    optional: boolean;
    options: FormDataTypes.SelectOption[];
}

const LeadsTab: AdditionalFilter = {
    label: 'Testdrive Type',
    paramName: 'testdrive_type',
    optional: false,
    options: [
        {
            label: "Pending",
            value: "1"
        },
        {
            label: "On Going",
            value: "2"
        },
        {
            label: "Completed",
            value: "3"
        },
    ]
};

const UserTesDriveFilterModal: React.FC<{ showModal: boolean, closeModal: () => void, filterLeads: any }> = ({ showModal, closeModal, filterLeads }) => {
    const customStyles = { control: (provided: any) => ({ ...provided, border: '2px solid #D3D3D3', '&:hover': { borderColor: '#14408B' }, borderRadius: '10px' }) };
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [status, setStatus] = useState("1");
    const [testdrivetype, setTestdrivetype] = useState("");
    const [testdriveLocationType, setTestdriveLocationType] = useState("");
    const [activeShowroom, setActiveShowroom] = useState(0);
    const [showTestDriveOptions, setShowTestDriveOptions] = useState(false);
    const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({
        testDrive: false,
        home: false,
        showroom: false,
        fuelRefill: false,
        showroomTransfer: false,
        others: false
    });
    const [showroomOptions, setShowroomOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [showroomUUID, setShowroomUUID] = useState<string[]>([]);
    console.log(showroomUUID, "showroomUUID")

    const handleShowroomChange = (selectedOptions: MultiValue<FormDataTypes.SelectOption>) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setShowroomUUID(selectedValues);
    };

    const removeSelectedOption = (value: string) => {
        setShowroomUUID(prevUUIDs => prevUUIDs.filter(uuid => uuid !== value));
    };

    useEffect(() => {
        let params: { [k: string]: any } = {};
        getDataFromAPI(API.EndPoint.ShowroomList, setShowroomOptions, addProcessingRequests, reduceProcessingRequests, null, true, 'uuid', 'showroom_name', 'data', 'list');
    }, []);

    const handleFilter = () => {
        const filters: APIData.FilterTestdrive = {
            status: parseInt(status),
            testdrive_type: parseInt(testdrivetype),
            testdrive_location_type: parseInt(testdriveLocationType)
        };
        filterLeads(filters);
    };

    const clearFilter = () => {
        setStatus("");
        setTestdrivetype("");
        setTestdriveLocationType("");
        setShowTestDriveOptions(false);
        setShowroomUUID([]);
        setCheckedItems({
            testDrive: false,
            home: false,
            showroom: false,
            fuelRefill: false,
            showroomTransfer: false,
            others: false
        });
    };

    const handleStatusSelection = (value: string, index: number) => {
        setStatus(value);
        setActiveShowroom(index);
    };

    const handleTestDriveCheckboxChange = (checked: boolean) => {
        setCheckedItems({
            testDrive: checked,
            home: false,
            showroom: false,
            fuelRefill: false,
            showroomTransfer: false,
            others: false
        });
        if (checked) {
            setTestdrivetype("1");
            setShowTestDriveOptions(true);
        } else {
            setTestdrivetype("");
            setTestdriveLocationType("");
            setShowTestDriveOptions(false);
        }
    };

    const handleTestDriveLocationChange = (type: string, checked: boolean) => {
        setCheckedItems(prev => ({
            ...prev,
            home: type === 'home' ? checked : false,
            showroom: type === 'showroom' ? checked : false
        }));
        setTestdriveLocationType(checked ? (type === 'home' ? "1" : "2") : "");
    };

    const handleAdditionalTestDriveChange = (type: string, checked: boolean) => {
        setCheckedItems({
            testDrive: false,
            home: false,
            showroom: false,
            fuelRefill: type === 'fuelRefill' ? checked : false,
            showroomTransfer: type === 'showroomTransfer' ? checked : false,
            others: type === 'others' ? checked : false
        });
        setTestdrivetype(checked ? type : "");
        setShowTestDriveOptions(false);
    };

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        minWidth: '400px',
        minHeight: '380px'
    };

    const ValueContainer = ({ children, ...props }: any) => {
        let [values, input] = children;

        if (Array.isArray(values)) {
            const plural = values.length === 1 ? "" : "s";
            values = `${values.length} item${plural} selected`;
        }

        return (
            <components.ValueContainer {...props}>
                {values}
                {input}
            </components.ValueContainer>
        );
    };

    return (
        <div className='tabdetails' style={{ height: "87vh", overflowY: "auto" }}>
            <h4 className="title-text text-center" style={{ fontWeight: "400" }}>Filter</h4>
            <div className="custom-border-grey my-2"></div>
            <div className='px-2 pb-2'>
                <div className="text-center auth-logo-text pb-2">
                    {/* <h4 className="mt-0 mb-3">Filter</h4> */}
                </div>
                {showroomOptions && showroomOptions.length > 1 && <><div className="form-group text-left mb-3 mt-2">
                    <div className="ew-section-2">
                        <div className="text-field-empty-custom-user lead-select">
                            <label className='label-custom' style={{ left: "11px" }}>Showroom</label>
                            <Select
                                components={{
                                    ValueContainer,
                                    MultiValue: () => null
                                }}
                                styles={customStyles}
                                onChange={handleShowroomChange}
                                value={showroomOptions.filter(option => showroomUUID.includes(option.value))}
                                options={showroomOptions}
                                placeholder={"Select Showroom"}
                                isMulti={true}
                            />
                        </div>
                    </div>
                </div>
                    <div className='row'>
                        {showroomUUID.map((optionValue, index) => {
                            const selectedOption = showroomOptions.find(option => option.value === optionValue);
                            if (selectedOption) {
                                return (
                                    <div key={index}>
                                        <div className='selected-options-container'>
                                            <span className='select-size'>{selectedOption.label}</span>
                                            <div className='cancel-button'>
                                                <button className='cancelbutton' onClick={() => removeSelectedOption(optionValue)}>X</button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>
                </>
                }
                <div className="showroomlist-btncon">
                    {LeadsTab.options.map((data, index) => (
                        <button
                            key={index}
                            className={`showroomlist-btn ${activeShowroom === index ? 'active' : ''}`}
                            onClick={() => handleStatusSelection(data.value, index)}
                        >
                            {data.label}
                        </button>
                    ))}
                </div>
                <div className='d-flex py-2 flex-column'>
                    <div className='d-flex align-items-center'>
                        <input
                            type="checkbox"
                            className='mr-2'
                            checked={checkedItems.testDrive}
                            onChange={(e) => handleTestDriveCheckboxChange(e.target.checked)}
                        />
                        <p className='mb-0 userlead-numval' style={{ fontSize: "16px", fontWeight: 400, color: "#000" }}>Test Drive</p>
                    </div>
                    {showTestDriveOptions && (
                        <div className='d-flex'>
                            <div className='d-flex px-2'>
                                <input
                                    type="checkbox"
                                    className='mr-2'
                                    checked={checkedItems.home}
                                    onChange={(e) => handleTestDriveLocationChange('home', e.target.checked)}
                                />
                                <p className='mb-0 userlead-numval' style={{ fontSize: "16px", fontWeight: 400, color: "#000" }}>Home</p>
                            </div>
                            <div className='d-flex px-2'>
                                <input
                                    type="checkbox"
                                    className='mr-2'
                                    checked={checkedItems.showroom}
                                    onChange={(e) => handleTestDriveLocationChange('showroom', e.target.checked)}
                                />
                                <p className='mb-0 userlead-numval' style={{ fontSize: "16px", fontWeight: 400, color: "#000" }}>Showroom</p>
                            </div>
                        </div>
                    )}
                    <div className='d-flex align-items-center'>
                        <input
                            type="checkbox"
                            className='mr-2'
                            checked={checkedItems.fuelRefill}
                            onChange={(e) => handleAdditionalTestDriveChange('fuelRefill', e.target.checked)}
                        />
                        <p className='mb-0 userlead-numval' style={{ fontSize: "16px", fontWeight: 400, color: "#000" }}>Fuel Refill</p>
                    </div>
                    <div className='d-flex align-items-center'>
                        <input
                            type="checkbox"
                            className='mr-2'
                            checked={checkedItems.showroomTransfer}
                            onChange={(e) => handleAdditionalTestDriveChange('showroomTransfer', e.target.checked)}
                        />
                        <p className='mb-0 userlead-numval' style={{ fontSize: "16px", fontWeight: 400, color: "#000" }}>Showroom Transfer</p>
                    </div>
                    <div className='d-flex align-items-center'>
                        <input
                            type="checkbox"
                            className='mr-2'
                            checked={checkedItems.others}
                            onChange={(e) => handleAdditionalTestDriveChange('others', e.target.checked)}
                        />
                        <p className='mb-0 userlead-numval' style={{ fontSize: "16px", fontWeight: 400, color: "#000" }}>Others</p>
                    </div>
                </div>
                <div className="row btn-form-submit" style={{ position: "absolute", bottom: '10px', width: '100%' }}>
                    <button type="button" className="button1" onClick={clearFilter}>Clear</button>
                    <button type="button" className="button2" onClick={() => { handleFilter(); closeModal(); }}>Filter</button>
                </div>
            </div>
        </div>
    );
};

export default UserTesDriveFilterModal;
