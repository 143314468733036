
const CellTDVehicleDetail: React.FC<{ data: any }> = ({ data }) => {

    return (
        <>
            {data['model_name']} {data['variant_name']}<br />
            <small>{data['color_name']} - {data['fuel_name']}</small><br />
            <small>Vin: {data['car_vin_number']}</small><br />
            <small>TC: {data['car_tc_number']}</small>
        </>
    )
}

export default CellTDVehicleDetail;