import { Drawer, Tab, Tabs } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import React from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { InitialData } from "./InitialData";
import FormField from "../components/form_items/FormField";
import { API, ProjectStrings } from "../common/Constants";
import { getDataFromAPI } from "../common/Utilities";
import { getLoginUserID } from "../common/Utilities";

type InventoryState = APIData.RequestApproval | APIData.PriceQuotes;

const AvailableInventoryEntryDialog: React.FC<{ showDialog: boolean, closeDialog: any, reloadData: any, id?: string, detailView?: boolean }> = ({ showDialog, closeDialog, reloadData, id, detailView }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const formikRef = useRef<FormikProps<APIData.InventoryPriceAdd>>(null);
    const [initialValue, setInitialValue] = useState<InventoryState>(InitialData.PriceQuotes);
    const [formDataSet, setFormDataSet] = useState(false);
    const [customerDetailsFields, setCustomerDetailsFields] = useState(false);
    const [unallocatedLeadList, setUnallocatedLeadList] = useState<APIData.Vehicle[]>();
    const [modelOptions, setModelOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [variantOptions, setVariantOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [customerStateOptions, setCustomerStateOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedModelUUID, setSelectedModelUUID] = useState('');
    const [selectedVariantUUID, setSelectedVariantUUID] = useState('');
    const [selectedInteriorUUID, setSelectedInteriorUUID] = useState('');
    const [selectedExteriorUUID, setSelectedExteriorUUID] = useState('');
    const [selectedFuelTypeUUID, setSelectedFuelTypeUUID] = useState('');
    const [selectedLeadUUID, setSelectedLeadUUID] = useState('');
    const [approvalUUID, setApprovalUUID] = useState('');
    const [bookingUUID, setBookingUUID] = useState('');
    const [activeButton, setActiveButton] = useState(null);
    const customTableViewRef = useRef<HTMLDivElement | null>(null);
    const [selectedLeads, setSelectedLeads] = useState<{ [key: string]: boolean }>({});
    const [selectedAssignees, setSelectedAssignees] = useState<{ [key: string]: boolean }>({});
    const [assignedToOptions, setAssignedToOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const userID = getLoginUserID();

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (customTableViewRef.current && !customTableViewRef.current.contains(event.target as Node)) {
                setCustomerDetailsFields(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    const handleButtonClick = (button: any) => {
        setActiveButton(button);
    };

    useEffect(() => {
        if (currentTab === 0) {
            setInitialValue(InitialData.PriceQuotes)
        } else if (currentTab === 1) {
            setInitialValue(InitialData.RequestApproval)
        }
    }, [currentTab])


    const currentYear = new Date().getFullYear();
    const last50Years = Array.from({ length: 50 }, (_, index) => currentYear - index);


    const modelYearOptions = last50Years.map(year => ({
        value: year.toString(),
        label: year.toString(),
    }));

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.ModelList, setModelOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'model_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.StateList, setCustomerStateOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'state_name', 'data', 'list');
        setFormDataSet(true);
    }, []);

    useEffect(() => {
        addProcessingRequests();
        ServerAPI.getAssignToList().then(response => {
            if (response && response.data && response.data.length > 0) {
                const filteredAssignTo = response.data.filter((selectedItem: any) => selectedItem.uuid !== userID)
                    .map((item: any) => ({
                        label: item.name,
                        value: item.uuid
                    }))
                setAssignedToOptions(filteredAssignTo);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
        setFormDataSet(true);
    }, [userID]);

    useEffect(() => {
        if (id) {
            addProcessingRequests();
            ServerAPI.getInventoryDetail(id).then(response => {
                if (response && response.data && response.data.details) {
                    const inventoryPriceDetail = response.data.details;
                    setSelectedModelUUID(inventoryPriceDetail.models_uuid);
                    setSelectedVariantUUID(inventoryPriceDetail.variant_uuid);
                    setSelectedInteriorUUID(inventoryPriceDetail.interior_uuid);
                    setSelectedExteriorUUID(inventoryPriceDetail.exterior_uuid);
                    setSelectedFuelTypeUUID(inventoryPriceDetail.fuel_type_uuid);
                    setInitialValue({
                        models_uuid: inventoryPriceDetail.models_uuid,
                        variant_uuid: inventoryPriceDetail.variant_uuid,
                        mf_year: inventoryPriceDetail.mf_year,
                        model_year: inventoryPriceDetail.model_year,
                        ex_showroom_price: inventoryPriceDetail.ex_showroom_price,
                        booking_uuid: inventoryPriceDetail.booking_uuid,
                        tcs: inventoryPriceDetail.tcs,
                        insurance: inventoryPriceDetail.insurance,
                        registration_charges: inventoryPriceDetail.registration_charges,
                        state_uuid: inventoryPriceDetail.state_uuid,
                        logistic_handing: inventoryPriceDetail.logistic_handing,
                        vas_charge: inventoryPriceDetail.vas_charge,
                        extend_warrenty: inventoryPriceDetail.extend_warrenty,
                        service_plan: inventoryPriceDetail.service_plan,
                        others: inventoryPriceDetail.others,
                        exchange_loyalty_bonus: inventoryPriceDetail.exchange_loyalty_bonus,
                        corporate_discount: inventoryPriceDetail.corporate_discount,
                        delear_discount: inventoryPriceDetail.delear_discount,
                        referral_bonus: inventoryPriceDetail.referral_bonus,
                        oem_scheme: inventoryPriceDetail.oem_scheme,
                        discount_others: inventoryPriceDetail.discount_others
                    })
                    setFormDataSet(true);
                    // setEditMode(true);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }, [id, currentTab]);

    useEffect(() => {
        if (selectedModelUUID !== "" && selectedVariantUUID !== "" && selectedInteriorUUID !== "" && selectedExteriorUUID !== "" && selectedFuelTypeUUID !== "") {
            addProcessingRequests();
            ServerAPI.getUnallocatedleadLists(selectedModelUUID, selectedVariantUUID, selectedInteriorUUID, selectedExteriorUUID, selectedFuelTypeUUID).then(response => {
                if (response && response.data) {
                    setUnallocatedLeadList(response.data);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }, [selectedModelUUID, selectedVariantUUID, selectedInteriorUUID, selectedExteriorUUID, selectedFuelTypeUUID]);

    const handleLeadCheckboxChange = (leadUUID: string, bookingUUID: string) => {
        setSelectedLeads(prevState => ({
            ...prevState,
            [leadUUID]: !prevState[leadUUID]
        }));
        setSelectedLeadUUID(leadUUID);
        setBookingUUID(bookingUUID);
    };

    const handleAssigneeCheckboxChange = (assigneeUUID: string) => {
        setSelectedAssignees(prevState => ({
            ...prevState,
            [assigneeUUID]: !prevState[assigneeUUID]
        }));
        setApprovalUUID(assigneeUUID);
    };

    function TabPanel(props: { children: any; value: any; index: any; }) {
        const { children, value, index } = props;
        return (
            <div>
                {
                    value === index && (
                        <h1>{children}</h1>
                    )
                }
            </div>
        )
    }

    const handelTabChange = (e: any, val: React.SetStateAction<number>) => {
        setCurrentTab(val)
    }

    const yupSchema = Yup.object().shape({
        models_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        variant_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        model_year: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        state_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        mf_year: Yup.string().required(ProjectStrings.ValidationRequired),
        ex_showroom_price: Yup.string().required(ProjectStrings.ValidationRequired),
        tcs: Yup.string().required(ProjectStrings.ValidationRequired),
        insurance: Yup.string().required(ProjectStrings.ValidationRequired),
        registration_charges: Yup.string().required(ProjectStrings.ValidationRequired),
        logistic_handing: Yup.string().required(ProjectStrings.ValidationRequired),
        vas_charge: Yup.string().required(ProjectStrings.ValidationRequired),
        extend_warrenty: Yup.string().required(ProjectStrings.ValidationRequired),
        service_plan: Yup.string().required(ProjectStrings.ValidationRequired),
        others: Yup.string().required(ProjectStrings.ValidationRequired),
        exchange_loyalty_bonus: Yup.string().required(ProjectStrings.ValidationRequired),
        corporate_discount: Yup.string().required(ProjectStrings.ValidationRequired),
        delear_discount: Yup.string().required(ProjectStrings.ValidationRequired),
        oem_scheme: Yup.string().required(ProjectStrings.ValidationRequired),
        discount_others: Yup.string().required(ProjectStrings.ValidationRequired),
        referral_bonus: Yup.string().required(ProjectStrings.ValidationRequired),
    });

    useEffect(() => {
        addProcessingRequests();
        ServerAPI.getModelVariants(selectedModelUUID).then(response => {
            if (response && response.data && response.data.list.length > 0) {
                const modelVariantOptions = response.data.list.map((item: any) => ({
                    label: item.variant_name,
                    value: item.uuid
                }))
                setVariantOptions(modelVariantOptions);
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }, [selectedModelUUID]);

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="drawer-fit">
            {formDataSet && (
                <Formik validationSchema={yupSchema} enableReinitialize={true} validateOnChange={false} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                    let submitValues: any = { ...values };

                    if (customerDetailsFields && currentTab === 0) {
                        if (!submitValues['customer_name']) {
                            toast.error("Customer Name Required")
                            setSubmitting(false);
                            return
                        }
                        if (!submitValues['mobile_number']) {
                            toast.error("Mobile Number Required")
                            setSubmitting(false);
                            return
                        }
                        if (!submitValues['customer_address']) {
                            toast.error("Customer Address Required")
                            setSubmitting(false);
                            return
                        }
                        if (!submitValues['email_address']) {
                            toast.error("Email ID Required")
                            setSubmitting(false);
                            return
                        }
                    }

                    if (currentTab === 1) {
                        if (!selectedLeadUUID) {
                            toast.error("Lead UUID Required")
                            setSubmitting(false);
                            return
                        }
                        if (!bookingUUID) {
                            toast.error("Booking UUID Required")
                            setSubmitting(false);
                            return
                        }
                        if (!approvalUUID) {
                            toast.error("Approval User UUID Required")
                            setSubmitting(false);
                            return
                        }
                    }
                    // submitValues['tcs'] = submitValues['tcs'].toString();
                    // submitValues['ex_showroom_price'] = submitValues['ex_showroom_price'].toString();
                    // submitValues['registration_charges'] = submitValues['registration_charges'].toString();
                    // submitValues['logistic_handing'] = submitValues['logistic_handing'].toString();
                    // submitValues['vas_charge'] = submitValues['vas_charge'].toString();
                    // submitValues['extend_warrenty'] = submitValues['extend_warrenty'].toString();
                    // submitValues['service_plan'] = submitValues['service_plan'].toString();
                    // submitValues['others'] = submitValues['others'].toString();
                    // submitValues['exchange_loyalty_bonus'] = submitValues['exchange_loyalty_bonus'].toString();
                    // submitValues['corporate_discount'] = submitValues['corporate_discount'].toString();
                    // submitValues['delear_discount'] = submitValues['delear_discount'].toString();
                    // submitValues['referral_bonus'] = submitValues['referral_bonus'].toString();
                    // submitValues['oem_scheme'] = submitValues['oem_scheme'].toString();
                    // submitValues['discount_others'] = submitValues['discount_others'].toString();
                    const numericalFields = [
                        'tcs', 'ex_showroom_price', 'registration_charges', 'logistic_handing',
                        'vas_charge', 'extend_warrenty', 'service_plan', 'others',
                        'exchange_loyalty_bonus', 'corporate_discount', 'delear_discount',
                        'referral_bonus', 'oem_scheme', 'discount_others', 'insurance'
                    ];
                    numericalFields.forEach(field => {
                        if (typeof submitValues[field] === 'number') {
                            submitValues[field] = submitValues[field].toString();
                        }
                    });
                    if (currentTab === 0) {
                        addProcessingRequests();
                        ServerAPI.UserPriceQuotes(submitValues).then(response => {
                            if (response && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    } else if (currentTab === 1) {
                        addProcessingRequests();
                        submitValues['booking_uuid'] = bookingUUID;
                        submitValues['approval_user_uuid'] = approvalUUID;
                        submitValues['lead_uuid'] = selectedLeadUUID;
                        ServerAPI.UserAllocatePrice(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                reloadData();
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });
                    }
                }} >
                    {(formikProps) => {
                        if (formikProps.values.models_uuid !== selectedModelUUID) {
                            setSelectedModelUUID(formikProps.values.models_uuid);
                        }


                        return (
                            <div className="tabdetails" style={{ overflowY: "auto", height: "90vh" }}>
                                <h4 className="title-text text-center">View Inventory</h4>
                                <div className="tabpage">
                                    <div className="tab-details-section">
                                        <Form>
                                            <div className="px-2 form-field-container-tabs">
                                                <Tabs value={currentTab} onChange={handelTabChange}
                                                    sx={{ backgroundColor: "white", borderTop: "2px solid #e2e2e2", borderBottom: "2px solid #e2e2e2", "& button": { width: "50%" }, justifyContent: "space-between" }}
                                                    TabIndicatorProps={{
                                                        style: {
                                                            backgroundColor: "#14408B",
                                                        },
                                                    }}
                                                >
                                                    <Tab label="Inventory Details" className="tab-headers" />
                                                    <Tab label="Request Approval" className="tab-headers" />
                                                </Tabs>
                                                <TabPanel value={currentTab} index={0}>
                                                    <>
                                                        <div className="row px-2 py-2">
                                                            <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom-1 available-select">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "models_uuid", label: "Model", placeholder: "Select Model", options: modelOptions, required: true, readOnly: detailView ? true : false }} />
                                                            </div>
                                                            <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom available-select">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "variant_uuid", label: "Variant", placeholder: "Select Variant", options: variantOptions, required: true, readOnly: detailView ? true : false }} />
                                                            </div>
                                                        </div>
                                                        <div className="row px-2 py-2">
                                                            <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "monthyearpicker", name: "mf_year", label: "Mf Year", placeholder: "Select Mf Year", required: true, readOnly: detailView ? true : false }} />
                                                            </div>
                                                            <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom available-select">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "model_year", label: "Model Year", placeholder: "Select Model Year", required: true, options: modelYearOptions, readOnly: detailView ? true : false }} />
                                                            </div>
                                                        </div>
                                                        <div className="row px-2 py-2">
                                                            <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "ex_showroom_price", label: "Ex Showroom Price", placeholder: " Enter Price", required: true, readOnly: detailView ? true : false }} />
                                                            </div>
                                                            <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "tcs", label: "Tcs", placeholder: "Enter Tcs", required: true, readOnly: detailView ? true : false }} />
                                                            </div>
                                                        </div>
                                                        <div className="row px-2 py-2">
                                                            <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom add-insurance">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", name: "insurance", required: true, label: "Insurance", placeholder: "Enter Insurance", readOnly: detailView ? true : false }} />
                                                            </div>
                                                            <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "registration_charges", required: true, label: "Registration Charges", placeholder: "Enter Registration Charges", readOnly: detailView ? true : false }} />
                                                            </div>
                                                        </div>
                                                        <div className="row px-2 py-2">
                                                            <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom available-select">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", name: "state_uuid", label: "State", placeholder: "Select State", options: customerStateOptions, required: true, readOnly: detailView ? true : false }} />
                                                            </div>
                                                            <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "logistic_handing", label: "Logistic Handling", placeholder: "Enter Logistic Handling", readOnly: detailView ? true : false }} />
                                                            </div>
                                                        </div>
                                                        <div className="row px-2 py-2">
                                                            <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "vas_charge", label: "VAS Charges", placeholder: "Enter VAS Charges", required: true, readOnly: detailView ? true : false }} />
                                                            </div>
                                                            <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "extend_warrenty", label: "Extend Warranty", placeholder: "Enter Extend Warranty", readOnly: detailView ? true : false }} />
                                                            </div>
                                                        </div>
                                                        <div className="row px-2 py-2">
                                                            <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "service_plan", label: "Service Plan", placeholder: "Enter Service Plan", required: true, readOnly: detailView ? true : false }} />
                                                            </div>
                                                            <div className={`col-md-12 col-lg-6 col-xl-6 text-field-empty-custom${detailView ? " detail-view-num" : ""}`}>
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "others", label: "Others", placeholder: "Enter Others", readOnly: detailView ? true : false }} />
                                                            </div>
                                                        </div>
                                                        <div className="row px-2 py-2">
                                                            <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "exchange_loyalty_bonus", label: "Exchange Loyalty Bonus", placeholder: "Enter Exchange Loyalty Bonus", required: true, }} />
                                                            </div>
                                                            <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "corporate_discount", label: "Corporate Discount", placeholder: "Enter Corporate Discount" }} />
                                                            </div>
                                                        </div>
                                                        <div className="row px-2 py-2">
                                                            <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "delear_discount", label: "Dealer Discount", placeholder: "Enter Dealer Discount", required: true }} />
                                                            </div>
                                                            <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "referral_bonus", label: "Referral Bonus", placeholder: "Enter Referral Bonus" }} />
                                                            </div>
                                                        </div>
                                                        <div className="row px-2 py-2">
                                                            <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "oem_scheme", label: "Oem Scheme", placeholder: "Enter Oem Scheme", required: true }} />
                                                            </div>
                                                            <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "number", name: "discount_others", label: "Discount Others", placeholder: "Enter Discount Others" }} />
                                                            </div>
                                                        </div>
                                                        {customerDetailsFields && <>
                                                            <div className='custom-tableheight'><div ref={customTableViewRef} className='custom-tableview' style={{ width: "50vw" }}>
                                                                <div className='custom-tableview-scroll'>
                                                                    <h4 className="title-text ml-5 mt-0 text-center">Customer Details</h4>
                                                                    <div className="custom-border-grey"></div>
                                                                    <div className="row px-2 py-2 mt-3">
                                                                        <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", name: "customer_name", label: "Customer Name", placeholder: "Enter Customer Name" }} />
                                                                        </div>
                                                                        <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", name: "email_address", label: "Customer Email", placeholder: "Enter Customer Email" }} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row px-2 py-2">
                                                                        <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", name: "mobile_number", label: "Mobile Number", placeholder: "Enter Mobile Number" }} />
                                                                        </div>
                                                                        <div className="col-md-12 col-lg-6 col-xl-6 text-field-empty-custom">
                                                                            <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", name: "customer_address", label: "Customer Address", placeholder: "Enter Customer Address" }} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                                        <button type="button" className="button1" onClick={() => setCustomerDetailsFields(false)}>Cancel</button>
                                                                        {customerDetailsFields && <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                                            formikProps.submitForm();
                                                                            if (!formikProps.isValid) {
                                                                                toast.error("Please ensure all fields are Filled and Valid!");
                                                                            }
                                                                        }}>Price Quotes</button>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </>}
                                                        {!customerDetailsFields && <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                            <button type="button" className="button1" onClick={closeDialog}>Cancel</button>
                                                            <button type="button" className="button2" onClick={() => setCustomerDetailsFields(true)}>Price Quotes</button>
                                                        </div>}
                                                    </>
                                                </TabPanel>
                                                <TabPanel value={currentTab} index={1}>
                                                    <>
                                                        {!!unallocatedLeadList && unallocatedLeadList.map((vehicle, vehicleIdx) => (
                                                            <div className='lead-popup-profile' key={vehicleIdx}>
                                                                <div className='lead-popup-task-detail-container'>
                                                                    <div className='lead-popup-task-detail'>
                                                                        <div className='lead-popup-task-detail-header'>
                                                                            <div>
                                                                                <div className='lead-popup-profile-details' style={{ cursor: "pointer" }}>
                                                                                    <div className='lead-value-16-500-blue'>{vehicle.lead_cust_first_name}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div className='lead-popup-profile-details'>
                                                                                    <div style={{ cursor: "pointer" }}>
                                                                                        {vehicle?.booking_details?.booking_car_details && (
                                                                                            <input type="checkbox" name="approval_user_uuid" checked={!!selectedLeads[vehicle.lead_uuid]}
                                                                                                onChange={() => handleLeadCheckboxChange(vehicle.lead_uuid, vehicle.booking_details.booking_car_details.booking_uuid)} />
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {vehicle?.booking_details?.booking_customer_details && (
                                                                            <div>
                                                                                <div className='vehicle-assign-section-container'>
                                                                                    <div className='assign-section-container'>
                                                                                        <div className='assign-section '>
                                                                                            <div className='assign-section-left'>
                                                                                                <div className='lead-heading-14-400'>Email</div>
                                                                                                <div className='lead-popup-profile-details'>
                                                                                                    <div className='lead-value-16-500'>{vehicle.booking_details.booking_customer_details.email_id}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='assign-section-left'>
                                                                                                <div className='lead-heading-14-400'>Mobile Number</div>
                                                                                                <div className='lead-popup-profile-details'>
                                                                                                    <div className='lead-value-16-500'>{vehicle.booking_details.booking_customer_details.mobile_number}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='assign-section-left-1'>
                                                                                                <div className='lead-heading-14-400'>State</div>
                                                                                                <div className='lead-popup-profile-details'>
                                                                                                    <div className='lead-value-16-500'>{vehicle.booking_details.booking_customer_details.state_name}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}

                                                        {assignedToOptions.length > 0 ? (
                                                            <div className="flex mb-3">
                                                                <button className={`approve-button d-flex align-items-center justify-content-center ${activeButton === 'self-approve' ? 'active' : ''}`} onClick={() => { handleButtonClick('self-approve'); setApprovalUUID(userID) }}><img className="mr-2" src={window.location.origin + "/assets/svg/self-approve.svg"} /> Self Approve</button>
                                                                <button className={`approve-button ${activeButton === 'escalate' ? 'active' : ''}`} onClick={() => { handleButtonClick('escalate'); }}><img className="mr-2" src={window.location.origin + "/assets/svg/escalate.svg"} /> Escalate</button>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <button className={`approve-button d-flex align-items-center justify-content-center ${activeButton === 'self-approve' ? 'active' : ''}`} onClick={() => { handleButtonClick('self-approve'); setApprovalUUID(userID) }}><img className="mr-2" src={window.location.origin + "/assets/svg/self-approve.svg"} /> Self Approve</button>
                                                            </div>
                                                        )}
                                                        {assignedToOptions.length > 0 && activeButton === "escalate" &&
                                                            <>
                                                                {assignedToOptions.map((item: any) => (
                                                                    <div className='d-flex justify-content-between align-items-center py-1 px-2' style={{ border: "1px solid #e5e5e5", borderRadius: "8px", fontSize: "16px" }}>
                                                                        <div className='d-flex flex-column align-items-left'><span>{item.label} </span></div> <div><input type="checkbox" name="lead_uuid" checked={!!selectedAssignees[item.value]}
                                                                            onChange={() => handleAssigneeCheckboxChange(item.value)} /></div>
                                                                    </div>
                                                                ))}
                                                            </>}
                                                        <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                            <button type="button" className="button1" onClick={() => setCurrentTab(0)}>Back</button>
                                                            <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                                formikProps.submitForm();
                                                                if (!formikProps.isValid) {
                                                                    toast.error("Please ensure all fields are Filled and Valid!");
                                                                }
                                                            }}>Request Approval</button>
                                                        </div>
                                                    </>
                                                </TabPanel>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                </Formik>
            )
            }
        </Drawer >
    )
}

export default AvailableInventoryEntryDialog;