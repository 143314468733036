import { Route, Routes } from 'react-router-dom';
import { PageURLNames } from '../common/Constants';
import { useLocation } from 'react-router-dom';
import CommonListing from '../listing/CommonListing';
import UserDashboard from '../listing/UserDashboard';

const ListTemplate: React.FC = () => {

    const location = useLocation();

    return (
        <div className="hp-main-layout-content text-start vh-100">
            <Routes>
                {/* SUPER ADMIN LOGIN PAGES */}
                {/* <Route path={PageURLNames.DEALERS} element={<AllDealers />} /> */}
                <Route path={PageURLNames.BUSINESS} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.BUSINESS_MODE} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.CATEGORY_USER} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.CATEGORYTYPEUSER} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.MENUS} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.PROVIDER_CATEGORY} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.PROVIDERS} element={<CommonListing page={location.pathname} />} />

                {/* DYNAMIC LISTING PAGES */}
                <Route path={PageURLNames.USERS} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.TEAMS} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.DEALERS} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.LOCATIONS} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.MODEL} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.MODELVARIANT} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.COLOR} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.FUELTYPE} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.DEMOCAR} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.BODY_TYPE} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.TRANSMISSION_TYPE} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.INTERIOR_COLOR} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.EXTERIOR_COLOR} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.ROLE} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.CATEGORY} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.STATE} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.CITY} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.PINCODE} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.LEAD_CATEGORY} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.CUSTOMER_TYPE} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.LEAD_SOURCE} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.LEAD_SOURCE_CATEGORY} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.LEAD_STATUS} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.INSURANCE_TYPE} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.INSURANCE_COMPANIES} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.LEAD_TYPE} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.LEAD_TASK} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.LEAD_PROSPECT_TYPE} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.LEAD_REASON} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.LEAD_TITLE} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.PAYMENT_MODE} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.CATEGORY_TYPE} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.VERSION} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.TESTDRIVE} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.SHOWROOM_TRANSFER} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.FUEL_REFILL} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.OTHER_TRIP} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.LEADS_TODAY} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.LEADS_FUTURE} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.LEADS_COMPLETE} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.REPORT_TESTDRIVE} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.REPORT_STOCKTRANSFER} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.REPORT_FUELREFILL} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.REPORT_OTHERTRIPS} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.TARGET_RULES} element={<CommonListing page={location.pathname} />} />

                <Route path={PageURLNames.USER_DASHBOARD_TODAY} element={<UserDashboard />} />
                <Route path={PageURLNames.USER_DASHBOARD_ETVBRL} element={<UserDashboard />} />
                <Route path={PageURLNames.USER_DASHBOARD_LOST_DROP} element={<UserDashboard />} />
                <Route path={PageURLNames.ENQUIRY_TRANSFER} element={<UserDashboard />} />
                <Route path={PageURLNames.TARGETS} element={<UserDashboard />} />
                <Route path={PageURLNames.USER_MY_LEADS} element={<UserDashboard />} />
                <Route path={PageURLNames.USER_MY_TASKS} element={<UserDashboard />} />
                <Route path={PageURLNames.USER_INVENTORY} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.USER_AVAILABLE_INVENTORY} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.USER_INVENTORY_PRICE} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.USER_ALLOTMENT_LIST} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.USER_REPORT} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.USER_HELP_SUPPORT} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.USER_TESTDRIVE_DASHBOARD} element={<UserDashboard />} />
                <Route path={PageURLNames.USER_TESTDRIVE} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.USER_TESTDRIVE_TRANSFER} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.USER_TESTDRIVE_REFILL} element={<CommonListing page={location.pathname} />} />
                <Route path={PageURLNames.USER_TESTDRIVE_OTHERS} element={<CommonListing page={location.pathname} />} />
            </Routes>
        </div>
    )
}

export default ListTemplate;