import { useState } from "react";
import AllocateVinEntryDialog from "../../forms/AllocateVinEntryDialog";

const ActionAllotmentList: React.FC<{ data: any }> = ({ data }) => {

    const [showDialog, setShowDialog] = useState(false);
    const [modelUUID, setModelUUID] = useState<string>("");
    const [variantUUID, setVariantUUID] = useState<string>("");
    const [inventoryAllotmentUUID, setInventoryAllotmentUUID] = useState<string>("");
    const [bookingUUID, setBookingUUID] = useState<string>("");
    const [leadUUID, setLeadUUID] = useState<string>("");
    const handleAllocateClick = () => {
        setModelUUID(data.models_uuid);
        setVariantUUID(data.variant_uuid);
        setInventoryAllotmentUUID(data.uuid);
        setBookingUUID(data.booking_uuid);
        setLeadUUID(data.lead_uuid);
        setShowDialog(true);
    };

    return (
        <>
            <button type="button" className="btn btn-login-primary btn-round btn-block waves-effect waves-light w-80" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "30px", fontSize: "14px" }} title="Dialog" onClick={handleAllocateClick}>
                Allocate
            </button>
            <AllocateVinEntryDialog showDialog={showDialog} closeDialog={() => setShowDialog(false)} modelUUID={modelUUID} variantUUID={variantUUID} inventoryAllotmentUUID={inventoryAllotmentUUID} bookingUUID={bookingUUID} leadUUID={leadUUID} />
        </>
    )
}

export default ActionAllotmentList;