import React, { useEffect, useRef, useState } from 'react';
import { useAppStateAPI } from '../../common/AppStateAPI';
import { APIData, FormDataTypes } from '../../common/DataTypes';
import toast from 'react-hot-toast';
import { ServerAPI } from '../../common/ServerAPI';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { getDataFromAPI } from '../../common/Utilities';
import { API } from '../../common/Constants';
import Select from 'react-select';

interface SelectOption {
    label: string;
    value: string;
}

const UserDashboardET: React.FC = () => {
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [showRoomList, setShowroomList] = useState<APIData.ShowroomList>();
    const [userETTeamLists, setUserETTeamLists] = useState<APIData.UserETTeamList>();
    const [userETToTeamLists, setUserETToTeamLists] = useState<APIData.UserETToTeamList>();
    const [teamMembersVisibility, setTeamMembersVisibility] = useState<{ [key: number]: boolean }>({});
    const [toTeamMembersVisibility, setToTeamMembersVisibility] = useState<{ [key: number]: boolean }>({});
    const [showFilter, setShowFilter] = useState(false)
    const [showroomUUid, setShowroomUUID] = useState("");
    const [showroomName, setShowroomName] = useState("");
    const [activeShowroom, setActiveShowroom] = useState(0);
    const [fromSelectedUsers, setFromSelectedUsers] = useState<string[]>([]);
    const [toSelectedUsers, setToSelectedUsers] = useState<string[]>([]);
    const [etToLeadUUID, setEtToLeadUUID] = useState<string[]>([]);
    const [ToTeamDataVisible, setToTeamDataVisible] = useState(false);
    const [modelOptions, setModelOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [leadSourceCategoryOptions, setLeadSourceCategoryOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedModelID, setSelectedModelID] = useState<string>("");
    const [selectedLeadSourceCategoryID, setSelectedLeadSourceCategoryID] = useState<string>("");
    const customTableViewRef = useRef<HTMLDivElement | null>(null);
    const customStyles = { control: (provided: any) => ({ ...provided, border: '2px solid #D3D3D3', '&:hover': { borderColor: '#14408B' }, borderRadius: '10px' }) }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (customTableViewRef.current && !customTableViewRef.current.contains(event.target as Node)) {
                setSelectedModelID(""); setSelectedLeadSourceCategoryID(""); setShowFilter(false); setToTeamDataVisible(false); setToSelectedUsers([]); setFromSelectedUsers([]); setShowFilter(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleTeamMembersVisibility = (teamId: number) => {
        setTeamMembersVisibility((prevVisibility) => ({
            ...prevVisibility,
            [teamId]: !prevVisibility[teamId],
        }));
    };
    const toggleToTeamMembersVisibility = (teamId: number) => {
        setToTeamMembersVisibility((prevVisibility) => ({
            ...prevVisibility,
            [teamId]: !prevVisibility[teamId],
        }));
    };


    // const handleModelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    //     const selectedValue = event.target.value;
    //     setSelectedModelID(selectedValue);
    // };

    const handleModelChange = (selectedOption: SelectOption | null) => {
        if (selectedOption) {
            setSelectedModelID(selectedOption.value);
        } else {
            setSelectedModelID('')
        }
    }

    // const handleLeadSourceCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    //     const selectedValue = event.target.value;
    //     setSelectedLeadSourceCategoryID(selectedValue);
    // }

    const handleLeadSourceCategoryChange = (selectedOption: SelectOption | null) => {
        if (selectedOption) {
            setSelectedLeadSourceCategoryID(selectedOption.value);
        } else {
            setSelectedLeadSourceCategoryID("");
        }
    };

    const etTeamCheckboxChange = (userId: string) => {
        setFromSelectedUsers((prevSelectedUsers) => {
            if (prevSelectedUsers.includes(userId)) {
                return prevSelectedUsers.filter((id) => id !== userId);
            } else {
                return [...prevSelectedUsers, userId];
            }
        });
    };

    const etToTeamCheckboxChange = (userId: string) => {
        setToSelectedUsers((prevSelectedUsers) => {
            if (prevSelectedUsers.includes(userId)) {
                return prevSelectedUsers.filter((id) => id !== userId);
            } else {
                return [...prevSelectedUsers, userId];
            }
        });
    };

    const fetchShowroomList = () => {
        addProcessingRequests();
        ServerAPI.getShowrooms()
            .then((response: any) => {
                if (response['success'] === true) {
                    setShowroomList({ data: { list: response.data.list } });
                    const firstShowRoomUUID = response.data.list[0].uuid;
                    setShowroomUUID(firstShowRoomUUID);
                    const firstShowRoomName = response.data.list[0].showroom_name;
                    setShowroomName(firstShowRoomName);
                } else {
                    toast.error('Something Went Wrong');
                }
            })
            .finally(() => {
                reduceProcessingRequests();
            });
    };

    const fetchETTeamList = () => {
        if (showroomUUid === "") {
            return
        } else {
            addProcessingRequests();
            ServerAPI.getETTeamList(showroomUUid)
                .then((response: any) => {
                    if (response['success'] === true) {
                        setUserETTeamLists({ data: response.data });
                    } else {
                        toast.error('Something Went Wrong');
                    }
                })
                .finally(() => {
                    reduceProcessingRequests();
                });
        }
    };

    const fetchETToTeamList = () => {
        if (showroomUUid === "" || fromSelectedUsers.length === 0) {
            return;
        } else {
            addProcessingRequests();
            ServerAPI.getETToTeamList(showroomUUid, fromSelectedUsers.join(','), selectedModelID, selectedLeadSourceCategoryID)
                .then((response: any) => {
                    if (response['success'] === true) {
                        setUserETToTeamLists({ totalleads: response.totalleads, leads: response.leads, data: response.data });
                        setEtToLeadUUID(response.leads)
                    } else {
                        toast.error('Something Went Wrong');
                    }
                })
                .finally(() => {
                    reduceProcessingRequests();
                });
        }
    };

    const userETLeadTransfer = () => {
        addProcessingRequests();
        ServerAPI.userETLeadTransfer(etToLeadUUID, fromSelectedUsers, toSelectedUsers)
            .then((response: any) => {
                if (response['success'] === true) {
                    const successMessage = response['message'];
                    toast.success(successMessage);
                } else {
                    const errorMessage = response['message'] || 'Something Went Wrong';
                    toast.error(errorMessage);
                }
            })
            .finally(() => {
                reduceProcessingRequests();
            });
    };


    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.ModelList, setModelOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'model_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.LeadSourceCategoryList, setLeadSourceCategoryOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'lead_source_category_name', 'data');
    }, []);

    useEffect(() => {
        fetchShowroomList();
    }, []);

    // useEffect(() => {
    //     fetchETToTeamList();
    // }, [fromSelectedUsers, showroomUUid]);

    useEffect(() => {
        fetchETTeamList();
    }, [showroomUUid]);


    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setToTeamDataVisible(false)
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            setFromSelectedUsers([])
        };
    }, []);
    return (
        <>
            <div>
                {showRoomList !== undefined && (
                    <div className="showroomlist-btncon mt-3">
                        {showRoomList.data.list.map((showroom, index) => (
                            <button
                                key={index}
                                className={`showroomlist-btn ${activeShowroom === index ? 'active' : ''}`}
                                onClick={() => { setActiveShowroom(index); setShowroomUUID(showroom.uuid); setShowroomName(showroom.showroom_name) }}
                            >
                                {showroom.showroom_name}
                            </button>
                        ))}
                    </div>
                )}
                {userETTeamLists !== undefined && userETTeamLists.data.length > 0 ? (
                    <>
                        <div className='custom-tablescroll-2'>
                            <div className="table-container">
                                <div className="table-scroll mt-2" style={{ gap: "10px", display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}>
                                    {userETTeamLists.data.map((team, teamIdx) => (
                                        <div className={`mdi ${teamMembersVisibility[team.id] ? "table-responsive table-responsive-1" : "table-responsive"}`} key={teamIdx} >
                                            {/* <div className="table-responsive" key={teamIdx} > */}
                                            <div className='d-flex' style={{ justifyContent: "space-between" }} onClick={() => toggleTeamMembersVisibility(team.id)}>
                                                <div className='d-flex align-items-center ml-2' style={{ height: '47px', gap: '5px' }}>
                                                    <PeopleAltIcon fontSize='small' />
                                                    <h4 className='team-headers'>{team.team_name}</h4>
                                                </div>
                                                <i
                                                    className={`arrow-icon mdi ${teamMembersVisibility[team.id] ? "mdi-chevron-right" : "mdi-chevron-down"}`}
                                                    style={{ textAlign: "end", padding: "10px 10px 0 0", cursor: "pointer" }}
                                                ></i>
                                            </div>

                                            <table className="table table-1">
                                                {!teamMembersVisibility[team.id] && (
                                                    <tbody>
                                                        {team.users.map((user, userIdx) => (
                                                            <tr key={userIdx}>
                                                                <td>
                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                        <div className='d-flex flex-column align-items-left'><span>{user.name} </span><span>{user.role_name}</span></div> <div><input type="checkbox" name="selectedUser" value={user.role_uuid} onChange={() => etTeamCheckboxChange(user.user_id)} checked={fromSelectedUsers.includes(user.user_id)} /></div>
                                                                    </div>
                                                                </td>
                                                                {/* <td></td>
                                                            <td></td> */}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                )}
                                            </table>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {fromSelectedUsers.length > 0 ? <button className='et-nextbtn' onClick={() => {
                                if (fromSelectedUsers.length > 0) {
                                    setToTeamDataVisible(true);
                                    // setTeamMembersVisibility({});
                                    fetchETToTeamList();
                                }
                            }}>Next</button> : ""}
                        </div>
                        {/* <Select
                                styles={customStyles}
                                onChange={(selectedOption) => handleLeadSourceCategoryChange(selectedOption as SelectOption | null)}
                                value={leadSourceCategoryOptions.find(option => option.value === selectedLeadSourceCategoryID) || null}
                                options={leadSourceCategoryOptions}
                                placeholder="Select Lead Source Category"
                            /> */}
                    </>
                ) : <div className="table-responsive">
                    <table className="table table-1 custom-border">
                        <tbody>
                            <tr>
                                <td className="text-align-l">No Data Available</td>
                            </tr>
                        </tbody>

                    </table>
                </div>}
            </div >
            {ToTeamDataVisible && <div className='custom-tableheight'><div ref={customTableViewRef} className='custom-tableview'>
                <div className='custom-tableview-scroll'>
                    {userETToTeamLists && (
                        <div>
                            {/* <i className='mdi mdi-close' style={{ cursor: "pointer" }} onClick={() => { setToTeamDataVisible(false); setToSelectedUsers([]); setFromSelectedUsers([]); setToTeamMembersVisibility({}); setShowFilter(false) }}></i> */}
                            <h4 className="et-custom-h4 title-text text-center">{showFilter ? "Filters" : "To Employee"}</h4>
                            <hr />
                            <p className='detailed-heading'>{showFilter ? "Select Filters" : showroomName}</p>
                            <hr />
                            {showFilter === false && <div className="table-container">
                                <div className='d-flex' style={{ justifyContent: "space-between", paddingBottom: '10px' }}>
                                    <p className='et-totalleads' style={{ margin: 'auto 0' }}>Overall Showroom Total Leads <span className='et-totalleads-span'>{userETToTeamLists.totalleads}</span></p>
                                    <button className='et-filterbtn' onClick={() => setShowFilter(true)}>Filter</button>
                                </div>

                                {userETToTeamLists.data.map((team, teamIdx) => (
                                    <div className="table-scroll d-flex " style={{ gap: "10px", flexDirection: "column" }}>
                                        <div className={`mdi ${toTeamMembersVisibility[team.id] ? "table-responsive table-responsive-1" : "table-responsive"}`} key={teamIdx}>
                                            {/* <div className="table-responsive" key={teamIdx}> */}
                                            <div className='d-flex' style={{ justifyContent: "space-between", cursor: "pointer" }} onClick={() => toggleToTeamMembersVisibility(team.id)}>

                                                <div className='d-flex align-items-center ml-2' style={{ height: '47px', gap: '5px' }}>
                                                    <PeopleAltIcon fontSize='small' />
                                                    <h4>{team.team_name}</h4>
                                                </div>
                                                <i
                                                    className={`arrow-icon mdi ${toTeamMembersVisibility[team.id] ? "mdi-chevron-right" : "mdi-chevron-down"}`}
                                                    style={{ textAlign: "end", padding: "10px 10px 0 0" }}
                                                ></i>
                                            </div>

                                            <table className="table table-1">
                                                {!toTeamMembersVisibility[team.id] && (
                                                    <tbody>
                                                        {team.users.map((user, userIdx) => (
                                                            <tr key={userIdx}>
                                                                <td>
                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                        <div className='d-flex flex-column align-items-left'><span>{user.name} </span><span>{user.role_name}</span></div> <div><input type="checkbox" name="selectedUser" value={user.role_uuid} onChange={() => etToTeamCheckboxChange(user.user_id)} checked={toSelectedUsers.includes(user.user_id)} /></div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                )}
                                            </table>
                                        </div>
                                    </div>
                                ))}
                                {/* {toSelectedUsers.length > 0 ? <button className='et-nextbtn' onClick={() => { userETLeadTransfer(); setToTeamDataVisible(false); setToTeamMembersVisibility({}); setToSelectedUsers([]); setFromSelectedUsers([]) }}>Next</button> : ""} */}
                            </div>}
                            {showFilter === true && <>
                                <div className="ew-section-1">
                                    <div className="text-field-empty-custom-user">
                                        <div className='mb-24 pl-2 pr-2' style={{ margin: '30px 0 40px 0' }}>
                                            <label className='label-custom-1'>Model</label>
                                            {/* <option value="">Select Model</option> */}
                                            {/* <select className='form-control et-option   ' style={{ padding: "0px 14px" }} onChange={handleModelChange} value={selectedModelID || ""} >
                                                {modelOptions.map((option) => (
                                                    <option className='custom-option-sec' key={option.value} value={option.value}>{option.label}</option>
                                                ))}
                                            </select> */}
                                            <Select
                                                styles={customStyles}
                                                onChange={(selectedOption) => handleModelChange(selectedOption as SelectOption | null)}
                                                value={modelOptions.find(option => option.value === selectedModelID) || null}
                                                options={modelOptions}
                                                placeholder="Select Model"
                                            />
                                            {/* <span className="input-symbol"><span className='input-pipe'>|</span></span> */}
                                        </div>
                                    </div>
                                    <div className="text-field-empty-custom-user">
                                        <div className='mb-24 pl-2 pr-2' style={{ height: '50px' }}>
                                            <label className='label-custom-1'>Lead Source Category</label>
                                            {/* <select className='form-control' style={{ padding: "0px 14px" }} onChange={handleLeadSourceCategoryChange} value={selectedLeadSourceCategoryID || ""}
                                            >
                                                <option value="">Select Lead Source Category</option>
                                                {leadSourceCategoryOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>{option.label}</option>
                                                ))}
                                            </select> */}
                                            <Select
                                                styles={customStyles}
                                                onChange={(selectedOption) => handleLeadSourceCategoryChange(selectedOption as SelectOption | null)}
                                                value={leadSourceCategoryOptions.find(option => option.value === selectedLeadSourceCategoryID) || null}
                                                options={leadSourceCategoryOptions}
                                                placeholder="Select Lead Source Category"
                                            />
                                            {/* <span className="input-symbol"><span className='input-pipe'>|</span></span> */}
                                        </div>
                                    </div>
                                </div>

                                {/* <button onClick={() => { fetchETToTeamList(); setSelectedModelID(""); setSelectedLeadSourceCategoryID(""); setShowFilter(false); }} className='et-nextbtn'>Next</button> */}
                            </>}
                        </div>
                    )}
                </div >
                <div className="row btn-form-submit-1">
                    {showFilter === false ? <><button type="button" className="button1" onClick={() => { setToTeamDataVisible(false); setToSelectedUsers([]); setFromSelectedUsers([]); setShowFilter(false) }}>Cancel</button>
                        <button type="button" className="button2" onClick={() => { userETLeadTransfer(); setToTeamDataVisible(false); setToSelectedUsers([]); setFromSelectedUsers([]) }}>Submit</button></> :
                        <><button type="button" className="button1" onClick={() => { setSelectedModelID(""); setSelectedLeadSourceCategoryID(""); setShowFilter(false); }}>Cancel</button>
                            <button type="button" className="button2" onClick={() => { fetchETToTeamList(); setSelectedModelID(""); setSelectedLeadSourceCategoryID(""); setShowFilter(false); }}>Next</button></>}
                </div>
            </div>
            </div >
            }
        </>
    );
};

export default UserDashboardET;
