import { Drawer, Tabs, Tab, Button } from "@mui/material";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import * as Yup from 'yup';
import React, { useEffect, useRef, useState } from "react";
import FormField from "../components/form_items/FormField";
import { InitialData } from "./InitialData";
import { APIData, FormDataTypes } from "../common/DataTypes";
import { useAppStateAPI } from "../common/AppStateAPI";
import { API, ProjectStrings } from "../common/Constants";
import { getDataFromAPI } from "../common/Utilities";
import toast from "react-hot-toast";
import { ServerAPI } from "../common/ServerAPI";

interface CreateNewLeadEntryDialogProps {
    showDialog: boolean;
    closeDialog: () => void;
    reloadData?: () => void;
    id?: string;
    detailView?: boolean;
}

const CreateNewLeadEntryDialog: React.FC<CreateNewLeadEntryDialogProps> = ({ showDialog, closeDialog, reloadData, id, detailView }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [initialValue, setInitialValue] = useState<APIData.CreateLead | undefined>(InitialData.CreateLead);
    const formikRef = useRef<FormikProps<APIData.CreateLead>>(null);
    const [formDataSet, setFormDataSet] = useState(false);
    const [modelOptions, setModelOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [variantOptions, setVariantOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [exteriorColorOptions, setExteriorColorOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [interiorColorOptions, setInteriorColorOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [fuelTypeOptions, setFuelTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [leadTitleOptions, setleadTitleOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [LeadSourceOptions, setLeadSourceOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [LeadCatedgoryOptions, setLeadCatedgoryOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [assignedToOptions, setAssignedToOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [demoCarOptions, setDemoCarOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [showroomOptions, setShowroomOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [leadStatusOptions, setLeadStatusOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [leadTypeOptions, setleadTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [customerTypeOptions, setCustomerTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [customerCityOptions, setCustomerCityOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [customerStateOptions, setCustomerStateOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [leadTaskOptions, setLeadTaskOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [leadTestdrivelocationOptions, setLeadTestdrivelocationOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedCustomerStateID, setSelectedCustomerStateID] = useState('');
    const [selectedTaskUUID, setSelectedTaskUUID] = useState('');
    const [selectedShowroomUUID, setSelectedShowroomUUID] = useState('');
    const [selectedTaskData, setSelectedTaskData] = useState<FormDataTypes.SelectOption[]>([]);
    const [leadQualify, setleadQualify] = useState<number | undefined>();
    const [selectedAddress, setSelectedAddress] = useState('');
    const [selectedLandmark, setSelectedLandmark] = useState('');
    const [isSameBillingAddress, setIsSameBillingAddress] = useState('');
    const createLeadRef = useRef<HTMLDivElement | null>(null);
    const [selectedCityID, setSelectedCityID] = useState('');
    const [selectedStateID, setSelectedStateID] = useState('');
    const [selectedPincode, setSelectedPincode] = useState<number | undefined>();
    const [landmark, setlandmark] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedBillingCityID, setSelectedBillingCityID] = useState('');
    const [selectedBillingStateID, setSelectedBillingStateID] = useState('');
    const [selectedBillingPincode, setSelectedBillingPincode] = useState<number | undefined>();
    const [billingLandmark, setBillingLandmark] = useState<FormDataTypes.SelectOption[]>([]);
    const [selectedModelUUID, setSelectedModelUUID] = useState('');
    const [selectedVariantUUID, setSelectedVariantUUID] = useState('');
    const [selectedExteriorUUID, setSelectedExteriorUUID] = useState('');
    const [selectedLeadCategoryUUID, setSelectedLeadCategoryUUID] = useState('');
    const [availableModelVariants, setAvailablemodelVariants] = useState<APIData.AvailableModelVariant[]>([]);

    useEffect(() => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10000;
        params["status"] = 1;
        getDataFromAPI(API.EndPoint.ModelList, setModelOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'model_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.LeadTitleList, setleadTitleOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'lead_title', 'data', 'list');
        getDataFromAPI(API.EndPoint.LeadProspectTypeList, setLeadStatusOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'prospect_type_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.CustomerTypeList, setCustomerTypeOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'customer_type_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.StateList, setCustomerStateOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'state_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.UserDemoCarList, setDemoCarOptions, addProcessingRequests, reduceProcessingRequests, null, true, 'uuid', 'model_name', 'data', 'demo_car');
        getDataFromAPI(API.EndPoint.ShowroomList, setShowroomOptions, addProcessingRequests, reduceProcessingRequests, null, true, 'uuid', 'showroom_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.LeadTypeList, setleadTypeOptions, addProcessingRequests, reduceProcessingRequests, null, true, 'uuid', 'vehicle_lead_type_name', 'data', 'list');
        getDataFromAPI(API.EndPoint.LeadSourceCategoryList, setLeadCatedgoryOptions, addProcessingRequests, reduceProcessingRequests, null, true, 'uuid', 'lead_source_category_name', 'data');
        getDataFromAPI(API.EndPoint.LeadTestDriveLocation, setLeadTestdrivelocationOptions, addProcessingRequests, reduceProcessingRequests, null, true, 'uuid', 'test_drive_location_name', 'data');
        setFormDataSet(true);
        // const handleClickOutside = (event: MouseEvent) => {

        //     if (createLeadRef.current && !createLeadRef.current.contains(event.target as Node)) {
        //         setCurrentTab(0);
        //         setSelectedPincode(undefined);
        //         setlandmark([]);
        //         setBillingLandmark([]);
        //         setSelectedCityID("");
        //         setSelectedCustomerStateID("");
        //         setSelectedStateID("");
        //         setSelectedAddress("");
        //         setSelectedBillingCityID("");
        //         setSelectedBillingStateID("");
        //         setSelectedBillingPincode(undefined);
        //         setInitialValue(InitialData.CreateLead);
        //         setIsSameBillingAddress("0")
        //         setSelectedModelUUID("");
        //         setSelectedVariantUUID("");
        //         setSelectedExteriorUUID("");
        //         setSelectedLeadCategoryUUID("");
        //         setInteriorColorOptions([]);
        //         setExteriorColorOptions([]);
        //         setVariantOptions([])
        //         setFuelTypeOptions([])
        //         console.log("clciked")
        //     }
        // };

        // document.addEventListener("mousedown", handleClickOutside);

        // return () => {
        //     document.removeEventListener("mousedown", handleClickOutside);
        // };
    }, []);

    useEffect(() => {
        if (showDialog === false) {
            setCurrentTab(0);
            setSelectedPincode(undefined);
            setlandmark([]);
            setBillingLandmark([]);
            setSelectedCityID("");
            setSelectedCustomerStateID("");
            setSelectedStateID("");
            setSelectedAddress("");
            setSelectedBillingCityID("");
            setSelectedBillingStateID("");
            setSelectedBillingPincode(undefined);
            setInitialValue(InitialData.CreateLead);
            setIsSameBillingAddress("0")
            setSelectedModelUUID("");
            setSelectedVariantUUID("");
            setSelectedExteriorUUID("");
            setSelectedLeadCategoryUUID("");
            setInteriorColorOptions([]);
            setExteriorColorOptions([]);
            setVariantOptions([])
            setFuelTypeOptions([])
        }

    }, [showDialog]);

    useEffect(() => {
        if (selectedPincode !== undefined && selectedPincode.toString().length === 6) {
            addProcessingRequests();
            ServerAPI.getBookCarPincode(selectedPincode).then(response => {
                if (response && response.data && response.data.list.length > 0) {
                    const landmarkData = response.data.list[0].landmark.map((item: any) => ({
                        label: item,
                        value: item
                    }));
                    const newLandmark = landmarkData;
                    const newStateID = response.data.list[0].state_uuid;
                    const newCityID = response.data.list[0].city_uuid;
                    setSelectedCityID(newCityID);
                    setSelectedStateID(newStateID);
                    setlandmark(newLandmark);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }, [selectedPincode]);

    useEffect(() => {
        if (selectedBillingPincode !== undefined && selectedBillingPincode.toString().length === 6) {
            addProcessingRequests();
            ServerAPI.getBookCarPincode(selectedBillingPincode).then(response => {
                if (response && response.data && response.data.list.length > 0) {
                    const landmarkData = response.data.list[0].landmark.map((item: any) => ({
                        label: item,
                        value: item
                    }));
                    const newLandmark = landmarkData;
                    const newStateID = response.data.list[0].state_uuid;
                    const newCityID = response.data.list[0].city_uuid;
                    setSelectedBillingCityID(newCityID);
                    setSelectedBillingStateID(newStateID);
                    setBillingLandmark(newLandmark);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }, [selectedBillingPincode]);

    useEffect(() => {
        if (selectedCustomerStateID) {
            let params: { [k: string]: any } = {};
            params["limit"] = 10000;
            params["status"] = 1;
            params["state_uuid"] = selectedCustomerStateID;
            getDataFromAPI(API.EndPoint.CityList, setCustomerCityOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'city_name', 'data', 'list');
        } else {
            setCustomerCityOptions([]);
        }
    }, [selectedCustomerStateID]);


    useEffect(() => {
        let params: { [k: string]: any } = {};
        if (leadQualify && leadQualify !== 0) {
            params['qualify_status'] = leadQualify;
        }
        if (selectedShowroomUUID) {
            params['showroom_uuid'] = selectedShowroomUUID;
        }
        if (selectedLeadCategoryUUID) {
            params['showroom_uuid'] = selectedShowroomUUID;
        }
        getDataFromAPI(API.EndPoint.LeadAssignToList, setAssignedToOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'name', 'data');
        getDataFromAPI(API.EndPoint.LeadSourceList, setLeadSourceOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'lead_source_name', 'data', 'list');
    }, [selectedShowroomUUID, leadQualify]);

    useEffect(() => {
        let params: { [k: string]: any } = {};
        if (selectedLeadCategoryUUID) {
            params['lead_source_cat_uuid'] = selectedLeadCategoryUUID;
        }
        getDataFromAPI(API.EndPoint.LeadSourceList, setLeadSourceOptions, addProcessingRequests, reduceProcessingRequests, params, true, 'uuid', 'lead_source_name', 'data', 'list');
    }, [selectedLeadCategoryUUID]);

    useEffect(() => {
        addProcessingRequests();
        ServerAPI.getModelVariants(selectedModelUUID).then(response => {
            if (response && response.data && response.data.list.length > 0) {
                setAvailablemodelVariants(response.data.list);
                formikRef.current?.setFieldValue('interested_vehicle_details.vehicle_variant_uuid', "");
                formikRef.current?.setFieldValue('interested_vehicle_details.vehicle_fuel_type', "");
                formikRef.current?.setFieldValue('interested_vehicle_details.vehicle_exterior_uuid', "");
                formikRef.current?.setFieldValue('interested_vehicle_details.vehicle_interior_uuid', "");
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }, [selectedModelUUID]);


    useEffect(() => {
        if (availableModelVariants) {
            const modelVariants = availableModelVariants.map((item: any) => ({
                label: item.variant_name,
                value: item.uuid
            }));
            setVariantOptions(modelVariants);

            // Filter interior colors based on selected variant and exterior color
            const selectedVariant = availableModelVariants.find((item: any) => item.uuid === selectedVariantUUID);
            if (selectedVariant) {
                const uniqueExteriorColors = Array.from(
                    new Map(selectedVariant.colors.map((color: any) => [color.exterior_colors_uuid, {
                        label: color.exterior_color_name,
                        value: color.exterior_colors_uuid
                    }])).values()
                );
                setExteriorColorOptions(uniqueExteriorColors);
            }

            // Filter interior colors based on selected variant and interior color
            const selectedVariantWithColors = availableModelVariants.find((item: any) => item.uuid === selectedVariantUUID);
            if (selectedVariantWithColors) {
                const selectedExteriorColors = selectedVariantWithColors.colors.filter((color: any) => color.exterior_colors_uuid === selectedExteriorUUID);
                const uniqueInteriorColors = Array.from(
                    new Map(selectedExteriorColors.map((color: any) => [color.interior_color_uuid, {
                        label: color.interior_color_name,
                        value: color.interior_color_uuid
                    }])).values()
                );
                setInteriorColorOptions(uniqueInteriorColors);
            }

            // Filter fuel types based on selected variant
            const selectedVariantFuelType = availableModelVariants.find((item: any) => item.uuid === selectedVariantUUID);
            if (selectedVariantFuelType) {
                const fuelType = [{
                    label: selectedVariantFuelType.fuel_name,
                    value: selectedVariantFuelType.fuel_type_uuid
                }];
                setFuelTypeOptions(fuelType);
                formikRef.current?.setFieldValue('interested_vehicle_details.vehicle_fuel_type', selectedVariantFuelType.fuel_type_uuid);
            }
        }
    }, [selectedModelUUID, selectedExteriorUUID, selectedVariantUUID, availableModelVariants]);


    useEffect(() => {
        addProcessingRequests();
        ServerAPI.executeAPI(API.EndPoint.LeadTaskList, ServerAPI.APIMethod.GET, true, null)
            .then(response => {
                if (response.data !== undefined && response.success && response.data.list.length > 0) {
                    let leadtaskData;
                    let selectedtaskData;
                    if (leadQualify === 0) {
                        leadtaskData = response.data.list
                            .filter((item: any) => item.lead_task_name === "Followup Call")
                            .map((item: any) => ({
                                label: item.lead_task_name,
                                value: item.uuid
                            }));
                    } else if (leadQualify === 1) {
                        leadtaskData = response.data.list
                            .filter((item: any) => ["Followup Call", "Test Drive", "Visit"].includes(item.lead_task_name))
                            .map((item: any) => ({
                                label: item.lead_task_name,
                                value: item.uuid
                            }));
                    }
                    setLeadTaskOptions(leadtaskData || []);

                    if (selectedTaskUUID !== "") {
                        selectedtaskData = response.data.list
                            .filter((item: any) => item.uuid === selectedTaskUUID)
                            .map((item: any) => ({
                                label: item.lead_task_name,
                                value: item.uuid
                            }))
                    }
                    setSelectedTaskData(selectedtaskData)
                }
            })
            .finally(() => {
                reduceProcessingRequests();
                setFormDataSet(true);
            });
    }, [leadQualify, selectedTaskUUID]);

    useEffect(() => {
        formikRef.current?.setFieldValue('customer_details.state_uuid', selectedStateID);
        formikRef.current?.setFieldValue('customer_details.city_uuid', selectedCityID);

    }, [selectedCityID, selectedStateID]);
    useEffect(() => {
        formikRef.current?.setFieldValue('customer_details.billing_address_state_uuid', selectedBillingStateID);
        formikRef.current?.setFieldValue('customer_details.billing_address_city_uuid', selectedBillingCityID);

    }, [selectedBillingCityID, selectedBillingStateID]);
    useEffect(() => {
        if (isSameBillingAddress === "1") {
            formikRef.current?.setFieldValue('customer_details.billing_address_state_uuid', selectedStateID);
            formikRef.current?.setFieldValue('customer_details.billing_address_city_uuid', selectedCityID);
            formikRef.current?.setFieldValue('customer_details.billing_pincode', selectedPincode);
            formikRef.current?.setFieldValue('customer_details.billing_landmark', selectedLandmark);
        }
        // else if (isSameBillingAddress === "0") {
        //     formikRef.current?.setFieldValue('customer_details.billing_address_state_uuid', "");
        //     formikRef.current?.setFieldValue('customer_details.billing_address_city_uuid', "");
        //     formikRef.current?.setFieldValue('customer_details.billing_pincode', "");
        //     formikRef.current?.setFieldValue('customer_details.billing_landmark', "");
        // }
    }, [selectedCityID, selectedStateID, selectedPincode, selectedLandmark, isSameBillingAddress]); //selectedAddress

    function TabPanel(props: { children: any; value: any; index: any; }) {
        const { children, value, index } = props;
        return (
            <div>
                {
                    value === index && (
                        <h1>{children}</h1>
                    )
                }
            </div>
        )
    }

    const handelTabChange = (e: any, val: React.SetStateAction<number>) => {
        setCurrentTab(val)
    }

    const yupSchema = Yup.object().shape({
        customer_details: Yup.object().shape({
            title: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            first_name: Yup.string().required(ProjectStrings.ValidationRequired),
            last_name: Yup.string().required(ProjectStrings.ValidationRequired),
            date_of_birth: Yup.string().required(ProjectStrings.ValidationRequired),
            primary_mobile_number: Yup.string().matches(/^[6-9]\d{9}$/, { message: "Please enter valid number.", excludeEmptyString: false }).required(ProjectStrings.ValidationRequired),
            email_id: Yup.string().matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, { message: "Please enter valid Email.", excludeEmptyString: false }).required(ProjectStrings.ValidationRequired),
            customer_type_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            address: Yup.string().required(ProjectStrings.ValidationRequired),
            pin_code: Yup.string().required(ProjectStrings.ValidationRequired),
            state_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            city_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            landmark: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            billing_as_shipping: Yup.string(),
            billing_landmark: Yup.string().when('billing_as_shipping', ([billing_as_shipping], schema) => {
                if (parseInt(billing_as_shipping) === 1)
                    return Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired)
                return schema;
            }),
            billing_address: Yup.string().when('billing_as_shipping', ([billing_as_shipping], schema) => {
                if (parseInt(billing_as_shipping) === 1)
                    return Yup.string().required(ProjectStrings.ValidationRequired)
                return schema;
            }),
            billing_address_city_uuid: Yup.string().when('billing_as_shipping', ([billing_as_shipping], schema) => {
                if (parseInt(billing_as_shipping) === 1)
                    return Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired)
                return schema;
            }),
            billing_address_state_uuid: Yup.string().when('billing_as_shipping', ([billing_as_shipping], schema) => {
                if (parseInt(billing_as_shipping) === 1)
                    return Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired)
                return schema;
            }),
            billing_pincode: Yup.string().when('billing_as_shipping', ([billing_as_shipping], schema) => {
                if (parseInt(billing_as_shipping) === 1)
                    return Yup.string().required(ProjectStrings.ValidationRequired)
                return schema;
            })
        }),
        lead_details: Yup.object().shape({
            lead_type_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            lead_source_category_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            lead_source_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            lead_status_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        }),
        interested_vehicle_details: Yup.object().shape({
            vehicle_model_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            // vehicle_variant_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            // vehicle_exterior_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            // vehicle_interior_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            // vehicle_fuel_type: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
        }),
        task_assign_details: Yup.object().shape({
            add_task_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            assiged_to_uuid: Yup.string().notOneOf(["0"], ProjectStrings.ValidationSelect).required(ProjectStrings.ValidationRequired),
            date_time: Yup.string().required(ProjectStrings.ValidationRequired),
        }),
    });

    return (
        <Drawer anchor="right" open={showDialog} onClose={closeDialog} className="book-a-car-section drawer-max">
            {formDataSet && initialValue !== undefined && (
                <Formik
                    validationSchema={yupSchema} enableReinitialize={true} initialValues={initialValue} innerRef={formikRef} onSubmit={(values, { setSubmitting }) => {
                        let submitValues: any = { ...values };
                        const TaskAssign = submitValues.task_assign_details;

                        //task_assign_details
                        if (selectedTaskData[0].label === "Visit" || selectedTaskData[0].label === "Test Drive") {
                            if (!TaskAssign.testdrive_location_type_uuid) {
                                toast.error("TestDrive Location is Required");
                                setSubmitting(false);
                                return
                            }
                        }
                        if (selectedTaskData[0].label === "Visit" || selectedTaskData[0].label === "Test Drive") {
                            if (!TaskAssign.showroom_uuid) {
                                toast.error("Showroom ID is Required");
                                setSubmitting(false);
                                return
                            }
                        }
                        if (selectedTaskData[0].label === "Test Drive") {
                            if (!TaskAssign.demo_car_uuid) {
                                toast.error("Demo Car is Required");
                                setSubmitting(false);
                                return
                            }
                        }

                        submitValues.customer_details.billing_as_shipping = parseInt(submitValues.customer_details.billing_as_shipping);
                        submitValues.customer_details.billing_pincode = submitValues.customer_details.billing_pincode + "";
                        submitValues.customer_details.pin_code = submitValues.customer_details.pin_code + "";
                        submitValues.customer_details.primary_mobile_number = submitValues.customer_details.primary_mobile_number + "";
                        addProcessingRequests();
                        ServerAPI.CreateLead(submitValues).then(response => {
                            if (response && response['success'] && response['message']) {
                                toast.success(response['message']);
                                setCurrentTab(0);
                                closeDialog();
                            } else if (response && response['message']) {
                                toast.error(response['message']);
                            }
                        }).finally(() => {
                            setSubmitting(false);
                            reduceProcessingRequests();
                        });

                    }}>
                    {(formikProps) => {
                        if (formikProps.values.customer_details.state_uuid && formikProps.values.customer_details.state_uuid !== selectedCustomerStateID) {
                            setSelectedCustomerStateID(formikProps.values.customer_details.state_uuid);
                        }

                        if (
                            formikProps.values.customer_details.address &&
                            formikProps.values.customer_details.address !== formikProps.values.customer_details.billing_address
                        ) {
                            if (isSameBillingAddress === "1") {
                                formikRef.current?.setFieldValue('customer_details.billing_address', formikProps.values.customer_details.address);
                            }
                        }

                        if (
                            formikProps.values.customer_details.billing_as_shipping !== undefined &&
                            formikProps.values.customer_details.billing_as_shipping != parseInt(isSameBillingAddress)
                        ) {
                            setIsSameBillingAddress(formikProps.values.customer_details.billing_as_shipping.toString());
                        }


                        if (formikProps.values.customer_details.landmark && formikProps.values.customer_details.landmark !== selectedLandmark) {
                            setSelectedLandmark(formikProps.values.customer_details.landmark);
                        }
                        if (formikProps.values.task_assign_details.showroom_uuid && formikProps.values.task_assign_details.showroom_uuid !== selectedShowroomUUID) {
                            setSelectedShowroomUUID(formikProps.values.task_assign_details.showroom_uuid);
                        }

                        if (formikProps.values.task_assign_details.lead_qualify !== leadQualify) {
                            setleadQualify(formikProps.values.task_assign_details.lead_qualify);
                        }

                        if (formikProps.values.task_assign_details.add_task_uuid !== selectedTaskUUID) {
                            setSelectedTaskUUID(formikProps.values.task_assign_details.add_task_uuid);
                        }
                        if (formikProps.values.lead_details.lead_source_category_uuid !== selectedLeadCategoryUUID) {
                            setSelectedLeadCategoryUUID(formikProps.values.lead_details.lead_source_category_uuid);
                        }
                        if (formikProps.values.interested_vehicle_details.vehicle_exterior_uuid !== selectedExteriorUUID) {
                            setSelectedExteriorUUID(formikProps.values.interested_vehicle_details.vehicle_exterior_uuid);
                        }
                        if (formikProps.values.interested_vehicle_details.vehicle_variant_uuid !== selectedVariantUUID) {
                            setSelectedVariantUUID(formikProps.values.interested_vehicle_details.vehicle_variant_uuid);
                        }
                        if (formikProps.values.interested_vehicle_details.vehicle_model_uuid !== selectedModelUUID) {
                            setSelectedModelUUID(formikProps.values.interested_vehicle_details.vehicle_model_uuid);
                        }

                        if (
                            formikProps.values.customer_details.pin_code &&
                            formikProps.values.customer_details.pin_code.toString().length === 6 &&
                            parseInt(formikProps.values.customer_details.pin_code) !== selectedPincode
                        ) {
                            const newPincode = parseInt(formikProps.values.customer_details.pin_code);
                            setSelectedPincode(newPincode);
                        }

                        if (
                            formikProps.values.customer_details.billing_pincode &&
                            formikProps.values.customer_details.billing_pincode.toString().length === 6 &&
                            parseInt(formikProps.values.customer_details.billing_pincode) !== selectedBillingPincode
                        ) {
                            const newPincode = parseInt(formikProps.values.customer_details.billing_pincode);
                            setSelectedBillingPincode(newPincode);
                        }


                        return (
                            <Form>
                                <div className="tabdetails create-new-lead-section" ref={createLeadRef} >
                                    <div className="tabdetails-1" style={{ overflowY: "auto", height: "90vh" }}>
                                        <h4 className="title-text text-center"> Create New Lead</h4>
                                        <div className="custom-border-grey"></div>
                                        <div className="tabpage">
                                            <div className="form-field-container-tabs">
                                                <Tabs value={currentTab} onChange={handelTabChange}
                                                    sx={{ backgroundColor: "white", borderTop: "2px solid #e2e2e2", borderBottom: "2px solid #e2e2e2", "& button": { width: "auto" }, justifyContent: "space-between" }}
                                                    TabIndicatorProps={{
                                                        style: {
                                                            backgroundColor: "#14408B",
                                                        },
                                                    }}
                                                >
                                                    <Tab label="Customer Details " className="tab-headers" />
                                                    <Tab label="Interested Vehicle Details" className="tab-headers" />
                                                    <Tab label="Lead Details" className="tab-headers" />
                                                    <Tab label="Task & Assign Details" className="tab-headers" />

                                                </Tabs>
                                                <TabPanel value={currentTab} index={0}>
                                                    <>
                                                        <div className="row pt-3 pb-1">
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 mt-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Title", name: "customer_details.title", placeholder: "Select Title", options: leadTitleOptions, required: true, }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "First Name", name: "customer_details.first_name", placeholder: "Enter First Name", required: true, }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Last Name", name: "customer_details.last_name", placeholder: "Enter Last Name", required: true, }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1 date-field">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "datepicker", label: "Date of Birth", name: "customer_details.date_of_birth", placeholder: "Select DOB" }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1 mt-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Mobile Number", name: "customer_details.primary_mobile_number", placeholder: "Enter Mobile Number", required: true, }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1 mt-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Secondary Number", name: "customer_details.secondary_mobile_number", placeholder: "Enter Mobile Number" }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 mt-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Customer Type", name: "customer_details.customer_type_uuid", placeholder: "Select Type", options: customerTypeOptions, required: true, }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Email Id  ", name: "customer_details.email_id", placeholder: "Enter Email Id", required: true, }} />
                                                                {/* <img src="/assets/images/icons/add-black.svg" alt="" onClick={closeDialog} style={{ cursor: 'pointer' }} /> */}
                                                            </div>
                                                            <div style={{ width: "100%", marginLeft: "12px", marginRight: "8px", marginBottom: "10px" }}>
                                                                <FieldArray name="customer_details.other_email_ids">
                                                                    {({ insert, remove, push }) => (
                                                                        <>
                                                                            <button type="button" className="mb-3 mr-2 btn" style={{ backgroundColor: "#14408b", color: "#FFF" }} onClick={() => push('')}>Add Other Email Id</button>
                                                                            <div className='individual_sub_content' style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridColumnGap: '17px', height: 'auto' }}>

                                                                                <>
                                                                                    {formikProps.values.customer_details.other_email_ids.map((data, index) => {
                                                                                        const uniqueKey = `${index}`;
                                                                                        return (

                                                                                            <div key={uniqueKey} className="overall mb-4">
                                                                                                <div className="field text-field-empty-custom-user-1">
                                                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Other Email id", name: `customer_details.other_email_ids.${index}`, placeholder: "Enter Other Email Id", required: true }} />
                                                                                                </div>
                                                                                                <div className="icon text-field-empty-custom-user">
                                                                                                    <button type="button" className={`btn btn-sm ml-1`} style={{ backgroundColor: "#f14b4b" }} onClick={() => remove(index)}><i className="text-white fas fa-trash pr-1 pl-1"></i></button>
                                                                                                </div>
                                                                                            </div>

                                                                                        )
                                                                                    })}
                                                                                </>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </FieldArray>
                                                            </div>
                                                        </div>
                                                        <div className="custom-border-grey mb-3 px-2"></div>
                                                        <div className="row pt-3 pb-1">
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Pincode", name: "customer_details.pin_code", placeholder: "Enter Pin Code", required: true, }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 mt-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "State", name: "customer_details.state_uuid", placeholder: "Select State", options: customerStateOptions, required: true, }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 mt-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "City", name: "customer_details.city_uuid", placeholder: "Select City", options: customerCityOptions, required: true, }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Landmark  ", name: "customer_details.landmark", placeholder: "Select Landmark", options: landmark, required: true, }} />
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-xl-8 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 textarea-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textarea", label: "Address", name: "customer_details.address", placeholder: "Enter Address *", required: true, }} />
                                                            </div>
                                                        </div>
                                                        <div className="row pb-1">
                                                            <div className="col-lg-12 col-md-12 col-xl-12 mb-4 ">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "checkbox", label: "Billing Address same as above", name: "customer_details.billing_as_shipping", }} />
                                                            </div>
                                                            {formikProps.values.customer_details.billing_as_shipping === 1 && <>
                                                                <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Billing Pincode", name: "customer_details.billing_pincode", placeholder: "Enter Pin Code", required: true, disabled: true }} />
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 mt-1">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Billing State", name: "customer_details.billing_address_state_uuid", placeholder: "Select State", options: customerStateOptions, required: true, readOnly: true }} />
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 mt-1">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Billing City", name: "customer_details.billing_address_city_uuid", placeholder: "Select City", options: customerCityOptions, required: true, readOnly: true }} />
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Billing Landmark", name: "customer_details.billing_landmark", placeholder: "Select Landmark", options: landmark, required: true, readOnly: true }} />
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-xl-8 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 textarea-1">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "textarea", label: "Billing Address", name: "customer_details.billing_address", placeholder: "Enter Billing Address *", required: true, readOnly: true }} />
                                                                </div>
                                                            </>}
                                                            {formikProps.values.customer_details.billing_as_shipping === 0 && <>
                                                                <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "number", label: "Billing Pincode", name: "customer_details.billing_pincode", placeholder: "Enter Pin Code", required: true }} />
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 mt-1">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Billing State", name: "customer_details.billing_address_state_uuid", placeholder: "Select State", options: customerStateOptions, required: true }} />
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 mt-1">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Billing City", name: "customer_details.billing_address_city_uuid", placeholder: "Select City", options: customerCityOptions, required: true }} />
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Billing Landmark", name: "customer_details.billing_landmark", placeholder: "Select Landmark", options: billingLandmark, required: true }} />
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-xl-8 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 textarea-1">
                                                                    <FormField formik={formikProps} fieldProps={{ fieldType: "textarea", label: "Billing Address", name: "customer_details.billing_address", placeholder: "Enter Billing Address *", required: true }} />
                                                                </div>
                                                            </>}
                                                        </div>
                                                        {/* <div className="">
                                                            <FieldArray name="customer_details.other_email_ids">
                                                                {({ insert, remove, push }) => (
                                                                    <>
                                                                        <button type="button" className="mb-2 mr-2 btn" style={{ backgroundColor: "#14408b", color: "#FFF" }} onClick={() => push('')}>Add Other Email Id</button>
                                                                        <div className='individual_sub_content' style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridGap: '10px', height: 'auto' }}>
                                                                           
                                                                            <>
                                                                                {formikProps.values.customer_details.other_email_ids.map((data, index) => {
                                                                                    const uniqueKey = `${index}`;
                                                                                    return (

                                                                                        <div key={uniqueKey} className="overall py-3">
                                                                                            <div className="field text-field-empty-custom-user-1">
                                                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Other Email id", name: `customer_details.other_email_ids.${index}`, placeholder: "Enter Other Email Id", required: true }} />
                                                                                            </div>
                                                                                            <div className="icon text-field-empty-custom-user">
                                                                                                <button type="button" className={`btn btn-sm ml-1`} style={{ backgroundColor: "#f14b4b" }} onClick={() => remove(index)}><i className="text-white fas fa-trash pr-1 pl-1"></i></button>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </FieldArray>
                                                        </div> */}
                                                        <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                            <button type="button" className="button1" onClick={() => closeDialog()}>Back</button>
                                                            <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => setCurrentTab(1)}>Next</button>
                                                        </div>
                                                    </>
                                                </TabPanel>
                                                <TabPanel value={currentTab} index={1}>
                                                    <>
                                                        <div className="row  py-3">
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Model", name: "interested_vehicle_details.vehicle_model_uuid", placeholder: "Select Model", options: modelOptions, required: true, }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Variant", name: "interested_vehicle_details.vehicle_variant_uuid", placeholder: "Select Variant", options: variantOptions }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Exterior Color", name: "interested_vehicle_details.vehicle_exterior_uuid", placeholder: "Select Exterior Color", options: exteriorColorOptions }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Interior Color", name: "interested_vehicle_details.vehicle_interior_uuid", placeholder: "Select Interior Color", options: interiorColorOptions }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Fuel Type", name: "interested_vehicle_details.vehicle_fuel_type", placeholder: "Select Fuel Type", options: fuelTypeOptions }} />
                                                            </div>
                                                        </div>
                                                        <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                            <button type="button" className="button1" onClick={() => setCurrentTab(0)}>Previous</button>
                                                            <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => setCurrentTab(2)}>Next</button>
                                                        </div>
                                                    </>
                                                </TabPanel>
                                                <TabPanel value={currentTab} index={2}>
                                                    <>
                                                        <div className="row  py-3">
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: " Lead Type", name: "lead_details.lead_type_uuid", placeholder: "Select Lead Type", options: leadTypeOptions, required: true, }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: " Lead Source Category", name: "lead_details.lead_source_category_uuid", placeholder: "Select Lead Source Category ", options: LeadCatedgoryOptions, required: true, }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: " Lead Source", name: "lead_details.lead_source_uuid", placeholder: "Select  Lead Source", options: LeadSourceOptions, required: true, }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: " Lead Status", name: "lead_details.lead_status_uuid", placeholder: "Select Lead Status", options: leadStatusOptions, required: true, }} />
                                                            </div>
                                                            {/* <div className="col-lg-12 col-md-12 col-xl-12 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1">
                                                            <div style={{ width: "100%" }}><img src="/assets/images/icons/location.svg" alt="" width={"100%"} /></div>
                                                            <div className="location-direction">Latitude: 38.8951 & Longitude: -77.0364</div>
                                                        </div> */}
                                                        </div>
                                                        <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                            <button type="button" className="button1" onClick={() => setCurrentTab(1)}>Previous</button>
                                                            <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => setCurrentTab(3)}>Next</button>
                                                        </div>
                                                    </>
                                                </TabPanel>
                                                <TabPanel value={currentTab} index={3}>
                                                    <>
                                                        <div className="row py-2" style={{ height: "54px" }}>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 ">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "checkbox", label: "Lead Qualify", name: "task_assign_details.lead_qualify", }} />
                                                            </div>
                                                        </div>
                                                        <div className="row  py-3">
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 mt-2">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Add Task", name: "task_assign_details.add_task_uuid", placeholder: "Click to Add a Task ", options: leadTaskOptions, required: true, }} />
                                                            </div>
                                                            {!!selectedTaskData && selectedTaskData[0].label === "Test Drive" && (
                                                                <>
                                                                    <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 mt-2">
                                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Test Drive Location", name: "task_assign_details.testdrive_location_type_uuid", placeholder: "Select Test Drive Location", options: leadTestdrivelocationOptions, required: true, }} />
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 mt-2">
                                                                        <FormField formik={formikProps} fieldProps={{
                                                                            fieldType: "select", label: "Demo Car", name: "task_assign_details.demo_car_uuid", placeholder: "Select Demo Car", options: demoCarOptions, required: true
                                                                        }} />

                                                                    </div>
                                                                </>
                                                            )}
                                                            {!!selectedTaskData && selectedTaskData[0].label === "Visit" && (
                                                                <>
                                                                    <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 mt-2">
                                                                        <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Visit Location", name: "task_assign_details.testdrive_location_type_uuid", placeholder: "Select Visit Location", options: leadTestdrivelocationOptions, required: true, }} />
                                                                    </div>
                                                                </>
                                                            )}
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 mt-2">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Showroom", name: "task_assign_details.showroom_uuid", placeholder: "Select Showroom", options: showroomOptions, required: true, }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user text-field-empty-custom-user-1 mt-2">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "select", label: "Assigned To", name: "task_assign_details.assiged_to_uuid", placeholder: "Select Employee", options: assignedToOptions, required: true, }} />
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-xl-4 mb-4 text-field-empty-custom-user-1 book-final date-field">
                                                                <FormField formik={formikProps} fieldProps={{ fieldType: "datetimepicker", label: "Date & Time", name: "task_assign_details.date_time", placeholder: "Select Date & Time", required: true, }} />
                                                            </div>
                                                        </div>
                                                        <div className="row btn-form-submit" style={{ position: "absolute" }}>
                                                            <button type="button" className="button1" onClick={() => setCurrentTab(2)}>Previous</button>
                                                            <button type="button" className="button2" disabled={formikProps.isSubmitting} onClick={() => {
                                                                formikProps.submitForm();
                                                                if (!formikProps.isValid) {
                                                                    console.log('Errors', formikProps.errors);
                                                                    toast.error("Please ensure all fields are Filled and Valid!");
                                                                }
                                                            }}>Submit</button>
                                                        </div>
                                                    </>
                                                </TabPanel>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            )
            }
        </Drawer >
    )
}

export default CreateNewLeadEntryDialog;
