import React from "react";
import { formatNumberToIndianStyle } from "../../common/Utilities";

const CellPriceDisplay: React.FC<{ data: any, data_key: string }> = ({ data, data_key }) => {
    return (
        <span>
            {data[data_key] ? `Rs. ${formatNumberToIndianStyle(data[data_key])}` : "N/A"}
        </span>
    );
};

export default CellPriceDisplay;
