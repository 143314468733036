import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import toast from "react-hot-toast";
import { ServerAPI } from "../common/ServerAPI";
import { persistLoginDetail } from "../common/Utilities";
import { EndUser, PageLinks } from "../common/Constants";
import OtpInput from 'react-otp-input';

const AdminLogin: React.FC = () => {

    const [step, setStep] = useState(0);
    const [otp, setOtp] = useState('');
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const navigate = useNavigate();
    const { addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const validateLogin = () => {
        if (!email) {
            toast.error('Please Enter Email');
        } else if (!password) {
            toast.error('Please Enter Password');
        } else {
            addProcessingRequests();
            ServerAPI.superAdminLogin(email, password).then((response: any) => {
                if (response['success'] === true) {
                    toast.success(response['message']);
                    if (response.data && response.data.login_otp) {
                        setOtp(response.data.login_otp);
                    }
                    setStep(2);
                } else if (response['message']) {
                    toast.error(response['message']);
                } else {
                    toast.error('Something Went Wrong');
                }
            }).finally(() => {
                reduceProcessingRequests();
            })
        }
    }

    const executeVerifyOtp = () => {
        if (!email) {
            toast.error('Enter Valid Email');
        } else if (!password) {
            toast.error('Enter a Valid Password');
        } else {

            addProcessingRequests();
            ServerAPI.superAdminVerifyOtp(email, otp).then((response: any) => {
                if (response['success'] === true) {
                    toast.success(response['message']);
                    const userDetail = response['data'];
                    persistLoginDetail(true, "0", "", "Super Admin", email, userDetail['login_token'], window.location.origin + "/assets/images/blank-profile.png", EndUser.SuperAdmin);
                    navigate(PageLinks.ADMIN_USERS);
                } else if (response['message']) {
                    toast.error(response['message']);
                } else {
                    toast.error('Something Went Wrong');
                }
            }).finally(() => {
                reduceProcessingRequests();
            });

        }
    }

    return (
        <div className="account-body">
            <div className="row vh-100 ">
                <div className="col-12 align-self-center d-flex vh-100">
                    <div className="col-6 p-0">
                        <div className="color-native-blue vh-100 custom-padding-admin-login-left">
                            <div>
                                <img src={window.location.origin + "/assets/images/logo512_white.png"} height="70" alt="logo" className="auth-logo login-logo" />
                            </div>
                            <div className="small-spacer"></div>
                        </div>
                    </div>
                    <div className="col-6 p-0 d-flex-justify-center">
                        <div className="auth-card">
                            <div className="card-body p-0">
                                {step === 0 && (
                                    <div>
                                        <div className="text-center auth-logo-text">
                                            <h4 className="mt-0 mb-3 mt-5">Super Admin Login</h4>
                                            <p className="text-muted text-muted-user-action mb-0">Please Sign in to access your account</p>
                                        </div>
                                        <div className="py-2"></div>
                                        <form className="form-horizontal auth-form my-4" onSubmit={
                                            e => { e.preventDefault(); validateLogin(); }}>
                                            <div className="form-group text-left">
                                                <div className="input-group mb-3">
                                                    <span className="auth-form-icon">
                                                        <i className="dripicons-store"></i>
                                                    </span>
                                                    <input type="text" className="form-control" id="email" placeholder="Enter Super Admin Email" onChange={(e) => setEmail(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="py-1"></div>
                                            <div className="form-group text-left">
                                                <div className="input-group mb-3">
                                                    <span className="auth-form-icon">
                                                        <i className="dripicons-phone"></i>
                                                    </span>
                                                    <input type="password" className="form-control" id="password" placeholder="Enter Password" onChange={(e) => setPassword(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="py-1"></div>
                                            <div className="form-group mb-0 row">
                                                <div className="col-12 mt-2">
                                                    <button className="btn btn-login-primary btn-round btn-block waves-effect waves-light">Next</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                )}
                                {step === 2 && (
                                    <div className="px-3">
                                        <div className="text-center auth-logo-text">
                                            <h4 className="mt-0 mb-3 mt-5">Verify OTP</h4>
                                            <p className="text-muted text-muted-user-action mb-0">We Send you a 4 digit code to associated mobile number with your account</p>
                                        </div>
                                        <form className="form-horizontal auth-form" onSubmit={e => {
                                            e.preventDefault();
                                            executeVerifyOtp();
                                        }}>
                                            <div className="d-flex justify-content-center mt-4 mb-0">
                                                <OtpInput
                                                    value={otp}
                                                    onChange={setOtp}
                                                    numInputs={4}
                                                    renderSeparator={<span> </span>}
                                                    renderInput={(props) => <input {...props} className="otp-box" />}
                                                />
                                            </div>
                                            <div className="form-group mb-0 row">
                                                <div className="col-12 mt-2">
                                                    <button type="submit" className="btn btn-login-primary btn-round btn-block waves-effect waves-light w-70">Verify</button>
                                                </div>
                                            </div>
                                            <div className="text-center auth-logo-text mt-4">
                                                <p className="text-muted text-muted-user-action mb-0">Did'nt get a OTP? <span className="color-orange">Resend OTP</span></p>
                                            </div>
                                        </form>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminLogin;