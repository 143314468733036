import TestDriveStatusBadge from "../ui_components/TestDriveStatusBadge";


const CellOrderStatus: React.FC<{ data: any }> = ({ data }) => {

    return (
        <TestDriveStatusBadge testDriveStatus={data['status']} />
    )
}

export default CellOrderStatus;