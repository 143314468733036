import ActionAllotmentList from "../components/table_action/ActionAllotmentList";
import ActionTestDriveCancel from "../components/table_action/ActionTestDriveCancel";
import ActionTestDriveVerify from "../components/table_action/ActionTestDriveVerify";
import CellCustomerDetail from "../components/table_data/CellCustomerDetail";
import CellCustomerDetailWithEdit from "../components/table_data/CellCustomerDetailWithEdit";
import CellDCTcVinNumber from "../components/table_data/CellDCTcVinNumber";
import CellLeadCustomerDetail from "../components/table_data/CellLeadCustomerDetail";
import CellOdoMeterReading from "../components/table_data/CellOdoMeterReading";
import CellOdoMeterReadingFuel from "../components/table_data/CellOdoMeterReadingFuel";
import CellOrderStatus from "../components/table_data/CellOrderStatus";
import CellReportTDVehicleDetail from "../components/table_data/CellReportTDVehicleDetail";
import CellTDStartEndTime from "../components/table_data/CellStartTimeEndTime";
import CellStatusBadge from "../components/table_data/CellStatusBadge";
import CellStatusUpdateBadge from "../components/table_data/CellStatusUpdateBadge";
import CellTDAddress from "../components/table_data/CellTDAddress";
import CellTDDateAndTime from "../components/table_data/CellTDDateAndTime";
import CellTDEmployeeDetail from "../components/table_data/CellTDEmployeeDetail";
import CellTDRefillAmt from "../components/table_data/CellTDRefillAmt";
import CellTDShowroom from "../components/table_data/CellTDShowroom";
import CellTDVehicleDetail from "../components/table_data/CellTDVehicleDetail";
import CellTLSalesConsultant from "../components/table_data/CellTLSalesConsultant";
import CellInventoryStatusBadge from "../components/table_data/CellInventoryStatusBadge";
import { PageLinks } from "./Constants";
import CellPriceDisplay from "../components/table_data/CellPriceDisplay";
import CellUserNestedFromDate from "../components/table_data/CellUserNestedFromDate";
import CellUserNestedToDate from "../components/table_data/CellUserNestedToDate";
import CellNADisplay from "../components/table_data/CellNADisplay";
import CellTestdriveStatus from "../components/table_data/CellTestdriveStatus";

export namespace TableViewMappings {
    export interface TableColumn {
        label: string;
        data_key: string;
        type: "text" | "number" | "status" | "image" | "date" | "datetime" | "time" | "custom";
        mapped?: boolean;
        component?: any;
    }

    export interface TableActions {
        type: "edit" | "delete" | "view" | "copy" | "custom" | "mark" | "cancel"
        icon?: string;
        tooltip?: string;
        link?: string;
        params?: { param_key: string, data_key: string }[];
        component?: any;
    }

    export interface TableDetail {
        pageLink: string;
        columns: TableColumn[];
        actions?: TableActions[];
        primary_column: string;
        status_column?: string;
    }

    const tableList: TableDetail[] = [
        {
            pageLink: PageLinks.DEALER_USERS,
            columns: [
                { label: "Name", data_key: "name", type: "text" },
                { label: "Employee Code", data_key: "emp_code", type: "text" },
                { label: "Contact Number", data_key: "contact_phone", type: "text" },
                { label: "Contact Email", data_key: "contact_email", type: "text" },
                { label: "Role", data_key: "role_name", type: "text" },
                // { label: "Designation", data_key: "emp_code", type: "text" },
                // { label: "Reporting to", data_key: "showroom_address", type: "text" },
                { label: "Status", data_key: "status", type: "custom", component: CellStatusUpdateBadge },
            ],
            actions: [
                { type: "view" },
                { type: "edit" }
                //{ type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_TEAMS,
            columns: [
                { label: "Name", data_key: "team_name", type: "text" },
                { label: "Showroom", data_key: "showroom_name", type: "text" },
                { label: "Status", data_key: "status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                { type: "view" },
                { type: "edit" }
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.USER_INVENTORY,
            columns: [
                { label: "WHS - Code", data_key: "WHS_code", type: "text" },
                { label: "Model", data_key: "model_name", type: "text" },
                { label: "Variant", data_key: "variant_name", type: "text" },
                // { label: "Model Group", data_key: "Model Group", type: "text" },
                { label: "Exterior Color", data_key: "exterior_color_name", type: "text" },
                { label: "Interior Color", data_key: "interior_color_name", type: "text" },
                { label: "Fuel Type", data_key: "fuel_name", type: "text" },
                { label: "Model Year", data_key: "model_year", type: "text" },
                { label: "MFG Date", data_key: "mf_year", type: "text" },
                { label: "Purchase Date", data_key: "purchase_date", type: "date" },
                { label: "Received Date", data_key: "recevied_date", type: "date" },
                { label: "Status", data_key: "vehicle_status_name", type: "custom", component: CellInventoryStatusBadge },
            ],
            actions: [
                { type: "view" },
                { type: "edit" }
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.USER_AVAILABLE_INVENTORY,
            columns: [
                { label: "Model", data_key: "model_name", type: "text" },
                { label: "Variant", data_key: "variant_name", type: "text" },
                // { label: "Model Group", data_key: "Model Group", type: "text" },
                { label: "Exterior Color", data_key: "exterior_color_name", type: "text" },
                { label: "Interior Color", data_key: "interior_color_name", type: "text" },
                { label: "Fuel Type", data_key: "fuel_name", type: "text" },
                { label: "State Name", data_key: "state_name", type: "text" },
                { label: "Showroom Name", data_key: "showroom_name", type: "text" },
                { label: "Ex Showroom Price", data_key: "ex_showroom_price", type: "custom", component: CellPriceDisplay },
                { label: "Tcs", data_key: "tcs", type: "text" },
                { label: "Insurance", data_key: "insurance", type: "text" },
                { label: "Registration Charges", data_key: "registration_charges", type: "text" },
                { label: "Logistic Handling", data_key: "logistic_handing", type: "text" },
                { label: "Vas Charge", data_key: "vas_charge", type: "text" },
                { label: "Extend Warranty", data_key: "extend_warrenty", type: "text" },
                { label: "Service Plan", data_key: "service_plan", type: "text" },
                { label: "Others", data_key: "others", type: "text" },
                { label: "Exchange Loyalty Bonus", data_key: "exchange_loyalty_bonus", type: "text" },
                { label: "Corporate Discount", data_key: "corporate_discount", type: "text" },
                { label: "Dealer Discount", data_key: "delear_discount", type: "text" },
                { label: "Referral Bonus", data_key: "referral_bonus", type: "text" },
                { label: "OEM Scheme", data_key: "oem_scheme", type: "text" },
                { label: "Discount Others", data_key: "discount_others", type: "text" },
                { label: "Status", data_key: "vehicle_status_name", type: "custom", component: CellInventoryStatusBadge },
            ],
            actions: [
                { type: "view" },
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.USER_INVENTORY_PRICE,
            columns: [
                { label: "Variant", data_key: "variant_name", type: "text" },
                { label: "Model", data_key: "model_name", type: "text" },
                { label: "MF Year", data_key: "mf_year", type: "text" },
                { label: "Model Year", data_key: "model_year", type: "text" },
                { label: "Ex Showroom Price", data_key: "ex_showroom_price", type: "custom", component: CellPriceDisplay },
                { label: "Tcs", data_key: "tcs", type: "text" },
                { label: "Insurance", data_key: "insurance", type: "text" },
                { label: "Registration Charges", data_key: "registration_charges", type: "text" },
                { label: "State Name", data_key: "state_name", type: "text" },
                { label: "Logistic Handling", data_key: "logistic_handing", type: "text" },
                { label: "Vas Charge", data_key: "vas_charge", type: "text" },
                { label: "Extend Warranty", data_key: "extend_warrenty", type: "text" },
                { label: "Service Plan", data_key: "service_plan", type: "text" },
                { label: "Others", data_key: "others", type: "text" },
                { label: "Exchange Loyalty Bonus", data_key: "exchange_loyalty_bonus", type: "text" },
                { label: "Corporate Discount", data_key: "corporate_discount", type: "text" },
                { label: "Dealer Discount", data_key: "delear_discount", type: "text" },
                { label: "Referral Bonus", data_key: "referral_bonus", type: "text" },
                { label: "OEM Scheme", data_key: "oem_scheme", type: "text" },
                { label: "Discount Others", data_key: "discount_others", type: "text" },
                // { label: "Status", data_key: "vehicle_status_name", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                { type: "view" },
                // { type: "edit" }
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.USER_ALLOTMENT_LIST,
            columns: [
                { label: "Model", data_key: "model_name", type: "text" },
                { label: "Variant", data_key: "variant_name", type: "text" },
                //{ label: "Model Year", data_key: "model_year", type: "text" },
                { label: "Ex Showroom Price", data_key: "ex_showroom_price", type: "custom", component: CellPriceDisplay },
                { label: "State Name", data_key: "state_name", type: "text" },
                // { label: "MF Year", data_key: "mf_year", type: "text" },
                // { label: "Tcs", data_key: "tcs", type: "text" },
                // { label: "Insurance", data_key: "insurance", type: "text" },
                // { label: "Registration Charges", data_key: "registration_charges", type: "text" },
                // { label: "Logistic Handling", data_key: "logistic_handing", type: "text" },
                // { label: "Vas Charge", data_key: "vas_charge", type: "text" },
                // { label: "Extend Warranty", data_key: "extend_warrenty", type: "text" },
                // { label: "Service Plan", data_key: "service_plan", type: "text" },
                // { label: "Others", data_key: "others", type: "text" },
                // { label: "Exchange Loyalty Bonus", data_key: "exchange_loyalty_bonus", type: "text" },
                // { label: "Corporate Discount", data_key: "corporate_discount", type: "text" },
                // { label: "Dealer Discount", data_key: "delear_discount", type: "text" },
                // { label: "Referral Bonus", data_key: "referral_bonus", type: "text" },
                // { label: "OEM Scheme", data_key: "oem_scheme", type: "text" },
                // { label: "Discount Others", data_key: "discount_others", type: "text" },
                // { label: "Status", data_key: "vehicle_status_name", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                { type: "custom", component: ActionAllotmentList },
                // { type: "edit" }
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_LOCATIONS,
            columns: [
                { label: "Location Name", data_key: "showroom_name", type: "text" },
                { label: "Location Code", data_key: "showroom_code", type: "text" },
                { label: "Location Address", data_key: "showroom_address", type: "text" },
                { label: "Location Type", data_key: "location_type", type: "text" },
                { label: "Status", data_key: "status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_MODEL,
            columns: [
                { label: "Model Name", data_key: "model_name", type: "text" },
                { label: "Model Code", data_key: "model_code", type: "text" },
                // { label: "Body Type", data_key: "body_type_uuid", type: "text" },
                { label: "Status", data_key: "model_status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "model_status"
        },
        {
            pageLink: PageLinks.DEALER_MODEL_VARIANT,
            columns: [
                { label: "Model Variant Name", data_key: "variant_name", type: "text" },
                { label: "Model Name", data_key: "model_name", type: "text" },
                { label: "Fuel Name", data_key: "fuel_name", type: "text" },
                { label: "Transmision Type", data_key: "transmission_type_name", type: "text" },
                // { label: "Fuel Type", data_key: "fuel_type_uuid", type: "text" },
                // { label: "Transmission Type", data_key: "transmission_type_uuid", type: "text" },
                { label: "Status", data_key: "variant_status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                { type: "edit" },
                { type: "copy" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "variant_status"
        },
        {
            pageLink: PageLinks.DEALER_COLOR,
            columns: [
                { label: "Color Name", data_key: "color_name", type: "text" },
                { label: "Status", data_key: "color_status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                //{ type: "edit" },
                // { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "color_status"
        },
        {
            pageLink: PageLinks.DEALER_FUEL_TYPE,
            columns: [
                { label: "Fuel Name", data_key: "fuel_name", type: "text" },
                { label: "Status", data_key: "fuel_status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "fuel_status"
        },
        {
            pageLink: PageLinks.DEALER_DEMO_CAR,
            columns: [
                { label: "TC Number & VIN Number", data_key: "car_tc_number", type: "custom", component: CellDCTcVinNumber },
                { label: "Showroom", data_key: "showroom_name", type: "text" },
                { label: "Model", data_key: "model_name", type: "text" },
                { label: "Variant", data_key: "variant_name", type: "text" },
                { label: "Fuel", data_key: "fuel_name", type: "text" },
                { label: "Color", data_key: "color_name", type: "text" },
                { label: "Transmission Type", data_key: "transmission_type_name", type: "text" },
                { label: "Manufacturing Year", data_key: "car_manufacture_year", type: "text" },
                { label: "Status", data_key: "status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_BODY_TYPE,
            columns: [
                { label: "Body Type Name", data_key: "body_type_name", type: "text" },
                { label: "Body Type Status", data_key: "body_type_status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "body_type_status"
        },
        {
            pageLink: PageLinks.DEALER_TRANSMISSION_TYPE,
            columns: [
                { label: "Transmission Type Name", data_key: "transmission_type_name", type: "text" },
                { label: "Transmission Type Status", data_key: "transmission_type_status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "transmission_type_status"
        },
        {
            pageLink: PageLinks.DEALER_INTERIOR_COLOR,
            columns: [
                { label: "Interior Color Name", data_key: "interior_color_name", type: "text" },
                { label: "Interior Color Status", data_key: "interior_color_status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "interior_color_status"
        },
        {
            pageLink: PageLinks.DEALER_EXTERIOR_COLOR,
            columns: [
                { label: "Exterior Color Name", data_key: "exterior_color_name", type: "text" },
                { label: "Exterior Color Status", data_key: "exterior_color_status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "exterior_color_status"
        },
        {
            pageLink: PageLinks.DEALER_ROLE,
            columns: [
                { label: "Role Name", data_key: "role_name", type: "text" },
                { label: "Hierarchy Level", data_key: "hierarchy_level", type: "text" },
                { label: "Role Status", data_key: "status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_TARGET_RULES,
            columns: [
                { label: "Target Stage Name", data_key: "target_stage_name ", type: "text" },
                // { label: "Showroom ID", data_key: "showroom_uuid", type: "text" },
                { label: "Showroom Name", data_key: "showroom_name", type: "text" },
                { label: "Rules", data_key: "rules", type: "text" },
                // { label: "", data_key: "status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                { type: "edit" },
                // { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_CATEGORY,
            columns: [
                { label: "Category Name", data_key: "category_name", type: "text" },
                { label: "Category Status", data_key: "status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_STATE,
            columns: [
                { label: "State Name", data_key: "state_name", type: "text" },
                { label: "State Status", data_key: "state_status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_CITY,
            columns: [
                { label: "State Name", data_key: "state_name", type: "text" },
                { label: "City Name", data_key: "city_name", type: "text" },
                { label: "City Status", data_key: "city_status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_PINCODE,
            columns: [
                { label: "State Name", data_key: "state_name", type: "text" },
                { label: "City Name", data_key: "city_name", type: "text" },
                { label: "Pincode", data_key: "pincode", type: "text" },
                { label: "Landmark", data_key: "landmark", type: "text" },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
        },
        {
            pageLink: PageLinks.DEALER_LEAD_CATEGORY,
            columns: [
                { label: "Lead Category Name", data_key: "lead_category_name", type: "text" },
                { label: "Lead Category Status", data_key: "lead_category_status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_CUSTOMER_TYPE,
            columns: [
                { label: "Customer Name", data_key: "customer_type_name", type: "text" },
                { label: "Customer Status", data_key: "customer_type_status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_LEAD_SOURCE,
            columns: [
                { label: "Lead Source Category Name", data_key: "lead_source_cat_name", type: "text" },
                { label: "Lead Source Name", data_key: "lead_source_name", type: "text" },
                { label: "Lead Source Status", data_key: "lead_source_status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_LEAD_SOURCE_CATEGORY,
            columns: [
                { label: "Lead Source Category Name", data_key: "lead_source_category_name", type: "text" },
                { label: "Lead Source Category Status", data_key: "status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_LEAD_STATUS,
            columns: [
                { label: "Lead Status Name", data_key: "lead_status_name", type: "text" },
                { label: "Lead Status Code", data_key: "lead_status_code", type: "text" },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid"
        },
        {
            pageLink: PageLinks.DEALER_INSURANCE_TYPE,
            columns: [
                { label: "Insurance Type Name", data_key: "insurance_type_name", type: "text" },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
        },
        {
            pageLink: PageLinks.DEALER_INSURANCE_COMPANIES,
            columns: [
                { label: "Insurance Company Name", data_key: "insurance_company_name", type: "text" },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
        },
        {
            pageLink: PageLinks.DEALER_LEAD_TYPE,
            columns: [
                { label: "Lead Type Name", data_key: "vehicle_lead_type_name", type: "text" },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
        },
        {
            pageLink: PageLinks.DEALER_LEAD_TASK,
            columns: [
                { label: "Lead Task Name", data_key: "lead_task_name", type: "text" },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
        },
        {
            pageLink: PageLinks.DEALER_LEAD_PROSPECT_TYPE,
            columns: [
                { label: "Prospect Type Name", data_key: "prospect_type_name", type: "text" },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
        },
        {
            pageLink: PageLinks.DEALER_LEAD_REASON,
            columns: [
                { label: "Task Name", data_key: "lead_task_name", type: "text" },
                { label: "Lead Reason Name", data_key: "lead_reason_name", type: "text" },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
        },
        {
            pageLink: PageLinks.DEALER_LEAD_TITLE,
            columns: [
                { label: "Lead Title", data_key: "lead_title", type: "text" },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
        },
        {
            pageLink: PageLinks.DEALER_PAYMENT_MODE,
            columns: [
                { label: "Payment Mode Name", data_key: "payment_mode_name", type: "text" },
            ],
            actions: [
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
        },
        {
            pageLink: PageLinks.DEALER_CATEGORY_TYPE,
            columns: [
                { label: "Name", data_key: "category_type_name", type: "text" },
                { label: "Status", data_key: "status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                //     { type: "view" },
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "status",
        },
        {
            pageLink: PageLinks.DEALER_TESTDRIVE,
            columns: [
                { label: "Customer", data_key: "customer_name", type: "custom", component: CellCustomerDetailWithEdit },
                { label: "Vehicle", data_key: "model_name", type: "custom", component: CellTDVehicleDetail },
                { label: "Address", data_key: "test_drive_address", type: "custom", component: CellTDAddress },
                { label: "Start KM", data_key: "starting_odometer_reading", type: "text" },
                { label: "End KM", data_key: "ending_odometer_reading", type: "text" },
                { label: "Date & Time", data_key: "test_drive_date_time", type: "custom", component: CellTDDateAndTime },
                { label: "Status", data_key: "status", type: "custom", component: CellOrderStatus },
            ],
            actions: [
                { type: "view" },
                { type: "custom", component: ActionTestDriveVerify },
                { type: "custom", component: ActionTestDriveCancel },
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_SHOWROOM_TRANSFER,
            columns: [
                { label: "Employee", data_key: "assign_to_name", type: "custom", component: CellTDEmployeeDetail },
                { label: "Vehicle", data_key: "model_name", type: "custom", component: CellTDVehicleDetail },
                { label: "From Showroom", data_key: "from_showroom_name", type: "text" },
                { label: "To Showroom", data_key: "to_showroom_name", type: "text" },
                { label: "Start KM", data_key: "starting_odometer_reading", type: "text" },
                { label: "End KM", data_key: "ending_odometer_reading", type: "text" },
                { label: "Date & Time", data_key: "test_drive_date_time", type: "custom", component: CellTDDateAndTime },
                { label: "Status", data_key: "status", type: "custom", component: CellOrderStatus },
            ],
            actions: [
                { type: "view" },
                { type: "custom", component: ActionTestDriveCancel },
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_FUEL_REFILL,
            columns: [
                { label: "Employee", data_key: "assign_to_name", type: "custom", component: CellTDEmployeeDetail },
                { label: "Vehicle", data_key: "model_name", type: "custom", component: CellTDVehicleDetail },
                { label: "Showroom", data_key: "showroom_name", type: "text" },
                { label: "Start KM", data_key: "starting_odometer_reading", type: "text" },
                { label: "Amount", data_key: "fuel_amount", type: "text" },
                { label: "Liter", data_key: "fuel_liter", type: "text" },
                { label: "Date & Time", data_key: "test_drive_date_time", type: "custom", component: CellTDDateAndTime },
                { label: "Status", data_key: "status", type: "custom", component: CellOrderStatus },
            ],
            actions: [
                { type: "view" },
                { type: "custom", component: ActionTestDriveCancel },
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_OTHER_TRIP,
            columns: [
                { label: "Employee", data_key: "assign_to_name", type: "custom", component: CellTDEmployeeDetail },
                { label: "Vehicle", data_key: "model_name", type: "custom", component: CellTDVehicleDetail },
                { label: "Showroom", data_key: "showroom_name", type: "text" },
                { label: "Start KM", data_key: "starting_odometer_reading", type: "text" },
                { label: "End KM", data_key: "ending_odometer_reading", type: "text" },
                { label: "Date & Time", data_key: "test_drive_date_time", type: "custom", component: CellTDDateAndTime },
                { label: "Status", data_key: "status", type: "custom", component: CellOrderStatus },
            ],
            actions: [
                { type: "view" },
                { type: "custom", component: ActionTestDriveCancel },
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_LEADS_TODAY,
            columns: [
                { label: "Customer", data_key: "customer_name", type: "custom", component: CellLeadCustomerDetail },
                { label: "City", data_key: "city_name", type: "text", },
                { label: "State", data_key: "state_name", type: "text", },
                { label: "Created By", data_key: "created_by", type: "text", },
                { label: "Model", data_key: "model_name", type: "text", },
            ],
            actions: [
                { type: "view" },
            ],
            primary_column: "lead_uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_LEADS_FUTURE,
            columns: [
                { label: "Customer", data_key: "customer_name", type: "custom", component: CellLeadCustomerDetail },
                { label: "City", data_key: "city_name", type: "text", },
                { label: "State", data_key: "state_name", type: "text", },
                { label: "Created By", data_key: "created_by", type: "text", },
                { label: "Model", data_key: "model_name", type: "text", },
            ],
            actions: [
                { type: "view" },
            ],
            primary_column: "lead_uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_LEADS_COMPLETE,
            columns: [
                { label: "Customer", data_key: "customer_name", type: "custom", component: CellLeadCustomerDetail },
                { label: "City", data_key: "city_name", type: "text", },
                { label: "State", data_key: "state_name", type: "text", },
                { label: "Created By", data_key: "created_by", type: "text", },
                { label: "Model", data_key: "model_name", type: "text", },
            ],
            actions: [
                { type: "view" },
            ],
            primary_column: "lead_uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_REPORT_TESTDRIVE,
            columns: [
                { label: "Customer", data_key: "customer_name", type: "custom", component: CellCustomerDetail },
                { label: "Vehicle", data_key: "vehicle_data", type: "custom", component: CellReportTDVehicleDetail },
                { label: "TL & Sales Consultant", data_key: "team_leader", type: "custom", component: CellTLSalesConsultant },
                { label: "DMS Lead Id", data_key: "dms_lead_id", type: "text" },
                { label: "Showroom Address", data_key: "showroom_location", type: "text" },
                { label: "Testdrive At", data_key: "testdrive_at", type: "text", },
                { label: "Date", data_key: "testdrive_date", type: "text", },
                { label: "ODO Meter Reading", data_key: "odometer_start_reading", type: "custom", component: CellOdoMeterReading },
                { label: "Start & End Time", data_key: "start_time", type: "custom", component: CellTDStartEndTime },
                // { label: "Start Time", data_key: "start_time", type: "text" },
                // { label: "End Time", data_key: "end_time", type: "text" },
                { label: "Status", data_key: "status", type: "text" },
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_REPORT_STOCKTRANSFER,
            columns: [
                { label: "Employee", data_key: "employee_name", type: "text" },
                { label: "Vehicle", data_key: "vehicle_data", type: "custom", component: CellReportTDVehicleDetail },
                { label: "Showroom", data_key: "showroom_name", type: "custom", component: CellTDShowroom },
                { label: "Date", data_key: "transfer_date", type: "text", },
                { label: "ODO Meter Reading", data_key: "odometer_start_reading", type: "custom", component: CellOdoMeterReading },
                { label: "Start & End Time", data_key: "start_time", type: "custom", component: CellTDStartEndTime },
                { label: "Status", data_key: "status", type: "text" },
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_REPORT_FUELREFILL,
            columns: [
                { label: "Employee", data_key: "employee_name", type: "text" },
                { label: "Vehicle", data_key: "vehicle_data", type: "custom", component: CellReportTDVehicleDetail },
                { label: "Showroom", data_key: "showroom_name", type: "text" },
                { label: "Date", data_key: "fuel_refill_date", type: "text", },
                { label: "ODO Meter Reading", data_key: "odometer_start_reading", type: "custom", component: CellOdoMeterReadingFuel },
                { label: "Refill Amount", data_key: "current_refill_amount", type: "custom", component: CellTDRefillAmt },
                { label: "No.Of Litres", data_key: "no_of_liters", type: "text", },
                { label: "Start & End Time", data_key: "start_time", type: "custom", component: CellTDStartEndTime },
                { label: "Status", data_key: "status", type: "text" },
            ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.DEALER_REPORT_OTHERTRIPS,
            columns: [
                { label: "Employee", data_key: "employee_name", type: "text" },
                { label: "Vehicle", data_key: "vehicle_data", type: "custom", component: CellReportTDVehicleDetail },
                { label: "Showroom", data_key: "showroom_name", type: "text" },
                { label: "Date", data_key: "trip_date", type: "text" },
                { label: "Purpose", data_key: "purpose_of_trip", type: "text" },
                { label: "ODO Meter Reading", data_key: "odometer_start_reading", type: "custom", component: CellOdoMeterReading },
                { label: "Start & End Time", data_key: "start_time", type: "custom", component: CellTDStartEndTime },
                { label: "Status", data_key: "status", type: "text" },
            ],
            primary_column: "uuid",
            status_column: "status"
        },











        {
            pageLink: PageLinks.COMPANY_USERS,
            columns: [
                { label: "Contact Number", data_key: "contact_phone", type: "text" },
                { label: "Contact Email", data_key: "contact_email", type: "text" },
                { label: "Dealer Name", data_key: "dealer_name", type: "text" },
                { label: "Dealer Code", data_key: "dealer_code", type: "text" },
            ],
            // actions: [
            //     { type: "view" },
            //     { type: "edit" }
            //     { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            // ],
            primary_column: "uuid"
        },
        {
            pageLink: PageLinks.COMPANY_DEALERS,
            columns: [
                { label: "Name", data_key: "name", type: "text" },
                { label: "Code", data_key: "code", type: "text" },
                { label: "Contact Number", data_key: "contact_phone", type: "text" },
                { label: "Contact Email", data_key: "contact_email", type: "text" },
                { label: "Status", data_key: "status", type: "custom", component: CellStatusUpdateBadge },
            ],
            actions: [
                //{ type: "view" },
                { type: "edit" },
                //{ type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "code",
            status_column: "status"
        },

















        {
            pageLink: PageLinks.ADMIN_COMPANIES,
            columns: [
                { label: "Name", data_key: "company_name", type: "text" },
                { label: "Code", data_key: "company_code", type: "text" },
                { label: "Contact Number", data_key: "contact_phone", type: "text" },
                { label: "Contact Email", data_key: "contact_email", type: "text" },
                { label: "URL Context Path", data_key: "url_context_path", type: "text" },
                { label: "Status", data_key: "status", type: "custom", component: CellStatusUpdateBadge },
            ],
            actions: [
                //     { type: "view" },
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "status",
        },
        {
            pageLink: PageLinks.ADMIN_DEALERS,
            columns: [
                { label: "Name", data_key: "name", type: "text" },
                { label: "Code", data_key: "code", type: "text" },
                { label: "Contact Number", data_key: "contact_phone", type: "text" },
                { label: "Contact Email", data_key: "contact_email", type: "text" },
                { label: "Status", data_key: "status", type: "custom", component: CellStatusUpdateBadge },
            ],
            // actions: [
            //     { type: "view" },
            //     { type: "edit" },
            //     { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            // ],
            primary_column: "code",
            status_column: "status",
        },
        {
            pageLink: PageLinks.ADMIN_MENUS,
            columns: [
                { label: "Name", data_key: "name", type: "text" },
                { label: "Code", data_key: "code", type: "text" },
                { label: "Status", data_key: "status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                // { type: "view" },
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "status",
        },
        {
            pageLink: PageLinks.ADMIN_PROVIDERS,
            columns: [
                { label: "Name", data_key: "provider_name", type: "text" },
                { label: "Status", data_key: "status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                //     { type: "view" },
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "status",
        },
        {
            pageLink: PageLinks.ADMIN_PROVIDER_CATEGORY,
            columns: [
                { label: "Name", data_key: "provider_category_name", type: "text" },
                { label: "Status", data_key: "status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                //     { type: "view" },
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "status",
        },
        {
            pageLink: PageLinks.ADMIN_BUSINESS,
            columns: [
                { label: "Name", data_key: "business_name", type: "text" },
                { label: "Status", data_key: "status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                //     { type: "view" },
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "status",
        },
        {
            pageLink: PageLinks.ADMIN_BUSINESS_MODE,
            columns: [
                { label: "Name", data_key: "business_mode_name", type: "text" },
                { label: "Status", data_key: "status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                //     { type: "view" },
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "status",
        },
        {
            pageLink: PageLinks.ADMIN_CATEGORY,
            columns: [
                { label: "Name", data_key: "category_name", type: "text" },
                { label: "Status", data_key: "status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                //     { type: "view" },
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "status",
        },
        {
            pageLink: PageLinks.ADMIN_CATEGORY_TYPE,
            columns: [
                { label: "Name", data_key: "category_type_name", type: "text" },
                { label: "Status", data_key: "status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                //     { type: "view" },
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "status",
        },
        {
            pageLink: PageLinks.ADMIN_VERSION,
            columns: [
                { label: "Version", data_key: "version", type: "text" },
                { label: "Status", data_key: "status", type: "custom", component: CellStatusBadge },
            ],
            actions: [
                //     { type: "view" },
                { type: "edit" },
                { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            ],
            primary_column: "uuid",
            status_column: "status",
        },

        {
            pageLink: PageLinks.USER_DASHBOARD_TODAY,
            columns: [
                { label: "Employee", data_key: "contact_phone", type: "text" },
                { label: "Call", data_key: "contact_email", type: "text" },
                { label: "Test Drive", data_key: "dealer_name", type: "text" },
                { label: "Visits", data_key: "dealer_code", type: "text" },
            ],
            // actions: [
            //     { type: "view" },
            //     { type: "edit" }
            //     { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            // ],
            primary_column: "uuid",
            status_column: "status"
        },
        {
            pageLink: PageLinks.USER_REPORT,
            columns: [
                { label: "Export Type", data_key: "export_type", type: "text" },
                { label: "From Date", data_key: "from_date", type: "custom", component: CellUserNestedFromDate },
                { label: "To Date", data_key: "to_date", type: "custom", component: CellUserNestedToDate },
                { label: "Status", data_key: "status", type: "text" },
            ],
            // actions: [
            //     { type: "view" },
            //     { type: "edit" }
            //     { type: "delete", params: [{ param_key: "uuid", data_key: "uuid" }] }
            // ],
            primary_column: "uuid",
            status_column: "status",
        },
        {
            pageLink: PageLinks.USER_HELP_SUPPORT,
            columns: [
                { label: "Issue", data_key: "issue", type: "text" },
                { label: "Status", data_key: "status_value", type: "text" },
                // { label: "Update", data_key: "uuid", type: "custom", component: ListButtonUpdateStatus },
            ],
            actions: [
                { type: "view" },
                { type: "mark" },
            ],
            primary_column: "uuid",
            status_column: "status",
        },
        {
            pageLink: PageLinks.USER_TESTDRIVE,
            columns: [
                { label: "Customer Name", data_key: "customer_name", type: "text" },
                { label: "Mobile Number", data_key: "mobile_number", type: "text" },
                { label: "Showroom Name", data_key: "showroom_name", type: "custom", component: CellNADisplay },
                { label: "Model", data_key: "model_name", type: "custom", component: CellNADisplay },
                { label: "Variant", data_key: "variant_name", type: "custom", component: CellNADisplay },
                { label: "Home Address", data_key: "test_drive_address", type: "custom", component: CellNADisplay },
                { label: "Testdrive Time", data_key: "test_drive_date_time", type: "custom", component: CellTDDateAndTime },
                { label: "Status", data_key: "status", type: "custom", component: CellTestdriveStatus },
            ],
            actions: [
                { type: "view" },
                { type: "custom", component: ActionTestDriveCancel }
            ],
            primary_column: "uuid",
        },
        {
            pageLink: PageLinks.USER_TESTDRIVE_TRANSFER,
            columns: [
                { label: "From Showroom", data_key: "from_showroom_name", type: "custom", component: CellNADisplay },
                { label: "To Showroom", data_key: "to_showroom_name", type: "custom", component: CellNADisplay },
                { label: "Model", data_key: "model_name", type: "custom", component: CellNADisplay },
                { label: "Variant", data_key: "variant_name", type: "custom", component: CellNADisplay },
                { label: "Testdrive Time", data_key: "test_drive_date_time", type: "custom", component: CellTDDateAndTime },
                { label: "Status", data_key: "status", type: "custom", component: CellTestdriveStatus },
            ],
            actions: [
                { type: "view" },
                { type: "custom", component: ActionTestDriveCancel }
            ],
            primary_column: "uuid",
        },
        {
            pageLink: PageLinks.USER_TESTDRIVE_REFILL,
            columns: [
                { label: "From Showroom", data_key: "showroom_name", type: "custom", component: CellNADisplay },
                { label: "Model", data_key: "model_name", type: "custom", component: CellNADisplay },
                { label: "Variant", data_key: "variant_name", type: "custom", component: CellNADisplay },
                { label: "Testdrive Time", data_key: "test_drive_date_time", type: "custom", component: CellTDDateAndTime },
                { label: "Status", data_key: "status", type: "custom", component: CellTestdriveStatus },
            ],
            actions: [
                { type: "view" },
                { type: "custom", component: ActionTestDriveCancel }
            ],
            primary_column: "uuid",
        },
        {
            pageLink: PageLinks.USER_TESTDRIVE_OTHERS,
            columns: [
                { label: "Showroom Name", data_key: "showroom_name", type: "custom", component: CellNADisplay },
                { label: "Model", data_key: "model_name", type: "custom", component: CellNADisplay },
                { label: "Variant", data_key: "variant_name", type: "custom", component: CellNADisplay },
                { label: "Testdrive Time", data_key: "test_drive_date_time", type: "custom", component: CellTDDateAndTime },
                { label: "Status", data_key: "status", type: "custom", component: CellTestdriveStatus },
            ],
            actions: [
                { type: "view" },
                // { type: "delete", params: [{ param_key: "test_drive_uuid", data_key: "uuid" }, { param_key: "remarks", data_key: "remarks" }] }
                { type: "custom", component: ActionTestDriveCancel }
            ],
            primary_column: "uuid",
        },
    ]

    export const getPageTableDetail = (pageLink: string): TableDetail => {
        const tableDetail = tableList.find(table => {
            return table.pageLink === pageLink;
        });
        return tableDetail ? tableDetail : { pageLink: '', columns: [], primary_column: '' };
    }

    export interface MappingDetail {
        ID: number,
        mappingName: string,
    }

    const StatusList: MappingDetail[] = [
        { ID: 1, mappingName: 'Active', },
        { ID: 0, mappingName: 'InActive', },
    ]

    const TestDriveStatusList: MappingDetail[] = [
        { ID: 1, mappingName: 'Scheduled', },
        { ID: 2, mappingName: 'Ongoing', },
        { ID: 3, mappingName: 'Completed', },
    ]

    export const getMappedData = (pageLink: string, column_data_key: string, dataID: number, data?: any) => {
        let itemDetail: MappingDetail | undefined = undefined
        if ((pageLink === PageLinks.DEALER_TESTDRIVE || pageLink === PageLinks.DEALER_SHOWROOM_TRANSFER || pageLink === PageLinks.DEALER_FUEL_REFILL || pageLink === PageLinks.DEALER_OTHER_TRIP) && column_data_key === 'status') {
            itemDetail = TestDriveStatusList.find(item => {
                return item.ID === dataID;
            });
        } else if (column_data_key === 'status' || column_data_key.endsWith('_status')) {
            itemDetail = StatusList.find(item => {
                return item.ID === dataID;
            });
        }

        return itemDetail ? itemDetail : { ID: 0, mappingName: '' };
    }
}